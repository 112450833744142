

import * as React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import gift from "../../assets/images/giftanimation.png"
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:"10px",
    p: 4,
  };

const RunsBackModal = ({setOpenRunsModal,handleCloseRunModal,runsBack}) => {

    return (
        <>
            <Modal
            open={setOpenRunsModal}
            onClose={handleCloseRunModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <div style={{textAlign:"center"}}>
                    <img src={gift} width={170}/>
                    <p style={{color:"#313335", textTransform:"uppercase", fontWeight:"bold", fontSize:"23px"}}>WAY TO GO!</p>

                    <p style={{color:"#F37121",fontWeight:"bold", fontSize:"19px", marginTop:"10px",marginBottom:"20px"}}>
                    You've reclaimed {runsBack}% of your run
                    </p>
                    <button style={{backgroundColor:"#0C5BA0", border:"none", padding:"15px 30px", color:"white", width:"300px", borderRadius:"10px",cursor:"pointer"}}>
                        <a href='/pitch' style={{color:"white",textDecoration:"none",fontSize:"17px"}}>
                            Go to pitch page
                        </a>
                    </button>
                </div>
            </Box>
            </Modal>

        </>
    )
}
export default RunsBackModal