import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  overflowY: "auto",
};

const InviteModal = ({ open, handleClose, invites, onGroupSelect }) => {
  const [invited, setInvited] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedGroup, setExpandedGroup] = useState(null);
  // console.log(invites);

  useEffect(() => {
    if (invites && invites.invites) {
      setInvited(invites.invites);
      setIsLoading(false);
    }
  }, [invites]);

  const hasInvites = invited.length > 0;

  if (!open || isLoading) {
    return null;
  }

  const handleToggleExpand = (groupId, event) => {
    event.stopPropagation();
    setExpandedGroup(expandedGroup === groupId ? null : groupId);
  };

  const handleGroupClick = (groupId) => {
    onGroupSelect(groupId);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="invite-modal-title"
      aria-describedby="invite-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" id="invite-modal-title" sx={{ mb: 2 }}>
          Group Invitations
        </Typography>
        {hasInvites ? (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {invited.map((invite) => (
              <React.Fragment key={invite.group.id}>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => handleGroupClick(invite.group.id)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { bgcolor: "action.hover" },
                    mb: 1,
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: 2,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={invite.group.groupProfile}
                      alt={invite.group.name}
                      sx={{ width: 60, height: 60 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={invite.group.name}
                    secondary={
                      <React.Fragment>
                        {/* <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {invite.group.description}
                        </Typography> */}
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.secondary"
                          sx={{ cursor: "pointer", ml: 1 }}
                          onClick={(e) =>
                            handleToggleExpand(invite.group.id, e)
                          }
                        >
                          - Invited By: {invite.invitedBy.length}
                          {expandedGroup === invite.group.id ? (
                            <ExpandLessIcon fontSize="small" />
                          ) : (
                            <ExpandMoreIcon fontSize="small" />
                          )}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Collapse
                  in={expandedGroup === invite.group.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {invite.invitedBy.map((inviter, index) => (
                      <ListItem key={index} sx={{ pl: 4 }}>
                        <ListItemAvatar>
                          <Avatar
                            src={inviter.profilePhoto}
                            alt={`${inviter.firstName} ${inviter.lastName}`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${inviter.firstName} ${inviter.lastName}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography>No invitations available.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default InviteModal;
