import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ReactTimeAgo from "react-time-ago";
import { Divider, Avatar, Grid, Paper } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss";
import { FaUserCircle } from "react-icons/fa";
import SendIcon from "@mui/icons-material/Send";
import { MentionsInput, Mention } from "react-mentions";
import { IconButton } from "@mui/material";
import { textFieldClasses } from "@mui/material";
import "./index.css";
import axios from "axios";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import {
  getNestedCommentCount,
  getNestedCommentCountPitch,
} from "./commentService";
import { useDispatch, useSelector } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import NestedComments from "./NestedComments";
import DropdownMenu from "../dropdown-menu/dropdownMenu";
function CommentBox(props) {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const {
    comment,
    setIsNestedComment,
    setNestedCommentData,
    commentCreated,
    nId,
    type,
    setRecentNestedComment,
    recentNestedComment,
  } = props;
  const navigate = useNavigate();
  const [readMore, setReadMore] = useState(false);
  const [id, setId] = useState();
  const [nestedVisible, setNestedVisible] = useState(false);
  const [showRecent, setShowRecent] = useState(false);
  const [hideShowReply, setHideShowReply] = useState(false);
  const [noData, setNoData] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [replyCount, setReplyCount] = useState(comment?.replyCount);
  const [pagePerSize, setPagePerSize] = useState(2);
  const [nestedComments, setNestedComments] = useState([]);
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [count, setCount] = useState(0);
  const [giphyUrl, setGiphyUrl] = useState("");
  const shouldLoad = useSelector((state) => state.path.loadNestedCmts);
  const handleShowMoreComments = () => {
    setRecentNestedComment("");
    setPageNumber((pages) => pages + 1);
    // dispatch(PathAction.handleReloadComments({ payload:false }));
  };

  const handleShowLessComments = () => {
    setHideShowReply(false);
    setRecentNestedComment("");
    setNestedVisible(false);
    setPageNumber(1);
    setNestedComments([]);
  };

  const handleData = (
    postId,
    parentCommentId,
    isChildComment,
    _id,
    mUserName,
    mUserId
  ) => {
    // setReplyUser({ id: mUserId, display: `@${mUserName}` })
    // setComments(`@${mUserName}`)
    console.log(
      "postId, parentCommentId, isChildComment, _id, mUserName, mUserId",
      postId,
      parentCommentId,
      isChildComment,
      _id,
      mUserName,
      mUserId
    );
    setIsNestedComment();
    setNestedCommentData({
      pitchId: postId,
      parentCommentId:
        parentCommentId === null && parentCommentId !== ""
          ? _id
          : parentCommentId,
      parentCommentUserId: mUserId,
    });
  };

  const getNestedComments = (pitchId, commentId) => {
    setRecentNestedComment("");
    setHideShowReply(true);
    // if (nestedComments.length === 0) {
    //   setPageNumber(1)
    // }
    let url;
    if (!pitchId && !comment?.isChildComment) return;
    if (type === "shareJob") {
      url =
        global.config.ROOTURL.prod +
        `/job/sharejob/comment/getNestedCommentsById/${pitchId}/${commentId}/${pageNumber}/${pagePerSize}`;
    } else {
      url =
        global.config.ROOTURL.prod +
        `/pitch/comment/getNestedComment/${pitchId}/${commentId}/${pageNumber}/${pagePerSize}`;
    }
    const submitComment = {
      method: "GET",
      url: url,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(submitComment)
      .then((response) => {
        setNestedComments([...nestedComments, ...response?.data]);
        setNestedVisible(true);
        if (response.data.length === 0 || response.data.length < pagePerSize) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const countWords = (desc) => {
    // Split the description into an array of words
    const words = desc.split(" ");

    // Initialize a new string and a counter variable
    let wordCount = 0;

    // Iterate over the array of words
    for (const word of words) {
      wordCount++;
    }

    //Setting up the word count
    setCount(wordCount);
  };

  function Description(desc) {
    // Split the description into an array of words
    const words = desc.split(" ");

    // Initialize a new string and a counter variable
    let truncatedDescription = "";
    let wordCount = 0;

    // Iterate over the array of words
    for (const word of words) {
      // Add the word to the new string
      truncatedDescription += word + " ";
      wordCount++;

      // If the counter has reached 150, break out of the loop
      if (wordCount >= 30) {
        break;
      }
    }

    // Truncate the string to remove any excess words
    truncatedDescription = truncatedDescription.trim();

    setShortDescription(truncatedDescription);
  }
  const getReplyCount = () => {
    if (type === "shareJob") {
      getNestedCommentCount(comment?.shareJobId, comment._id)
        .then((response) => {
          console.log(response.data);
          setReplyCount(response.data);

          setNoData(response.data > nestedComments.length ? false : true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getNestedCommentCountPitch(comment?.postId, comment._id)
        .then((response) => {
          console.log(response.data);
          setReplyCount(response.data);

          setNoData(response.data > nestedComments.length ? false : true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    setDescription(props.comment.text);
    Description(props.comment.text);
    countWords(props.comment.text);
    if (replyCount === 0) getReplyCount();
  }, []);
  useEffect(() => {
    if (pageNumber > 1)
      if (type === "shareJob")
        getNestedComments(comment?.shareJobId, comment._id);
      else getNestedComments(comment?.postId, comment._id);
  }, [pageNumber]);

  // useEffect(() => {
  //   const commenttype=comment?.shareJobId
  //   getNestedComments(comment?.shareJobId?);
  // }, [shouldLoad]);
  useEffect(() => {
    let text = comment.text;
    if (text.includes("giphy.com/media/")) {
      // Extract the giphy URL from the comment text
      const url = text
        .split(" ")
        .find((word) => word.includes("giphy.com/media/"));
      setGiphyUrl(url);
      // comment.text = "";
      // Modify the comment text to remove the Giphy URL
      const newText = text.replace(url, "");
      setDescription(newText);
      Description(newText);
      comment.text = newText;
    }
  }, [comment]);

  useEffect(() => {
    if (nId === comment?._id) {
      console.log("getReplyCount");
      getReplyCount();
      setShowRecent(true);
    }
  }, [commentCreated, nId]);

  const [postData, setPostData] = useState(null)

  useEffect(async ()=>{
    const getPost = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/pitch/pitchById/${comment.postId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios(getPost)
      .then((response)=>{
        console.log(response)
        setPostData(response.data)
      })
  },[])

  return (
    <div className="App ">
      {/* <h1>Comments</h1> */}
      <Grid container wrap="nowrap" spacing={2} style={{ margin: "0px" }}>
        <div  className="comment-image-wrapper">
        <Grid item className="comment-image">
          {comment.userProfilePhoto ? (
            <img
              alt=""
              src={comment.userProfilePhoto}
              className="comment-image"
            />
          ) : (
            <FaUserCircle className="comments-user-icon" />
          )}

          {nestedComments && nestedComments.length > 0 && (
            <div className="comments-connector"></div>
          )}
          </Grid>
        </div>
        <div className="width__comment__box">
          <Grid
            justifyContent="left"
            item
            xs
            zeroMinWidth
            className="border__comment"
          >
            <div className="comment-name">
              {/* <p
                style={{ margin: 0, textAlign: "left" }}
                onClick={() => navigate(`/profile/${comment.userId}`)}
              >
                {comment.userName}
              </p> */}
              <p style={{paddingTop: "10px"}}>
                  <Link style={{ margin: 0, textAlign: "left", color: "#1a1919", fontWeight: "600", fontFamily: "Inter", textDecoration: "none" }} to={`/profile/${comment.userId}`}>
                    {comment.userName}
                  </Link>
                </p>
              {userId === postData?.userId && (
                <DropdownMenu
                  isCommentDelete={true}
                  type={"post-menu"}
                  userId={userId}
                  deleteCommentId={comment._id}
                  postId={comment?.postId}
                  shareJObId={comment?.shareJobId}
                />
              )}
            </div>
            <p className="comment-time" style={{ fontWeight: "300" }}>
              {comment.createdDate && (
                <ReactTimeAgo
                  date={Date.parse(comment.createdDate)}
                  locale="en-US"
                />
              )}
            </p>
            {giphyUrl && (
              <img src={giphyUrl} alt="Giphy" className="react-giphy__giphs" />
            )}
            {count < 50 ? (
              // <p className="comment-text">{comment.text} </p>
              <div>
                {" "}
                <p
                  className="comment-text"
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/\n\r?/g, "<br />"),
                  }}
                />
              </div>
            ) : !readMore ? (
              <div className="comment-text">
                <p
                  className="comment-text"
                  dangerouslySetInnerHTML={{
                    __html: shortDescription.replace(/\n\r?/g, "<br />"),
                  }}
                />
                <span
                  className="read-more"
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                >
                  read more
                </span>
                ...{" "}
              </div>
            ) : (
              <div>
                <p
                  className="comment-text"
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/\n\r?/g, "<br /> "),
                  }}
                />
                <span
                  className="read-more"
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                >
                  show less
                </span>
              </div>
            )}
          </Grid>

          <div
            style={{
              display: "flex",
              height: "28px",
              justifyContent: !hideShowReply ? "space-between" : "flex-end",
            }}
          >
            {!hideShowReply && (
              <>
                {replyCount === 1 ? (
                  <div
                    style={{
                      cursor: "pointer",
                      color: "#0C5BA0",
                      marginLeft: "4px",
                    }}
                    onClick={() => {
                      !nestedVisible &&
                        getNestedComments(
                          type === "shareJob"
                            ? comment?.shareJobId
                            : comment?.postId,
                          comment._id
                        );
                    }}
                  >
                    show {replyCount} reply
                  </div>
                ) : replyCount > 1 ? (
                  <div
                    onClick={() => {
                      !nestedVisible &&
                        getNestedComments(
                          type === "shareJob"
                            ? comment?.shareJobId
                            : comment?.postId,
                          comment._id
                        );
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#0C5BA0",
                      marginLeft: "4px",
                    }}
                  >
                    show {replyCount} replies
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#0C5BA0",
                      marginLeft: "4px",
                    }}
                  >
                    0 reply
                  </div>
                )}
              </>
            )}

            <div
              onClick={() => {
                handleData(
                  type === "shareJob" ? comment?.shareJobId : comment?.postId,
                  comment?.parentCommentId,
                  comment?.isChildComment,
                  comment?._id,
                  comment?.userName,
                  comment?.userId
                );
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9989 9.50019H7.40892L8.70892 8.21019C8.89722 8.02188 9.00301 7.76649 9.00301 7.50019C9.00301 7.23388 8.89722 6.97849 8.70892 6.79019C8.52062 6.60188 8.26522 6.49609 7.99892 6.49609C7.73262 6.49609 7.47722 6.60188 7.28892 6.79019L4.28892 9.79019C4.19788 9.88529 4.12651 9.99743 4.07892 10.1202C3.9789 10.3636 3.9789 10.6367 4.07892 10.8802C4.12651 11.0029 4.19788 11.1151 4.28892 11.2102L7.28892 14.2102C7.38188 14.3039 7.49248 14.3783 7.61434 14.4291C7.7362 14.4798 7.86691 14.506 7.99892 14.506C8.13093 14.506 8.26164 14.4798 8.3835 14.4291C8.50536 14.3783 8.61596 14.3039 8.70892 14.2102C8.80265 14.1172 8.87704 14.0066 8.92781 13.8848C8.97858 13.7629 9.00472 13.6322 9.00472 13.5002C9.00472 13.3682 8.97858 13.2375 8.92781 13.1156C8.87704 12.9937 8.80265 12.8831 8.70892 12.7902L7.40892 11.5002H16.9989C17.2641 11.5002 17.5185 11.6055 17.706 11.7931C17.8936 11.9806 17.9989 12.235 17.9989 12.5002V16.5002C17.9989 16.7654 18.1043 17.0198 18.2918 17.2073C18.4794 17.3948 18.7337 17.5002 18.9989 17.5002C19.2641 17.5002 19.5185 17.3948 19.706 17.2073C19.8936 17.0198 19.9989 16.7654 19.9989 16.5002V12.5002C19.9989 11.7045 19.6829 10.9415 19.1202 10.3789C18.5576 9.81626 17.7946 9.50019 16.9989 9.50019Z"
                  fill="#B7CFE3"
                />
              </svg>
            </div>
          </div>
        </div>
      </Grid>
      {/* nestedComments */}
      <div>
        {replyCount === 1 &&
          comment._id === recentNestedComment?.parentCommentId &&
          showRecent &&
          recentNestedComment && (
            <NestedComments
              nComment={recentNestedComment}
              handleData={handleData}
              comment={comment}
              type={type}
            />
          )}
        {nestedComments &&
          nestedComments.length > 0 &&
          nestedComments.map(
            (nComment, index) =>
              nComment?.parentCommentId === comment?._id &&
              nComment?.isChildComment && (
                <div key={nComment?._id} style={{ position: "relative" }}>
                  <div
                    className="comment-connector"
                    style={{ zIndex: "1" }}
                  ></div>
                  <NestedComments
                    key={nComment?._id}
                    nComment={nComment}
                    handleData={handleData}
                    comment={comment}
                    type={type}
                  />
                </div>
              )
          )}

        {replyCount > 1 &&
          comment._id === recentNestedComment?.parentCommentId &&
          showRecent &&
          recentNestedComment && (
            <NestedComments
              nComment={recentNestedComment}
              handleData={handleData}
              comment={comment}
              type={type}
            />
          )}
        {nestedVisible && !noData && (
          <div
            onClick={() => handleShowMoreComments()}
            style={{ cursor: "pointer", marginLeft: "78px", fontSize: "12px" }}
          >
            show more
          </div>
        )}

        {nestedVisible && noData && (
          <div
            onClick={() => handleShowLessComments()}
            style={{ cursor: "pointer", marginLeft: "78px", fontSize: "12px" }}
          >
            hide reply
          </div>
        )}
      </div>
    </div>
  );
}

export default CommentBox;
