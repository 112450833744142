import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Backdrop,
  Typography,
  Box,
  Fade,
} from "@mui/material";
import Card from "@mui/material/Card";

import TextField from "@mui/material/TextField";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import "./index.scss";
// React-Toastifyimport
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardHeader } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import NameCard from "../../commons/components/share-modal/name-card";
import Loader from "../../commons/components/Loader";
import { ThreeDots } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DefaultRunScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [defaultRuns, setDefaultRuns] = useState([]);
  const [editRun, setEditRun] = useState("");
  const [name, setName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = () => {
    if(!accessToken) return;
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/defaultrun/getAllruns`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    setIsLoading(true);
    axios(options)
      .then(({ data }) => {
        console.log(data);
        setDefaultRuns([...data]);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
          setError(true);
          setIsLoading(false);
        }
      });
  };
  const handleSubmit = () => {
    if (submitIsLoading || isLoading) return;
    if (!name || !editRun) return window.alert("Please enter proper details");
    if (editRun >= 1000) {
      if (!window.confirm(`Do you really want to change ${name} to ${editRun} runs?`))
        return;
    }

    const options = {
      method: "post",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/defaultrun/updateRunsByName`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: { name: name, run: editRun },
    };
    setSubmitIsLoading(true);
    axios(options)
      .then(({ data }) => {
        console.log(data);
        setModalOpen(false);
        fetchData();
        setSubmitIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
          setSubmitIsLoading(false);
        }
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const MoreOptions = ["change"];

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <div>
      <Grid
        className="pagination"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      ></Grid>
      {isLoading ? (
        <Loader isLoading={isLoading} error={error} />
      ) : (
        <div className="card-wrapper">
          <Modal
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
              setName("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Change {name}
              </Typography>

              <TextField
                id="outlined-controlled"
                value={editRun}
                onChange={(e) => {
                  setEditRun(e.target.value);
                }}
                type={"number"}
                min="0"
              />
              <Button
                onClick={handleSubmit}
                className="edit-run-btn"
                variant="contained"
              >
                {submitIsLoading ? (
                  <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="#ffffff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Change"
                )}
              </Button>
            </Box>
          </Modal>
          {defaultRuns.map((run) => {
            return (
              <Card
                display="flex"
                className="card-main-default-user"
                key={run.name}
                onClick={() => {
                  // navigate(`/admin/deal/${users._id}`);
                  console.log(run);
                  setName(run.name);
                  setEditRun(run.run);
                  setModalOpen(true);
                }}
              >
                <CardHeader
                  action={
                    <IconButton
                      aria-label="more"
                      onClick={handleMoreClick}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                    ></IconButton>
                  }
                  title={run.name}
                  subheader={run.run}
                ></CardHeader>
                {run.desc && (
                  <CardContent className="content-default-user">
                    {run.desc}
                  </CardContent>
                )}
              </Card>
            );
          })}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default DefaultRunScroll;
