import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./index.scss";
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import { useNavigate, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import { TailSpin } from "react-loader-spinner";

// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { display } from "@mui/system";

const EventRegistration = () => {
  const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);

  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const { event_id } = useParams();
  if (!event_id) {
    navigate("/all-events");
  }

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    email: "",
  });

  // Find event details
  const [event, setEvent] = useState({});
  const [imageIsUploading, setImageIsUploading] = useState(false);
  const fetchUserInfo = () => {
    if (!accessToken && !userId) return
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        // console.log(response.data);
        setUserInfo(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const fetchEventDetails = () => {
    if (!accessToken && !event_id) return
    const eventOptions = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/events/getEventById/${event_id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(eventOptions)
      .then(({ data }) => {
        setEvent({ ...data });
        // console.log(event);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Not authorized");
          navigate("/all-events");
        }
        console.log(error);
        console.log("No such event found");
        navigate("/all-events");
      });
  };

  useEffect(() => {
    fetchUserInfo();
    fetchEventDetails();
    // Checking it the user already submitted the form
  }, []);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const dobRef = useRef();
  const cityRef = useRef();
  const idRef = useRef();
  const [id, setId] = useState();
  const [file, setFile] = useState();
  const fileRef = useRef();
  const heightRef = useRef();
  const weightRef = useRef();
  const [club, setClub] = useState(null);
  const [coaching, setCoaching] = useState(null);
  const [batter, setbatter] = useState(false);
  const [batterLH, setbatterLH] = useState(false);
  const [batterRH, setbatterRH] = useState(false);
  const [bowlerPace, setbowlerPace] = useState(false);
  const [bowlerPaceLH, setbowlerPaceLH] = useState(false);
  const [bowlerPaceRH, setbowlerPaceRH] = useState(false);
  const [bowlerSpin, setbowlerSpin] = useState(false);
  const [bowlerSpinLH, setbowlerSpinLH] = useState(false);
  const [bowlerSpinRH, setbowlerSpinRH] = useState(false);
  const [wckBatter, setwckBatter] = useState(false);
  const [wckBatterLH, setwckBatterLH] = useState(false);
  const [wckBatterRH, setwckBatterRH] = useState(false);
  const [allrounderPace, setallrounderPace] = useState(false);
  const [aPLHBatter, setaPLHBatter] = useState(false);
  const [aPRHBatter, setaPRHBatter] = useState(false);
  const [aPLHBowler, setaPLHBowler] = useState(false);
  const [aPRHBowler, setaPRHBowler] = useState(false);
  const [allrounderSpin, setallrounderSpin] = useState(false);
  const [aSLHBatter, setaSLHBatter] = useState(false);
  const [aSRHBatter, setaSRHBatter] = useState(false);
  const [aSLHBowler, setaSLHBowler] = useState(false);
  const [aSRHBowler, setaSRHBowler] = useState(false);
  const handleClub = (event) => {
    setClub(event.target.value);
  };

  const handleCoaching = (event) => {
    setCoaching(event.target.value);
  };

  // const dateCheck = (age) => {
  //   var dob = new Date(age);
  //   //calculate month difference from current date in time
  //   var month_diff = Date.now() - dob.getTime();

  //   //convert the calculated difference in date format
  //   var age_dt = new Date(month_diff);

  //   //extract year from date
  //   var year = age_dt.getUTCFullYear();

  //   //now calculate the age of the user
  //   var age = Math.abs(year - 1970);

  //   return age;
  // };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const [selectedCategory, setSelectedCategory] = useState([
    {
      categoryName: "",
      isChecked: true,
      subCategory: [],
    },
  ]);

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    console.log("This is upload img function call:");
    axios
      .post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        if (response.data) {
          console.log("Image saved successfully");
          setImageIsUploading(false);
          setId(response.data[0].location);
        } else {
          console.log("error occured:");
          return;
        }
      })
      .catch((error) => {
        setImageIsUploading(false);
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    setImageIsUploading(true);

    const { name, value } = event.target;
    if (name === "image") {
      if (event.target.files[0].type.includes("image")) {
        const file = event.target.files[0];
        //...not using compress object to pass service the issue is that after compress object is blob so in service multer is not able to parse it
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            if (compressedResult.size > 2242880) {
              toast.error("File size should not exceed 1Mb.");
              setImageIsUploading(false);
            } else {
              uploadImage(file);
            }
          },
        });
      }
    }
  };

  useEffect(() => {
    // Selected Category
    if (!batter) {
      setbatterLH(false);
      setbatterRH(false);
    } else {
      setSelectedCategory([
        {
          categoryName: "Batter",
          isChecked: true,
          subCategory: [
            { name: "Left Hand", isChecked: batterLH },
            { name: "Right Hand", isChecked: batterRH },
          ],
        },
      ]);
    }

    if (!bowlerPace) {
      setbowlerPaceLH(false);
      setbowlerPaceRH(false);
    } else {
      setSelectedCategory([
        {
          categoryName: "Bowler-Pace",
          isChecked: true,
          subCategory: [
            { name: "Left Hand", isChecked: bowlerPaceLH },
            { name: "Right Hand", isChecked: bowlerPaceRH },
          ],
        },
      ]);

    }

    if (!bowlerSpin) {
      setbowlerSpinLH(false);
      setbowlerSpinRH(false);
    } else {
      setSelectedCategory([
        {
          categoryName: "Bowler-Spin",
          isChecked: true,
          subCategory: [
            { name: "Left Hand", isChecked: bowlerSpinLH },
            { name: "Right Hand", isChecked: bowlerSpinRH },
          ],
        },
      ]);
      // console.log(selectedCategory);
    }

    if (!wckBatter) {
      setwckBatterLH(false);
      setwckBatterRH(false);
    } else {
      setSelectedCategory([
        {
          categoryName: "Wicketkeeper-Batter",
          isChecked: true,
          subCategory: [
            { name: "Left Hand", isChecked: wckBatterLH },
            { name: "Right Hand", isChecked: wckBatterRH },
          ],
        },
      ]);
    }

    if (!allrounderPace) {
      setaPLHBatter(false);
      setaPRHBatter(false);
      setaPLHBowler(false);
      setaPRHBowler(false);
    } else {
      setSelectedCategory([
        {
          categoryName: "Allrounder - Pace",
          isChecked: true,
          subCategory: [
            { name: "Left Hand Batter", isChecked: aPLHBatter },
            { name: "Right Hand Batter", isChecked: aPRHBatter },
            { name: "Left Hand Bowler", isChecked: aPLHBowler },
            { name: "Right Hand Bowler", isChecked: aPRHBowler },
          ],
        },
      ]);
    }

    if (!allrounderSpin) {
      setaSLHBatter(false);
      setaSRHBatter(false);
      setaSLHBowler(false);
      setaSRHBowler(false);
    } else {
      setSelectedCategory([
        {
          categoryName: "Allrounder - Spin",
          isChecked: true,
          subCategory: [
            { name: "Left Hand Batter", isChecked: aSLHBatter },
            { name: "Right Hand Batter", isChecked: aSRHBatter },
            { name: "Left Hand Bowler", isChecked: aSLHBowler },
            { name: "Right Hand Bowler", isChecked: aSRHBowler },
          ],
        },
      ]);
    }
  }, [
    aPLHBatter,
    aPLHBowler,
    aPRHBatter,
    aPRHBowler,
    aSLHBatter,
    aSLHBowler,
    aSRHBatter,
    aSRHBowler,
    allrounderPace,
    allrounderSpin,
    batter,
    batterLH,
    batterRH,
    bowlerPace,
    bowlerPaceLH,
    bowlerPaceRH,
    bowlerSpin,
    bowlerSpinLH,
    bowlerSpinRH,
    wckBatter,
    wckBatterLH,
    wckBatterRH,
  ]);

  const handleRegister = async () => {
    if (!userInfo.firstName) {
      toast.error("No firstName");
      return;
    }

    if (!userInfo.lastName) {
      toast.error("No lastname");
      return;
    }

    if (!userInfo.email) {
      toast.error("No email");
      return;
    }

    if (!dobRef.current.value) {
      toast.error("No dob");
      return;
    }

    if (!cityRef.current.value) {
      toast.error("No city");
      return;
    }

    if (!idRef.current.value) {
      toast.error("No Id");
      return;
    }

    // if (!heightRef.current.value) {
    //   toast.error("No height");
    //   return;
    // }

    // if (!weightRef.current.value) {
    //   toast.error("No weight");
    //   return;
    // }

    // FirstName verification
    if (typeof userInfo.firstName !== "string") {
      toast.error("Not a proper first name");
      return;
    }

    if (firstNameRef.current.value !== userInfo.firstName) {
      toast.error("First name hampered");
      return;
    }

    // Last Name verification
    if (typeof userInfo.lastName !== "string") {
      toast.error("Not a proper last name");
      return;
    }

    if (lastNameRef.current.value !== userInfo.lastName) {
      toast.error("Last name hampered");
      return;
    }

    // Email verification
    const verifyEmail = validateEmail(userInfo.email);
    if (!verifyEmail) {
      toast.error("Email verification failed. Not a proper email");
      return;
    }

    if (emailRef.current.value !== userInfo.email) {
      toast.error("Email hampered");
      return;
    }

    // Age Limit -> 1987 from Jan 2022 => 35 Years maximum and 12 years minimum DOB verification

    // const ageVerify = dateCheck(dobRef.current.value);
    // const minAge = 12;
    // const maxAge = 35;
    // if (ageVerify > maxAge || ageVerify < minAge) {
    //   toast.error(
    //     "Age requirments not matched ( Min.12 years, Max. 35 years )"
    //   );
    //   return;
    // }

    // const Indian Cities
    const cityList = [
      "Indian Cities",
      "Mumbai",
      "Delhi",
      "Bangalore",
      "Hyderabad",
      "Ahmedabad",
      "Chennai",
      "Kolkata",
      "Surat",
      "Pune",
      "Jaipur",
      "Lucknow",
      "Kanpur",
      "Nagpur",
      "Indore",
      "Thane",
      "Bhopal",
      "Visakhapatnam",
      "Pimpri & Chinchwad",
      "Patna",
      "Vadodara",
      "Ghaziabad",
      "Ludhiana",
      "Agra",
      "Nashik",
      "Faridabad",
      "Meerut",
      "Rajkot",
      "Kalyan & Dombivali",
      "Vasai Virar",
      "Varanasi",
      "Srinagar",
      "Aurangabad",
      "Dhanbad",
      "Amritsar",
      "Navi Mumbai",
      "Allahabad",
      "Ranchi",
      "Haora",
      "Coimbatore",
      "Jabalpur",
      "Gwalior",
      "Vijayawada",
      "Jodhpur",
      "Madurai",
      "Raipur",
      "Kota",
      "Guwahati",
      "Chandigarh",
      "Solapur",
      "Hubli and Dharwad",
      "Bareilly",
      "Moradabad",
      "Karnataka",
      "Gurgaon",
      "Aligarh",
      "Jalandhar",
      "Tiruchirappalli",
      "Bhubaneswar",
      "Salem",
      "Mira and Bhayander",
      "Thiruvananthapuram",
      "Bhiwandi",
      "Saharanpur",
      "Gorakhpur",
      "Guntur",
      "Bikaner",
      "Amravati",
      "Noida",
      "Jamshedpur",
      "Bhilai Nagar",
      "Warangal",
      "Cuttack",
      "Firozabad",
      "Kochi",
      "Bhavnagar",
      "Dehradun",
      "Durgapur",
      "Asansol",
      "Nanded Waghala",
      "Kolapur",
      "Ajmer",
      "Gulbarga",
      "Jamnagar",
      "Ujjain",
      "Loni",
      "Siliguri",
      "Jhansi",
      "Ulhasnagar",
      "Nellore",
      "Jammu",
      "Sangli Miraj Kupwad",
      "Belgaum",
      "Mangalore",
      "Ambattur",
      "Tirunelveli",
      "Malegoan",
      "Gaya",
      "Jalgaon",
      "Udaipur",
      "Maheshtala",
      "Achhnera",
      "Adalaj",
      "Adoor",
      "Adra",
      "Adyar",
      "Afzalpur",
      "Akot",
      "Alipurduar",
      "Alirajpur",
      "Almora",
      "Aluva",
      "Amalapuram",
      "Amalner",
      "Amaravathi",
      "Ambejogai",
      "Anakapalle",
      "Angul",
      "Anjangaon",
      "Anjar",
      "Ankleshwar",
      "Arakkonam",
      "Arambagh",
      "Araria",
      "Arsikere",
      "Aruppukkottai",
      "Arvi",
      "Arwal",
      "Asarganj",
      "Ashok Nagar",
      "Athni",
      "Attili",
      "Attingal",
      "Avinissery",
      "Badrinath",
      "Bagepalli",
      "Bageshwar",
      "Bagha Kusmar",
      "Bajpur",
      "Balangir",
      "Balurghat",
      "Bapatla",
      "Barahiya",
      "Barauli",
      "Barbigha",
      "Barbil",
      "Bargarh",
      "Barh",
      "Barkot",
      "Barpeta",
      "Bellampalle",
      "Belonia",
      "Bhabua",
      "Bhadrachalam",
      "Bhainsa",
      "Bhatapara",
      "Bhawanipatna",
      "Bheemunipatnam",
      "Bhimtal",
      "Bhowali",
      "Bhusawar",
      "Bhuvanagiri",
      "Bindki",
      "Bobbili",
      "Bodhan",
      "Bongaigaon City",
      "Budhlada",
      ,
      "Byasanagar",
      "Chadchan",
      "Chaibasa",
      "Chakradharpur",
      "Chalakudy",
      "Challakere",
      "Chamba",
      "Chamoli Gopeshwar",
      "Champawat",
      "Chandpara",
      "Changanassery",
      "Charkhari",
      "Charkhi Dadri",
      "Charthaval",
      "Chatra",
      "Chengannur",
      "Cherthala",
      "Chhapra",
      "Chidambaram",
      "Chikkaballapur",
      "Chintamani",
      "Chirala",
      "Chirkunda",
      "Chirmiri",
      "Chittur-Thathamangalam",
      "Cooch Behar",
      "Dalli-Rajhara",
      "Daltonganj",
      "Daudnagar",
      "Degana",
      "Devprayag",
      "Dhamtari",
      "Dharchula",
      "Dharmanagar",
      "Dhenkanal",
      "Dhone",
      "Dhoraji",
      "Dhubri",
      "Dhuri",
      "Didihat",
      "Dildarnagar",
      "Dineshpur",
      "Diphu",
      "Dogadda",
      "Doiwala",
      "Dumka",
      "Dumraon",
      "Dwarahat",
      "Ellenabad",
      "Faizabad",
      "Faridkot",
      "Farooqnagar",
      "Fatehabad",
      "Fazilka",
      "Firozpur Cantonment",
      "Forbesganj",
      "Gadarpur",
      "Gadwal",
      "Gangarampur",
      "Gangotri",
      "Garhwa",
      "Gavaravaram",
      "Ghoti Budruk",
      "Ghumarwin",
      "Goalpara",
      "Gobichettipalayam",
      "Gobindgarh",
      "Gochar",
      "Gohana",
      "Gokak",
      "Golaghat",
      "Gooty",
      "Gopalganj",
      "Gowribidanur",
      "Gudur",
      "Gumia",
      "Gunupur",
      "Gurdaspur",
      "Guruvayoor",
      "Hansi",
      "Herbertpur",
      "Himatnagar",
      "Ichgam",
      "Inkollu",
      "Itarsi",
      "Jaggaiahpet",
      "Jagraon",
      "Jagtial",
      "Jalandhar Cantt.",
      "Jammalamadugu",
      "Jamui",
      "Jangaon",
      "Jaspur",
      "Jatani",
      "Jaynagar Majilpur",
      "Jhabrera",
      "Jhargram",
      "Jharsuguda",
      "Jhumri Tilaiya",
      "Jorhat",
      "Joshimath",
      "Junnardeo",
      "Kadi",
      "Kadiri",
      "Kagaznagar",
      "Kailasahar",
      "Kaladhungi",
      "Kalimpong",
      "Kallakurichi",
      "Kalpi",
      "Kamareddy",
      "Kandukur",
      "Kangeyam",
      "Kanhangad",
      "Kanigiri",
      "Kannur",
      "Kapadvanj",
      "Karaikal",
      "Karimganj",
      "Karjat",
      "Karnaprayag",
      "Karunagappally",
      "Karur",
      "Kasaragod",
      "Kathua",
      "Kavali",
      "Kayamkulam",
      "Kedarnath",
      "Kela Khera",
      "Kendrapara",
      "Kendujhar",
      "Keshod",
      "Khairthal",
      "Khambhat",
      "Khanda",
      "Kharar",
      "Khatima",
      "Khowai",
      "Kichha",
      "Kirtinagar",
      "Kodungallur",
      "Kohima",
      "Kokrajhar",
      "Kora Jahanabad",
      "Koratla",
      "Kot Kapura",
      "Kotdwar",
      "Kothagudem",
      "Kottayam",
      "Kovvur",
      "Koyilandy",
      "Kulpahar",
      "Kumarganj",
      "Kunnamkulam",
      "Kyathampalle",
      "Lachhmangarh",
      "Ladnu",
      "Ladwa",
      "Lahar",
      "Laharpur",
      "Lakheri",
      "Lakhisarai",
      "Laksar",
      "Lakshmeshwar",
      "Lal Gopalganj Nindaura",
      "Lalganj",
      "Lalgudi",
      "Lalkuan",
      "Lalsot",
      "Landhaura",
      "Lanka",
      "Lar",
      "Lathi",
      "Lilong",
      "Limbdi",
      "Lingsugur",
      "Loha",
      "Lohaghat",
      "Lohardaga",
      "Lonar",
      "Lonavla",
      "Longowal",
      "Losal",
      "Lumding",
      "Lunawada",
      "Lunglei",
      "Macherla",
      "Maddur",
      "Madhepura",
      "Madhubani",
      "Madhugiri",
      "Madhupur",
      "Madikeri",
      "Magadi",
      "Mahad",
      "Mahalingapura",
      "Maharajganj",
      "Maharajpur",
      "Mahasamund",
      "Mahe",
      "Mahemdabad",
      "Mahendragarh",
      "Mahnar Bazar",
      "Mahua Dabra Haripura",
      "Mahua Kheraganj",
      "Maihar",
      "Mainaguri",
      "Makhdumpur",
      "Makrana",
      "Malaj Khand",
      "Malavalli",
      "Malda",
      "Malkangiri",
      "Malkapur",
      "Malout",
      "Malpura",
      "Malur",
      "Manachanallur",
      "Manasa",
      "Manavadar",
      "Manawar",
      "Mandalgarh",
      "Mandamarri",
      "Mandapeta",
      "Mandawa",
      "Mandi Dabwali",
      "Mandideep",
      "Mandla",
      "Mandvi",
      "Manendragarh",
      "Maner Sharif",
      "Mangaldoi",
      "Mangalvedhe",
      "Manglaur",
      "Mangrol",
      "Mangrulpir",
      "Manihari",
      "Manjlegaon",
      "Mankachar",
      "Manmad",
      "Mansa",
      "Manuguru",
      "Manvi",
      "Manwath",
      "Mapusa",
      "Margao",
      "Margherita",
      "Marhaura",
      "Mariani",
      "Marigaon",
      "Markapur",
      "Masaurhi",
      "Mathabhanga",
      "Mattannur",
      "Mauganj",
      "Mavelikkara",
      "Mavoor",
      "Mayang Imphal",
      "Medak",
      "Memari",
      "Merta City",
      "Metpally",
      "Mhaswad",
      "Mhow Cantonment",
      "Mhowgaon",
      "Mihijam",
      "Mirganj",
      "Miryalaguda",
      "Modasa",
      "Mokameh",
      "Mokokchung",
      "Monoharpur",
      "Morinda, India",
      "Morshi",
      "Motipur",
      "Mount Abu",
      "Mudabidri",
      "Mudalagi",
      "Muddebihal",
      "Mudhol",
      "Mukerian",
      "Mukhed",
      "Muktsar",
      "Mul",
      "Mulbagal",
      "Multai",
      "Mundargi",
      "Mundi",
      "Mungeli",
      "Muni Ki Reti",
      "Murliganj",
      "Murshidabad",
      "Murtijapur",
      "Musabani",
      "Mussoorie",
      "Muvattupuzha",
      "Nabarangapur",
      "Nabha",
      "Nadbai",
      "Nagar",
      "Nagari",
      "Nagarkurnool",
      "Nagina",
      "Nagla",
      "Nahan",
      "Naharlagun",
      "Naidupet",
      "Naila Janjgir",
      "Nainital",
      "Nainpur",
      "Najibabad",
      "Nakodar",
      "Nakur",
      "Nalbari",
      "Namagiripettai",
      "Namakkal",
      "Nandaprayag",
      "Nandgaon",
      "Nandivaram-Guduvancheri",
      "Nandura",
      "Nangal",
      "Nanjangud",
      "Nanjikottai",
      "Nanpara",
      "Narasapuram",
      "Naraura",
      "Narayanpet",
      "Narendranagar",
      "Nargund",
      "Narkatiaganj",
      "Narkhed",
      "Narnaul",
      "Narsinghgarh",
      "Narsipatnam",
      "Narwana",
      "Nasirabad",
      "Natham",
      "Nathdwara",
      "Naugachhia",
      "Naugawan Sadat",
      "Naura",
      "Nautanwa",
      "Navalgund",
      "Nawabganj",
      "Nawada",
      "Nawanshahr",
      "Nawapur",
      "Nedumangad",
      "Nedumbassery",
      "Neem-Ka-Thana",
      "Nehtaur",
      "Nelamangala",
      "Nellikuppam",
      "Nepanagar",
      "Neyyattinkara",
      "Nidadavole",
      "Nilambur",
      "Nilanga",
      "Nimbahera",
      "Nirmal",
      "Niwai",
      "Niwari",
      "Nohar",
      "Nokha",
      "Nongstoin",
      "Noorpur",
      "North Lakhimpur",
      "Nowgong",
      "Nowrozabad (Khodargama)",
      "Nuzvid",
      "O' Valley",
      "Obra",
      "Oddanchatram",
      "Ottappalam",
      "Owk",
      "Ozar",
      "P.N.Patti",
      "Pachora",
      "Pachore",
      "Pacode",
      "Padmanabhapuram",
      "Padra",
      "Padrauna",
      "Paithan",
      "Pakaur",
      "Palai",
      "Palampur",
      "Palani",
      "Palasa Kasibugga",
      "Palghar",
      "Pali",
      "Palia Kalan",
      "Palitana",
      "Palladam",
      "Pallapatti",
      "Pallikonda",
      "Palwancha",
      "Panagar",
      "Panagudi",
      "Panaji*",
      "Panamattom",
      "Panchla",
      "Pandharkaoda",
      "Pandharpur",
      "Pandhurna",
      "Pandua",
      "Panna",
      "Panniyannur",
      "Panruti",
      "Pappinisseri",
      "Paradip",
      "Paramakudi",
      "Parangipettai",
      "Parasi",
      "Paravoor",
      "Pardi",
      "Parlakhemundi",
      "Parli",
      "Partur",
      "Parvathipuram",
      "Pasan",
      "Paschim Punropara",
      "Pasighat",
      "Pathanamthitta",
      "Pathardi",
      "Pathri",
      "Patratu",
      "Pattamundai",
      "Patti",
      "Pattran",
      "Pattukkottai",
      "Patur",
      "Pauni",
      "Pauri",
      "Pavagada",
      "Pedana",
      "Peddapuram",
      "Pehowa",
      "Pen",
      "Perambalur",
      "Peravurani",
      "Peringathur",
      "Perinthalmanna",
      "Periyakulam",
      "Periyasemur",
      "Pernampattu",
      "Perumbavoor",
      "Petlad",
      "Phalodi",
      "Phaltan",
      "Phillaur",
      "Phulabani",
      "Phulera",
      "Phulpur",
      "Pihani",
      "Pilani",
      "Piler",
      "Pilibanga",
      "Pilkhuwa",
      "Pindwara",
      "Pipar City",
      "Piriyapatna",
      "Piro",
      "Pithampur",
      "Pithapuram",
      "Pithoragarh",
      "Polur",
      "Ponnani",
      "Ponneri",
      "Ponnur",
      "Poonch",
      "Porsa",
      "Port Blair*",
      "Powayan",
      "Prantij",
      "Pratapgarh",
      "Prithvipur",
      "Pudupattinam",
      "Pukhrayan",
      "Pulgaon",
      "Puliyankudi",
      "Punalur",
      "Punganur",
      "Punjaipugalur",
      "Puranpur",
      "Purna",
      "Purquazi",
      "Purwa",
      "Pusad",
      "Puthuppally",
      "Puttur",
      "Qadian",
      "Rabkavi Banhatti",
      "Radhanpur",
      "Rafiganj",
      "Raghogarh-Vijaypur",
      "Raghunathpur",
      "Rahatgarh",
      "Rahuri",
      "Raikot",
      "Rairangpur",
      "Raisen",
      "Raisinghnagar",
      "Rajagangapur",
      "Rajakhera",
      "Rajaldesar",
      "Rajam",
      "Rajauri",
      "Rajepur",
      "Rajesultanpur",
      "Rajgarh",
      "Rajgarh (Alwar)",
      "Rajgarh (Churu)",
      "Rajgir",
      "Rajpipla",
      "Rajpura",
      "Rajsamand",
      "Rajula",
      "Rajura",
      "Ramachandrapuram",
      "Ramanagaram",
      "Ramanathapuram",
      "Ramdurg",
      "Rameshwaram",
      "Ramganj Mandi",
      "Ramnagar",
      "Ramngarh",
      "Rampur Maniharan",
      "Rampura Phul",
      "Rampurhat",
      "Ramtek",
      "Ranavav",
      "Rangiya",
      "Rania",
      "Ranibennur",
      "Rapar",
      "Rasipuram",
      "Rasra",
      "Ratangarh",
      "Rath",
      "Ratia",
      "Ratnagiri",
      "Rau",
      "Raver",
      "Rawatbhata",
      "Rawatsar",
      "Raxaul Bazar",
      "Rayachoti",
      "Rayadurg",
      "Rayagada",
      "Reengus",
      "Rehli",
      "Renigunta",
      "Renukoot",
      "Reoti",
      "Repalle",
      "Revelganj",
      "Rishikesh",
      "Risod",
      "Robertsganj",
      "Ron",
      "Rosera",
      "Rudauli",
      "Rudraprayag",
      "Rudrapur",
      "Rupnagar",
      "Sabalgarh",
      "Sadabad",
      "Sadasivpet",
      "Sadri",
      "Sadulshahar",
      "Safidon",
      "Safipur",
      "Sagara",
      "Sagwara",
      "Sahaspur",
      "Sahaswan",
      "Sahawar",
      "Sahibganj",
      "Sahjanwa",
      "Saidpur",
      "Saiha",
      "Sailu",
      "Sainthia",
      "Sakaleshapura",
      "Sakti",
      "Salaya",
      "Salumbar",
      "Salur",
      "Samalkha",
      "Samalkot",
      "Samana",
      "Samastipur",
      "Sambhar",
      "Samdhan",
      "Samthar",
      "Sanand",
      "Sanawad",
      "Sanchore",
      "Sandi",
      "Sandila",
      "Sanduru",
      "Sangamner",
      "Sangareddy",
      "Sangaria",
      "Sangole",
      "Sangrur",
      "Sanivarapupeta",
      "Sankagiri",
      "Sankarankovil",
      "Sankeshwara",
      "Sarangpur",
      "Sardarshahar",
      "Sardhana",
      "Sarni",
      "Sarsawa",
      "Sarsod",
      "Sasvad",
      "Satana",
      "Sathyamangalam",
      "Satrampadu",
      "Sattenapalle",
      "Sattur",
      "Saunda",
      "Saundatti-Yellamma",
      "Sausar",
      "Savanur",
      "Savarkundla",
      "Savner",
      "Sawantwadi",
      "Sedam",
      "Sehore",
      "Sendhwa",
      "Seohara",
      "Seoni",
      "Seoni-Malwa",
      "Shahabad",
      "Shahabad, Hardoi",
      "Shahabad, Rampur",
      "Shahade",
      "Shahbad",
      "Shahdol",
      "Shahganj",
      "Shahpur",
      "Shahpura",
      "Shajapur",
      "Shaktigarh",
      "Shamgarh",
      "Shamli",
      "Shamsabad, Agra",
      "Shamsabad, Farrukhabad",
      "Shegaon",
      "Sheikhpura",
      "Shendurjana",
      "Shenkottai",
      "Sheoganj",
      "Sheohar",
      "Sheopur",
      "Sherghati",
      "Sherkot",
      "Shiggaon",
      "Shikaripur",
      "Shikarpur, Bulandshahr",
      "Shikohabad",
      "Shirdi",
      "Shirpur-Warwade",
      "Shirur",
      "Shishgarh",
      "Sholavandan",
      "Sholingur",
      "Shoranur",
      "Shrigonda",
      "Shrirampur",
      "Shrirangapattana",
      "Shujalpur",
      "Siana",
      "Sibsagar",
      "Siddipet",
      "Sidhi",
      "Sidhpur",
      "Sidlaghatta",
      "Sihawa",
      "Sihor",
      "Sihora",
      "Sikanderpur",
      "Sikandra Rao",
      "Sikandrabad",
      "Silao",
      "Silapathar",
      "Sillod",
      "Silvassa*",
      "Simdega",
      "Sindagi",
      "Sindhagi",
      "Sindhnur",
      "Singhana",
      "Sinnar",
      "Sira",
      "Sircilla",
      "Sirhind Fatehgarh Sahib",
      "Sirkali",
      "Sirohi",
      "Sironj",
      "Sirsaganj",
      "Sirsi",
      "Siruguppa",
      "Sitamarhi",
      "Sitarganj",
      "Sivaganga",
      "Sivagiri",
      "Sohagpur",
      "Sohna",
      "Sojat",
      "Solan",
      "Sonamukhi",
      "Sonepur",
      "Songadh",
      "Sopore",
      "Soro",
      "Soron",
      "Soyagaon",
      "Sri Madhopur",
      "Srikalahasti",
      "Srinagar, Uttarakhand",
      "Srinivaspur",
      "Srirampore",
      "Srisailam (RFC) Township",
      "Srivilliputhur",
      "Suar",
      "Sugauli",
      "Sujangarh",
      "Sujanpur",
      "Sullurpeta",
      "Sultanganj",
      "Sultanpur",
      "Sumerpur",
      "Sunabeda",
      "Sunam",
      "Sundargarh",
      "Sundarnagar",
      "Supaul",
      "Surandai",
      "Surapura",
      "Suratgarh",
      "Suri",
      "Suriyampalayam",
      "Suroth-Hindaun",
      "Takhatgarh",
      "Taki",
      "Talaja",
      "Talbehat",
      "Talcher",
      "Talegaon Dabhade",
      "Talikota",
      "Taliparamba",
      "Talode",
      "Talwara",
      "Tamluk",
      "Tanakpur",
      "Tanda",
      "Tandur",
      "Tanuku",
      "Tarakeswar",
      "Tarana",
      "Taranagar",
      "Taraori",
      "Tarbha",
      "Tarikere",
      "Tarn Taran",
      "Tasgaon",
      "Tehri",
      "Tekkalakote",
      "Tenkasi",
      "Tenu dam-cum-Kathhara",
      "Terdal",
      "Tezpur",
      "Thakurdwara",
      "Thammampatti",
      "Thana Bhawan",
      "Thangadh",
      "Tharad",
      "Tharamangalam",
      "Tharangambadi",
      "Theni Allinagaram",
      "Thirumangalam",
      "Thirupuvanam",
      "Thiruthuraipoondi",
      "Thiruvalla",
      "Thiruvallur",
      "Thiruvarur",
      "Thodupuzha",
      "Thoubal",
      "Thrippunithura",
      "Thrissur",
      "Thuraiyur",
      "Tikamgarh",
      "Tilda Newra",
      "Tilhar",
      "Tindivanam",
      "Tinsukia",
      "Tiptur",
      "Tirora",
      "Tiruchendur",
      "Tiruchengode",
      "Tirukalukundram",
      "Tirukkoyilur",
      "Tirupathur",
      "Tiruppur",
      "Tirur",
      "Tiruttani",
      "Tiruvethipuram",
      "Tiruvuru",
      "Tirwaganj",
      "Titlagarh",
      "Tittakudi",
      "Todabhim",
      "Todaraisingh",
      "Tohana",
      "Tuensang",
      "Tuljapur",
      "Tulsipur",
      "Tumsar",
      "Tundla",
      "Tuni",
      "Tura",
      "Uchgaon",
      "Udaipurwati",
      "Udhagamandalam",
      "Udhampur",
      "Udumalaipettai",
      "Ujhani",
      "Umarga",
      "Umargam",
      "Umaria",
      "Umarkhed",
      "Umred",
      "Umreth",
      "Una",
      "Unjha",
      "Unnamalaikadai",
      "Upleta",
      "Uran",
      "Uran Islampur",
      "Uravakonda",
      "Urmar Tanda",
      "Usilampatti",
      "Uthamapalayam",
      "Uthiramerur",
      "Utraula",
      "Uttarkashi",
      "Vadakkuvalliyur",
      "Vadalur",
      "Vadgaon Kasba",
      "Vadnagar",
      "Vaijapur",
      "Vaikom",
      "Valparai",
      "Vandavasi",
      "Vaniyambadi",
      "Vapi",
      "Varandarappilly",
      "Varkala",
      "vasudevanallur",
      "Vatakara",
      "Vedaranyam",
      "Vellakoil",
      "Venkatagiri",
      "Vijainagar, Ajmer",
      "Vijapur",
      "Vijayapura",
      "Vijaypur",
      "Vikarabad",
      "Vikasnagar",
      "Vikramasingapuram",
      "Viluppuram",
      "Vinukonda",
      "Viramgam",
      "Virudhachalam",
      "Virudhunagar",
      "Visnagar",
      "Viswanatham",
      "Vita",
      "Vrindavan",
      "Vuyyuru",
      "Vyara",
      "Wadgaon Road",
      "Wadhwan",
      "Wadi",
      "Wai",
      "Wanaparthy",
      "Wani",
      "Wankaner",
      "Wara Seoni",
      "Warhapur",
      "Warisaliganj",
      "Warora",
      "Warud",
      "Washim",
      "Wokha",
      "Yadgir",
      "Yanam",
      "Yawal",
      "Yellandu",
      "Yemmiganur",
      "Yerraguntla",
      "Yevla",
      "Zaidpur",
      "Zamania",
      "Zira",
      "Zirakpur",
      "Zunheboto",
    ];
    let userCity = cityRef.current.value;
    userCity = userCity.toLowerCase();
    const capitalizeUserCityName =
      userCity.charAt(0).toUpperCase() + userCity.slice(1);
    if (
      typeof cityRef.current.value !== "string" ||
      cityList.indexOf(capitalizeUserCityName) === -1
    ) {
      toast.error("Not a valid city");
      return;
    }

    // // Verify height
    // if (!+heightRef.current.value) {
    //   toast.error("height not valid");
    //   return;
    // }

    // // Verify weight
    // if (!+weightRef.current.value) {
    //   toast.error("weignt not valid");
    //   return;
    // }

    // Validate club
    if (!club) {
      toast.error("Cricket club details required");
      return;
    }

    // Validate coaching
    if (!coaching) {
      toast.error("Cricket coaching details required");
      return;
    }

    // Validating categories
    if (batter && !batterLH && !batterRH) {
      toast.error("Batter details required");
      return;
    }
    if (bowlerPace && !bowlerPaceLH && !bowlerPaceRH) {
      toast.error("BowlerPace details required");
      return;
    }
    if (bowlerSpin && !bowlerSpinLH && !bowlerSpinRH) {
      toast.error("BowlerSpin details required");
      return;
    }
    if (wckBatter && !wckBatterLH && !wckBatterRH) {
      toast.error("Wicketkeeper-Batter details required");
      return;
    }
    if (
      allrounderPace &&
      !aPLHBatter &&
      !aPRHBatter &&
      !aPRHBatter &&
      !aPRHBowler
    ) {
      toast.error("Allrounder - Pace details required");
      return;
    }
    if (aPLHBatter && !aPRHBowler && !aPLHBowler) {
      toast.error("Allrounder - Pace details required");
      return;
    }

    if (aPRHBatter && !aPLHBowler && !aPRHBowler) {
      toast.error("Allrounder - Pace details required");
      return;
    }

    if (
      allrounderSpin &&
      !aSLHBatter &&
      !aSRHBatter &&
      !aSRHBatter &&
      !aSRHBowler
    ) {
      toast.error("Allrounder - Spin details required");
      return;
    }
    if (aSLHBatter && !aSRHBowler && !aSLHBowler) {
      toast.error("Allrounder - Spin details required");
      return;
    }

    if (aSRHBatter && !aSLHBowler && !aSRHBowler) {
      toast.error("Allrounder - Spin details required");
      return;
    }

    club === "true" ? setClub("yes") : setClub("no");
    coaching === "true" ? setCoaching("yes") : setCoaching("no");

    // console.log(selectedCategory);

    const data = {
      eventId: event_id,

      firstName: userInfo.firstName,

      lastName: userInfo.lastName,

      email: userInfo.email,

      dateOfBirth: dobRef.current.value,

      address: cityRef.current.value,

      eventBanner: event.eventBanner,

      eventTitle: event.eventTitle,

      startTime: event.startTime,

      startDate: event.startDate,

      eventdescription: event.eventdescription,

      registrationFee: event.registrationFee,

      eventLocation: event.eventLocation,

      eventNameCode: event.eventNameCode,

      endTime: event.endTime,

      endDate: event.endDate,

      // idPhoto: file,
      idPhoto: id,

      height: heightRef.current.value || null,

      weight: weightRef.current.value || null,

      question: [
        {
          question: "hasPlayed?",
          option1: "yes",
          option2: "no",
          answer: club,
        },
        {
          question: "hadCoaching?",
          option1: "yes",
          option2: "no",
          answer: coaching,
        },
      ],

      category: selectedCategory,
    };
    if (isRegistering) {
      return;
    }
    setIsRegistering(true);

    // console.log(data);

    // Submitting the form
    let submitPostOptions = {
      method: "post",
      url: global.config.ROOTURL.prod + "/events/userEvent/register",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: data,
    };

    axios(submitPostOptions)
      .then(({ data }) => {
        console.log(data);
        navigate(`/registration-successful/${data.uniqueJerseyNo}`);
        setIsRegistering(false);

      })

      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
        setIsRegistering(false);
      });
  };

  return (
    <Container>
      <Grid container spacing={2} style={{ marginTop: "1px", marginBottom: "5px" }} alignItems="center">
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ zIndex: "10", backgroundColor: "#0c5ba0", color: "white" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={11}>
          <Typography
            variant="h6"
            className="heading"
            textAlign="center"
            sx={{ position: "relative", right: "60px" }}
          >
            Registration
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="content" style={{marginTop:"50px"}}>
        {/* First Name */}
        <Grid item xs={12} sm={6}>
          <FormLabel className="formLabel">First Name</FormLabel>
          <TextField
            required
            inputRef={firstNameRef}
            id="outlined-required"
            value={userInfo.firstName}
            placeholder={userInfo.firstName}
            fullWidth
            className="inputs"
            sx={{
              border: "1px solid dashed #bbb",
              pointerEvents: "none",
              backgroundColor: "#c7c5c5",
            }}
          />
        </Grid>
        {/* Last Name */}
        <Grid item xs={12} sm={6}>
          <FormLabel className="formLabel">Last Name</FormLabel>
          <TextField
            required
            inputRef={lastNameRef}
            value={userInfo.lastName}
            placeholder={userInfo.lastName}
            fullWidth
            className="inputs"
            sx={{
              border: "1px solid dashed #bbb",
              pointerEvents: "none",
              backgroundColor: "#c7c5c5",
            }}
          />
        </Grid>
        {/* Email */}
        <Grid item xs={12}>
          <FormLabel className="formLabel">Email</FormLabel>
          <TextField
            required
            inputRef={emailRef}
            id="outlined-required"
            type="email"
            value={userInfo.email}
            placeholder={userInfo.email}
            fullWidth
            className="inputs"
            sx={{
              border: "1px solid dashed #bbb",
              pointerEvents: "none",
              backgroundColor: "#c7c5c5",
            }}
          />
        </Grid>
        {/* Date of Birth */}
        <Grid item xs={12}>
          <FormLabel className="formLabel">Date of Birth</FormLabel>
          <TextField
            type="date"
            required
            id="outlined-required"
            fullWidth
            className="inputs"
            placeholder={null}
            inputRef={dobRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">Calender</InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Village/ Town/ City */}
        <Grid item xs={12}>
          <FormLabel className="formLabel">Village/ Town/ City</FormLabel>
          <TextField
            required
            id="outlined-required"
            inputRef={cityRef}
            fullWidth
            className="inputs"
            sx={{ border: "1px solid dashed #bbb" }}
          />
        </Grid>
        {/* Aadhar /PAN Card */}
        <Grid item xs={12}>
          <FormLabel className="formLabel">Photo Id</FormLabel>
          <TextField
            type="file"
            required
            id="outlined-end-adornment"
            name="image"
            accept="image/*"
            onChange={handleInputChange}
            ref={fileRef}
            placeholder="Aadhar/ PAN Card"
            fullWidth
            className="inputs"
            helperText="Please upload an image of any official ID with your photo and DOB. Image size should not be more than 5 mb."
            sx={{ border: "1px solid dashed #bbb" }}
            inputRef={idRef}
          />
          {imageIsUploading && (
            <TailSpin
              height="35"
              width="35"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-100%,-200%)",
              }}
              wrapperClass=""
              visible={true}
            />
          )}
        </Grid>

        {/* Height */}
        <Grid item xs={12} sm={6}>
          <FormLabel className="formLabel">Height</FormLabel>
          <TextField
            // required
            id="outlined-required"
            fullWidth
            className="inputs"
            sx={{ border: "1px solid dashed #bbb" }}
            placeholder="cm"
            inputRef={heightRef}
          />
        </Grid>
        {/* Weight */}
        <Grid item xs={12} sm={6}>
          <FormLabel className="formLabel">Weight</FormLabel>
          <TextField
            // required
            id="outlined-required"
            fullWidth
            className="inputs"
            sx={{ border: "1px solid dashed #bbb" }}
            placeholder="kg"
            inputRef={weightRef}
          />
        </Grid>
        {/* Cricket Club */}
        <Grid item xs={12} sm={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            className="formLabel"
          >
            Have you played cricket at school, college or club level ?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={club}
            onChange={handleClub}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              className="form-radio-label"
            />
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              className="form-radio-label"
            />
          </RadioGroup>
        </Grid>
        {/* Cricket Coaching */}
        <Grid item xs={12} sm={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            className="formLabel"
          >
            Have you taken any formal cricket coaching ?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={coaching}
            onChange={handleCoaching}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              className="form-radio-label"
            />
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              className="form-radio-label"
            />
          </RadioGroup>
        </Grid>
        {/* Category Label */}
        <Grid item xs={12}>
          <FormLabel className="formLabel">Category</FormLabel>
        </Grid>
        {/* Category-Batter */}
        <Grid container item xs={12}>
          <Grid item xs={6} sm={2}>
            <FormControlLabel
              control={<Checkbox />}
              label="Batter"
              disabled={
                bowlerPace ||
                bowlerSpin ||
                wckBatter ||
                allrounderPace ||
                allrounderSpin
              }
              onChange={(e) => {
                setbatter(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} className="semi-grid">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand"
                disabled={!batter || batterRH}
                onChange={(e) => {
                  setbatterLH(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand"
                disabled={!batter || batterLH}
                onChange={(e) => {
                  setbatterRH(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Category-Bowler-Pace */}
        <Grid container item xs={12}>
          <Grid item xs={6} sm={2}>
            <FormControlLabel
              control={<Checkbox />}
              label="Bowler-Pace"
              disabled={
                batter ||
                bowlerSpin ||
                wckBatter ||
                allrounderPace ||
                allrounderSpin
              }
              onChange={(e) => {
                setbowlerPace(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} className="semi-grid">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand"
                disabled={!bowlerPace || bowlerPaceRH}
                onChange={(e) => {
                  setbowlerPaceLH(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand"
                disabled={!bowlerPace || bowlerPaceLH}
                onChange={(e) => {
                  setbowlerPaceRH(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Category-Bowler-Spin */}
        <Grid container item xs={12}>
          <Grid item xs={6} sm={2}>
            <FormControlLabel
              control={<Checkbox />}
              label="Bowler-Spin"
              disabled={
                batter ||
                bowlerPace ||
                wckBatter ||
                allrounderPace ||
                allrounderSpin
              }
              onChange={(e) => {
                setbowlerSpin(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} className="semi-grid">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand"
                disabled={!bowlerSpin || bowlerSpinRH}
                onChange={(e) => {
                  setbowlerSpinLH(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand"
                disabled={!bowlerSpin || bowlerSpinLH}
                onChange={(e) => {
                  setbowlerSpinRH(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Category-Wicketkeeper-Batter */}
        <Grid container item xs={12}>
          <Grid item xs={6} sm={2}>
            <FormControlLabel
              control={<Checkbox />}
              label="Wicketkeeper-Batter"
              disabled={
                batter ||
                bowlerPace ||
                bowlerSpin ||
                allrounderPace ||
                allrounderSpin
              }
              onChange={(e) => {
                setwckBatter(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} className="semi-grid">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand"
                disabled={!wckBatter || wckBatterRH}
                onChange={(e) => {
                  setwckBatterLH(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand"
                disabled={!wckBatter || wckBatterLH}
                onChange={(e) => {
                  setwckBatterRH(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Allrounder - Pace */}
        <Grid container item xs={12}>
          <Grid item xs={6} sm={2}>
            <FormControlLabel
              control={<Checkbox />}
              label="Allrounder - Pace"
              disabled={
                batter ||
                bowlerPace ||
                bowlerSpin ||
                wckBatter ||
                allrounderSpin
              }
              onChange={(e) => {
                setallrounderPace(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={8} className="semi-grid">
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand Batter"
                disabled={!allrounderPace || aPRHBatter}
                onChange={(e) => {
                  setaPLHBatter(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand Batter"
                disabled={!allrounderPace || aPLHBatter}
                onChange={(e) => {
                  setaPRHBatter(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand Bowler"
                disabled={!allrounderPace || aPRHBowler}
                onChange={(e) => {
                  setaPLHBowler(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand Bowler"
                disabled={!allrounderPace || aPLHBowler}
                onChange={(e) => {
                  setaPRHBowler(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Allrounder - Spin */}
        <Grid container item xs={12}>
          <Grid item xs={6} sm={2}>
            <FormControlLabel
              control={<Checkbox />}
              label="Allrounder - Spin"
              disabled={
                batter ||
                bowlerPace ||
                bowlerSpin ||
                wckBatter ||
                allrounderPace
              }
              onChange={(e) => {
                setallrounderSpin(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={8} className="semi-grid">
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand Batter"
                disabled={!allrounderSpin || aSRHBatter}
                onChange={(e) => {
                  setaSLHBatter(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand Batter"
                disabled={!allrounderSpin || aSLHBatter}
                onChange={(e) => {
                  setaSRHBatter(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Left Hand Bowler"
                disabled={!allrounderSpin || aSRHBowler}
                onChange={(e) => {
                  setaSLHBowler(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Right Hand Bowler"
                disabled={!allrounderSpin || aSLHBowler}
                onChange={(e) => {
                  setaSRHBowler(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Submit Button */}
        <Grid item xs="12">
          <Button
            style={{ backgroundColor: "#0c5ba0", color: "white", marginBottom:"10px"}}
            variant="contained"
            onClick={handleRegister}
            disabled={
              !batter &&
              !bowlerPace &&
              !bowlerSpin &&
              !wckBatter &&
              !allrounderPace &&
              !allrounderSpin
            }
          >
            {isRegistering ? "Registering..." : "Register"}

          </Button>
        </Grid>
      </Grid>
      <ToastContainer />
    </Container>
  );
};

export default EventRegistration;
