import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import cancel from "../../assets/images/home/cancel.png"
import blockconfirm from "../../assets/images/home/okBlock.png"
import { getStorageItem } from '../../utils/sessionStorage';
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '11px'
};

export default function BlockModel({ open, handleblockClose, userId, userName }) {
    const [block, setblock] = useState(false);
    const [isuserBlock, setdata] = useState("");
    const accessToken = getStorageItem("token");
    const handleClose = () => {
        handleblockClose(isuserBlock)
    }
    // console.log('userdid'.userId)
    const BlockUser = (id) => {
        if (!userId && !accessToken) return;
        const getList = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/block/block-user",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                blockedUserId: userId
            }

        };


        axios(getList)
            .then((res) => {
                setblock(true);
                setdata("yes");
            })
            .catch((error) => {
                console.log(error)
            });

    };

    const handleblock = () => {

        BlockUser();
    }

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={block ? "Popup_profile1" : 'Popup_profile2'}>
                    <div style={{ textAlign: "end" }}>
                        <img src={cancel} className="profile_cancel" onClick={handleClose} />
                    </div>


                    {
                        !block ?
                            <>
                                <p className='profile_block-Message'>Are you sure you want to block {userName}?</p>
                                <div className='profile_Popupu_button'>

                                    <Button variant="contained" className='profile_popup_block' onClick={handleblock}>Block</Button>
                                    <Button variant="text" className='profile_popup_cancel' onClick={handleClose}>Cancel</Button>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={blockconfirm} className='profile-ok' /></div>
                                <div className='profile_done_message'>
                                    <p>{userName} is Blocked Now</p>
                                </div>
                            </>
                    }


                </Box>
            </Modal>
        </div>
    );
}