import React from 'react';
import playstore from '../../assets/images/landigpage2/playstore.png';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const UspFooter = () => {
    const mobileView = useMediaQuery("(max-width:600px)");
    const navigate=  useNavigate()
  return (
    <div style={{  backgroundColor: '#F5F5F5' }}>
        <div className='usp_footer'>
          <a href='https://play.google.com/store/apps/details?id=com.champ.huntapp' target="_blank"><img src={playstore} alt=" "/> </a>
            <Box sx={{paddingTop:{xs:'10px',md:'40px'}}}>
            <p>&#169; Copyright 2022 - CHAMPHUNT LLC</p>
            </Box>
        </div>
    </div>
  )
}

export default UspFooter

