import React, { useContext, useEffect, useState } from "react";
import RewardCarousel from "./RewardCarousel";
import { AdCountProvider } from './Context.js';
import Toss from "./Toss";
import "./index.scss";
import Gift from "../NewDeals/Gift";
import Contest from "./Contest";
import { Button, useMediaQuery, Box } from "@material-ui/core";
import dealBall from "../../assets/images/rewards/dealBall.png";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import Footer from "../../components/footer/Footer.js";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import AppContext from "../../context/AppContext";
import Run from "../posts/run";

export default function Rewards() {
  const mobileView = useMediaQuery("(max-width:600px)");
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;

  useEffect(() => {

    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);
  return (
    <AdCountProvider>
      <div className="RewadsMain">
        {mobileView && (
          <div className="MobileRuns">
            <img src={dealBall} />
            <p className="mobilepara">
              <Run isRunValueNeeded={true} />
            </p>
          </div>
        )}
        <RewardCarousel />
        {/* <Toss /> */}
        <Contest />
      </div>
    </AdCountProvider>
  );
}
