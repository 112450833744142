import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from "@mui/material";

import liveAnimation from '../../assets/images/rewards/AuctionAce/liveAnimation.gif'
import flightIcon from '../../assets/images/rewards/AuctionAce/flight.png';
import indianFlag from '../../assets/images/rewards/AuctionAce/india.png';
import cup from '../../assets/images/rewards/AuctionAce/cup.png';
import star from '../../assets/images/rewards/AuctionAce/Star.png';

import useGetPlayerById from './useGetPlayerById';
import { getStorageItem } from "../../utils/sessionStorage";

const LivePopup = (props) => {
    const { open, setOpen, livePlayerId, setSelectedPlayer, setPlayerId } = props;
    const accessToken = getStorageItem("token");
    const { playerDetails, getPlayerById } = useGetPlayerById();
    const [remainingTime, setRemainingTime] = useState(null);
    const mobileView = useMediaQuery("(max-width:768px)");
    console.log("playerDetailsAtLive", playerDetails)

    const handleBidClick = () => {
        setPlayerId(livePlayerId)
        setSelectedPlayer(true)
        setOpen(false)
    }

    useEffect(() => {
        if (livePlayerId) {
            getPlayerById(livePlayerId);
        }
    }, [accessToken, livePlayerId]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: mobileView ? '90%' : "40%",
        bgcolor: '#FFFFFF',
        border: 'none',
        borderRadius: '12px',
        p: 3,
    };

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px'
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpen(false)}
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                            <CloseIcon sx={{ border: "1px solid #000000", borderRadius: "50%" }} />
                        </IconButton>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '60px' }}>
                            {mobileView ? (
                                <img src={liveAnimation} alt='animation' />
                            ) : (
                                <>
                                    <img src={liveAnimation} alt='animation' />
                                    <img src={liveAnimation} alt='animation' />
                                    <img src={liveAnimation} alt='animation' />
                                </>
                            )}
                        </Box>
                        <Typography
                            variant="h5"
                            align="center"
                            sx={{
                                color: '#0C5BA0',
                                fontWeight: 500,
                                fontSize: '18px',
                            }}
                        >
                            Ready to Make Your Bid?
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    color: '#62666A',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                }}
                            >
                                Click the button below to place your bid
                            </Typography>
                            <img src={cup} alt='logo' />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    color: '#0C5BA0',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                }}
                            >
                                <span style={{ color: '#62666A' }}>Player Spotlight:</span> Jasprit Bumrah
                            </Typography>
                            <img src={star} alt='logo' />
                        </Box>
                        <Box
                            sx={{
                                width: mobileView ? "100%" : '90%',
                                background: '#E7F3FDA3',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                borderRadius: '8px',
                                padding: '10px',
                                position: 'relative'
                            }}>
                            <Avatar
                                src={playerDetails?.image || null}
                                alt='player'
                                sx={{ width: mobileView ? '50px' : '63px', height: mobileView ? '50px' : '63px', borderRadius: '50%' }}
                            />
                            <img
                                src={playerDetails?.country === 'India' ? indianFlag : flightIcon}
                                alt='logo'
                                style={{
                                    width: mobileView ? '25px' : '40px',
                                    height: mobileView ? "25px" : '40px',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                }}
                            />
                            <Box>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    sx={{
                                        color: '#0C5BA0',
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        textAlign: 'start'
                                    }}
                                >
                                    <small style={{ color: "#3E3E3E" }}>Name:</small> {playerDetails?.name || ""}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    sx={{
                                        color: '#0C5BA0',
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        textAlign: 'start'
                                    }}
                                >
                                    <small style={{ color: "#3E3E3E" }}>Role:</small> {playerDetails?.specialization || ""}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    sx={{
                                        color: '#0C5BA0',
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        textAlign: 'start'
                                    }}
                                >
                                    <small style={{ color: "#3E3E3E" }}>Base Price:</small> {playerDetails?.basePrice || 0} L
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            onClick={() => handleBidClick()}
                            sx={{
                                width: '70%',
                                background: '#0C5BA0',
                                color: '#FFFFFF',
                                textTransform: 'none',
                                fontWeight: 500,
                                ':hover': {
                                    background: '#0C5BA0'
                                }
                            }}
                        >
                            Bid Now
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default LivePopup;
