import React, { useRef, useState } from 'react'

const useAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingSuccess, setIsRecordingSuccess] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunk = useRef([]);

  const startRecording = async (e) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      audioChunk.current = []
      setAudioFile(null)
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunk.current.push(e.data);
        }
      }

      mediaRecorder.onstop = async () => {
        if (audioChunk.current.length > 0) {
          const audioBlob = new Blob(audioChunk.current, { type: "audio/wav" });
          const newFile = new File([audioBlob], "audio.wav", { type: "audio/wav" });
          setAudioFile(newFile);
          audioChunk.current = [];
        } else {
          console.warn("No audio chunks available to create a Blob");
        }
      }
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording' || mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsRecordingSuccess(true)
    }
  }

  return { isRecording, isRecordingSuccess, setIsRecordingSuccess, audioFile, setAudioFile, startRecording, stopRecording }
}

export default useAudioRecorder;