import React, { useEffect, useState } from "react";
import "./index.scss";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
const AdminSubscription = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = async (_id) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod+`/subscription/delete/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete subscription");
      }

      setSubscriptions(
        subscriptions.filter((subscription) => subscription._id !== _id)
      );
      console.log(response);
      console.log("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(global.config.ROOTURL.prod+`/subscription/all`); // Adjust the URL as needed
        if (!response.ok) {
          throw new Error("Failed to fetch subscriptions");
        }
        const data = await response.json();
        setSubscriptions(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleUpdateSubscription = async (id, updatedSubscription) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod+`/subscription/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedSubscription),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update subscription");
      }

      const updatedSubscriptions = subscriptions.map((subscription) =>
        subscription._id === id
          ? { ...subscription, ...updatedSubscription }
          : subscription
      );

      setSubscriptions(updatedSubscriptions);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="subscription-list">
      {subscriptions.map((subscription) => (
        <SubscriptionCard
          key={subscription._id}
          subscription={subscription}
          onUpdate={handleUpdateSubscription}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

const SubscriptionCard = ({ subscription, onUpdate, handleDelete }) => {
  const { _id, planName, planAmount, planDuration, extraDays, benefits } =
    subscription;
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlanName, setEditedPlanName] = useState(planName);
  const [editedPlanAmount, setEditedPlanAmount] = useState(planAmount);
  const [editedPlanDuration, setEditedPlanDuration] = useState(planDuration);
  const [editedExtraDays, setEditedExtraDays] = useState(extraDays);
  const [editedBenefits, setEditedBenefits] = useState(benefits.join(", "));

  const handleUpdate = async () => {
    const updatedSubscription = {
      planName: editedPlanName,
      planAmount: editedPlanAmount,
      planDuration: editedPlanDuration,
      extraDays: editedExtraDays,
      benefits: editedBenefits.split(",").map((benefit) => benefit.trim()),
    };

    try {
      await onUpdate(_id, updatedSubscription);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update subscription:", error);
    }
  };

  return (
    <div className="subscription-card">
      {isEditing ? (
        <>
          <input
            type="text"
            value={editedPlanName}
            onChange={(e) => setEditedPlanName(e.target.value)}
          />
          <input
            type="number"
            value={editedPlanAmount}
            onChange={(e) => setEditedPlanAmount(e.target.value)}
          />
          <input
            type="number"
            value={editedPlanDuration}
            onChange={(e) => setEditedPlanDuration(e.target.value)}
          />
          <input
            type="number"
            value={editedExtraDays}
            onChange={(e) => setEditedExtraDays(e.target.value)}
          />
          <input
            type="text"
            value={editedBenefits}
            onChange={(e) => setEditedBenefits(e.target.value)}
          />
          <button onClick={handleUpdate}>Save</button>
          <button onClick={() => setIsEditing(false)}>Cancel</button>
        </>
      ) : (
        <>
          <div className="plan-heading">
            <h2>{planName}</h2>

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => handleDelete(_id)}
            >
              Delete
            </Button>
          </div>
          {/* <h2>{planName}</h2> */}
          <p>Plan Amount: {planAmount} Rs</p>
          <p>Plan Duration: {planDuration} days</p>
          <p>Extra Days for plan: {extraDays}</p>
          <h3>Benefits:</h3>
          <ul>
            {benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
          <button
            onClick={() => setIsEditing(true)}
            style={{ marginTop: "10px" }}
          >
            Update
          </button>
        </>
      )}
    </div>
  );
};

export default AdminSubscription;
