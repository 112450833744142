import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Avatar, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import GuestLoginPopup from "../../components/preview/Popup";

const accessToken = getStorageItem("token");
const SearchBar = () => {
  
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  
  return (
  <>
    <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden", alignItems: "center" }}>
      <Autocomplete
        sx={{
          flexGrow: 1,
          width: 340,
          background: "rgb(43, 99, 149)",
          borderRadius: "5px",
          height: 40,
          '@media (max-width:600px)': {
            width: 270,
          }
        }}
        style={{ overflow: "hidden !important", color: "#FFFFFF !important" }}
        className="autocomplete"
        autoFocus={true}
        freeSolo
        disableClearable
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search…"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton sx={{ color: "#FFFFFF" }}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& fieldset': { border: 'none' },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&::placeholder': {
                color: '#FFFFFF', 
                opacity: 1, 
              },
              color: "#FFFFFF", 
            }}
            inputProps={{
              style: { color: "#FFFFFF" , padding:"0px" }, 
            }}
          />
        )}
      />
    </Box>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
  </>
  );
};

export default SearchBar;
