import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";
import { Grid, Paper, Button } from "@mui/material";
// import useScroller from "../../commons/useScroller.js";
import { Box } from "@mui/system";
import UserCard from "../followers/followers";
import SearchPosts from "./search-posts";
import Loader from "../../commons/components/Loader";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import AppContext from "../../context/AppContext";
import useInfiniteScroll from "../../commons/useScroller_new";
import sagaActions from "../../redux/actions";
import { setSearchText } from "../../redux/reducers/searchByPost";
import InfiniteScroll from "react-infinite-scroll-component";
import { BallTriangle } from "react-loader-spinner";

const Search = (props) => {
  const appContext = useContext(AppContext);
  const { searchText } = appContext;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchByPost = useSelector((state) => state.searchByPost);
  const {
    searchByPostData,
    searchByPostIsLoading,
    searchByPostSearchText,
    searchByPostError,
  } = searchByPost;
  const followingUsers = useSelector((state) => state.followingUsers);
  const { followingUsersData, followingUsersError } = followingUsers;

  useEffect(() => {
    if (searchText) {
      fetchMoreListItems();
    }
  }, [searchText]);

  const [toggleSubmenu, setToggleSubmenu] = useState("matchprofile");

  const fetchMoreListItems = () => {
    if (searchText && !searchByPostIsLoading) {
      let pageNum = searchByPostData.length === 0 ? 1 : 0;
      let prevData = [];
      if (searchByPostSearchText != searchText) {
        pageNum = 1;
      } else if (searchByPostData.length % global.config.pagePerSize === 0) {
        const ppg = Math.floor(
          searchByPostData.length / global.config.pagePerSize
        );
        const newPageNum =
          searchByPostData.length === 0
            ? 1
            : ppg > 0 && searchByPostData.length % ppg === 0
            ? ppg + 1
            : 0;
        pageNum = newPageNum;
        prevData = searchByPostData;
        debugger;
      }

      if (pageNum > 0) {
        dispatch(setSearchText(searchText));
        dispatch({
          type: sagaActions.SEARCHBYPOST_DATA_FETCH,
          payload: {
            data: {
              searchText: searchText,
              pageNumber: pageNum,
              pagePerSize: global.config.pagePerSize,
            },
            prevData: prevData,
          },
        });
      }
    } else {
      setIsFetching(false);
    }
  };

  const [isFetching, setIsFetching] = useInfiniteScroll(() => {
    fetchMoreListItems();
  });

  useEffect(() => {
    setIsFetching(false);
    dispatch({
      type: sagaActions.FOLLOWING_USERS_DATA_FETCH,
    });
  }, [searchByPostData]);

  useEffect(() => {
    if (followingUsersError?.response?.status === 401) {
      clearStorage();
      navigate("/login");
    }
  }, [followingUsersError]);
  console.log("followingUsersData", followingUsersData);

  return (
    <div>
      <Grid container spacing={0} className="search_page">
        <Grid item xs={12} sm={6} md={6} style={{ margin: "auto" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "center", sm: "left" },
            }}
          >
            <label className="recent_search">Recent Search</label>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} style={{ margin: "auto" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "center", sm: "right" },
            }}
          >
            <Button
              variant={
                toggleSubmenu === "matchprofile" ? "contained" : "outlined"
              }
              className={
                toggleSubmenu === "matchprofile"
                  ? "matching_profilies_btn contained"
                  : "matching_profilies_btn outlined"
              }
              onClick={() => {
                setToggleSubmenu("matchprofile");
                // setUrl(global.config.ROOTURL.prod + "/auth/name-search");
              }}
            >
              Matching Profilies
            </Button>
            <Button
              variant={toggleSubmenu === "post" ? "contained" : "outlined"}
              className={
                toggleSubmenu === "post"
                  ? "post_btn contained"
                  : "post_btn outlined"
              }
              onClick={() => {
                setToggleSubmenu("post");
                // setUrl(
                //   global.config.ROOTURL.prod + "/pitch/search-pitch-by-text"
                // );
              }}
            >
              Post
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div className={searchByPostData.length > 0 ? "search" : ""}>
        {toggleSubmenu === "matchprofile" && (
          <InfiniteScroll
            dataLength={searchByPostData.length}
            next={fetchMoreListItems}
            hasMore={!searchByPostIsLoading} // Set to false when all data is loaded
            loader={
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />
            }
          >
            <Grid container spacing={{ xs: 0, md: 2 }} className="search_page">
              {searchByPostData?.map((element, index) => {
                if (searchByPostData.length === index + 1) {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                      <UserCard
                        following={element}
                        key={index}
                        followings={followingUsersData}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <UserCard
                        following={element}
                        key={index}
                        followings={followingUsersData}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </InfiniteScroll>
        )}
        {searchByPostIsLoading && toggleSubmenu === "matchprofile" && (
          <Loader
            isLoading={isFetching}
            error={followingUsersError || searchByPostError}
          />
        )}
        {toggleSubmenu === "post" && <SearchPosts query={searchText} />}
      </div>
    </div>
  );
};

export default Search;

