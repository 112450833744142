import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import NewCard from "../card/NewCard";
import "./list.scss";

import { ChevronDown, ChevronUp, ChevronRight } from "lucide-react";
import { Button } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useMediaQuery } from "@mui/material";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import searchIcon from "../../assets/images/marketplace/searchIcon.png";
import filterIcon from "../../assets/images/marketplace/filterIcon.png";
import verifiedIcon from "../../assets/images/marketplace/badge.png";
import { useNavigate } from "react-router-dom";


// User Information
const userId = getStorageItem("user_id");
const userEmail = getStorageItem("user_email");

// Access Token
const accessToken = getStorageItem("token");

const Index = () => {
  const [myProducts, setMyProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(50);
  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState("");
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [isStockOpen, setIsStockOpen] = useState(false);
  const [isSellerOpen, setIsSellerOpen] = useState(false);
  const [isVerifiedSeller, setIsVerifiedSeller] = useState(false);
  const [isProductOutOfStock, setIsProductOutOfStock] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isVerifiedSellerSelected, setIsVerifiedSellerSelected] = useState(false);
  const [filterPriceValue, setFilterPriceValue] = useState(50);
  const [filteredProductResults, setFilteredProductResults] = useState([]);
  const [productByVerifiedSeller, setProductByVerifiedSeller] = useState([]);
  const [productSearchResults, setProductSearchResults] = useState([]);
  const [productSearchByName, setProductSearchByName] = useState('');
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [openFilterModal, setOpenFilterModal] = React.useState(false);


  const handleOpenFilterModal = () => setOpenFilterModal(true);
  const handleCloseFilterModal = () => setOpenFilterModal(false);

  // Function to handle page
  const handlePage = (input) => {
    if (page === 1 && input === -1) {
      setPage(1);
    } else if (page === pageCount && input === 1) {
      setPage(pageCount);
    } else {
      setPage(page + input);
    }
    setMyProducts([]);
  };

  // Function to fetchMyProducts
  async function fetchMyProducts(sellerId) {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + `/marketplace/product/getAllProductsBySellerId/${sellerId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const listedProducts = await response.json();
      setMyProducts(listedProducts);
      // setPageCount(count);
    } catch (err) {
      console.log(err);
    }
  }

  // Product Edit or Delete Modal
  const handleModal = (id) => {
    setModal(!modal);
    setModalId(id);
  };

  const handleSearchProduct = async () => {
    if (!accessToken || !productSearchByName) return;

    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/marketplace/product/searchProductByTitle?title=${encodeURIComponent(productSearchByName)}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const searchResults = await response.json();
        setProductSearchResults(searchResults);
      } else {
        toast.error("Search failed: " + (await response.text()));
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while searching");
    }
  };

  const handleFilterProduct = async () => {
    if (!accessToken) return;

    if (filterPriceValue > 50 || isProductOutOfStock) {
      try {
        let query = '';
        query += '?priceMin=50'
        if (isProductOutOfStock) {
          query += `&outOfStock=${isProductOutOfStock}`;
        }

        if (filterPriceValue > 50) {
          query += `&priceMax=${filterPriceValue}`;
        }

        const response = await fetch(
          `${global.config.ROOTURL.prod}/marketplace/product/filterProducts?${query}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const filterResults = await response.json();
          if (filterResults.length === 0) {
            toast.success("No product found between applied filter range")
          }
          setFilteredProductResults(filterResults);
          setIsFilterApplied(true);
          setIsVerifiedSeller(false);
          setOpenFilterModal(false);
        } else {
          toast.error("Filtering products failed: " + (await response.text()));
        }
      } catch (err) {
        console.error(err);
        toast.error("An error occurred while filtering products");
      }
    }
    else {
      if (isVerifiedSeller) {
        try {
          const response = await fetch(
            `${global.config.ROOTURL.prod}/marketplace/product/filterProductsByVerifiedSeller?isVerifiedSeller=${isVerifiedSeller}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.ok) {
            const filterResult = await response.json();
            if (filterResult?.length === 0) {
              toast.success("No verified Seller Product found");
            }
            setProductByVerifiedSeller(filterResult);
            setIsVerifiedSellerSelected(true);
            setIsFilterApplied(false);
            setOpenFilterModal(false);
          } else {
            toast.error("Filtering products failed: " + (await response.text()));
          }
        } catch (err) {
          console.error(err);
          toast.error("An error occurred while filtering products");
        }
      }
      else {
        toast.success("Please select filter options to apply filters")
      }
    }


  };


  // Delete Product
  const handleDeleteProduct = async () => {
    if (!accessToken && !modalId) return;
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + `/marketplace/product/deleteProductByProductId/${modalId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        toast.success("Product deleted successfully");
        const updatedProducts = myProducts.filter((product) => product._id !== modalId);
        setMyProducts(updatedProducts);
        handleModal();
        setModalId("");
      } else {
        toast.error("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Out of Stock Product
  const handleOutOfStock = async () => {
    if (!modalId && !accessToken) return
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + `/marketplace/product/outOfStockProductByProductId/${modalId}`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        toast.success("Product made out of stock successfully");
        handleModal();
        setModalId("");
      } else {
        toast.error("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMyProducts(userId);
  }, []);

  const togglePriceSection = () => {
    setIsPriceOpen(!isPriceOpen);
  };

  const toggleStockSection = () => {
    setIsStockOpen(!isStockOpen);
  };

  const toggleSellerSection = () => {
    setIsSellerOpen(!isSellerOpen);
  };

  const handleFilterPriceChange = (e) => {
    e.preventDefault();
    setFilterPriceValue(e.target.value);
  };

  return (
    <div className="product_list_mian_container">
      <div className="nav_search_container">
        <div className="nav_container">
          {isMobile ? (
            <div className="filter-button">
              <FilterListIcon
                onClick={handleOpenFilterModal}
                style={{ fontSize: 24, color: "#F37121", cursor: "pointer" }}
              />
            </div>
          ) : (
            <div className="nav_inner">
              <p className="nav_text" onClick={(e) => {
                e.stopPropagation();
                navigate("/marketplace");
              }}>Home</p>
              <ChevronRight className="arrowRight" strokeWidth={1.5} />
            </div>
          )}
          {/* <p className="nav_text2">Popular</p> */}
        </div>
        {isMobile && (
          <Modal
            open={openFilterModal}
            onClose={handleCloseFilterModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleForMobileViewModal}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "20px"
                }}
              >
                <h2
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "#0c5ba0",
                    textAlign: "center",
                  }}
                >Filter</h2>

                <div
                  style={{
                    width: "100%",
                    borde: "1px solid #0000001a"
                  }}
                ></div>

                <div style={{ width: "100%" }}>
                  <div
                    onClick={togglePriceSection}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "#424242",
                        textAlign: "left",
                      }}
                    >Price</h2>
                    {isPriceOpen ? <ChevronUp style={{
                      color: "var(--Icon-Icon-basic-default, #5e5f60)",
                      fontWeight: 400,
                    }} /> : <ChevronDown style={{
                      color: "var(--Icon-Icon-basic-default, #5e5f60)",
                      fontWeight: 400
                    }} />}
                  </div>
                  {isPriceOpen && (
                    <div className="price_content">
                      <input
                        type="range"
                        min={50} max={5000}
                        value={filterPriceValue}
                        onChange={handleFilterPriceChange}
                        style={{
                          width: "100%",
                          height: "6px",
                          background: "#fef0e7",
                          border: "none",
                          WebkitAppearance: "none",
                          appearance: "none",
                          outline: "none",
                          borderRadius: "20px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <p className="price_text">{filterPriceValue}</p>
                        <p className="price_text">5000</p>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{
                  width: "100%",
                  border: "1px solid #0000001a",
                }}>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                    onClick={toggleStockSection}>
                    <h2
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        textAlign: "left",
                        color: "#424242"
                      }}
                    >Stock</h2>
                    {isStockOpen ? <ChevronUp
                      style={{
                        color: "var(--Icon-Icon-basic-default, #5e5f60)",
                        fontWeight: 400,
                      }} /> : <ChevronDown
                      style={{
                        color: "var(--Icon-Icon-basic-default, #5e5f60)",
                        fontWeight: 400,
                      }}
                    />}
                  </div>
                  {isStockOpen && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "space-between",
                        marginTop: "10px"
                      }}
                    >
                      <input
                        type="checkbox"
                        value={isProductOutOfStock}
                        onChange={() => setIsProductOutOfStock(!isProductOutOfStock)}
                      />
                      <h4
                        style={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#9b9b9b"
                        }}
                      >Include Out of Stock</h4>
                    </div>
                  )}
                </div>

                <div style={{
                  width: "100%",
                  border: "1px solid #0000001a",
                }}>
                </div>

                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={toggleSellerSection}>
                    <h2
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        textAlign: "left",
                        color: "#424242",
                      }}
                    >Seller</h2>
                    {isSellerOpen ? <ChevronUp
                      style={{
                        color: "var(--Icon-Icon-basic-default, #5e5f60)",
                        fontWeight: 400,
                      }}
                    /> : <ChevronDown
                      style={{
                        color: "var(--Icon-Icon-basic-default, #5e5f60)",
                        fontWeight: 400,
                      }}
                    />}
                  </div>
                  {isSellerOpen && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        value={isVerifiedSeller}
                        onChange={() => setIsVerifiedSeller(!isVerifiedSeller)}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px"
                        }}
                      >
                        <h4
                          style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#9b9b9b"
                          }}
                        >Verified Seller</h4>
                        <img src={verifiedIcon} alt="" />
                      </div>
                    </div>
                  )}
                </div>
                <div style={{
                  width: "100%",
                  border: "1px solid #0000001a",
                }}>
                </div>

                <Button
                  style={{
                    width: "100%",
                    height: "48px",
                    borderRadius: "60px",
                    textTransform: "none",
                    color: "#ffffff",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    background: "var(--Neutral-Foreground-2-Brand-Selected, #0f6cbd)"
                  }}
                  onClick={handleFilterProduct} >Apply Filter</Button>
              </div>
            </Box>
          </Modal>
        )}
        <div className="search_container">
          <div className="icon_container">
            <img onClick={handleSearchProduct} src={searchIcon} alt="" />
          </div>
          <input
            className="search_Input"
            type="text"
            placeholder="Search among 1000+ products"
            value={productSearchByName}
            onChange={(e) => setProductSearchByName(e?.target?.value)}
            onKeyDown={(e) => {
              if (e?.key === 'Enter') {
                e?.preventDefault();
                handleSearchProduct();
              }
            }}
          />
        </div>
      </div>

      <div className="filter_card_container">
        <div className="filter_container">
          <div className="filter_icon_container">
            <h2 className="filter_text">Filter</h2>
            <img src={filterIcon} alt="" />
          </div>

          <div className="horizontal_line"></div>

          <div className="price_container">
            <div className="price_inner" onClick={togglePriceSection}>
              <h2 className="price_text">Price</h2>
              {isPriceOpen ? <ChevronUp className="arrowIcon" /> : <ChevronDown className="arrowIcon" />}
            </div>
            {isPriceOpen && (
              <div className="price_content">
                <input
                  className="input_value"
                  type="range"
                  min={50} max={5000}
                  value={filterPriceValue}
                  onChange={handleFilterPriceChange}
                />
                <div className="price_range">
                  <p className="price_text">{filterPriceValue}</p>
                  <p className="price_text">5000</p>
                </div>
              </div>
            )}
          </div>

          <div className="horizontal_line"></div>

          <div className="stock_container">
            <div className="stock_inner" onClick={toggleStockSection}>
              <h2 className="stock_text">Stock</h2>
              {isStockOpen ? <ChevronUp className="arrowIcon" /> : <ChevronDown className="arrowIcon" />}
            </div>
            {isStockOpen && (
              <div className="stock_content">
                <input
                  className="input_value"
                  type="checkbox"
                  value={isProductOutOfStock}
                  onChange={() => setIsProductOutOfStock(!isProductOutOfStock)}
                />
                <h4 className="stock_out_text">Include Out of Stock</h4>
              </div>
            )}
          </div>

          <div className="horizontal_line"></div>

          <div className="seller_container">
            <div className="seller_inner" onClick={toggleSellerSection}>
              <h2 className="seller_text">Seller</h2>
              {isSellerOpen ? <ChevronUp className="arrowIcon" /> : <ChevronDown className="arrowIcon" />}
            </div>
            {isSellerOpen && (
              <div className="seller_content">
                <input
                  className="input_value"
                  type="checkbox"
                  value={isVerifiedSeller}
                  onChange={() => setIsVerifiedSeller(!isVerifiedSeller)}
                />
                <div className="verified_container">
                  <h4 className="verifie_seller_text">Verified Seller</h4>
                  <img src={verifiedIcon} alt="" />
                </div>
              </div>
            )}
          </div>

          <div className="horizontal_line"></div>

          <Button className="filter_button" onClick={handleFilterProduct} >Apply Filter</Button>
        </div>
        {productSearchResults?.length > 0 ? (
          <div className="product_card_container">
            {productSearchResults?.map((product, index) => {
              return <NewCard key={index} product={product} index={index} />
            })}
          </div>
        ) : isFilterApplied ? (
          <div className="product_card_container">
            {filteredProductResults?.map((product, index) => {
              return <NewCard key={index} product={product} index={index} />
            })}
          </div>
        ) : isVerifiedSellerSelected ? (
          <div className="product_card_container">
            {productByVerifiedSeller?.map((product, index) => {
              return <NewCard key={index} product={product} index={index} />
            })}
          </div>
        ) : (
          <div className="product_card_container">
            {myProducts?.map((product, index) => {
              return <NewCard key={index} product={product} index={index} />
            })}
          </div>
        )}
      </div>
    </div >
  )
};

export default Index;


const styleForMobileViewModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "94%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "4px",
  outline: "none",
  p: 4,
  outlinedInputClasses: "none",
};
