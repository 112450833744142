import react from "react";

import logo from "../../assets/images/profile/default_avatar.png";
// import redball from "../../assets/images/redball.png"
import people from "../../assets/images/peoples.png";
import clockimg from "../../assets/images/clock.png";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";

export default function ProfileCard({ data }) {
  const accessToken = getStorageItem("token");
  const addToBlackList = () => {
    if (!accessToken) return;

    const options = {
      method: "post",
      url:
        global.config.ROOTURL.prod +
        `/groupchat/addUserToBlacklist/6697917f614a6ce64150805f/${data.userId._id}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };
  return (
    <div
      style={{
        width: "300px",
        height: "230px",
        alignItems: "center",
        border: "1px solid gray",
        backgroundColor: "#FFFFFF",
        padding: "20px",
        margin: "10px",
        textAlign: "center",
        borderRadius: "10px",
      }}
    >
      <img
        src={data.userId.profilePhoto ? data.userId.profilePhoto : logo}
        width={80}
        height={80}
        style={{ borderRadius: "50%" }}
      />
      <p style={{ marginTop: "10px" }}>
        <strong style={{ color: "#0C5BA0", fontSize: "20px" }}>
          {data?.userId?.firstName ? data.userId.firstName : ""}{" "}
          {data?.userId?.lastName ? data?.userId.lastName : ""}
        </strong>
      </p>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "3px" }}>
          <img src={people} width={20} />
          <p style={{ color: "#898A8D", fontSize: "13px" }}>20020</p>
        </div>
        <div style={{ display: "flex", gap: "3px" }}>
          <img src={clockimg} width={20} height={20} />
          <p style={{ color: "#898A8D", fontSize: "13px" }}>
            5th July’24| 5:00 pm
          </p>
        </div>
      </div> */}

      <p style={{ marginTop: "10px" }}>Reported By {data.count} users</p>

      <button
        style={{
          padding: "5px 20px",
          border: "none",
          borderRadius: "7px",
          cursor: "pointer",
        }}
        onClick={addToBlackList}
      >
        Add to Black List
      </button>
    </div>
  );
}
