import React from 'react';
import ProductImage from '../../assets/images/marketplace/shoesimage.png';
import SellerDummyImage from '../../assets/images/marketplace/dummyImage.png';
import VerifiedImage from '../../assets/images/marketplace/verified.png'
import "./ProductItem.scss";


const ProductItems = () => {
  return (
    <div className='Product_all_Item'>
    <img
        src={ProductImage}
        alt="Nike RedStar Pro Sports"
        style={{ width: "147.37px", height: "147.37px", marginBottom: '5px' }}
      />
      <hr style={{ width: '100%', margin: '5px 0', color: "#EAEAEA" }} />

      <div className='text_holder_cantainer'>
        <p className='Product_name'>Nike RedStar Pro Sports</p>
        <p className='Product_prices'>
            Original price <span className='price'>₹ 22000</span>
        </p>

        <div className="seller_details">
        <img
          src={SellerDummyImage}
          alt="seller Profile"
          style={{ width: '20px', height: '20px', marginRight: '5px' }}
        />
        <p style={{color: "#1A1919", fontSize: "8px", fontWeight: "300", paddingRight: "10px"}}>Seller Name</p>
        <img src={VerifiedImage } />

      </div>
      </div>

    
    
    </div>

  )

}

export default ProductItems
