import React from 'react';
import logo from '../../assets/images/landigpage2/logo.svg';
import arrow from "../../assets/images/usplandingpage/arrow.png";
import SignUp from '../signin-signup/signup/signup.component';
import { useNavigate } from "react-router-dom";
import shoespic from "../../assets/images/usplandingpage/shoespic.png";
import { Box } from "@mui/material";



const UspFirst = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='usp_bgimage'>
        <div className='usp_maindiv'>
          <div className='usp_container'>
            <div className='usp_logotext'>
              <img style={{ width: "64px" }} src={logo} alt="champhuntlogo" />
              <p style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Poppins' }}>Champ Hunt</p>
            </div>
            <button className='Usplogin_btn' onClick={() => navigate('/login')}>Login</button>
          </div>
        </div>

        <div className='usp_firstcont'>

          <div className='uspFirst'>
            <div className='usprow1'>
              <h1>Sign up and win!</h1>
              <img src={arrow} alt="arrowimg" />
            </div>
            <div className='shoes_text'>
              <p className='usp_para'>Sign up now and stand a chance to win an<span> Adidas Agora 1.0 Multisport Training Shoes.</span> Guaranteed bonus runs for everyone who signs up. Special giveaway winners announced on our instagram handle every week.</p>
            </div>
            <button variant="contained" className='uspsignup_btn' onClick={() => navigate('/signup')}>SIGN UP NOW</button>

            <div className='usp_shoesimg'>
              <div className='uspdiamondgimg'> </div>
              <img src={shoespic} alt="shoesimg" />
            </div>


          </div>


          <div className='uspsecond'>
            <Box className="firstSection_Signup" sx={{ width: { xs: "100%", md: "400px" }, maxWidth: '400px' }}>
              <SignUp custClassName="landingPageSignUp" />
            </Box>
          </div>
        </div>

        <div className='main_rectangle'></div>

        {/* bgiamgeclosediv */}
      </div>
    </>
  )
}

export default UspFirst
