// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase,ref, onValue } from "firebase/database";
import config from './config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcsK55rvX88P6-hua8XkXdmlB3sVgHdE4",
  authDomain: "champ-hunt-app.firebaseapp.com",
  databaseURL: "https://champ-hunt-app-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "champ-hunt-app",
  storageBucket: "champ-hunt-app.appspot.com",
  messagingSenderId: "974792648382",
  appId: "1:974792648382:web:fe81769bb6e1968f8520ef",
  measurementId: "G-HKMF1FQ4R7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const initOnValueListner = (path)=>{
  console.log('${NODE_ENV}/${path}',`${config.NODE_ENV}/${path}`);
  const starCountRef = ref(db, `${config.NODE_ENV}/${path}`);
  return {onValue, starCountRef };
}

export default initOnValueListner;


