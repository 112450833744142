import React, { useState, useEffect } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import { Box, Tabs, Tab, InputBase, Switch } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import FilterPopup from "../statusModal/FilterOrderModal";
import dayjs from "dayjs";
import "./index.scss";

import NewCard from "../card/NewCard";
import NotFoundPage from "../notfound";

const accessToken = getStorageItem("token");
const userId = getStorageItem("user_id");

const Index = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [toggleChecked, setToggleChecked] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSellActive, setIsSellActive] = useState(true);
  const [sellProductBySeller, setSellProductBySeller] = useState([]);
  const [searchDataBySeller, setSearchDataBySeller] = useState([]);
  const [productsInStockBySeller, setProductsInStockBySeller] = useState([]);
  const [productsOutOfStockBySeller, setProductsOutOfStockBySeller] = useState(
    []
  );
  const [purchasedProductByBuyer, setPurchasedProductByBuyer] = useState([]);
  const [searchDataByUser, setSearchDataByUser] = useState([]);
  const [placeProductsByBuyer, setPlacedProductsByBuyer] = useState([]);
  const [dispatchedProductsByBuyer, setDispatchedProductsByBuyer] = useState(
    []
  );
  const [onTheWayProductsByBuyer, setOnTheWayProductsByBuyer] = useState([]);
  const [deliveredProductsByBuyer, setDeliveredProductsByBuyer] = useState([]);
  const [cancelledProductsByBuyer, setCancelledProductsByBuyer] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [queryParams, setQueryParams] = useState("thisWeek");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [toggleButton, setToggleButton] = useState(false);
  const [isSearchForPurchaseOrder, setIsSearchForPurchaseOrder] = useState(false);
  const [isSearchForSellOrder, setIsSearchForSellOrder] = useState(false);
  const [sellerDetail, setSellerDetail] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState("");
  const navigate = useNavigate();

  const formatDate = (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "");

  //get all products listed by seller
  const getAllProductBySellerId = async (id) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
          `/marketplace/product/getAllProductsBySellerId/${id}?dateRange=${queryParams}&startDate=${formatDate(
            fromDate
          )}&endDate=${formatDate(toDate)}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setSellProductBySeller(data);
      setProductsInStockBySeller(data.filter((product) => product.stock > 0));
      setProductsOutOfStockBySeller(
        data.filter((product) => product.stock === 0)
      );
      setIsSearchForPurchaseOrder(false);
      setIsSearchForSellOrder(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSellerOrderPage = () => {
    navigate("/marketplace/seller_orders");
  };

  //get all products purchased by user or seller
  const getAllProductByBuyerId = async (id) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
          `/marketplace/buyer/getallPurchasedProductsByDate/${id}?dateRange=${queryParams}&startDate=${formatDate(
            fromDate
          )}&endDate=${formatDate(toDate)}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setPurchasedProductByBuyer(data);
      setPlacedProductsByBuyer(
        data.filter((product) => product.currentState === "placed")
      );
      setDispatchedProductsByBuyer(
        data.filter((product) => product.currentState === "dispatched")
      );
      setOnTheWayProductsByBuyer(
        data.filter((product) => product.currentState === "on-its-way")
      );
      setDeliveredProductsByBuyer(
        data.filter((product) => product.currentState === "delivered")
      );
      setCancelledProductsByBuyer(
        data.filter((product) => product.currentState === "cancelled")
      );
      setIsSearchForPurchaseOrder(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  // search items listed by seller
  const searchProductsByOrder = async (id) => {
    if (!accessToken || !searchInput) return;

    if (toggleButton) {
      try {
        const response = await fetch(
          global.config.ROOTURL.prod +
            `/marketplace/buyer/searchPurchasedProductsByTitle/${userId}?title=${encodeURIComponent(
              searchInput
            )}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSearchDataByUser(data);
          setSearchDataBySeller([]);
          setIsSearchForPurchaseOrder(true);
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      try {
        const response = await fetch(
          global.config.ROOTURL.prod +
            `/marketplace/product/searchListedProductBySellerByTitle/${userId}?title=${encodeURIComponent(
              searchInput
            )}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSearchDataBySeller(data);
          setSearchDataByUser([]);
          setIsSearchForSellOrder(true);
          // setIsSellerData(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  // Function to fetch seller details
  const handleCheckSeller = async (sellerId) => {
    if (!sellerId && !accessToken) return;
    const opt = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/findInformationOfSingleSellerById/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(opt)
      .then((data) => {
        setSellerDetail(data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // delete product
  const handleDeleteProduct = async () => {
    if (!accessToken && !modalId) return;
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
          `/marketplace/product/deleteProductByProductId/${modalId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        toast.success("Product deleted successfully");
        const updatedProducts = sellProductBySeller.filter(
          (product) => product._id !== modalId
        );
        setSellProductBySeller(updatedProducts);
        handleModal();
        setModalId("");
      } else {
        toast.error("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // handle out of stock
  const handleOutOfStock = async () => {
    if (!modalId && !accessToken) return;
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
          `/marketplace/product/outOfStockProductByProductId/${modalId}`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        toast.success("Product made out of stock successfully");
        handleModal();
        setModalId("");
      } else {
        toast.error("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  //delete modal
  const handleModal = (id) => {
    setModal(!modal);
    setModalId(id);
  };

  useEffect(() => {
    handleCheckSeller(userId);
  }, [userId]);

  useEffect(() => {
    if (toggleButton) {
      getAllProductByBuyerId(id);
    }
    getAllProductBySellerId(id);
  }, [id, queryParams, toggleButton]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggleChange = (event) => {
    setToggleChecked(event.target.checked);
  };

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const selectFilter = (filter) => {
    setSelectedFilter(filter);
    setFilterOpen(false);
  };

  //handle Toggle Button Here
  const handleToggle = () => {
    setIsSellActive(!isSellActive);
  };

  const handleFilterClick = () => {
    setIsFilterOpen(true);
  };

  const handleClose = () => {
    setIsFilterOpen(false);
  };

  const handleApply = (filters) => {
    setFilters(filters);
  };

  const handleFilterChange = (selectedFilter) => {
    setFilters(selectedFilter);
  };

  const handleApplyFilters = () => {
    if (toggleChecked) {
      getAllProductByBuyerId(id); 
    } else {
      getAllProductBySellerId(id); 
    }
    setFilterOpen(false); // Close filter popup
  };

  return (
    <>
      {modal && (
        <div className="edit__modal">
          <div className="modal">
            <img
              src="/marketplace/cancel.svg"
              alt="cancel"
              className="cancel__button"
              onClick={handleModal}
            />
            <p className="modal__text">Are you sure you want to Delete?</p>
            <button className="modal__button" onClick={handleDeleteProduct}>
              Yes
            </button>
            <button className="modal__button" onClick={handleOutOfStock}>
              Change to out of Stock
            </button>
          </div>
        </div>
      )}
      {isMobile && (
        //this code is for mobile View
        //Start from here
        <div className="MainOrderStatus-1">
          <div className="mobile-headers">
            <ArrowBackIcon className="order-back-icon" 
              onClick={() => navigate("/marketplace")}
            />
            <p className="myOrder-heading">My Orders</p>
          </div>

          <div className="secarh-nav-conatiner">
            <div className="search-container">
              <InputBase
                placeholder="Search orders"
                inputProps={{ "aria-label": "search" }}
                className="search-input"
                value={searchInput}
                onChange={(e) => {
                  e?.preventDefault();
                  setSearchInput(e?.target?.value);
                }}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    e?.preventDefault();
                    searchProductsByOrder(userId);
                  }
                }}
              />
              <div className="search-icon">
                <SearchIcon
                  onClick={(e) => {
                    e.preventDefault();
                    searchProductsByOrder(userId);
                  }}
                />
              </div>
            </div>
            <div className="filter-button" onClick={handleFilterClick}>
              <FilterListIcon
                style={{ fontSize: 24, color: "#F37121", cursor: "pointer" }}
              />
            </div>
            {/* here Render the Filter Popup for Mobile view */}
            <FilterPopup
              // isOpen={isFilterOpen}
              // onClose={handleClose}
              // onApply={handleApply}
              // setIsOpne={setIsFilterOpen}
              isOpen={isFilterOpen}
              onClose={handleClose}
              onFilterChange={handleFilterChange}
              onApplyFilters={handleApplyFilters}
              filters={filters}
            />
          </div>

          {/* Toggle Buuton Section*/}
          <div className="toggle-container">
            <button
              className={`toggle-button ${isSellActive ? "active" : ""}`}
              onClick={() => {
                handleToggle();
                getAllProductBySellerId(id);
                setToggleButton(false);
              }}
            >
              Product Sell
            </button>
            <button
              className={`toggle-button ${!isSellActive ? "active" : ""}`}
              onClick={() => {
                handleToggle();
                getAllProductByBuyerId(id);
                setToggleButton(true);
              }}
            >
              Product Purchased
            </button>
          </div>
        </div>
        //End here
      )}
      <div className="MainOrderStatus-1">
        {!isMobile && (
          <>
            <p className="myOrder-heading">My Orders</p>
            <Box
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                padding: "0 16px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                scrollButtons="auto"
                aria-label="time range tabs"
                TabIndicatorProps={{
                  sx: { display: "none" },
                }}
              >
                <Tab
                  label="This Week"
                  sx={tabStyles(value === 0)}
                  onClick={() => {
                    setQueryParams("thisWeek");
                    setSearchDataBySeller([]);
                    setSearchDataByUser([]);
                  }}
                />
                <Tab
                  label="Last Week"
                  sx={tabStyles(value === 1)}
                  onClick={() => {
                    setQueryParams("lastWeek");
                    setSearchDataBySeller([]);
                    setSearchDataByUser([]);
                  }}
                />
                <Tab
                  label="Last Month"
                  sx={tabStyles(value === 2)}
                  onClick={() => {
                    setQueryParams("lastMonth");
                    setSearchDataBySeller([]);
                    setSearchDataByUser([]);
                  }}
                />
                <Tab
                  label="Last 3 Months"
                  sx={tabStyles(value === 3)}
                  onClick={() => {
                    setQueryParams("last3Months");
                    setSearchDataBySeller([]);
                    setSearchDataByUser([]);
                  }}
                />
                <Tab
                  label="Last 6 Months"
                  sx={tabStyles(value === 4)}
                  onClick={() => {
                    setQueryParams("last6Months");
                    setSearchDataBySeller([]);
                    setSearchDataByUser([]);
                  }}
                />
                <Box sx={{ margin: "0px 5px 0px 0px !important"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                    />
                  </LocalizationProvider>
                </Box>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        value={toDate}
                        onChange={(newValue) => setToDate(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <Tab
                  label="Go"
                  sx={tabStyles(value === 5)}
                  onClick={() => {
                    setQueryParams("custom");
                    setSearchDataBySeller([]);
                    setSearchDataByUser([]);
                  }}
                />
              </Tabs>
            </Box>
          </>
        )}
        {!isMobile && (
          <div className="secarh-nav-conatiner">
            <div className="search-container">
              <InputBase
                placeholder="Search orders"
                inputProps={{ "aria-label": "search" }}
                className="search-input"
                type="text"
                value={searchInput}
                onChange={(e) => {
                  e?.preventDefault();
                  setSearchInput(e?.target?.value);
                }}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    e?.preventDefault();
                    searchProductsByOrder(userId);
                  }
                }}
              />
              <div className="search-icon">
                <SearchIcon
                  onClick={(e) => {
                    e.preventDefault();
                    searchProductsByOrder(userId);
                  }}
                />
              </div>
            </div>

            {/* Filter List Icon */}
            <div className="filter-button" onClick={toggleFilter}>
              <FilterListIcon
                style={{ fontSize: 24, color: "#F37121", cursor: "pointer" }}
              />
              {filterOpen && (
                <div className="filter-dropdown">
                  {sellerDetail?.sellerId !== userId ? (
                    <div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("All");
                          getAllProductByBuyerId(id);
                        }}
                      >
                        <span>All</span>
                        {selectedFilter === "All" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Placed");
                          setPurchasedProductByBuyer(placeProductsByBuyer);
                        }}
                      >
                        <span>Placed</span>
                        {selectedFilter === "Placed" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Dispatched");
                          setPurchasedProductByBuyer(dispatchedProductsByBuyer);
                        }}
                      >
                        <span>Dispatched</span>
                        {selectedFilter === "Dispatched" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("On-the-way");
                          setPurchasedProductByBuyer(onTheWayProductsByBuyer);
                        }}
                      >
                        <span>On the way</span>
                        {selectedFilter === "On-the-way" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Delivered");
                          setPurchasedProductByBuyer(deliveredProductsByBuyer);
                        }}
                      >
                        <span>Delivered</span>
                        {selectedFilter === "Delivered" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Cancelled");
                          setPurchasedProductByBuyer(cancelledProductsByBuyer);
                        }}
                      >
                        <span>Cancelled</span>
                        {selectedFilter === "Cancelled" && <CheckIcon />}
                      </div>
                    </div>
                  ) : sellerDetail?.sellerId === userId && toggleButton ? (
                    <div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("All");
                          getAllProductByBuyerId(id);
                        }}
                      >
                        <span>All</span>
                        {selectedFilter === "All" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Placed");
                          setPurchasedProductByBuyer(placeProductsByBuyer);
                        }}
                      >
                        <span>Placed</span>
                        {selectedFilter === "Placed" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Dispatched");
                          setPurchasedProductByBuyer(dispatchedProductsByBuyer);
                        }}
                      >
                        <span>Dispatched</span>
                        {selectedFilter === "Dispatched" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("On-the-way");
                          setPurchasedProductByBuyer(onTheWayProductsByBuyer);
                        }}
                      >
                        <span>On the way</span>
                        {selectedFilter === "On-the-way" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Delivered");
                          setPurchasedProductByBuyer(deliveredProductsByBuyer);
                        }}
                      >
                        <span>Delivered</span>
                        {selectedFilter === "Delivered" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Cancelled");
                          setPurchasedProductByBuyer(cancelledProductsByBuyer);
                        }}
                      >
                        <span>Cancelled</span>
                        {selectedFilter === "Cancelled" && <CheckIcon />}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("All");
                          getAllProductBySellerId(id);
                        }}
                      >
                        <span>All</span>
                        {selectedFilter === "All" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Active");
                          setSellProductBySeller(productsInStockBySeller);
                        }}
                      >
                        <span>Active</span>
                        {selectedFilter === "Active" && <CheckIcon />}
                      </div>
                      <div
                        className="filter-option"
                        onClick={() => {
                          selectFilter("Sold-Out");
                          setSellProductBySeller(productsOutOfStockBySeller);
                        }}
                      >
                        <span>Sold Out</span>
                        {selectedFilter === "Sold-Out" && <CheckIcon />}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="myOrder-rightside-toggle-main">
              <button
                className="seller_order_button"
                onClick={handleSellerOrderPage}
              >
                Seller Order
              </button>
              <p className="sell-text">Sell</p>
              {/* Toggle Switch */}
              <Switch
                checked={toggleChecked}
                onChange={handleToggleChange}
                onClick={() => {
                  setToggleButton(!toggleButton);
                  // setIsSellerData(!isSellerData);
                }}
                color="primary"
                inputProps={{ "aria-label": "toggle switch" }}
                className="custom-switch-color"
              />
              <p className="purchased-text">Purchased</p>
            </div>
          </div>
        )}
        {toggleButton ? (
          searchDataByUser?.length > 0 ? (
            <div className="cards_container">
              {searchDataByUser?.map((product, index) => {
                return (
                  <NewCard
                    key={index}
                    product={product?.productDetails}
                    buyerDetails={product}
                    index={index}
                    type="purchased"
                  />
                );
              })}
            </div>
          ) : isSearchForPurchaseOrder && searchDataByUser?.length === 0 ? (
            <NotFoundPage />
          ) : purchasedProductByBuyer?.length === 0 ? (
            <NotFoundPage />
          ) : (
            <div className="cards_container">
              {purchasedProductByBuyer?.map((product, index) => {
                return (
                  <NewCard
                    key={index}
                    product={product?.productDetails}
                    buyerDetails={product}
                    index={index}
                    type="purchased"
                  />
                );
              })}
            </div>
          )
        ) : searchDataBySeller?.length > 0 ? (
          <div className="cards_container">
            {searchDataBySeller?.map((product, index) => {
              return <NewCard key={index} product={product} index={index} />;
            })}
          </div>
        ) : isSearchForSellOrder && searchDataBySeller?.length === 0 ? (
          <NotFoundPage />
        ) : sellProductBySeller?.length === 0 ? (
          <NotFoundPage />
        ) : (
          <div className="cards_container">
            {sellProductBySeller?.map((product, index) => {
              return (
                <NewCard
                  key={index}
                  product={product}
                  index={index}
                  handleModal={handleModal}
                  type="editable"
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

const tabStyles = (isSelected) => ({
  backgroundColor: isSelected ? "#E0F2FF" : "#fff",
  color: "#000",
  border: "1px solid #ccc",
  borderRadius: "4px",
  margin: "0 4px",
  padding: "8px 16px",
  minWidth: "auto",
  textTransform: "none",
});

export default Index;

