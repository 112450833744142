import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Box, IconButton, Typography, } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PlayerList from './playerList';
import PlayerDetails from './playerDetails';
import PlayerDetailPopup from './playerDetailPopup';
import LivePopup from './livePopup';
import Leaderboard from './leaderboard';
import LeaderBoardRank from './leaderBoardRank';

import { SocketContext } from '../../context/SocketContext';

const AuctionAce = () => {
    const navigate = useNavigate();
    const mobileView = useMediaQuery("(max-width:768px)");
    const [selectedPlayer, setSelectedPlayer] = useState(false);
    const [openModel, setOpenModel] = useState(false);
    const [isPlayerSold, setIsPlayerSold] = useState(false);
    const [playerId, setPlayerId] = useState(null);
    const [openLivePopup, setOpenLivePopup] = useState(false);
    const [livePlayerId, setLivePlayerId] = useState(null)
    const [socket, setSocket] = useContext(SocketContext);
    const [socketConnected, setSocketConnected] = useState(false);

    const handleCardClick = () => {
        setSelectedPlayer(true);
    };

    const handleOpenPlayerDetails = (e) => {
        e.stopPropagation()
        setOpenModel(true)
    }

    useEffect(() => {
        if (!socket) {
            return;
        }

        const handleAuctionStart = (data) => {
            setLivePlayerId(data.playerId);
            setOpenLivePopup(true)
        };

        socket.on("connect", () => {
            setSocketConnected(true)
        });
        socket.on("auctionStart", handleAuctionStart);

        return () => {
            socket.off("connect");
            socket.off("auctionStart", handleAuctionStart);
        };
    }, [socket]);

    return (
        <Box
            sx={{
                width: "100%",
                position: 'relative',
                top: '115px',
                paddingBottom: '30px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100%",
                    height: '50px',
                    background: '#FFFFFF',
                    borderBottom: '1px solid #AFB8CF',
                }}
            >
                <IconButton sx={{ position: 'absolute', left: '20px' }} onClick={() => navigate(-1)}>
                    <ArrowBackIcon sx={{ fontSize: 35, color: '#212121' }} />
                </IconButton>
                <Typography
                    variant="h5"
                    align="center"
                    sx={{
                        color: '#0B518E',
                        fontWeight: 600,
                    }}
                >
                    Auction Ace
                </Typography>
            </Box>
            {isPlayerSold ? (
                <Box sx={{
                    display: 'flex',
                    gap: '20px',
                    padding: mobileView ? '10px 15px' : '10px 40px',
                    flexDirection: mobileView ? 'column' : 'row'
                }}>
                    <Leaderboard playerId={playerId} />
                    <LeaderBoardRank playerId={playerId} />
                </Box>
            ) : (
                <Box sx={{ display: selectedPlayer ? 'flex' : 'block' }}>
                    {mobileView && selectedPlayer ? (
                        <PlayerDetails
                            playerId={playerId} />
                    ) : (
                        <PlayerList
                            selectedPlayer={selectedPlayer}
                            setSelectedPlayer={setSelectedPlayer}
                            setIsPlayerSold={setIsPlayerSold}
                            setPlayerId={setPlayerId}
                            handleCardClick={handleCardClick}
                            handleOpenPlayerDetails={handleOpenPlayerDetails}
                        />
                    )}
                    {selectedPlayer && !mobileView && (
                        <PlayerDetails playerId={playerId} />
                    )}
                </Box>
            )}
            {openModel && (
                <PlayerDetailPopup
                    open={openModel}
                    setOpen={setOpenModel}
                    playerId={playerId}
                />
            )}
            {openLivePopup && (
                <LivePopup
                    open={openLivePopup}
                    setOpen={setOpenLivePopup}
                    livePlayerId={livePlayerId}
                    setSelectedPlayer={setSelectedPlayer}
                    setPlayerId={setPlayerId}
                />
            )}
        </Box>
    )
}

export default AuctionAce;