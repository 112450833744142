import React, { useEffect, useState } from "react";
import axios from "axios";
import UploadImg from "../.././../assets/images/uploadImage.png";
import { getStorageItem } from "../../../utils/sessionStorage";

const AdsSection = () => {
  const [form, setForm] = useState({
    adFile: null,
    adURL: "",
    title: "",
    redirectURL: "",
  });
  const [errors, setErrors] = useState({});
  const [ads, setAds] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingAds, setIsLoadingAds] = useState(false);
  const accessToken = getStorageItem("token");

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file, file.name);
    const uploadResponse = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (uploadResponse.data && uploadResponse.data.length > 0) {
      return uploadResponse.data[0].location;
    } else {
      throw new Error("No data returned from upload.");
    }
  };

  const getAllAds = () => {
    if (!accessToken) return;
    setIsLoadingAds(true);
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllAds`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setAds(data);
      })
      .catch((error) => {
        console.log("Error fetching ads:", error);
      })
      .finally(() => {
        setIsLoadingAds(false);
      });
  };

  const uploadAd = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsUploading(true);
    try {
      let adURL = form.adURL;
      if (form.adFile) {
        adURL = await uploadFileToS3(form.adFile);
      }

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/groupchat/addAds`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          title: form.title,
          image: adURL,
          redirectUrl: form.redirectURL,
        },
      };

      const { data } = await axios(options);
      setAds((prevAds) => [...prevAds, data]);
      setForm({ adFile: null, adURL: "", title: "", redirectURL: "" });
      setErrors({});
    } catch (error) {
      console.log("Error uploading ad:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const deleteAd = async (adId) => {
    try {
      await axios.delete(
        `${global.config.ROOTURL.prod}/groupchat/deleteAd/${adId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      setAds((prevAds) => prevAds.filter((ad) => ad._id !== adId));
    } catch (error) {
      console.log("Error deleting ad:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setForm({ ...form, adFile: file });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!form.adFile && !form.adURL) {
      formErrors.ad = "Please upload an ad image or provide a URL";
    }
    if (!form.title.trim()) {
      formErrors.title = "Title is required";
    }
    if (!form.redirectURL.trim()) {
      formErrors.redirectURL = "Redirect URL is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  useEffect(() => {
    getAllAds();
  }, []);

  const styles = {
    adsSection: {
      padding: "20px",
      maxWidth: "800px",
      margin: "0 auto",
      fontFamily: "Arial, sans-serif",
    },
    adForm: {
      backgroundColor: "#f8f9fa",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: "30px",
    },
    fileUpload: {
      textAlign: "center",
      marginBottom: "20px",
    },
    uploadImage: {
      marginBottom: "10px",
    },
    fileInputLabel: {
      display: "inline-block",
      padding: "10px 15px",
      backgroundColor: "#007bff",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    fileName: {
      marginTop: "10px",
      fontStyle: "italic",
    },
    textInput: {
      width: "100%",
      padding: "10px",
      marginBottom: "15px",
      border: "1px solid #ced4da",
      borderRadius: "5px",
      fontSize: "16px",
    },
    submitButton: {
      width: "100%",
      padding: "10px",
      backgroundColor: "#28a745",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "18px",
      transition: "background-color 0.3s",
    },
    error: {
      color: "#dc3545",
      marginTop: "5px",
    },
    adsContainer: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
    },
    loading: {
      textAlign: "center",
      fontSize: "18px",
      color: "#6c757d",
    },
    adsGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gap: "20px",
    },
    adCard: {
      position: "relative",
      backgroundColor: "#f8f9fa",
      borderRadius: "10px",
      overflow: "hidden",
      transition: "transform 0.3s",
    },
    adImage: {
      width: "100%",
      height: "150px",
      objectFit: "cover",
    },
    adInfo: {
      padding: "10px",
    },
    adTitle: {
      margin: "0 0 10px 0",
      fontSize: "16px",
    },
    adLink: {
      color: "#007bff",
      textDecoration: "none",
      cursor: "pointer",
    },
    deleteButton: {
      position: "absolute",
      top: "5px",
      right: "5px",
      backgroundColor: "rgba(220, 53, 69, 0.8)",
      color: "white",
      border: "none",
      borderRadius: "50%",
      width: "25px",
      height: "25px",
      fontSize: "18px",
      lineHeight: "1",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
  };

  return (
    <div style={styles.adsSection}>
      <form onSubmit={uploadAd} style={styles.adForm}>
        <div style={styles.fileUpload}>
          <img
            src={UploadImg}
            width={150}
            height={150}
            alt="Upload"
            style={styles.uploadImage}
          />
          <p>Upload Ad Image Here</p>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            id="adFileInput"
            style={{ display: "none" }}
          />
          <label htmlFor="adFileInput" style={styles.fileInputLabel}>
            Choose File
          </label>
          {form.adFile && <p style={styles.fileName}>{form.adFile.name}</p>}
          {errors.ad && <p style={styles.error}>{errors.ad}</p>}
        </div>
        <input
          type="text"
          name="adURL"
          value={form.adURL}
          onChange={handleInputChange}
          placeholder="Or enter ad image URL"
          style={styles.textInput}
        />
        <input
          type="text"
          name="title"
          value={form.title}
          onChange={handleInputChange}
          placeholder="Ad Title"
          style={styles.textInput}
        />
        {errors.title && <p style={styles.error}>{errors.title}</p>}
        <input
          type="text"
          name="redirectURL"
          value={form.redirectURL}
          onChange={handleInputChange}
          placeholder="Redirect URL"
          style={styles.textInput}
        />
        {errors.redirectURL && <p style={styles.error}>{errors.redirectURL}</p>}
        <button
          type="submit"
          disabled={isUploading}
          style={{
            ...styles.submitButton,
            backgroundColor: isUploading ? "#6c757d" : "#28a745",
            cursor: isUploading ? "not-allowed" : "pointer",
          }}
        >
          {isUploading ? "Uploading..." : "Upload Ad"}
        </button>
      </form>
      <div style={styles.adsContainer}>
        {isLoadingAds ? (
          <p style={styles.loading}>Loading ads...</p>
        ) : (
          <div style={styles.adsGrid}>
            {ads.map((ad) => (
              <div key={ad._id} style={styles.adCard}>
                <img src={ad.image} alt={ad.title} style={styles.adImage} />
                <div style={styles.adInfo}>
                  <h3 style={styles.adTitle}>{ad.title}</h3>
                  <a
                    href={ad.redirectURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.adLink}
                  >
                    View Ad
                  </a>
                </div>
                <button
                  onClick={() => deleteAd(ad._id)}
                  style={styles.deleteButton}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdsSection;
