import React from 'react'
import commntpic from '../../assets/images/landigpage2/commentpic.png'
import star from '../../assets/images/landigpage2/star.png'
import Carouselll from "./Carouselll"
import { useMediaQuery } from '@mui/material';
const dummmy = [{
    name: 'Ankit chauchan',
    comment: 'Lorem ipsum dolor sit amet consectetur. A pellentesque orci risus commodo aliquet placerat aliquam tincidunt.'
}, {
    name: 'Ankit chauchan',
    comment: 'Lorem ipsum dolor sit amet consectetur. A pellentesque orci risus commodo aliquet placerat aliquam tincidunt.'
}, {
    name: 'Ankit chauchan',
    comment: 'Lorem ipsum dolor sit amet consectetur. A pellentesque orci risus commodo aliquet placerat aliquam tincidunt.'
},]
export default function Fifth() {
    const mobileView = useMediaQuery("(max-width:600px)");
    return (
        <div style={{ height: 529, backgroundColor: '#F8F4EE' }}>
            <div style={{ paddingTop: 80 }}>

                <div style={{ textAlign: 'center' }}>
                    <p className='font36'>What Our Users say about us</p>
                </div>
                {
                    mobileView 
                    ?
                    <Carouselll/>

                :

                 <div className='flexbox2' style={{ paddingLeft: 90, paddingRight: 90, paddingTop: 40 }}>
                {dummmy.map((i,index) => (
                        <div className='commentbox' key={index}>
                        <div style={{ paddingTop: 10 }}>
                                <img src={commntpic} />
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <p className='font20ex' > {i.name}</p>
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <img src={star} />
                                <img src={star} />
                                <img src={star} />
                                <img src={star} />
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <p>{i.comment}</p>
                            </div>
                        </div>

                    ))}

                </div> 
            }


            </div>
        </div>
    )
}
