import React from 'react'
import Gift from './Gift'
import { useState } from 'react';
import { useEffect } from 'react';
import { getAuthToken } from '../../utils/auth';

import { useContext } from 'react';
import AppContext from '../../context/AppContext';
import { PathAction } from '../../redux/reducers/globalPath';
import { useDispatch } from 'react-redux';
import NewDealModal from './NewDealModal';
// import Coupons from "../userCoupons/coupons";
// import Vouchers from "../vouchers/index"


export default function Deals() {
  
    const path = window.location.pathname;
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));

    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;

    

    useEffect(() => {
        // getRuns();
        setShowFooter(true);
        return () => {
            setShowFooter(false);
        };
    }, [])
    return (
        <div>
            <Gift  />
            <NewDealModal />
        </div>
    )
}
