import React, { useState } from 'react';
import { toast } from 'react-toastify';
const AdminCreateSubscription = () => {
    const [planName, setPlanName] = useState('');
    const [planAmount, setPlanAmount] = useState('');
    const [planDuration, setPlanDuration] = useState('');
    const [extraDays, setExtraDays] = useState('');
    const [benefits, setBenefits] = useState([]);
    const [newBenefit, setNewBenefit] = useState('');
    const [loading, setLoading] = useState(false);
    
    

  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
     
     
  
      const subscriptionData = {
        planName,
        planAmount,
        planDuration,
        extraDays,
        benefits,
      };
  
      try {
        const response = await fetch(global.config.ROOTURL.prod+`/subscription/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(subscriptionData),
        });
  
        if (!response.ok) {
          throw new Error('Failed to create subscription');
        }
  
        const result = await response.json();
        toast.success('Subscription created successfully');
 
  
   
        setPlanName('');
        setPlanAmount('');
        setPlanDuration('');
        setExtraDays('');
        setBenefits([]);
        setNewBenefit('');
      } catch (error) {
      
        console.error('Error creating subscription:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleAddBenefit = () => {
      if (newBenefit.trim() !== '') {
        setBenefits([...benefits, newBenefit]);
        setNewBenefit('');
      }
    };
  
    return (
      <div className="ad-creation-wrapper">
        <h1 className="ad-creation__title">Create Plan</h1>
        <form className="ad-creation__form" onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor="plan-name" className="ad-creation__form-label">
              Plan Name
            </label>
            <input
              id="plan-name"
              type="text"
              className="ad-creation__form-input"
              placeholder="Enter Plan Name"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="plan-amount" className="ad-creation__form-label">
              Plan Amount
            </label>
            <input
              id="plan-amount"
              type="number"
              className="ad-creation__form-input"
              placeholder="Enter Plan Amount"
              value={planAmount}
              onChange={(e) => setPlanAmount(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="plan-duration" className="ad-creation__form-label">
              Plan Duration
            </label>
            <input
              id="plan-duration"
              type="number"
              className="ad-creation__form-input"
              placeholder="Enter Plan Duration"
              value={planDuration}
              onChange={(e) => setPlanDuration(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="extra-days" className="ad-creation__form-label">
              Extra Days
            </label>
            <input
              id="extra-days"
              type="number"
              className="ad-creation__form-input"
              placeholder="Enter Extra Days for Plan"
              value={extraDays}
              onChange={(e) => setExtraDays(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="benefits" className="ad-creation__form-label">
              Add Benefits
            </label>
            <input
              id="new-benefit"
              type="text"
              className="ad-creation__form-input"
              placeholder="Add a new benefit"
              value={newBenefit}
              onChange={(e) => setNewBenefit(e.target.value)}
            />
            <button type="button" onClick={handleAddBenefit} className="ad-creation-form-submit--active">
              Add Benefit
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={`${
                loading 
                  ? 'ad-creation-form-submit--disabled'
                  : 'ad-creation-form-submit--active'
              }`}
              disabled={loading }
            >
              Submit
            </button>
          </div>
 
        </form>
      </div>
  );
};




 export default AdminCreateSubscription;
