
// import defaultAvatar from "../../assets/images/profile/default_avatar.png";
// import { Link, useNavigate } from "react-router-dom";
// import "./index.scss";
// import { useState, useEffect } from "react";
// import NotificationModal from "./NotificationModal";
// import { useMediaQuery } from "@material-ui/core";
// import { ConstructionOutlined } from "@mui/icons-material";

// const NotificationCard = (props) => {
//   const mobileView = useMediaQuery("(max-width:600px)");
//   const { notification } = props;
//   const navigate = useNavigate();
//   const [notificationtype, setnotification] = useState('')
//   const [popupData, setPopupdata] = useState(false);
//   const [isNotificationOpen, setNotification] = useState(false);
//   const url = () => {
//     if (notification.idForUI !== 'null') {
//       let tmp = null;

//       if (notificationtype === 'PITCH') {
//         tmp = `/pitch/${notification.idForUI}`;
//       } else if (notificationtype === 'POLL') {

//         // data is not coming properly just change idForUI data in backend and give me refferal user id and everyting is working properly

//         //we dont have single poll page yet
//       } else {

//         tmp = `/profile/${notification.idForUI}`;
//       }
     

//       return tmp;
//     } else {
//       return null;
//     }
//   };

//   const handleOpen = () => {
//     setNotification(!isNotificationOpen)
//   }
//   const handlepopup = () => {
//     setPopupdata(true);
//     setNotification(true);
//   }
//   const handleclose = () => {
//     setPopupdata(false);
//   }

//   const handleprofile = () => {
//     const data = url()
//     navigate(data);
//   }
  

//   const splitnotificationType = (data) => {
//     let ye = data.notificationType;
//     let ans = '';
//     console.log(ye)
//     for (let i = ye.length - 1; i < ye.length; i--) {
//       if (ye.charAt(i) === '/') {
//         break;
//       }
//       ans += ye.charAt(i);
//     }

//     setnotification(ans.split("").reverse().join(""));
//   }
//   useEffect(() => {
//     splitnotificationType(notification);
//   }, [])

//   return (
//     <div className="notification-card">
//       {/* {console.log(notification)} */}
//       <div style={{ display: "flex" }}>
//         {

//           (notificationtype === 'PITCH' && !mobileView)
//           ?
 
//             <div className="left-block" onClick={handlepopup}>
//               <div className="profile-info">
//                 <img
//                   src={
//                     notification.createdUserProfilePhoto
//                       ? notification.createdUserProfilePhoto
//                       : defaultAvatar
//                   }
//                   alt={""}
//                   className="avatar"
//                   style={{ width: "45px", height: "45px" }}
//                 />
//                 <p className="name primary">{notification.description}</p>
//               </div>
//             </div>
//             : 
//             <div className="left-block show-pointer" onClick={handlepopup}>
//               <div className="profile-info">
//                 <img onClick={() => navigate(`profile/${notification.userIdForUI}`)}
//                   src={
//                     notification.createdUserProfilePhoto
//                       ? notification.createdUserProfilePhoto
//                       : defaultAvatar
//                   }
//                   alt={""}
//                   className="avatar"
//                   style={{ width: "45px", height: "45px" }}
//                 />
//                 <p onClick={() => navigate(`profile/${notification.createdUserId}`)}
//                   className="name primary">{notification.description}</p>

//               </div>
//             </div>
//           // </Link>
// }
        
//       </div>

//       {popupData &&
//         <NotificationModal
//           id={notification.idForUI}
//           open={isNotificationOpen}
//           setOpen={handleOpen}
//           handleclose={handleclose}
//         />
//       }
//     </div>
//   );
// };

// export default NotificationCard;


import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { useState, useEffect } from "react";
import NotificationModal from "./NotificationModal";
import { useMediaQuery } from "@material-ui/core";

const NotificationCard = (props) => {
  const mobileView = useMediaQuery("(max-width:600px)");
  const { notification } = props;
  const navigate = useNavigate();
  const [notificationtype, setNotification] = useState('');
  const [popupData, setPopupData] = useState(false);
  const [isNotificationOpen, setNotificationModal] = useState(false);

  const url = () => {
    if (notification.idForUI !== 'null') {
      let tmp = null;
      switch (notificationtype) {
        case 'PITCH':
          tmp = `/pitch/${notification.idForUI}`;
          break;
        case 'ARENA':
          tmp = `/arena`;
          break;
          case 'COMMENT':
            tmp = `/highlights`;
            break;

        case 'DEAL':
          tmp = `/deals`;
          break;
        case 'MARKETPLACE':
          tmp = `/marketplace`;
          break;
          case 'HIGHLIGHT':
            tmp = `/highlights` ;
            break;
        case 'DUGOUT':
          tmp = `/dugout`;
          break;
        default:
          tmp = `/profile/${notification.idForUI}`; 
          break;
      }
      return tmp;
    } else {
      return null;
    }
  };

  const handleOpen = () => {
    setNotificationModal(!isNotificationOpen);
  };

  const handlePopup = () => {
    setPopupData(true);
    setNotificationModal(true);
  };

  const handleClose = () => {
    setPopupData(false);
  };

  const handleProfileNavigation = () => {
    const data = url();
    console.log("Navigating to:", data);
    navigate(data);
    
  };

  const splitNotificationType = (data) => {
    let type = data.notificationType.split('/').pop(); 
    setNotification(type.toUpperCase()); 
  };

  useEffect(() => {
    splitNotificationType(notification);
  }, [notification]);

  return (
    <div className="notification-card">
      <div style={{ display: "flex" }}>
        {(notificationtype === 'PITCH' && !mobileView)
          ? (
            <div className="left-block" onClick={handlePopup}>
              <div className="profile-info">
                <img
                  src={notification.createdUserProfilePhoto ? notification.createdUserProfilePhoto : defaultAvatar}
                  alt=""
                  className="avatar"
                  style={{ width: "45px", height: "45px" }}
                />
                <p className="name primary">{notification.description}</p>
              </div>
            </div>
          )
          : (
            <div className="left-block show-pointer" onClick={handlePopup}>
              <div className="profile-info">
                <img
                  onClick={handleProfileNavigation} 
                  src={notification.createdUserProfilePhoto ? notification.createdUserProfilePhoto : defaultAvatar}
                  alt=""
                  className="avatar"
                  style={{ width: "45px", height: "45px" }}
                />
                <p onClick={handleProfileNavigation} className="name primary">{notification.description}</p>
              </div>
            </div>
          )}
      </div>

      {popupData && (
        <NotificationModal
          id={notification.idForUI}
          open={isNotificationOpen}
          setOpen={handleOpen}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default NotificationCard;
