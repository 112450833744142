import React, { createContext, useState, useEffect } from 'react';

// Create a context for address data
export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
    const [address, setAddress] = useState(() => {
        const savedAddress = localStorage.getItem('buyer-address');
        return savedAddress
            ? JSON.parse(savedAddress)
            : {
                fullName: "John Doe",
                addressLine1: "403 Main Street",
                addressLine2: "Apartment 4B",
                city: "Fremont",
                state: "California",
                postalCode: "62704"
            };
    });

    useEffect(() => {
        localStorage.setItem('buyer-address', JSON.stringify(address));
    }, [address]);

    return (
        <AddressContext.Provider value={{ address, setAddress }}>
            {children}
        </AddressContext.Provider>
    );
};
