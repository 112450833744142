import React from "react";
import "./index.scss";
import FirstSection from "../../components/new-landing-page/firstSection";
import SecondSection from "../../components/new-landing-page/secondSection";
import ThirdSection from "../../components/new-landing-page/thirdSection";
import FourthSection from "../../components/new-landing-page/fourthSection";
import FifthSection from "../../components/new-landing-page/fifthSection";
import NewFooter from "../../components/new-landing-page/NewFooter";

const NewLandingPage = () => {
  return (
    <div className="landing-page">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <NewFooter />
    </div>
  );
};

export default NewLandingPage;
