import React from 'react'
import './About.scss';
import defaultA from '../../../assets/images/icons/kohli.png'
import Scroller from './Scroller';
import FavType from './FavType';
import FavComentaror from './FavComentaror';
import AchivementLevel from './AchivementLevel';

export default function About({ userData }) {
 
  return (
    <div style={{ marginTop: '24px', paddingBottom: '60px' }}>
      <div className='commonClassAbout'>
        <p className='font16About'>About Me</p>
        <p className='font14About'>{userData.about_me}</p>
      </div>
      <div className='commonClassAbout'>
        <div className='commonMarginclassAbout'>
          <p className='font16About'>Gender</p>
          <p className='font14About'>{userData.gender}</p>
        </div>
        {userData?.showDob &&

          <div className='commonMarginclassAbout'>
            <p className='font16About'>Date Of Birth</p>
            <p className='font14About'>{userData.DOB}</p>
          </div>
        }
        <div className='commonMarginclassAbout' style={{ display: 'inline-block', textAlign: 'center' }}>
          <p className='font16About'>Country</p>
          <img src={userData.countryFlag} alt="country Flag" className='imgAbout'/>
          <p className='font14About'>{userData.country}</p>
        </div>


      </div>
      {/* <Scroller/>
      <FavType/>
      <div className='commonClassAbout'>
        <p className='font16About'>Favourite Cricketing Memory</p>
        <p className='font14About'>Lorem ipsum dolor sit amet consectetur. Phasellus tellus mauris risus aliquam mattis laoreet habitant rhoncus.</p>
      </div>
      <FavComentaror/>
      <AchivementLevel/> */}

    </div>
  )
}
