import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, Avatar } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { SocketContext } from '../../context/SocketContext';
import { getStorageItem } from "../../utils/sessionStorage";
import useGetPlayerById from './useGetPlayerById';

const PlayerDetails = (props) => {
    const { playerId } = props;
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const [socket, setSocket] = useContext(SocketContext);
    const [socketConnected, setSocketConnected] = useState(false);
    const [predictionAmt, setPredictionAmt] = useState("");
    const [error, setError] = useState("");
    const [remainingTime, setRemainingTime] = useState(null);
    const mobileView = useMediaQuery("(max-width:768px)");
    const [test, setTest] = useState(true)

    const { playerDetails, getPlayerById } = useGetPlayerById();

    const basePrice = playerDetails?.basePrice || 0;
    const maxPrice = 5000;

    const validatePrediction = (value) => {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue) || numericValue <= basePrice || numericValue > maxPrice) {
            setError("Value should be greater than base price and less than or equal to 5000 lakhs.");
        } else {
            setError("");
        }
    };

    const handlePredictionChange = (e) => {
        const value = e.target.value;
        setPredictionAmt(value);
        validatePrediction(value);
    };

    const convertToMinutesAndSeconds = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const submitPrediction = () => {
        const submitPrediction = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/auction/player/${playerId}/predict`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                userId: userId,
                prediction: predictionAmt,
            },
        };
        axios(submitPrediction)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("Predication added successfully");
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    useEffect(() => {
        if (playerId) {
            getPlayerById(playerId);
        }
    }, [accessToken, playerId]);

    useEffect(() => {
        if (!socket) return;

        const handleNewPrediction = (data) => {
            if (data.playerId === playerId) {
                getPlayerById(playerId);
            }
        };

        const handleAuctionStart = (data) => {
            if (data.playerId === playerId) {
                // toast.info(`Auction started for player: ${data.player.name}`);
                getPlayerById(playerId);
            }
        };

        const handlePredictionPeriodEnd = (data) => {
            if (data.playerId === playerId) {
                const playerName = playerDetails?.name || "the player";
                // toast.info(`Predictions are now locked for ${playerName}. Results will be announced soon.`);
                getPlayerById(playerId);
            }
        };

        socket.on("connect", () => setSocketConnected(true));
        socket.on("newPrediction", handleNewPrediction);
        socket.on("auctionStart", handleAuctionStart);
        socket.on("predictionPeriodEnd", handlePredictionPeriodEnd);

        return () => {
            socket.off("connect");
            socket.off("newPrediction", handleNewPrediction);
            socket.off("auctionStart", handleAuctionStart);
            socket.off("predictionPeriodEnd", handlePredictionPeriodEnd);
        };
    }, [socket, playerId]);

    // Countdown timer effect
    useEffect(() => {
        if (playerDetails?.prediction === 'start' && playerDetails?.time > 0) {
            setRemainingTime(playerDetails.time);

            const timerId = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timerId);
                        return 0; // Stop timer when it reaches zero
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => clearInterval(timerId);
        } else {
            setRemainingTime(null);
        }
    }, [playerDetails]);

    return (
        <Box
            sx={{
                width: mobileView ? '90%' : '30%',
                height: '115.5vh',
                backgroundColor: '#f5f5f5',
                transition: 'width 0.3s ease-in-out',
                padding: '20px',
                top: '215px',
                borderRadius: '25px',
                marginTop: '50px',
                marginRight: mobileView ? '0px' : '40px',
                marginLeft: mobileView ? '15px' : '0px'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                }}>
                <img
                    src={playerDetails?.image || null}
                    alt='Player'
                    style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: '12px',
                    }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Typography
                        variant="h5"
                        align="center"
                        sx={{
                            color: '#0B518E',
                            fontWeight: 600,
                        }}
                    >
                        {playerDetails?.name || ""}
                    </Typography>
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{
                            fontWeight: 400,
                            background: '#FFFFFF',
                            padding: '0px 10px',
                            color: '#F37121'
                        }}
                    >
                        {remainingTime !== null && convertToMinutesAndSeconds(remainingTime)}
                    </Typography>
                </Box>
                <Typography
                    variant="h6"
                    align="center"
                    sx={{
                        fontWeight: 400,
                        color: '#666666',
                        textAlign: 'start'
                    }}
                >
                    {playerDetails?.specialization || ""}
                </Typography>
                {playerDetails?.prediction === 'locked' || playerDetails?.prediction === 'completed' ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '56px',
                            background: '#B6FB9D',
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography
                            variant="h5"
                            align="center"
                            sx={{
                                fontWeight: 500,
                                color: '#2C9207',
                                fontSize: '20px',
                            }}
                        >
                            Waiting For Results
                        </Typography>
                    </Box>

                ) : playerDetails?.prediction === 'start' ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <TextField
                            value={predictionAmt}
                            onChange={handlePredictionChange}
                            error={!!error}
                            helperText={error}
                            InputProps={{
                                sx: {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#D1D1D1',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#D1D1D1',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#D1D1D1',
                                    },
                                },
                            }}
                        >

                        </TextField>
                        <Button
                            onClick={() => submitPrediction()}
                            sx={{
                                width: '25%',
                                height: '56px',
                                background: '#33D00B',
                                color: '#FFFFFF',
                                fontSize: mobileView ? '3.3vw' : '1.4vw',
                                fontWeight: 500,
                                marginTop: error ? "-45px" : 'default-margin-value',
                                ':hover': {
                                    background: '#33D00B'
                                }
                            }}
                        >
                            FREEZE
                        </Button>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            height: '56px',
                            background: '#B6FB9D',
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography
                            variant="h5"
                            align="center"
                            sx={{
                                fontWeight: 500,
                                color: '#2C9207',
                                fontSize: '20px',
                            }}
                        >
                            Waiting for start
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        width: '100%',
                        height: '345px',
                        background: '#FFFFFF',
                        borderRadius: '12px',
                        marginTop: '10px',
                        overflow: 'auto',
                        padding: '20px 15px 0px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}
                >
                    {playerDetails?.userPreductions?.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '100%',
                                border: '1px solid var(--Neutral-Stroke-1-Rest, #D1D1D1)',
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '8px',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Avatar
                                    src={item?.userId?.profilePhoto || null}
                                    sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                                    onClick={() => navigate(`/profile/${item?.userId?._id}`)}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    sx={{
                                        fontWeight: 400,
                                        color: '#4F4F4F',
                                        fontSize: '14px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {`${item?.userId?.firstName || ""} ${item?.userId?.lastName || ""}`}
                                </Typography>
                            </Box>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#00AA5B',
                                    fontSize: '13.4px'
                                }}
                            >
                                {item?.prediction || 0}L
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default PlayerDetails;