import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import './FilterOrderModal.scss';

const FilterPopup = ({ isOpen, onClose, onFilterChange, onApplyFilters, filters }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [localFilters, setLocalFilters] = useState(filters);

    if (!isOpen) return null;

    // Function to handle category selection
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    // Function to handle status selection
    const handleStatusClick = (status) => {
        setSelectedStatus(status);
    };

    // Function to handle period selection
    const handlePeriodClick = (period) => {
        setSelectedPeriod(period);
    };

    // Function to handle filter change in input fields
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setLocalFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    // Function to handle apply button click
    const handleApplyFilters = () => {
        onFilterChange({
            ...localFilters,
            category: selectedCategory,
            status: selectedStatus,
            period: selectedPeriod,
        });
        onApplyFilters();
        onClose();
    };

    return (
        <div className="filter-popup">
            <IconButton
                onClick={onClose}
                sx={{
                    position: "absolute", top: "3rem", right: 8,
                }}
            >
                <CloseIcon sx={{ color: "#000000", zIndex: "15" }} />
            </IconButton>
            <div className="filter-popup-content">
                <h3>FILTERS</h3>
                <div className="filter-section">
                    <h5>Category</h5>
                    <button
                        className={selectedCategory === 'Electronics' ? 'active' : ''}
                        onClick={() => handleCategoryClick('Electronics')}
                    >
                        Electronics
                    </button>
                    <button
                        className={selectedCategory === 'Sports' ? 'active' : ''}
                        onClick={() => handleCategoryClick('Sports')}
                    >
                        Sports
                    </button>
                    <button
                        className={selectedCategory === 'Clothes' ? 'active' : ''}
                        onClick={() => handleCategoryClick('Clothes')}
                    >
                        Clothes
                    </button>
                </div>
                <div className="filter-section">
                    <h5>Status</h5>
                    <button
                        className={selectedStatus === 'On the way' ? 'active' : ''}
                        onClick={() => handleStatusClick('On the way')}
                    >
                        On the way
                    </button>
                    <button
                        className={selectedStatus === 'Delivered' ? 'active' : ''}
                        onClick={() => handleStatusClick('Delivered')}
                    >
                        Delivered
                    </button>
                    <button
                        className={selectedStatus === 'Returned' ? 'active' : ''}
                        onClick={() => handleStatusClick('Returned')}
                    >
                        Returned
                    </button>
                    <button
                        className={selectedStatus === 'Cancelled' ? 'active' : ''}
                        onClick={() => handleStatusClick('Cancelled')}
                    >
                        Cancelled
                    </button>
                </div>
                <div className="filter-section">
                    <h5>Period</h5>
                    <button
                        className={selectedPeriod === 'Today' ? 'active' : ''}
                        onClick={() => handlePeriodClick('Today')}
                    >
                        Today
                    </button>
                    <button
                        className={selectedPeriod === 'Yesterday' ? 'active' : ''}
                        onClick={() => handlePeriodClick('Yesterday')}
                    >
                        Yesterday
                    </button>
                    <button
                        className={selectedPeriod === 'This Week' ? 'active' : ''}
                        onClick={() => handlePeriodClick('This Week')}
                    >
                        This Week
                    </button>
                    <button
                        className={selectedPeriod === 'This Month' ? 'active' : ''}
                        onClick={() => handlePeriodClick('This Month')}
                    >
                        This Month
                    </button>
                    <button
                        className={selectedPeriod === 'This Year' ? 'active' : ''}
                        onClick={() => handlePeriodClick('This Year')}
                    >
                        This Year
                    </button>
                </div>
                <button className="apply-button" onClick={handleApplyFilters}>
                    Apply
                </button>
            </div>
        </div>
    );
};

export default FilterPopup;
