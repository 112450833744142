import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import jobExperience from "../../assets/images/icons/jobExperienceimg.png";
import jobSalarylogo from "../../assets/images/icons/jobsalaryimg.png";
import jobCompanylogo from "../../assets/images/icons/jobcompanypic.png";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import "./JobSection.scss";



const JobSection = ({ postObj }) => {
    const navigate = useNavigate();

    const navigateToJobDescription= (id) => {
        // console.log(" navigateToJobDescription", id);
        navigate(`/job-description/${id}`);
    };

  return (
    postObj && (
      <div className="job-comment-section">
      <div className="job-Main">
        <div className="job-section-start">
          <hr />
          <p>Job</p>
        </div>
        <div className="job-section1">
          <div className="job-main1">
            <CardContent
              sx={{
                backgroundColor: "#FBFDFF",
                borderRadius: "8px",
                marginLeft: "24px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: "inline-block",
                    color: "#0C5BA0",
                    cursor: "pointer",
                  }}
                >
                  {postObj?.jobTitle}
                </Typography>
              </Box>

              <Box sx={{ fontSize: "0.9rem" }}>
                <Typography sx={{ display: "inline-block", color: "#870499" }}>
                  <img src={jobCompanylogo} alt="" />
                  &nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {" "}
                  {postObj?.companyName}
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  ({postObj?.jobLocation})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", fontSize: "0.9rem", height: 50 }}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    sx={{ display: "inline-block", color: " #808080" }}
                  >
                    <img src={jobExperience} alt="" />
                    &nbsp;{" "}
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline-block", color: "#808080" }}
                  >
                    {" "}
                    <img src={jobSalarylogo} alt="" />
                    &nbsp;{" "}
                  </Typography>
                </Box>
                <Box sx={{ display: "inline-block" }}>
                  <Typography sx={{ display: "inline-block" }}>
                    {" "}
                    {postObj?.Remuneration}
                  </Typography>
                  <br />
                  <Typography sx={{ display: "inline-block" }}>
                    {" "}
                    {postObj?.Experience}
                  </Typography>
                </Box>
              </Box>
            </CardContent>

            <div className="job-button">
              <button
                style={{ background: "none", border: "none" }}
                onClick={() => navigateToJobDescription(postObj?.jobId)}
              >
                Show Job
              </button>
            </div>
          </div>

          <div className="job-main2">
            <div className="job-description">
              <h3>Job Description</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: postObj?.jobDescriptionsFileName,
                }}
              />
              {/* <p>{jobData?.jobDescriptionsFileName} </p> */}
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  );
};

export default JobSection;
