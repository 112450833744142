import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import DataEvent from './DataEvent';
import moment from "moment";
import { CSVLink } from 'react-csv';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: '90vh',
  overflowY: "scroll",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UserModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const data = props.data;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [events, setEvents] = useState([]);
  const [modeldata, setModeldata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [eventData, setEventData] = useState([]);


  const fetchEventData = async () => {
    // Fetch additional data
    if (!accessToken) return;
    const req = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/events/userEvent/getEventRegistrationList/${data}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(req).then(response => {

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        console.log("data", response.data);
        setModeldata(response.data);
        // sets(prevItems => prevItems.concat(response.data));
        // setPage(prevPage => prevPage + 1);
        // setShouldLoadPage(true);
      }
    })
      .catch(error => console.log(error));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setEvents([]);
    fetchEventData();
  }, []);

  // Function to convert modeldata to CSV format
  const generateCSVData = () => {
    const headers = [
      'User ID',
      'First Name',
      'Last Name',
      'Email',
      'Address',
      'ID Photo',
      'Height',
      'Weight',
      'Unique Jersey No',
      'Registered On',
    ];

    const data = modeldata.map((dataItem) => {
      return [
        dataItem.userId,
        dataItem.firstName,
        dataItem.lastName,
        dataItem.email,
        dataItem.address,
        dataItem.idPhoto,
        dataItem.height,
        dataItem.weight,
        dataItem.uniqueJerseyNo,
        moment(dataItem.createdDate).format('DD-MM-YY HH:mm'),
      ];
    });

    return [headers, ...data];
  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          {modeldata.map((dataItem, index) => (
            <div key={index} style={{ paddingBottom: "20px" }}>
              <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
                userId:{dataItem.userId}
              </Typography>
              <Typography> firstName: {dataItem.firstName} </Typography>
              <Typography>lastName: {dataItem.lastName}</Typography>
              <Typography>email: {dataItem.email}</Typography>
              <Typography>address: {dataItem.address}</Typography>
              {/* idPhoto as an image */}
              <Typography>idPhoto: </Typography>
              {dataItem.idPhoto && (
                <img
                  src={dataItem.idPhoto}
                  alt={`ID Photo for ${dataItem.firstName} ${dataItem.lastName}`}
                  style={{ maxWidth: '25%', marginTop: '10px' }}
                />
              )}

              <Typography>height:{dataItem.height}</Typography>
              <Typography>weight:{dataItem.weight}</Typography>
              <Typography>uniqueJerseyNo: {dataItem.uniqueJerseyNo}</Typography>
              <Typography>Registered-On: {moment(dataItem.createdDate).format("DD-MM-YY HH:mm")}</Typography>
            </div>
          ))}
          <CSVLink
            data={generateCSVData()} //Passing  data in CSV format
            filename={`user_data_${moment().format("DD-MM-YY HH:mm")}.csv`}
          >
            <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
              Download
            </Button>
          </CSVLink>
        </Box>
      </Modal>
    </div>
  );
}

export default UserModal;




