import React from "react";
import { useState } from "react";
import "./index.scss";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Slider = ({ images, height, width }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstImage = currentIndex === 0;
    const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastImage = currentIndex === images.length - 1;
    const newIndex = isLastImage ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToImage = (imageIndex) => {
    setCurrentIndex(imageIndex);
  };
  return (
    <div className="slider__wrapper" style={{ height: height, width: width }}>
      <div className="slider__image" style={{ backgroundImage: `url(${images[currentIndex]})` }}>
        {images?.length > 1 && <AiOutlineLeft className="slider__arrow--left" onClick={goToPrevious} />}
        {images?.length > 1 && <AiOutlineRight className="slider__arrow--right" onClick={goToNext} />}
      </div>
      <div className="slider__dots">
        {images?.length > 1 && images?.map((image, imageIndex) => {
          return (
            <div
              key={imageIndex}
              className={`slider__dot ${currentIndex === imageIndex ? "active" : ""}`}
              onClick={() => {
                goToImage(imageIndex);
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
