
// import LiveWire from "../../components/news/livewire";
// import Header from "../../components/header";
// import { Text, useContext, useEffect, useState } from "react";
// import Poll from "../../components/poll/poll";
// import Styled from "@emotion/styled";
// import { Carousel } from "react-responsive-carousel";
// import NewImage from "../../assets/images/carousel/Slide1.png";
// import NewImageTwo from "../../assets/images/carousel/slide2.png";
// import NewImageThree from "../../assets/images/carousel/slide3.png";
// import axios from "axios";
// import { clearStorage, getStorageItem } from "../../utils/sessionStorage";

import "./index.scss";
// import LogoWithTitle from "../../components/signin-signup/logo.component";
// import AppContext from "../../context/AppContext";
// import { useDispatch } from "react-redux";
// import { PathAction } from "../../redux/reducers/globalPath";
// import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <main className="main-container">
      <div className="container1-about">
        <div className="text-container">
          <h1 className="bold-text">ABOUT US</h1>
          <p className="small-text">Connecting cricket enthusiasts worldwide through passion and play.</p>
        </div>
      </div>
      <div className="container2-about">
        <p className="words">
          <strong>Champhunt</strong> is your home for all things cricket. We are the
          virtual adda of cricket lovers where they can come, interact
          with other fans, share their opinions, have discussions, analyze
          games and much more. What separates Champ Hunt from other
          cricket portals on the internet is that Champ Hunt is a reward
          based platform. Every fan who registers to the platform earns
          runs for every single activity they do. Users can also give runs
          of their liking to other user's posts and that gets added to
          their runs earned.
          <br></br>
          <br></br>
          You can use these runs to redeem exclusive deals from our
          partners and get rewarded for your love of cricket!
          <br></br> <br></br>
          If you are a stakeholder from the cricketing ecosystem, feel
          free to reach out to us at{" "}
          <strong>partnerships@champhunt.com</strong>
        </p>
      </div>
    </main>
  );
};

export default AboutUs;
