import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import SortIcon from '@mui/icons-material/Sort';
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography, Container, TextField } from "@mui/material";
import { FaWindowClose } from "react-icons/fa";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import FilterModal from "../NewDeals/FilterModal";
import CardImgDeals from "../../assets/images/icons/adminAllDeals.png";

const DealScroll = ({ deals, handleNavigation,update }) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [shouldLoadPage, setShouldLoadPage] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [sortName, setSortName] = useState("");
  //const [deals, setDeals] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const handleClose = () => setFilterModal(!filterModal);

  return (
    <div>
      <Container>
        <Grid container spacing={3}>
          {deals.length === 0 &&
            <h1>
              No Deals Available.
            </h1>
          }
          {deals && deals.map((deal, index) => (
            <Grid item xs={12} md={4} key={index} data-value={deal._id} onClick={update === 'update' ? handleNavigation : null}>
              <Card style={{ borderRadius: '15px' }}>
                {update !== 'update' && <FaWindowClose size={30} color="#FF0000" data-value={deal._id} onClick={handleNavigation}/>}
                <CardMedia
                  component="img"
                  height="140"
                  image={ deal.dealImage ? deal.dealImage : CardImgDeals}
                  alt="Amazon Gift Card"
                />
                <CardContent>
                  <Typography variant="h4" gutterBottom>{deal.name}</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6} style={{ paddingBottom: '15px' }}>
                      <Typography>Start Time: <Box component="span" sx={{ color: '#2C9207' }}>{deal.startTime}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>End Time: <Box component="span" sx={{ color: '#2C9207' }}>{deal.endTime}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Redeem run: <Box component="span" sx={{ color: '#F37121' }}>{deal.redeemrun}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Deals count left: <Box component="span" sx={{ color: '#F37121' }}>{deal.count}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>App only: <Box component="span" sx={{ color: '#F37121' }}>{deal.appOnly ? "Yes" : "No"}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Category: <Box component="span" sx={{ color: '#F37121' }}>{deal.categories}</Box></Typography>
                    </Grid>
                    <Grid item xs={6} style={{ paddingBottom: '15px' }}>
                      <Typography>Redeemable once: <Box component="span" sx={{ color: '#F37121' }}>{deal.redeemableOnce ? "Yes" : "No"}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Locked deal: <Box component="span" sx={{ color: '#F37121' }}>{deal.isLocked ? "Yes" : "No"}</Box></Typography>
                    </Grid>

                    <Grid item xs={12} style={{ borderTop: '1px solid #D9D9D9' }}>
                      <Typography style={{ marginTop: '5px' }}>Deals count set by admin: <Box component="span" sx={{ color: '#F37121' }}>{deal.totalDeal}</Box></Typography>
                      <Typography>Discount Percent: <Box component="span" sx={{ color: '#F37121' }}>{deal?.discount}</Box></Typography>
                      <Typography>Discount start time: <Box component="span" sx={{ color: '#F37121' }}>{deal?.discountStartDateAndTime ? deal?.discountStartDateAndTime : ""}</Box></Typography>
                      <Typography style={{ marginBottom: '5px' }}>Discount end time: <Box component="span" sx={{ color: '#F37121' }}>{deal?.discountEndDateAndTime ? deal?.discountEndDateAndTime : ""}</Box></Typography>
                    </Grid>

                    {deal.isLocked && (
                      <Grid item xs={12} style={{ borderTop: '1px solid #D9D9D9' }}>
                        <Typography gutterBottom>
                          Condition to unlock deal:
                          <Box component="ul" style={{ display: 'flex', justifyContent: 'space-between', listStyleType: 'none' }}>
                            <li><Box component="span" sx={{ color: '#880499' }}>{deal.conditionForUnlock?.post}</Box> Posts</li>
                            <li><Box component="span" sx={{ color: '#880499' }}>{deal.conditionForUnlock?.comment}</Box> Comments</li>
                            <li><Box component="span" sx={{ color: '#880499' }}>{deal.conditionForUnlock?.highlight}</Box> Highlight</li>
                          </Box>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default DealScroll;
