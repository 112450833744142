import React from "react";
import "./index.scss";
import Ball from "../../../assets/images/newLandingPage/Ball.png";
import playerImg from "../../../assets/images/newLandingPage/secondSection/playerImg.png";

const SecondSection = () => {
  return (
    <div className="second_section">
      <div className="img_text_container">
        <img className="player_img" src={playerImg} alt="No image" />
        <div className="text_container">
          <img className="ball" src={Ball} alt="Ball" />
          <p className="bold_text">EARN RUNS</p>
          <p className="small_text">
            Every activity on <b>Champhunt</b> earns your run. More activity, more runs.
          </p>
        </div>
      </div>
      <div className="gradient_color"></div>
    </div>
  );
};

export default SecondSection;
