import React, { useEffect, useState } from "react";
import "./index.css";
import ChallengeItem from "./challengeItem";
import PaginationBox from "../contest/PaginationBox";
import { Button, Stack, TextField } from "@mui/material";
// import Filter from "./Filter";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import moment from "moment";
const accessToken = getStorageItem("token");
const userId = getStorageItem("user_id");

const Index = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [allChallenges, setAllChallenges] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

  const getAllChallenges = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/challenge/getAllChallenges/${pageNumber}/50`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        console.log(data);
        setAllChallenges(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  const downloadChallengeReport = async () => {
    if (!accessToken) return;

    try {
      const response = await axios({
        method: "GET",
        url: global.config.ROOTURL.prod +
          `/contest/userchallenge/getUserChallengeReport/${fromDate}/${toDate}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `user_challenge_${fromDate}_${toDate}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getAllChallenges();
  }, []);
  return (
    <div className="contest_admin-wrapper">
      <div className="contest_admin_head">
        <h3 className="contest_admin_head-content--left">All Challenges</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "right", marginTop: "8px" }}>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="fromDate"
            onChange={(e) => {
              setFromDate(
                moment(new Date(e.target.value)).format("yyyy-MM-DD")
              );
            }}
            label="From"
            type="date"
            value={fromDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="toDate"
            onChange={(e) => {
              setToDate(moment(new Date(e.target.value)).format("yyyy-MM-DD"));
            }}
            label="To"
            type="date"
            value={toDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          /><Button variant="contained" onClick={() => downloadChallengeReport()}>Download challenge report</Button></div>
      </div>

      <div className="contest_admin_prediction-list">
        {allChallenges?.map((prediction, index) => {
          return (
            <ChallengeItem
              key={index}
              data={prediction}
              length={allChallenges?.length}
              getAllChallenges={getAllChallenges}
            />
          );
        })}
      </div>
      {allChallenges.length > 10 && (
        <div className="contest_admin_pagination-wrapper">
          <PaginationBox setPageNumber={setPageNumber} />
        </div>
      )}

    </div>
  );
};

export default Index;
