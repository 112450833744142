import * as React from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState, useRef } from "react";
import {
    IconButton, Typography, InputLabel, Select, FormControl, Box, Button, Grid, TextField, Radio, RadioGroup, FormControlLabel
} from "@mui/material";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { getStorageItem } from '../../utils/sessionStorage'
import { AiFillCheckCircle } from 'react-icons/ai'
const styleDesktop = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};

const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};

const OptionBox = ({ option, answerPredicted, setSelectedOption, setAnswerPredictedByUser, selectedOption }) => {
    const handleSetAnswer = () => {
        if (answerPredicted !== option) {
            setSelectedOption(option);
            setAnswerPredictedByUser(option)
        }
    }
    return (
        <Box p={2} boxShadow={3} mt={2} mb={2}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                onClick={handleSetAnswer}
            > <div style={{ marginLeft: '10px' }}>{option}</div>
                {answerPredicted === option ?
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            color: '#0C5BA0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: '#0C5BA0',
                        }}
                    >
                        <AiFillCheckCircle />
                    </div>
                    :
                    <div
                        style={{
                            width: '20px', // Set the width and height of the outer circle
                            height: '20px',
                            borderRadius: '50%', // Create a circle shape for outer circle
                            border: '2px solid #00f', // Set border style for outer circle
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {(selectedOption === option) && (
                            <div
                                style={{
                                    width: '12px', // Set the width and height of the inner circle (blue filled circle)
                                    height: '12px',
                                    borderRadius: '50%', // Create a circle shape for inner circle
                                    backgroundColor: '#00f', // Blue color for inner circle
                                }}
                            />
                        )}
                    </div>}
            </div>
        </Box >
    )
}
export default function ActivateDealsModal(props) {
    const matches = useMediaQuery("(min-width:600px)");
    const [style, setStyle] = useState();
    const [selectedOption, setSelectedOption] = useState("");
    const { open, handleClose } = props;
    const userId = localStorage.getItem('user_id');
    const accessToken = localStorage.getItem('accesstoken');
    const {
        post, setAnswerPredictedByUser
    } = props;
    const { answerPredicted, challengeDetails } = post
    const { challengeQuestion, challengeOption1, challengeOption3, challengeOption4, challengeOption5, challengeOption2 } = challengeDetails
    const acceptChallenge = () => {
        props.acceptChallenge()
    }

    useEffect(() => {
        if (!matches) {
            setStyle(styleMobile);
        } else {
            setStyle(styleDesktop);
        }
    }, [matches]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: "absolute",
                            right: "3%",
                            top: "2%",
                            color: "blue !important",
                            backgroundColor: "white !important",
                            border: "none !important",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box style={{ color: "#1A1919", margin: "5px", fontWeight: "600", textAlign: 'center' }}>Predict the winner!</Box>
                    <Box style={{ margin: "5px", textAlign: 'center' }}>{challengeQuestion}</Box>

                    <OptionBox option={challengeOption1} answerPredicted={answerPredicted} setSelectedOption={setSelectedOption}
                        setAnswerPredictedByUser={setAnswerPredictedByUser}
                        selectedOption={selectedOption} />

                    <OptionBox option={challengeOption2}
                        setAnswerPredictedByUser={setAnswerPredictedByUser}
                        answerPredicted={answerPredicted} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />

                    {challengeOption3 &&
                        <OptionBox option={challengeOption3}
                            setAnswerPredictedByUser={setAnswerPredictedByUser}
                            answerPredicted={answerPredicted} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
                    }
                    {challengeOption4 &&
                        <OptionBox option={challengeOption4}
                            setAnswerPredictedByUser={setAnswerPredictedByUser}
                            answerPredicted={answerPredicted} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
                    }
                    {challengeOption5 &&
                        <OptionBox option={challengeOption5} setAnswerPredictedByUser={setAnswerPredictedByUser}
                            answerPredicted={answerPredicted} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
                    }



                    <Box mt={2} >
                        <Button style={{ width: "100%", backgroundColor: "#0C5BA0" }} onClick={acceptChallenge}
                            variant="contained"
                            disabled={selectedOption ? false : true}>Accept Challenge</Button>
                    </Box>
                    <Box mt={2}>
                        <Button style={{ width: "100%" }} onClick={handleClose}
                            variant="outlined"
                        >Cancel</Button>
                    </Box>
                </Box>
            </Modal >
        </div >
    );
}
