import React from "react";
import "./PaginationBox.css";
import Pagination from "@mui/material/Pagination";

const PaginationBox = ({ setPageNumber, length }) => {
  return (
    <div className="contest_admin-pagination">
      <Pagination count={length / 10} variant="outlined" shape="rounded" />
    </div>
  );
};

export default PaginationBox;
