import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import { clearStorage } from '../../utils/sessionStorage';

const AdCountContext = createContext();
export const useAdCount = () => useContext(AdCountContext);
export const AdCountProvider = ({ children}) => {

    const [adCount, setAdCount] = useState(+localStorage.getItem("ad_count") || 0);

    const incrementAdCount = () => {
        setAdCount((prevCount) => prevCount + 1);
    }

    useEffect(() => {
        localStorage.setItem("ad_count", adCount.toString());
    }, [adCount]);

    return (
        <AdCountContext.Provider value={{ adCount, setAdCount, incrementAdCount }}>
            {children}
        </AdCountContext.Provider>
    );
};




