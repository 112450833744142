import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import axios from "axios";
import "./index.scss";
import Card from "./card";

const Index = () => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [page, setPage] = useState(0);
  const [sellers, setSellers] = useState([]);
  const fetchSellers = () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/findAllSellers?page=${page}&limit=${global.config.pagePerSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        if (response?.data?.length === 0) {
          // setSellers(response.data);
        } else {
          setSellers(response?.data);
          //   setPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchSellers();
  }, []);
  return (
    <div className="all-sellers_container">
      <Helmet>
        <title>Champhunt | All-Sellers</title>
      </Helmet>
      <Box padding={1} style={{ paddingTop: "80px" }}>
        <div className="back-button">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
        <Typography
          variant="h6"
          align="center"
          className="all-sellers-main-heading"
        >
          All Sellers
        </Typography>
        <hr />
        <ul className="all-sellers_main">
          {sellers?.map((seller, index) => {
            return <Card key={index} seller={seller} index={index} />;
          })}
        </ul>
      </Box>
    </div>
  );
};

export default Index;
