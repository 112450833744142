import React, { useState, useEffect } from "react";
import axios from "axios";
import redBalls from "../../assets/images/icons/red-ball.png";
import pitchIcon from "../../assets/images/icons/pitch_icon.png";
import pollIcon from "../../assets/images/icons/poll_icon.png";
import predictionIcon from "../../assets/images/icons/prediction_icon.png";
import dealIcon from "../../assets/images/icons/deal_icon.png";
import pdfIcon from "../../assets/images/icons/icons_pdf.png";
import excelIcon from "../../assets/images/icons/icons_excel.png";
import { Box, Button, Typography } from "@mui/material";
import "./index.scss";
import moment from "moment";
import Lottie from 'react-lottie';
import animationData from '../../assets/images/rt-nodata.json';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";

const RunsTransactionTable = ({ activeItem, sortItem, dateFilter, runStartDate, runEndDate }) => {
  const adminRunsComment = 'Runs Credited by Admin ';
  const pitchComment = 'Total Runs on Pitch ';
  const dealComment = 'Run Debited on Deals ';
  const pollComment = 'Run Credited for Poll ';
  const predictionReward = 'Run Credited for Prediction ';
  const predictionDeduct = 'Run Debited for Prediction ';
  const [updatedRunData, setUpdatedRunData] = useState(0)
  const [newArray, setNewArray] = useState([])
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const [userRuns, setUserRuns] = useState(0)
  const [newFilter, setNewFilter] = useState([])
  const [items, setItems] = useState([]);
  const [totalData, setTotalData] = useState([])
  const pagePerSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pollDefaultRun, setPollDefaultRun] = useState(30);

  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:769px)')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const handlePitchClick = () => {
    navigate('/pitch');
  };

  const handleClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleNext = () => {
    if(totalData.length !== 0){
      setActivePage(activePage + 1);
      if ((activePage + 1) > totalPages) {
        setTotalPages(activePage + 1)
      }
    }
  };

  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  const getRuns = () => {
    if (!userId) return console.error("_userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // fetching according to date
  const fetchRunsDateWise = async () => {
    setNewArray([])
    setUpdatedRunData(0)
    fetchDataAndCombine(runStartDate, runEndDate).then((combinedData) => setNewArray(combinedData))
  }

  // fetching admin given runs
  const fetchAdminRuns = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        global.config.ROOTURL.prod + `/auth/default/${userId}/${startDate}/${endDate}?pageNo=${activePage}&pagePerSize=${pagePerSize}`,
        headers
      );
      let adminRuns = response.data;
      return adminRuns ? adminRuns : []
    } catch (err) {
      console.log(err);
    }
  }

  // fetching pitch runs
  const fetchPitchRuns = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        global.config.ROOTURL.prod + `/pitch/userPitchForRunTransaction/${userId}/${startDate}/${endDate}?pageNo=${activePage}&pagePerSize=${pagePerSize}`,
        headers
      );
      let resData = response.data;
      return resData ? resData : []
    } catch (err) {
      console.log(err);
    }
  };

  // fetching deal runs
  const fetchDealRuns = async (startDate, endDate) => {
    try {
      let data = [];
      const response = await axios.get(
        global.config.ROOTURL.prod + `/dealredeemdetails/getRedeemDetailsByUserId/${userId}/${startDate}/${endDate}?pageNo=${activePage}&pagePerSize=${pagePerSize}`,
        headers
      );
      const runDealsData = response.data;
      runDealsData.map((datass) => {
        data.push(datass);
      });
      return runDealsData ? runDealsData : []
    } catch (err) {
      console.log(err);
    }
  };

  // fetching poll runs
  const fetchPollRuns = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        global.config.ROOTURL.prod + `/poll/getPollByUserName/${userId}/${startDate}/${endDate}?pageNo=${activePage}&pagePerSize=${pagePerSize}`,
        headers
      );
      const pollData = response.data;
      return pollData ? pollData : []
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPollDefaultRuns = async () => {
    try {
      const response = await axios.get(
        global.config.ROOTURL.prod + `/auth/getPollDefaultRun`,
        headers
      );
      setPollDefaultRun(response.data[0].run)
    } catch (err) {
      console.log(err);
    }
  };

  // fetching prediction runs
  const fetchPredictionDeduct = async (startDate, endDate) => {
    try {
      const deductResponse = await axios.get(
        global.config.ROOTURL.prod + `/contest/userpredictionquestion/gettotaldeduct/${userId}/${startDate}/${endDate}?pageNo=${activePage}&pagePerSize=${pagePerSize}`,
        headers
      );
      const data = (deductResponse.data);
      return data ? data : []
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPredictionReward = async (startDate, endDate) => {
    try {

      const rewardResponse = await axios.get(
        global.config.ROOTURL.prod + `/contest/userpredictionquestion/gettotalreward/${userId}/${startDate}/${endDate}?pageNo=${activePage}&pagePerSize=${pagePerSize}`,
        headers
      );
      const data = (rewardResponse.data);
      return data ? data : []
    } catch (err) {
      console.log(err);
    }
  }

  // data is shown as per time
  const fetchDataAndCombine = async (startDate, endDate) => {
    try {
      const [adminRunsGiven, pitchRunsData, dealRunsData, pollData, predictionDeduct, predictionReward] = await Promise.all([
        fetchAdminRuns(startDate, endDate),
        fetchPitchRuns(startDate, endDate),
        fetchDealRuns(startDate, endDate),
        fetchPollRuns(startDate, endDate),
        fetchPredictionDeduct(startDate, endDate),
        fetchPredictionReward(startDate, endDate)
      ]);

      let combinedData = [...adminRunsGiven, ...pitchRunsData, ...dealRunsData, ...pollData, ...predictionReward, ...predictionDeduct];    // Combining all data into a single array

      setTotalData(combinedData);

      combinedData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      let updatedRun = [...combinedData].reduce((acc, cur) => {
        if (cur.givenRun) {
          return acc + cur.givenRun;
        } else if (cur.run) {
          return acc + cur.run;
        } else if (cur.redeemedRun) {
          return acc + cur.redeemedRun;
        } else if (cur.rewarded) {
          return acc + cur.rewarded
        } else if (cur.deductedEntryRun) {
          return acc + cur.deductedEntryRun
        } else {
          return acc + pollDefaultRun;
        }
      }, 0);
      setUpdatedRunData(updatedRun)
      setNewFilter([...combinedData])
      return combinedData;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  // filter as per option
  const filteroutRunData = () => {
    let filterArray = [...newArray]
    let updatedRun;
    if (activeItem === "Admin") {
      filterArray = [...filterArray].filter((data) => data.givenRun)

    } else if (activeItem === "Pitch") {
      filterArray = [...filterArray].filter((data) => data.run)

    } else if (activeItem === "Deals") {
      filterArray = [...filterArray].filter((data) => data.redeemedRun)

    } else if (activeItem === "Polls") {
      filterArray = [...filterArray].filter((data) => data.question)
    }
    else if (activeItem === 'Predictions') {
      filterArray = [...filterArray].filter((data) => data.deductedEntryRun || data.rewarded)
    }

    if (sortItem === 'Credited') {
      filterArray = [...filterArray].filter((data) => (data.givenRun || data.run || data.question || data.rewarded) ? true : false)

    } else if (sortItem === 'Debited') {
      filterArray = [...filterArray].filter((data) => (data.redeemedRun || data.deductedEntryRun) ? true : false)
    }

    updatedRun = [...filterArray].reduce((acc, cur) => {
      if (cur.givenRun) {
        return acc + cur.givenRun;
      } else if (cur.run) {
        return acc + cur.run;
      } else if (cur.redeemedRun) {
        return acc + cur.redeemedRun;
      } else if (cur.rewarded) {
        return acc + cur.rewarded
      } else if (cur.deductedEntryRun) {
        return acc + cur.deductedEntryRun
      }
      else {
        return acc + pollDefaultRun;
      }
    }, 0);
    setUpdatedRunData(updatedRun)
    setNewFilter([...filterArray])
  }

  useEffect (()=>{
    fetchPollDefaultRuns()
  })

  useEffect(() => {
    filteroutRunData()
  }, [activeItem, sortItem]);

  // filter as per date
  useEffect(() => {
    fetchRunsDateWise();
    filteroutRunData();
  }, [dateFilter, activePage]);


  return (
    <>
      <div className="runstransactiontable_container">

        <div className="runstransactiontable_header">
          <div className="runstransactiontable_title">
            <p className="table_day">Runs-Transaction</p>
          </div>
          <div className="runstransactiontable_total">
            <img className="runsball_img" src={redBalls} />
            <p className="table_totalruns">{updatedRunData}</p>
            <p className="table_totalruns">Runs</p>
          </div>
        </div>

        {newFilter && newFilter.length > 0 ? (
          newFilter.map((data, index) => (
            <div key={index} className="runs_transactions_sheet">
              <div className="transactions_info">

                <div className="transaction_img">
                  <img
                    className="rt_img_type"
                    src={
                      data.givenRun
                        ? pitchIcon
                        : data.question
                          ? pollIcon
                          : data.redeemedRun
                            ? dealIcon
                            : data.deductedEntryRun
                              ? predictionIcon
                              : data.rewarded
                                ? predictionIcon
                                : pitchIcon
                    }
                  />
                </div>

                <div className="transactions_userinfo">
                  <p className="transaction_username">
                    {data.givenRun ? adminRunsComment
                      : data.question ? pollComment
                        : data.redeemedRun ? dealComment
                          : data.deductedEntryRun ? predictionDeduct
                            : data.rewarded ? predictionReward
                              : pitchComment}
                    <span className="runsDate-rt">
                      {moment(data.createdDate).format('ll')}
                    </span>
                  </p>

                  <p className={`transaction_type`}
                    style={{ color: `${(data.redeemedRun || data.deductedEntryRun) ? '#F37121' : ''}` }}>
                    {(data.redeemedRun || data.deductedEntryRun) ? 'Debited' : 'Credited'}
                  </p>

                </div>
              </div>

              <div className="rt_transaction_runs">
                <img className="transactionball_img" src={redBalls} />

                <p className="transaction_totalruns">
                  {data.givenRun ? data.givenRun
                    : data.redeemedRun ? data.redeemedRun
                      : data.run ? data.run
                        : data.deductedEntryRun ? data.deductedEntryRun
                          : data.rewarded ? data.rewarded
                            : pollDefaultRun}
                </p>

                <p className="transaction_totalruns">Runs</p>
              </div>
            </div>
          ))
        ) : (
          <Box className="lottie-container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
            gap={1}
          >
            <Lottie
              options={defaultOptions}
              height={isMobile ? 100 : 200}
              width={isMobile ? 100 : 200}
            />
            <Typography sx={{ fontSize: isMobile ? '12px' : '18px' }}>Haven't done any activity yet!</Typography>
            <Typography sx={{ fontSize: isMobile ? '12px' : '18px', width: '90%' }}>Looks like there’s nothing to show just yet. Start tracking your progress and check back soon for insights!</Typography>
            <Button variant="contained" onClick={handlePitchClick}>Go to Pitch</Button>
          </Box>
        )}
      </div>

      {/* footer */}
      <div className="runstransactiontable_footer">
        {/* <div className="rt_data_download">
          <div>
            <img className="rt_pdf_icon" src={pdfIcon} />
          </div>
          <div>
            <img className="rt_excel_icon" src={excelIcon} />
          </div>
        </div> */}

        <div className="pagination">
          <ul>
            {[...Array(totalPages)].map((_, index) => (
              <li
                key={index}
                className={activePage === index + 1 ? 'active' : ''}
                onClick={() => handleClick(index + 1)}
              >
                {index + 1}
              </li>
            ))}
          </ul>
          <button id="next" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default RunsTransactionTable;
