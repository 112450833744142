import React, { useState, useEffect } from 'react';
import './index.scss';
import ProductCard from './ProductCard'
import { useNavigate } from "react-router-dom";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import axios from "axios";

const Index = () => {
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(0);

    const fetchProducts = () => {

        if (!accessToken) return
        const options = {
            method: "GET",
            url:
                global.config.ROOTURL.prod +
                `/marketplace/product/getAllProducts?page=${page}&limit=${global.config.pagePerSize}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then((response) => {
                if (response?.data?.length === 0) {
                    // setProducts(response.data);
                } else {
                    setProducts(response?.data);
                    //   setPage((prevPage) => prevPage + 1);
                }
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchProducts();
    }, [])
    return (
        <div className={"all-products__wrapper"}>
            <h1 className={"all-products__heading"}>All Products</h1>
            <div className={"all-products__products-card-wrapper"}>
                {
                    products.map(product => {
                        return <ProductCard key={product._id} productData={product} />
                    })
                }
            </div>
        </div>
    );
};

export default Index;