
import { useState, useRef, useEffect, useCallback } from "react";
import { Paper, Card, CardContent, Grid, Typography, Box, Modal, Button, Divider } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import new2ball from "../../assets/images/icons/2runNewBall.png";
import new4ball from "../../assets/images/icons/4runBall.png";
import new6ball from "../../assets/images/icons/6runnewBall.png";
import axios from "axios";
import { useMediaQuery } from "@material-ui/core";
import Runs from "../../assets/images/icons/newBall.png";
import { getStorageItem } from "../../utils/sessionStorage";
import pollVoterListScroller from "../../components/poll/votedListUseScroller";
import AllRun from './all-run';
import SixRuns from './six-four-two-run';
// import TwoRun from '';
// import FourRun from '';
import './runPopupModal.scss'
const accessToken = getStorageItem("token");
const style = {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: "695px",
    height: "437px",
    left: "50%",
    top: "50%",
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
    borderRadius: "15px"
};
const mobileStyle = {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    height: "437px",
    width: "328px",
    // left: "16px",
    // top: "322px",
    borderRadius: "15px",
    left: "50%",
    top: "50%",
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
}
const RunPopup = (props) => {

    const mobile = useMediaQuery("(max-width: 700px)");
    const [allRun, setAllRun] = useState(true);
    const [sixRun, setSixRun] = useState(false);
    const [twoRun, setTwoRun] = useState(false);
    const [fourRun, setFourRun] = useState(false);
    const [totalRun, setTotalRun] = useState(0);
    const [sixRunsTotal, setSixRunsTotal] = useState(0);
    const [twoRunsTotal, setTwoRunsTotal] = useState(0);
    const [fourRunsTotal, setFourRunsTotal] = useState(0);

    const handleAllRun = () => {
        setFourRun(false)
        setTwoRun(false)
        setSixRun(false)
        setAllRun(true)
    }
    const handleSixRun = () => {
        setSixRun(true)
        setFourRun(false)
        setTwoRun(false)
        setAllRun(false)
    }
    const handleFourRun = () => {
        setFourRun(true)
        setTwoRun(false)
        setSixRun(false)
        setAllRun(false)

    }
    const handleTwoRun = () => {
        setTwoRun(true)
        setFourRun(false)
        setSixRun(false)
        setAllRun(false)
    }


    const getTotalByRunGivenByPitchId = async () => {
        if (!accessToken) return
        let _url;
        if (props?.pitchId) {
            _url = global.config.ROOTURL.prod +
                `/pitch/run/getTotalByRunGivenByPitchId/${props?.pitchId}`
        }
        if (props?.shareJobId) {
            _url = global.config.ROOTURL.prod +
                `/job/sharejob/run/getTotalByRunGivenByShareJobId/${props?.shareJobId}`
        }

        let options = {
            method: "get",
            url: _url,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then((response) => {
                console.log("response", response.data);
                setTotalRun(response?.data?.totalRun);
                setSixRunsTotal(response?.data?.sixRuns);
                setTwoRunsTotal(response?.data?.twoRuns);
                setFourRunsTotal(response?.data?.fourRuns);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    useEffect(() => {
        getTotalByRunGivenByPitchId()
    }, [])
    return (

        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            {/* <Box sx={style}> */}
            <Box sx={mobile ? mobileStyle : style}>
                <Box style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box display="flex" justifyContent="space-between" >
                    <Box >
                        <img className="ball-design ball-space-left " src={Runs} alt="" onClick={handleAllRun}></img>
                        <p className="ball-space-left run-text" >{totalRun}</p>
                        {allRun && <div className="underline-selected  ball-space-left"></div>}</Box>
                    <Box> <img className="ball-design" src={new6ball} alt="" onClick={handleSixRun}></img>
                        <p className="run-text">{sixRunsTotal}</p>
                        {sixRun && <div className="underline-selected"></div>}</Box>
                    <Box>   <img className="ball-design" src={new4ball} alt="" onClick={handleFourRun}></img>
                        <p className="run-text">{fourRunsTotal}</p>
                        {fourRun && <div className="underline-selected"></div>}
                    </Box>
                    <Box> <img className="ball-design ball-space-right" src={new2ball} alt="" onClick={handleTwoRun}></img>
                        <p className="run-text">{twoRunsTotal}</p>
                        {twoRun && <div className="underline-selected ball-space-right"></div>}
                    </Box>
                </Box>
                <Divider />

                {twoRun && props?.shareJobId && <SixRuns shareJobId={props?.shareJobId} run={2} />}
                {allRun && props?.pitchId && <AllRun pitchId={props?.pitchId} />}
                {sixRun && props?.pitchId && <SixRuns pitchId={props?.pitchId} run={6} />}
                {fourRun && props?.pitchId && <SixRuns pitchId={props?.pitchId} run={4} />}
                {twoRun && props?.pitchId && <SixRuns pitchId={props?.pitchId} run={2} />}
                {allRun && props?.shareJobId && <AllRun shareJobId={props?.shareJobId} />}
                {sixRun && props?.shareJobId && <SixRuns shareJobId={props?.shareJobId} run={6} />}
                {fourRun && props?.shareJobId && <SixRuns shareJobId={props?.shareJobId} run={4} />}

            </Box>
            {/* </Box> */}


        </Modal>
    );
};
export default RunPopup;