import React from 'react';
import giftspic from "../../assets/images/usplandingpage/giftspic.png";
import champphone from "../../assets/images/usplandingpage/champphone.png";
import zigzaglinepic from "../../assets/images/usplandingpage/zigzaglinepic.png"

const SecondBlocksec = () => {

  return (
    <div className='secondmain_container'>

      <div className='Uspsecond_first'>

            <div className='seconblockgiftcontainer'>
             <div className='gift_pic'>
                <img  src={giftspic} alt="giftpicture"/>
             </div>
             <div className='toptext_gift'>
                <h2>It’s more than just a Adidas Shoe Giveaway! </h2>
                <p>Apart from 1 winner who gets a<span> Adidas Agora 1.0 Multisport</span> Training Shoes, we have <span>40 plus amazon giftcards</span> given out every week and all you need to do to qualify is Sign Up on Champhunt.</p>
            </div>
            </div>


         <div className="phone_container">
          <div className="sec_text">
          <h2>About Champhunt</h2>
          <p>Champhunt is a one of its kind community that aims to bring all stakeholders of cricket together on one platform. It is a virtual home of cricket lovers where they can interact with other fans, share their opinions, have discussions, analyze games as well as showcase their talent.</p>
          </div>
          <div className="champ_phoneimg">
           <img src={champphone} alt=''/>
          </div>
        </div>

      </div>

      <img className="zimg" src={zigzaglinepic} alt=''/>
    </div>
  )
}
export default SecondBlocksec
