import { createSlice } from '@reduxjs/toolkit';



const initialState = {
   path: null,
   shouldLoad: false,
   PostCount: 0,
   createdObject: null,
   deltedId: null,
   loadNestedCmts: false,
   loadComment:false
}

const PathSlice = createSlice({
   name: 'path',
   initialState,
   reducers: {
      handlePath(state, action) {
         state.path = action.payload.payload
      },
      handleReload(state, action) {
         state.shouldLoad = action.payload.payload
      },
      handlePostCount(state, action) {
         state.PostCount = action.payload.payload
      },
      handleCreatedObject(state, action) {
         state.createdObject = action.payload.payload
      },
      handleDeleteobject(state, action) {
         state.deltedId = action.payload.payload
      },
      handleReloadComments(state, action) {
         state.loadNestedCmts = action.payload.payload
      },
      handleReloadMainComments(state, action) {
         state.loadComment = action.payload.payload
      }
   }
});


export const PathAction = PathSlice.actions;

export default PathSlice;