import {
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import EventScroll from "../../components/find-all-events/eventScroll";
import { Helmet } from "react-helmet";

const FindAllEvents = () => {
  return (
    <div>
      <Helmet>
        <title>Champhunt | Find-All-Events</title>
      </Helmet>
    
    <Box>
      <Typography variant="h4">All Events</Typography>
      <Box marginTop={2}>
        <EventScroll />
      </Box>
    </Box>
    </div>
  );}

export default FindAllEvents