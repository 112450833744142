import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";

import deleteIcon from "../../../assets/images/chatreactions/alert.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const DeleteGroupModal = ({
  deleteGroup,
  openDelete,
  handleCloseDeleteModal,
}) => {
  return (
    <Modal
      open={openDelete}
      onClose={handleCloseDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={deleteIcon} alt="report png" width={140} height={140} />
          <p
            style={{
              color: "#313335",
              fontSize: "17px",
              marginTop: "10px",
            }}
          >
            Delete Group
          </p>
          <p
            style={{
              color: "#F37121",
              fontSize: "17px",
              marginTop: "10px",
            }}
          ></p>
          <p style={{ color: "#62666A", marginTop: "10px" }}>
            Are you sure you want to delete this group? This action cannot be
            undone.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "25px",
              width: "300px",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                border: "none",
                cursor: "pointer",
                padding: "10px 20px",
                width: "130px",
                borderRadius: "7px",
              }}
              onClick={deleteGroup}
            >
              Delete
            </button>
            <button
              style={{
                border: "none",
                cursor: "pointer",
                padding: "10px 20px",
                width: "130px",
                borderRadius: "7px",
              }}
              onClick={handleCloseDeleteModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteGroupModal;
