import React, { useEffect, useState } from "react";
import "./index.css";
import PredictionItem from "./PredictionItem";
import PaginationBox from "./PaginationBox";
import AddBox from "./AddBox";
import { Button, Stack, TextField } from "@mui/material";
import Filter from "./Filter";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import moment from "moment";
const accessToken = getStorageItem("token");
const userId = getStorageItem("user_id");

const Index = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [allPredictions, setAllPredictions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

  const downloadPredictionReport = async () => {
    if (!accessToken) return;

    try {
      const response = await axios({
        method: "GET",
        url: global.config.ROOTURL.prod +
          `/contest/userprediction/getUserPredictionReport/${fromDate}/${toDate}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `user_predictions_${fromDate}_${toDate}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log(error);
      }
    }
  };

  const getAllPredictions = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod + `/contest/prediction/getAllAdmin/${pageNumber}/50`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        console.log("Data=", data);
        setAllPredictions(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getAllPredictions();
  }, []);
  return (
    <div className="contest_admin-wrapper">
      <div style={{ display: "flex", justifyContent: "right", marginTop: "8px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="fromDate"
            onChange={(e) => {
              setFromDate(
                moment(new Date(e.target.value)).format("yyyy-MM-DD")
              );
            }}
            label="From"
            type="date"
            value={fromDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="toDate"
            onChange={(e) => {
              setToDate(moment(new Date(e.target.value)).format("yyyy-MM-DD"));
            }}
            label="To"
            type="date"
            value={toDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="contained" onClick={() => downloadPredictionReport()}>Download prediction report</Button>
        </div>
      </div>
      <div className="contest_admin_head">
        <h3 className="contest_admin_head-content--left">List</h3>
        <h3 className="contest_admin_head-content--right">
          <p className="contest_admin_head-content--text">Filter</p>
          <img
            src="/svg/admin/filter.svg"
            alt="filter"
            className="contest_admin_head-content--filter"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          />
        </h3>

      </div>
      {showFilter && (
        <div className="contest_admin-filter">
          <Filter />
        </div>
      )}
      <div className="contest_admin_prediction-list">
        {allPredictions?.map((prediction, index) => {
          return (
            <PredictionItem
              key={index}
              data={prediction}
              length={allPredictions?.length}
              getAllPredictions={getAllPredictions}
            />
          );
        })}
      </div>
      {allPredictions.length > 10 && (
        <div className="contest_admin_pagination-wrapper">
          <PaginationBox setPageNumber={setPageNumber} />
        </div>
      )}
      <div className="contest_admin_add">
        <AddBox />
      </div>
      <div className="contest_admin_cancel_save">
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            className="contest_admin_cancel_save--cancel"
          >
            Cancel
          </Button>
          <Button variant="contained" href="#outlined-buttons">
            Save
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default Index;
