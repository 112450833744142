import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import TextField from '@mui/material/TextField';
import { FormControl, Grid, MenuItem, InputLabel, Container, CssBaseline, Button } from '@mui/material';
import Select from '@mui/material/Select';
import { Box } from "@mui/system";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getStorageItem } from "../../utils/sessionStorage";
import config from '../../config';
import { useNavigate } from "react-router-dom";
import { TouchAppRounded } from "@material-ui/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CodeSnippet } from "carbon-components-react";
import Crop from "./Crop";

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import cancel from "../../assets/images/icons/cancel-logo.svg";
import JobCancelPopuP from "./JobCancelPopup";
import { Helmet } from "react-helmet";

import jobsuccess from "../../assets/images/icons/jobsuccess.png"
import success from "../../assets/images/icons/sucess.png"

const baseURL = config.ROOTURL.prod;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius:"10px",
    boxShadow: 24,
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    p: 4,
};


const PostJob = () => {
    const navigate = useNavigate();
    const navigateToJobs = () => {
        navigate('/jobs');
    };
    const [companyName, setCompanyName] = useState("");
    const [jobEmail, setJobEmail] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [department, setDepartment] = useState("");
    const [jobTitle, setjobTitle] = useState("");
    const [workPlaceType, setWorkPlaceType] = useState("");
    const [jobLocation, setJobLocation] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [experience, setExperience] = useState("");
    const [qualification, setQualification] = useState("");
    const [remuneration, setRemuneration] = useState("");
    const [otherRequirements, setOtherRequirements] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [image, setImage] = useState("");
    const [next, setNext] = useState("true");
    const userId = getStorageItem("user_id");

    const userID = getStorageItem("user_id");
    const userName = getStorageItem("full_name");
    //const userEmail = getStorageItem("user_email");
    const accessToken = getStorageItem("token");
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(true);
    const [jobValue, setjobValue] = useState("");
    const [ValidationFirst, setValidationFirst] = useState(false);
    const [ValidationSecoundPage, setValidationSecound] = useState(false);
    const [openlogoCrop, setlogocrop] = useState(false);
    const [isopen, setisopen] = useState(false);
    const [popupData, setpopupData] = useState(false);
    
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [count, setCount] = useState()
    const [submitMessage, setSubmitMessage] = useState("")

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const handleOpenSuccessModal = () => setOpenSuccessModal(true);
    const handleCloseSuccessModal = () => setOpenSuccessModal(false);
    

    const handleNext = () => {
        if (companyName === "" ||jobEmail === "" || department === "" || workPlaceType === "" || jobLocation === "" || jobTitle === "" || employmentType === "") {
            console.log(companyLogo)
            setValidationFirst(true)
            return;
        }
        setNext(false);
        uploadImage();
    }
    let handleSubmit = async (e) => {
        if (remuneration === "" || experience === "" || qualification === "" || jobValue === "") {

            setValidationSecound(true)
            return;
        }

        if(count>=5 && !isSubscriber){
            setSubmitMessage("You Can't Post Job")
            setOpenModal(true);
            return ;
        }
        //...call save file api if we will add file
        const option = {
            "jobTitle": jobTitle,
            "userId": userId,
            "userName": userName,
            "companyName": companyName,
            "companyLogo": companyLogo,
            "companyEmail": jobEmail,
            "jobLocation": jobLocation,
            "companyContact": "",
            "employmentType": employmentType,
            "jobDescription": jobValue,
            "workPlaceType": workPlaceType,
            "Experience": experience,
            "Qualification": qualification,
            "Remuneration": remuneration,
            "OtherJobrequirements": otherRequirements,
            'Department': department,
            'jobDescriptionsFileName': jobValue,

        }
        console.log(option.jobDescription, "option")

        console.log("this is option", option);
        let postData = {
            method: "POST",
            url: `${baseURL}/job/createJob`,
            data: option,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(postData).then((response) => {
            console.log("this is post response:", response)
            if (response) {
                // toast.success("Job Posted Successfully", { theme: "colored" });
                setSubmitMessage("Your job has been posted successfully")
                if(isSubscriber){
                    setOpenSuccessModal(true);
                }else{
                    // increament count;
                    setCount(count + 1);
                    setOpenModal(true)
                }
                
                setTimeout(() => {
                    setOpenModal(false)
                    
                    window.location.href = "/jobs";
                }, 3000);

            }
            else {
                toast.error("Some Error Occured", { theme: "colored" });
                console.log("error occured:");
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const personalEmailDomains = [
        "gmail.com",
        "yahoo.com",
        "outlook.com",
        "hotmail.com",
        "aol.com",
        "icloud.com"

    ];

    function validateEmail(email){
        const emailDomain = email.split('@')[1];

        if (personalEmailDomains.includes(emailDomain)){
            toast.error("You need a company domain mail address to create a job post.");
            return false;
        }
        return true;
    }

    useEffect(()=>(
        checkUserIsSubscriber()
    ),[])

    const checkUserIsSubscriber = async()=>{
         let checkIsSubcriber = {
            method: "GET",
            url: `${baseURL}/pay/isSubscriber/${userId}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(checkIsSubcriber).then((response) => {
            //data

            if(response?.data?.status==="active"){
                
                setIsSubscriber(true)
            }else {
                getJobCount()
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    console.log(accessToken)
    console.log(userId)
    const getJobCount = async()=>{
        let getCount = {
            method: "GET", // Change method to POST for passing userId in the body
            url: `${baseURL}/job/getJobCountFromLastMonth/${userId}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getCount).then((response) => {
            console.log(response)
            setCount(response.data.count)
        }).catch((error) => {
            console.log(error);
        });
    }
    const theme = createTheme(
        {
            typography: {
                fontFamily: 'poppins, Arial',
            },
        }
    );
    const uploadImage = () => {
        console.log(companyLogo)
        const formData = new FormData();
        formData.append("uploader", companyLogo);
        console.log("This is upload img function call:");
        axios.post(`${baseURL}/upload-file`, formData, {
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        }).then((response) => {
            if (response.data) {
                console.log("Image saved successfully");
                console.log(response.data[0].location)
                setCompanyLogo(response.data[0].location);
            }
            else {
                console.log("error occured:");
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    const opencrop = () => {
        setlogocrop(true);
    }
    const onChangeprofilepic = (file) => {
        setCompanyLogo(file);
        setlogocrop(false);
    }
    const handleClose = () => {
        setisopen(false);
        setpopupData(false)
    }
    const handlePOpup = () => {
        setisopen(true);
        setpopupData(true);
    }
    useEffect(() => { }, [popupData])
    return (
        <div>
            <Helmet>
                <title>Champhunt | Post-Job</title>
            </Helmet>

            <ThemeProvider theme={theme}>
                {(next) &&
                    <div style={{ paddingTop: "124px", paddingBottom: "20px" }}>
                        <Grid >

                            <Container component="main" maxWidth="sm" >

                                <CssBaseline />

                                <Box sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', border: 1, borderRadius: 3, backgroundColor: 'white' }}>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField id="standard-basic" label="Company Name*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            value={companyName}
                                            onClick={(e) => { setlogocrop(false) }}

                                            error={ValidationFirst && companyName === ""}

                                        />

                                        <TextField id="standard-basic" label="Company Email*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setJobEmail(e.target.value)}
                                            value={jobEmail}
                                            
                                            error={validateEmail(jobEmail)&& ValidationFirst && jobEmail === ""}
                                            onClick={(e) => { setlogocrop(false) }}
                                        />

                                        <TextField id="standard-basic" label="Department*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setDepartment(e.target.value)}
                                            value={department}
                                            error={ValidationFirst && department === ""}
                                            onClick={(e) => { setlogocrop(false) }}
                                        />

                                        <TextField id="standard-basic" label="Job Title*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setjobTitle(e.target.value)}
                                            value={jobTitle}
                                            error={ValidationFirst && jobTitle === ""}
                                            onClick={(e) => { setlogocrop(false) }}
                                        />

                                        <TextField id="standard-basic" label="Job Location*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setJobLocation(e.target.value)}
                                            value={jobLocation}
                                            error={ValidationFirst && jobLocation === ""}
                                            onClick={(e) => { setlogocrop(false) }}
                                        />

                                        <FormControl size="small" fullWidth sx={{ marginBottom: 2 }} onClick={(e) => { setlogocrop(false) }}>
                                            <InputLabel id="demo-select-small">Workplace Type*</InputLabel>
                                            <Select error={ValidationFirst && workPlaceType === ""}
                                                labelId="demo-select-small" id="demo-select-small" label="Workplace Type" value={workPlaceType}
                                                onChange={(e) => setWorkPlaceType(e.target.value)} required >
                                                <MenuItem value="Onsite" >On-site</MenuItem>
                                                <MenuItem value="Hybrid">Hybrid</MenuItem>
                                                <MenuItem value="Remote">Remote</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <FormControl size="small" fullWidth sx={{ marginBottom: 2 }} onClick={(e) => { setlogocrop(false) }}>
                                            <InputLabel id="demo-select-small">Employement Type*</InputLabel>
                                            <Select error={ValidationFirst && employmentType === ""}
                                                labelId="demo-select-small" id="demo-select-small" value={employmentType} label="Employement type*"
                                                onChange={(e) => setEmploymentType(e.target.value)} >
                                                <MenuItem value="Full-Time" >Full-Time</MenuItem>
                                                <MenuItem value="Part-Time" >Part-Time</MenuItem>
                                                <MenuItem value="Contract" >Contract</MenuItem>
                                                <MenuItem value="Internship">Internship</MenuItem>
                                                <MenuItem value="Volunteer">Volunteer</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Crop onChangeprofilepic={onChangeprofilepic} />
                                        <Box component="span" m={1} sx={{ mt: 3, textAlign: 'center' }} >
                                            <Button variant="contained" sx={{ mt: 3, ml: 'auto', float: 'left' }} color="primary" onClick={navigateToJobs}> Cancel</Button>
                                            <Button variant="contained" sx={{ mt: 3, ml: 'auto', float: 'right' }} onClick={handleNext} >
                                                Next
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Container>
                        </Grid>
                    </div>
                }
                {
                    (!next) &&
                    <div style={{ paddingTop: "80px", paddingBottom: "20px" }}>
                        <Grid>

                            <Container Container component="main" maxWidth="sm" >

                                <CssBaseline />
                                <Box sx={{ marginTop: 6, padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', border: 1, borderRadius: 3, backgroundColor: 'white' }}>

                                    <Box sx={{ width: '100%', margin: '-3% 0% 0% 7%;' }}>
                                        <img
                                            src={cancel}
                                            className="icon-btn"
                                            alt=""
                                            role="button"
                                            style={{ height: 20, marginLeft: "96%", cursor: "pointer" }}
                                            onClick={handlePOpup}
                                        />
                                        {popupData && <JobCancelPopuP open={isopen} setOpen={setisopen} handleclose={handleClose} />}


                                    </Box>

                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField id="standard-basic" label="Experience*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setExperience(e.target.value)} value={experience} error={ValidationSecoundPage && experience === ""}
                                        />

                                        <TextField id="standard-basic" label="Qualification*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setQualification(e.target.value)} value={qualification} error={ValidationSecoundPage && qualification === ""}
                                        />

                                        <TextField id="standard-basic" label="Remuneration*" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setRemuneration(e.target.value)} value={remuneration} error={ValidationSecoundPage && remuneration === ""}
                                        />

                                        <TextField id="standard-basic" label="Other requirements" size="small" variant="outlined" fullWidth sx={{ marginBottom: 2 }}
                                            onChange={(e) => setOtherRequirements(e.target.value)} value={otherRequirements}
                                        />

                                        <div>
                                            <ReactQuill
                                                theme="snow"
                                                value={jobValue}
                                                placeholder="PLEASE FILL JOB DESCRIPTION"
                                                onChange={setjobValue}
                                            />
                                            {(ValidationSecoundPage && jobValue === "") ? <p style={{ color: "red", marginTop: 0, fontSize: 12 }}>please fill the job description*</p> : ""}
                                        </div>



                                        <Button variant="contained" sx={{ mt: 3 }} onClick={() => setNext(true)}>
                                            Previous
                                        </Button>
                                        <Button variant="contained" sx={{ mt: 3, float: 'right' }} onClick={handleSubmit}>
                                            Publish the job
                                        </Button>
                                    </Box>
                                </Box>
                            </Container>
                        </Grid>
                    </div>
                }

                {   
                    openModal && (
                        <div>
                            {/* <Button onClick={handleOpenModal}>Open modal</Button> */}
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={jobsuccess} width={130} alt="Job Success" />
                                    </div>
                                    <p style={{ color: "#313335", fontWeight: "600", fontSize: "20px", marginBottom: "8px" }}>ATTENTION</p>

                                    <p>
                                        {submitMessage}
                                    </p>
                                    <p style={{ color: "#F37121", fontWeight: "bold", marginBottom: "8px" }}>Only <span style={{ color: "#C1510B" }}> {5 - count} FREE </span>Jobs available</p>
                                    <p style={{ color: "#62666A" }}>Subscribe now for unlimited</p>
                                    <p style={{ fontWeight: "bold", color: "#62666A", marginBottom: "17px" }}>Job Posting & Applying</p>
                                    <Typography>
                                        <button style={{ backgroundColor: "#0C5BA0", border: "none", padding: "10px", borderRadius: "7px", width: "100%", width: "350px", margin: "0 auto" }} >
                                            <a href="/champhunt-plus" style={{ color: "white", textDecoration: "none", marginLeft: '7px', fontWeight: "500" }}>
                                                Go to subscription page
                                            </a>
                                        </button>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    )
                }
                {   
                    openSuccessModal && (
                        <div>
                            {/* <Button onClick={handleOpenModal}>Open modal</Button> */}
                            <Modal
                                open={openSuccessModal}
                                onClose={handleCloseSuccessModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={success} width={130} alt="Job Success" />
                                    </div>
                                    <p style={{ color: "#313335", fontWeight: "600", fontSize: "20px", marginBottom: "8px" }}>CONGRATULATIONS</p>

                                    <p style={{color:"#62666A", marginBottom:"10px"}}>
                                        {submitMessage}
                                    </p>
                                    <Typography>
                                        <button style={{ backgroundColor: "#0C5BA0", border: "none", padding: "10px", borderRadius: "7px", width: "100%", width: "350px", margin: "0 auto" }} >
                                            <a href="/" style={{ color: "white", textDecoration: "none", marginLeft: '7px', fontWeight: "500" }}>
                                            Go to pitch page
                                            </a>
                                        </button>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    )
                }
            </ThemeProvider >

        </div >
    );
};


export default PostJob;
