import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PredictionItem.css";
import axios from "axios";
import { getAuthToken } from "../../../utils/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import PlayerAdd from './playerAddModal'
import { Button } from "@mui/material";

const AddPlayerItem = (props) => {
  const [data, setData] = useState(props.data)
  const [bdata,setBdata]=useState("Add+")
  const [openAddPlayerModal, setOpenAddPlayerModal] = useState(false)
  const accessToken = getAuthToken();
  const navigate = useNavigate();
  const handleOpenAddPlayerModal = () => setOpenAddPlayerModal(true);
  const handleCloseAddPlayerModal = () => setOpenAddPlayerModal(false);

  const AddPlayer = () => {
    if (!accessToken && !props.id) return
    const pdata={
        playerDetails: data,
        teamName:data?.country,
        fantasyId:props.fantasyId
      }
    var addDetails = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/fantasyMatch/addMatchPlayer`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },
      data:pdata
    };
    axios(addDetails)
      .then(response => {
        if (response) {
          toast.success("Player Added")
          handleCloseAddPlayerModal()
        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }
  console.log("daat", data)
  

  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_prediction-item">
        <div className="contest_admin_prediction-header">
          <div className="contest_admin_prediction-item-name">
            {data?.playerName}
          </div>
          <div><Button disabled={bdata=="Added"} onClick={()=>{setBdata("Added");AddPlayer();}}>{bdata}</Button></div>
        </div>
        <div className="contest_admin_prediction-sub-header">
          <div className="contest_admin_prediction-sub-header--runs">
            
              Role- {data?.role}
            
          </div>
          <div>
            <p className="contest_admin_prediction-sub-header--runs">
              Country- {data?.country}
            </p>
          </div>
          <div>
            <p className="contest_admin_prediction-sub-header--runs">
              Team- {data?.team}
            </p>
          </div>
          
        </div>
      </div>
      
      {openAddPlayerModal && <PlayerAdd handleOpen={handleOpenAddPlayerModal}
        handleClose={handleCloseAddPlayerModal} open={openAddPlayerModal} predictionId={data?._id}
        handleAddPlayer={AddPlayer} />

      }
      
    </div>

  );
};

export default AddPlayerItem;
