import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import dealBall from "../../../../assets/images/rewards/dealBall.png";
import wronganswer from "../../../../assets/images/rewards/wronganswer.png";
import rightansswerball from "../../../../assets/images/rewards/rightansswerball.png";
import leftarrow from "../../../../assets/images/rewards/arrowleft.png";
import rightarrow from "../../../../assets/images/rewards/arrowright.png";
import backarrow from "../../../../assets/images/rewards/backArrow.png";
import axios from "axios";
import { getAuthToken } from "../../../../utils/auth";
import { Button } from "@mui/material";
import PredictionShowAnswer from "./PredictionShowAnswer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Run from "../../../posts/run";

export default function CheckAnswer(props) {
  const mobileView = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const [userPredictionData, setUserPrediction] = useState([]);
  const userId = localStorage.getItem("user_id");
  const predictionid = useSelector((state) => state.prediction.prediction);
  const accessToken = getAuthToken();

  const [predictionQuestion, setPredictionQuestion] = useState({});
  const [predictionQuestionIndex, setPredictionQuestionIndex] = useState(0);

  useEffect(() => {
    console.log("i am userPrediction call ", userPredictionData);
    if (userPredictionData && userPredictionData.length) {
      setPredictionQuestion(userPredictionData[predictionQuestionIndex]);
    }
  }, [userPredictionData, predictionQuestionIndex]);

  useEffect(() => {
    console.log("i am prediction call ", predictionQuestion);
  }, [predictionQuestion]);

  const getPredictionResult = () => {
    if (!userId && !predictionid) return
    const getTotalRun = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        "/contest/userPredictionQuestion/getPlayNowResult",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
        predictionId: predictionid,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        if (response.data) {
          console.log("iamresult", response);
          setUserPrediction(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userId && predictionid) {
      getPredictionResult();
    }
  }, []);

  const handlePredictionPage = () => {
    mobileView ? navigate("/prediction") : props?.handleclosePopup();
  };
  const handlequestionindex = (index) => {
    setPredictionQuestionIndex(index);
  };

  return (
    <div style={{ backgroundColor: "#1A1919" }}>
      <div className="predictinbackArrow">
        <div>
          <img src={backarrow} onClick={handlePredictionPage} />
        </div>
      </div>

      <div className="PredictionPlayContainer">
        <div className="PMobileRuns">
          <img src={dealBall} />
          <p className="Pmobilepara">
            <Run isRunValueNeeded={true} />
          </p>
        </div>

        {predictionQuestion != null &&
          predictionQuestion !== "undefiend" &&
          Object.keys(predictionQuestion).length != 0 && (
            <PredictionShowAnswer
              PasspredictionQuestion={predictionQuestion}
              count={predictionQuestionIndex}
            />
          )}

        <div className="arrowcontainer">
          <div
            onClick={() => {
              if (predictionQuestionIndex > 0) {
                setPredictionQuestionIndex(
                  parseInt(predictionQuestionIndex - 1)
                );
              }
            }}
          >
            <img src={leftarrow} />
          </div>
          <div className="prediQuestionHeading">
            {userPredictionData.map((i, index) => (
              <div
                key={index}
                className="predictPaddingleft"
                style={{
                  paddingRight:
                    index === userPredictionData.length - 1 ? "24px" : "0px",
                }}
                onClick={() => handlequestionindex(index)}
              >
                {i?.rightAns?.text === i?.userselectedoption?.text ? (
                  <img src={rightansswerball} />
                ) : (
                  <img src={wronganswer} />
                )}
              </div>
            ))}
          </div>
          <div
            onClick={() => {
              if (predictionQuestionIndex < userPredictionData.length - 1) {
                setPredictionQuestionIndex(
                  parseInt(predictionQuestionIndex + 1)
                );
              }
            }}
          >
            <img src={rightarrow} />
          </div>
        </div>
        <div className="closeContainer">
          <Button
            className="predictionFont116 closeButtonPre"
            onClick={handlePredictionPage}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}
