import React from 'react';
import { Box, IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const CustomPagination = ({ totalDeals, dealsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalDeals / dealsPerPage);
  const pageNumbers = [];

  // Calculate a range of page numbers to display (maximum of 5)
  const maxVisiblePages = 3;
  const middlePage = Math.floor(maxVisiblePages / 2);
  let startPage = currentPage - middlePage;
  let endPage = currentPage + middlePage;

  if (startPage <= 0) {
    startPage = 1;
    endPage = Math.min(totalPages, maxVisiblePages);
  }

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {currentPage > 1 && (
        <IconButton onClick={handlePrevious}>
          <NavigateBeforeIcon />
        </IconButton>
      )}
      <IconButton onClick={() => onPageChange(1)} disabled={currentPage === 1}>
        <Box
          width={30}
          height={30}
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid #9A9A9A"
          color={currentPage === 1 ? 'white' : '#9A9A9A'}
          backgroundColor={currentPage === 1 ? '#0C5BA0' : 'white'}
          style={{
            fontFamily: 'Poppins',
            fontSize: '10px',
            fontWeight: '500',
            lineHeight: '20px',
            letterSpacing: '0px',
            textAlign: 'left',
          }}
        >
          {1}
        </Box>
      </IconButton>
      {pageNumbers.map((pageNumber) => (
       pageNumber!==1 && pageNumber!==totalPages ? (<IconButton key={pageNumber} onClick={() => onPageChange(pageNumber)} >
          <Box
            width={30}
            height={30}
            display="flex"
            justifyContent="center"
            alignItems="center"
            border="1px solid #9A9A9A"
          color={currentPage === pageNumber ? 'white' : '#9A9A9A'}
            backgroundColor={currentPage === pageNumber ? '#0C5BA0' : 'white'}
            style={{
              fontFamily: 'Poppins',
              fontSize: '10px',
              fontWeight: '500',
              lineHeight: '20px',
              letterSpacing: '0px',
              textAlign: 'left',
            }}
          >
            {pageNumber}
          </Box>
        </IconButton>):<></>
      ))}
      {totalPages!==1 &&<IconButton onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
        <Box
          width={30}
          height={30}
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid #9A9A9A"
          color={currentPage === totalPages ? 'white' : '#9A9A9A'}
          backgroundColor={currentPage === totalPages ? '#0C5BA0' : 'white'}
          style={{
            fontFamily: 'Poppins',
            fontSize: '10px',
            fontWeight: '500',
            lineHeight: '20px',
            letterSpacing: '0px',
            textAlign: 'left',
          }}
        >
          {totalPages}
        </Box>
      </IconButton>}
      {currentPage < totalPages && (
        <IconButton onClick={handleNext}>
          <NavigateNextIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default CustomPagination;
