
import { useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import BlockModel from './BlockModel';
import './index.scss'
import ReportModel from './ReportModel';
import Reportpopup from './Reportpopup';
import ShareToAppsPopupPage from "../posts/shareToOtherApps"
import { getStorageItem } from '../../utils/sessionStorage';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

// profile dropdown
export default function ProfileDropdown(props) {
  const [blockuser, setblockuser] = useState(props.Block);
  const matches = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);
  const [openReport, setReport] = useState(false);
  const [shareToOtherApps, setShareToOtherApps] = useState(false);
  const [url, setUrl] = useState("");
  const [report, setReporttype] = useState(null);
  const handleOpenBlock = () => setOpen(true);
  const accessToken = getStorageItem("token");
  const navigate = useNavigate();
  const handleCloseBlock = (data) => {

    setOpen(false);
    if (data === 'yes') {
      props.hadleUserBlockToparent()
    }
  }
  const handleOpenReportUser = () => setReport(true);
  const handleCloseReportuser = () => setReport(false);
  const handleCloseReportsubmit = () => setReporttype(null);
  const handleblockAccountThroughReport = () => { setOpen(true); setReporttype(null) }
  const handleCloseShareToOtherAppsPopup = () => { setShareToOtherApps(false) }

  const handleUnblock = () => {

    if (!props.userId && !accessToken) return;
    const shared = {
      method: "DELETE",
      url: global.config.ROOTURL.prod + "/auth/block/un-block-user",
      headers: {
        Authorization: "Bearer " + accessToken,
        'Content-Type': 'application/json'
      },
      data: {
        blockedUserId: props.userId
      }
    }
    axios(shared)
      .then((response) => {
        props.hanldeunblockuser();
      }).catch((error) => {
        console.log(error)
      })
  }

  const reporttype = (e) => { setReporttype(e) }

  const checkblockUser = (blocklist) => {
    const isblock = blocklist.filter(i => (i.blockedUserId == props.userId));
    if (isblock.length > 0) {
      setblockuser(true)
    } else {
      setblockuser(false)
    }
  }


  const handleShareToOtherAppsPopup = () => {

    let shareUrl = `${global.config.share_url}/profile-preview/${props.userId}`
    setUrl(shareUrl)
    setShareToOtherApps(true)
  }

  const getBlockuserList = () => {

    if (!props.userId && !accessToken) return;
    const getList = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/block/get-blocked-users-by-userID",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        blockUserId: props.userId
      }

    };


    axios(getList)
      .then((res) => {
        setblockuser(res.data)
      })
      .catch((error) => {
        console.log(error)
      });

  };

  useEffect(() => {
  }, [blockuser, report])
  useEffect(() => {
    getBlockuserList();
  }, [blockuser, open, props?.Block])
  return (
    <div className={props.ownProfile ? 'profile_owndropdown' : 'profile_dropdown'}>

      {
        // if this is my own profile then this code run 
        props.ownProfile ? <>
          <div className="comments-hld " onClick={() => navigate('/blockedUsers')}> Blocked Users</div>

        </>
          // othetwise this code run 
          : blockuser
            ?
            <p style={{ color: '#C52928' }} onClick={handleUnblock}>Unblock</p>
            :
            <>
              <div className="comments-hld " onClick={handleShareToOtherAppsPopup}> Share</div>
              <p onClick={handleOpenReportUser}>Report</p>
              <p onClick={handleOpenBlock}> Block</p>
            </>
      }

      {open && <BlockModel open={open} handleblockClose={handleCloseBlock} userId={props.userId} userName={props.userName} />}
      {openReport && <ReportModel open={openReport} handlRepoetClose={handleCloseReportuser} handlepassreport={reporttype} />}

      {report && <Reportpopup open={report} handlereport={handleCloseReportsubmit} userId={props.userId} userName={props.userName} blockAccount={handleblockAccountThroughReport} />}
      {shareToOtherApps && <ShareToAppsPopupPage open={shareToOtherApps} url={url} handleClose={handleCloseShareToOtherAppsPopup} />}

    </div>
  )
}
