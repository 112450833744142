import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getStorageItem } from "../../../utils/sessionStorage";
import { BallTriangle } from "react-loader-spinner";
import { Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import UserCard from "../../followers/followers";
import { useParams } from "react-router";

const FollowingList = () => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [followings, setFollowings] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();
  const [shouldload, setShouldload] = useState(false);

  const fetchMoreData = () => {
    // Fetch additional data
    if (!accessToken) return
    const req = {
      method: "GET",
      url: global.config.ROOTURL.prod +
        `/auth/get-following-byuserid/${id}/${pageNum}/${global.config.pagePerSize}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(req).then(response => {

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        // setFollowings(prevItems => prevItems.concat(response.data));
        const newFollowingsMap = new Map(followings.map(followings => [followings._id, followings]));
        response.data.forEach(followings => {
          if (!newFollowingsMap.has(followings._id)) {
            newFollowingsMap.set(followings._id, followings);
          }
        });
        const updatedFollowings = Array.from(newFollowingsMap.values());
        setFollowings(updatedFollowings);
        setPageNum(prevPage => prevPage + 1);
        setShouldload(true);
      }
    })
      .catch(error => console.log(error));
  };
  useEffect(() => {
    fetchMoreData();
  }, []);

  return (
    <>
      {shouldload &&
        (


          <InfiniteScroll
            className="page_profile"
            dataLength={followings.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />}
          >
            {followings
              .sort((a, b) => {
                const nameA = a.firstName.toLowerCase() + ' ' + a.lastName.toLowerCase();
                const nameB = b.firstName.toLowerCase() + ' ' + b.lastName.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((follower, index) => (
                <div className="single-user-main-wrapper" key={index} style={{ paddingRight: '10px' }}>
                  <UserCard following={follower} />
                </div>
              ))}

          </InfiniteScroll>

        )

      }
    </>
  );
};

export default FollowingList;