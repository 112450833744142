import { Box, FormControl, InputAdornment, Paper } from "@material-ui/core";
import React, { useState, useRef, useMemo } from "react";
import "./index.css";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";

// jodit-react
import JoditEditor from "jodit-react";

// Mui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const accessToken = getStorageItem("token");
const EventCreationForm = () => {
  const [banner, setBanner] = useState("/banner.png");
  const [pageBanner, setPageBanner] = useState("");
  const [detailBanner, setDetailBanner] = useState("");
  const moment = require("moment");
  const [formContent, setFormContent] = useState({
    image: "",
  });
  const [status, setStatus] = useState(true);
  // Image Wrapper Styles
  const styles = {
    paperContainer: {
      height: 200,
      width: "100%",
      backgroundImage: `url(${banner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    pageContainer: {
      height: 200,
      width: "100%",
      backgroundImage: `url(${pageBanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    detailsContainer: {
      height: 200,
      width: "100%",
      backgroundImage: `url(${detailBanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  };

  const editor = useRef(null);
  const [content, setContent] = useState("");

  // const config = useMemo(
  // 	{
  // 		readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  // 		placeholder: placeholder || 'Start typings...'
  // 	},
  // 	[placeholder]
  // );

  const titleRef = useRef();
  const locationRef = useRef();
  const priceRef = useRef();
  const codeRef = useRef();
  const organizingByRef = useRef();
  const pageBannerRef = useRef();
  const detailBannerRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const bannerRef = useRef();
  const descriptionRef = useRef();

  const handleSubmit = async () => {
    const eventTitle = titleRef.current.value;
    const eventLocation = locationRef.current.value;
    const registrationFee = priceRef.current.value;
    const eventNameCode = codeRef.current.value;
    const organizingBy = organizingByRef.current.value;
    const pageBanners = pageBanner;
    const detailBanners = detailBanner;
    const startDate = startDateRef.current.value;
    const startTime = startTimeRef.current.value;
    const endDate = endDateRef.current.value;
    const endTime = endTimeRef.current.value;
    const eventBanner = banner;
    const eventdescription = content;

    if (
      eventTitle === "" ||
      eventLocation === "" ||
      registrationFee === "" ||
      organizingBy === "" ||
      pageBanners === "" ||
      detailBanners === "" ||
      startDate === "" ||
      startTime === "" ||
      endDate === "" ||
      endTime === "" ||
      eventBanner === ""
    ) {
      toast.error("Error: Data incomplete");
      return;
    }

    const eventData = {
      eventTitle: eventTitle,
      eventLocation: eventLocation,
      registrationFee: registrationFee,
      eventNameCode: eventNameCode,
      organizingBy: organizingBy,
      pageBanner: pageBanners,
      detailBanner: detailBanners,
      startDate: moment(startDate).format("DD-MM-YYYY"),
      startTime: startTime,
      endDate: moment(endDate).format("DD-MM-YYYY"),
      endTime: endTime,
      eventBanner: eventBanner, 
      eventdescription: eventdescription,
    };
    console.log(eventData);

    // Object.keys(data).forEach(key => {
    //   console.log(key, typeof(data[key]), data[key]);
    // });

    // Axios Post request
    const createNewEvent = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/events/create",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: eventData,
    };
    axios(createNewEvent)
      .then((response) => {
        console.log("response.data", response.data);
        if (response?.status === 200 && response) {
          toast.success("New Event created successfuly");
          bannerRef.current.value = "";
          titleRef.current.value = "";
          setContent("");
          priceRef.current.value = "";
          pageBannerRef.current.value = "";
          organizingByRef.current.value = "";
          detailBannerRef.current.value = "";
          locationRef.current.value = "";
          codeRef.current.value = "";
          startDateRef.current.value = "";
          startTimeRef.current.value = "";
          endDateRef.current.value = "";
          endTimeRef.current.value = "";
          setBanner("");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          error.message
            ? toast.error("Error: " + error.message)
            : toast.error("Error: " + error);
        }
        error.message
          ? toast.error("Error: " + error.message)
          : toast.error("Error: " + error);
      });
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const uploadUrl = `${global.config.ROOTURL.prod}/upload-file`;
    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("response:", response.data[0].location);
      return response.data[0].location;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Image upload failed. Please try again.");
    }
  };

  const onChangePicture = (event, eventForm) => {
    const { name, files } = event.target;
    if (files[0].type.includes("image")) {
      const file = files[0];
      // Generate preview URL
      const reader = new FileReader();
      reader.onload = () => {
        if (name === "page-image") {
          setPageBanner(reader.result);
        } else if (name === "detail-image") {
          setDetailBanner(reader.result);
        } else if (name === "image") {
          setBanner(reader.result);
        }
      };
      reader.readAsDataURL(file);

      // Compress and upload the image
      const formData = new FormData();
      new Compressor(file, {
        quality: 0.8,
        success: (compressedResult) => {
          if (compressedResult.size > 5000000) {
            alert("File size exceeds 5MB.");
          } else {
            formData.append("uploader", compressedResult);
            axios
              .post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then((response) => {
                if (response.data) {
                  if (eventForm === "page-Banner") {
                    setPageBanner(response.data[0].location);
                  } else if (eventForm === "detail-Banner") {
                    setDetailBanner(response.data[0].location);
                  } else if (eventForm === "event-Banner") {
                    setBanner(response.data[0].location);
                  }
                  console.log("Image uploaded successfully");
                } else {
                  console.log("Error occurred during upload");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        },
      });
    }
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={6} xs={12}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container columnSpacing={1}>
            {/* Title */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="title"
                  label="Title"
                  name="title"
                  autoFocus
                  margin="normal"
                  inputRef={titleRef}
                />
              </FormControl>
            </Grid>

            {/* Location */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Location"
                  name="location"
                  autoFocus
                  margin="normal"
                  required
                  inputRef={locationRef}
                />
              </FormControl>
            </Grid>

            {/* Price */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">INR</InputAdornment>
                  }
                  label="Fees"
                  margin="normal"
                  required
                  inputRef={priceRef}
                />
              </FormControl>
            </Grid>

            {/* Code */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">Event Code</InputAdornment>
                  }
                  label="Code"
                  margin="normal"
                  required
                  inputRef={codeRef}
                />
              </FormControl>
            </Grid>

            {/* Organizing By */}
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">Organizing By</InputAdornment>
                  }
                  label="Organizing By"
                  margin="normal"
                  required
                  inputRef={organizingByRef}
                />
              </FormControl>
            </Grid>

            {/* Page Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="file"
                  label="Page Banner"
                  margin="normal"
                  name="page-image"
                  accept="image/*"
                  onChange={(e)=>onChangePicture(e,"page-Banner")}
                  InputLabelProps={{
                    shrink: true,
                    sx: { fontWeight: "bold" },
                  }}
                />
              </FormControl>
            </Grid>


            {/* Detail Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="file"
                  label="Detail Banner"
                  margin="normal"
                  name="detail-image"
                  accept="image/*"
                  onChange={(e)=>onChangePicture(e,"detail-Banner")}
                  InputLabelProps={{
                    shrink: true,
                    sx: { fontWeight: "bold" },
                  }}
                />
              </FormControl>
            </Grid>

            {/* Start Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-date"
                  label="Start date"
                  type="date"
                  defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={startDateRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>

            {/* Start Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-time"
                  label="Start time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={startTimeRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>

            {/* End Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-date"
                  label="End date"
                  type="date"
                  defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={endDateRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>

            {/* End Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-time"
                  label="End time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={endTimeRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>

            {/* Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="file"
                  autoFocus
                  margin="normal"
                  name="image"
                  accept="image/*"
                  onChange={(e)=>onChangePicture(e,"event-Banner")}
                  inputRef={bannerRef}
                  required
                />
                {/* <input id="bannerImage" type="file" onChange={onChangePicture}  ref={bannerRef}/> */}
              </FormControl>
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                {/* <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={5}
                  margin="normal"
                  inputRef={descriptionRef}
                /> */}
                <JoditEditor
                  ref={editor}
                  value={content}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(content) => setContent(content)} // preferred to use only this option to update the content for performance reasons
                  onChange={(content) => { }}
                  fullWidth
                  multiline
                  rows={5}
                  className="editor-main"
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* Button */}
            <Grid item xs={12}>
              <FormControl fullWidth></FormControl>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                margin="normal"
                onClick={handleSubmit}
                fullWidth
              >
                Create Event
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Paper
          component="img"
          src={banner}
          style={{
            width: "100%",
            height: "auto",
            marginTop: "10px",
            border: "1px solid #ccc",
          }}
          alt="General Banner Preview"
        />
        <Paper
          component="img"
          src={pageBanner}
          style={{
            width: "100%",
            height: "auto",
            marginTop: "10px",
            border: "1px solid #ccc",
          }}
          alt="Page Banner Preview"
        />
        <Paper
          component="img"
          src={detailBanner}
          style={{
            width: "100%",
            height: "auto",
            marginTop: "10px",
            border: "1px solid #ccc",
          }}
          alt="Detail Banner Preview"
        />
      </Grid>

      <ToastContainer />
    </Grid>
  );
};

export default EventCreationForm;
