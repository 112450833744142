import React, { useEffect, useState } from "react";
import axios from "axios";
import UploadImg from "../.././../assets/images/uploadImage.png";
import { getStorageItem } from "../../../utils/sessionStorage";

const Stickers = () => {
  const [form, setForm] = useState({
    stickerFile: null,
    stickerURL: "",
    title: "",
  });
  const [errors, setErrors] = useState({});
  const [stickers, setStickers] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingStickers, setIsLoadingStickers] = useState(false);
  const accessToken = getStorageItem("token");

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file, file.name);
    const uploadResponse = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (uploadResponse.data && uploadResponse.data.length > 0) {
      return uploadResponse.data[0].location;
    } else {
      throw new Error("No data returned from upload.");
    }
  };

  const getAllStickers = () => {
    if (!accessToken) return;
    setIsLoadingStickers(true);
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllSticker`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setStickers(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stickers:", error);
        }
      })
      .finally(() => {
        setIsLoadingStickers(false);
      });
  };

  const uploadSticker = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsUploading(true);
    try {
      let stickerURL = form.stickerURL;
      if (form.stickerFile) {
        stickerURL = await uploadFileToS3(form.stickerFile);
      }

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/groupchat/uploadSticker`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          stickerURL,
          title: form.title,
        },
      };

      const { data } = await axios(options);
      setStickers((prevStickers) => [...prevStickers, data]);
      setForm({ stickerFile: null, stickerURL: "", title: "" });
      setErrors({});
    } catch (error) {
      console.log("Error uploading sticker:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setForm({ ...form, stickerFile: file });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!form.stickerFile && !form.stickerURL) {
      formErrors.sticker = "Please upload a sticker or provide a URL";
    }
    if (!form.title.trim()) {
      formErrors.title = "Title is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  useEffect(() => {
    getAllStickers();
  }, []);

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <form onSubmit={uploadSticker} style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "15px", textAlign: "center" }}>
          <img
            src={UploadImg}
            width={150}
            height={150}
            alt="Upload"
            style={{ marginBottom: "10px" }}
          />
          <p style={{ marginBottom: "10px" }}>Upload Image or GIF Here</p>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*,.gif"
            style={{ display: "none" }}
            id="fileInput"
          />
          <label
            htmlFor="fileInput"
            style={{
              padding: "10px 15px",
              backgroundColor: "#007bff",
              color: "white",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Choose File
          </label>
          {form.stickerFile && (
            <p style={{ marginTop: "10px" }}>{form.stickerFile.name}</p>
          )}
          {errors.sticker && (
            <p style={{ color: "red", marginTop: "5px" }}>{errors.sticker}</p>
          )}
        </div>
        <div style={{ marginBottom: "15px" }}>
          <input
            type="text"
            name="stickerURL"
            value={form.stickerURL}
            onChange={handleInputChange}
            placeholder="Or enter sticker URL"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <input
            type="text"
            name="title"
            value={form.title}
            onChange={handleInputChange}
            placeholder="Sticker Title"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          {errors.title && (
            <p style={{ color: "red", marginTop: "5px" }}>{errors.title}</p>
          )}
        </div>
        <button
          type="submit"
          disabled={isUploading}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {isUploading ? "Uploading..." : "Upload Sticker"}
        </button>
      </form>
      <div
        style={{
          height: "600px",
          overflowY: "auto",
          //   border: "1px solid #ccc",
          //   borderRadius: "5px",
          padding: "10px",
        }}
      >
        {isLoadingStickers ? (
          <p style={{ textAlign: "center" }}>Loading stickers...</p>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "15px",
            }}
          >
            {stickers.map((sticker, index) => (
              <div key={index} style={{ textAlign: "center" }}>
                <img
                  src={sticker.stickerURL}
                  alt={sticker.title}
                  style={{ width: "100%", height: "auto", borderRadius: "5px" }}
                />
                <p style={{ marginTop: "5px" }}>{sticker.title}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Stickers;
