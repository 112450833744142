import React from "react";
import "./index.scss";
import profileImgHardik from "../../../assets/images/newLandingPage/hardik.png";
import profileImgAmar from "../../../assets/images/newLandingPage/amar.png";
import profileImgKaran from "../../../assets/images/newLandingPage/karan.png";
import ratingImg from "../../../assets/images/newLandingPage/Rating.png";

const testimonialData = [
  {
    name: "Hardik Joshi",
    profileImg: profileImgHardik,
    ratingImg: ratingImg,
    desc:
      "“I've always loved discussing cricket, and Champhunt makes it even better by rewarding me for my contributions. From match predictions to trivia quizzes, I'm earning 'Runs' - Hardik, Gujrat”",
  },
  {
    name: "Amar Chaudhary",
    profileImg: profileImgAmar,
    ratingImg: ratingImg,
    desc:
      "“With Champhunt, I don't just watch cricket; I live it! The real-time updates keep me on the edge of my seat, and the engaging community makes every match more enjoyable - Amar, Gorakhpur”",
  },
  {
    name: "Karan Negi",
    profileImg: profileImgKaran,
    ratingImg: ratingImg,
    desc:
      "“ I enjoy the exciting contests and challenges on Champhunt! It's a fun way to test my cricketing skills, compete with friends, and earn 'Runs' along the way - Karan, Delhi”",
  },
];

const FifthSection = () => {
  return (
    <div className="fifth_section">
      <div className="text_card_container">
        <p className="bold_text">TESTIMONIALS</p>
        <div className="cards">
          {testimonialData.map((testimonial, index) => (
            <div className="card" key={index}>
              <img className="profile_img" src={testimonial.profileImg} alt="Profile" />
              <img className="profile_rating" src={testimonial.ratingImg} alt="Rating" />
              <span className="profile_name">{testimonial.name}</span>
              <p className="profile_desc">{testimonial.desc}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="gradient_color"></div>
    </div>
  );
};

export default FifthSection;

