import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";

import deleteIcon from "../../../assets/images/chatreactions/alert.png";

import secure from "../../../assets/images/chatreactions/secure.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const ReportSuccessModal = ({
  messagetype,
  openSuccess,
  handleCloseSuccessModal,
}) => {
  return (
    <Modal
      open={openSuccess}
      onClose={handleCloseSuccessModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            src={messagetype === "report" ? secure : deleteIcon}
            alt="report png"
            width={140}
            height={140}
          />
          <p
            style={{
              color: "#313335",
              fontSize: "17px",
              marginTop: "10px",
            }}
          >
            {messagetype === "report"
              ? "Reported Successfully!"
              : "Ohhhoonooo!!!"}
          </p>
          <p
            style={{
              color: "#F37121",
              fontSize: "17px",
              marginTop: "10px",
            }}
          ></p>
          <p style={{ color: "#62666A", marginTop: "10px" }}>
            {messagetype === "report" &&
              "Thank you for helping us keep the community safe."}
            {messagetype === "delete" &&
              "Your group deleted successfully. Feel free to create it again.."}
          </p>
          <div
            style={{
              justifyContent: "space-between",
              marginTop: "25px",
              width: "300px",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                border: "none",
                cursor: "pointer",
                padding: "10px 20px",
                width: "300px",
                borderRadius: "7px",
              }}
              onClick={handleCloseSuccessModal}
            >
              {messagetype === "report" && "User Reported Successfully"}
              {messagetype === "delete" && `Group ${messagetype} Succesfully`}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ReportSuccessModal;
