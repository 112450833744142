import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import "./rules.scss";
import { Helmet } from "react-helmet";
import runImg from "../../assets/images/rules/run.png";
import dealImg from "../../assets/images/rules/deal.png";
import spamImg from "../../assets/images/rules/spam.png";
import bgImg from "../../assets/images/rules/champhunt.png";
import character from "../../assets/images/rules/Character.png";
import { useState } from "react";

const Rules = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpandedSection(isExpanded ? panel : null);
  };
  return (
    <div className="rule-container" style={{ backgroundColor: expandedSection ? '#fafafa' : '#07365f' }}>
      <Helmet>
        <title>Champhunt | Rules</title>
      </Helmet>
      <img className="bg" src={bgImg} alt="background" />
      <div className="inner-container">
        <Grid item xs={12} className="inner2-container">
          <img className="character" src={character} alt="character" />
          <Typography className="headding">Terms & Conditions</Typography>
        </Grid>
        <Grid item xs={12} className="rules-accordian">
          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >  <img src={runImg} alt="Runs" style={{ width: "40px", height: "40px" }} />
              <Typography className="accordian-heading">Runs</Typography>
            </AccordionSummary>
            < Grid item xs={12} className="horizontal">
            </Grid>
            <AccordionDetails className="accordian-details">
              <Typography>
                <h3 style={{ color: "#0C5BA0" }}>How to score Runs:</h3>
                <ol type="1" style={{ paddingLeft: "13px" }}>
                  <li style={{ color: "#C1510B" }}>By Engagement -</li>
                  <ol type="a" style={{ paddingLeft: "23px" }}>
                    <li>A user scores 10 runs for every pitch (post) he creates.</li>
                    <li>A user scores 5 runs for every pitch he reshares.</li>
                    <li>A user scores 2 runs for every comment he makes on other users’ pitches.</li>
                  </ol>

                  <li style={{ color: "#C1510B", }}>By making Good Content -</li>
                  <ol type="a" style={{ paddingLeft: "23px" }}>
                    <li>Other users can give your pitches 2/4/6 runs based on
                      how much they like it. These runs get added to your
                      runs tally, but are not deducted from the other user’s tally.
                    </li>
                  </ol>

                  <li style={{ color: "#C1510B" }}>By Referrals - </li>
                  <ol type="a" style={{ paddingLeft: "23px" }}>
                    <li>A user can score runs by referring his friends to Champhunt. He/she gets 1000 runs for every new user that joins using their referral.
                    </li>

                  </ol>
                  <h3 style={{ color: "#0C5BA0" }}>What to do with the Runs:</h3>
                  <ol type="a" style={{ paddingLeft: "23px" }}>
                    <li>User can use the runs to redeem deals from the deals section of the website. </li>
                  </ol>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{ marginTop: "20px" }}
            >
              <img src={spamImg} alt="Spam" style={{ width: "40px", height: "40px" }} />
              <Typography className="accordian-heading">Spamming</Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal">

            </Grid>
            <AccordionDetails className="accordian-details">
              <Typography>
                <ol type="1" style={{ paddingLeft: "23px" }}>
                  <li>This policy is applicable to all users of Champ Hunt. Reposting a pitch more than once
                    signals an intent to increase runs by unfair means and will be considered spamming which can lead to action being taken against the account holder. </li>
                  <li>While commenting on other posts is encouraged and appreciated,
                    unnecessary and multiple comments will be considered to be spamming as well. </li>
                  <li>Such activities can lead to the user’s account being suspended for 3 months,
                    6 months, a year or permanently depending on the severity of the violation of the policy. </li>
                  <li>As an alternative to suspension, deals redeemed can be canceled
                    as well as runs scored can be deducted from the user’s account. </li>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              style={{ marginTop: "20px" }}
            >
              <img src={dealImg} alt="Deal" style={{ width: "40px", height: "40px" }} />
              <Typography className="accordian-heading">Deals</Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal">
            </Grid>
            <AccordionDetails className="accordian-details">
              <Typography>

                <ol type="1" style={{ paddingLeft: "23px" }}>
                  <li>Deals which have been redeemed by a user will be released by Champ Hunt on a weekly basis. A user expect to receive his deal by the first half of the succeeding week. </li>
                  <li>In case a user enters incorrect or incomplete details regarding himself, he won’t be able to redeem deals until this error has been corrected. ‘</li>
                  <li>A user should create only one account for his personal use. This account will be linked to his contact number and will be considered his primary account. </li>
                  <li>If a user tries to redeem deals in more than one account having the same contact number, he will be unable to do so in accounts other than his primary one. </li>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid> 
      </div>
    </div>
  );
};
export default Rules

