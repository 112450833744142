import React, { useContext, useEffect, useState } from 'react'
import './BlockUser.scss'
import { Button } from '@material-ui/core'
import defaultuser from '../../../assets/images/profile/default_avatar.png'
import usericon from '../../../assets/images/icons/newusericon.png'
import blueball from '../../../assets/images/icons/blueBall.png'
import { useDispatch } from 'react-redux'
import { PathAction } from '../../../redux/reducers/globalPath'
import AppContext from '../../../context/AppContext'
import { getStorageItem } from '../../../utils/sessionStorage'
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';

export default function BlockUser() {
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");

    const [blocklist, setBlocklist] = useState([]);
    const [NoblockUser, setNOblockuser] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [shouldLoadPage, setShouldLoadPage] = useState(false);

    const path = window.location.pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    dispatch(PathAction.handlePath({ payload: path }));

    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;

    const handleUnBlock = (id) => {

        if (!id && !accessToken) return;
        const unblock = {
            method: "DELETE",
            url: global.config.ROOTURL.prod + "/auth/block/un-block-user",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                blockedUserId: id
            }
        };
        if (userId != null) {
            axios(unblock)
                .then((res) => {
                    // navigate(`/profile/${userId}`);
                    setPage(1);
                    setBlocklist([]);
                    getBlockuserList(1);

                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    const getBlockuserList = async (no) => {
        // Fetch additional data

        if (!accessToken) return;
        const req = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/auth/block/getBlockedUsers/${no ? no : page}/${global.config.pagePerSize}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };

        axios(req).then(response => {
            if (response.data.length === 0) {
                setHasMore(false);
                if (no === 1 || page === 1) {
                    setNOblockuser(true);
                }
            } else {
                setBlocklist(prevItems => prevItems.concat(response.data));
                setPage(prevPage => prevPage + 1);
                setShouldLoadPage(true);
            }
        })
            .catch(error => console.log(error));
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        setBlocklist([]);
        getBlockuserList();
        setShowFooter(true);
        return () => {
            setShowFooter(false);
        };
    }, []);
    return (
        <>
            {shouldLoadPage &&

                <div className=' MainBlockComponent'>
                    <InfiniteScroll
                        dataLength={blocklist.length}
                        next={getBlockuserList}
                        hasMore={hasMore}
                        loader={<BallTriangle
                            height="100"
                            width="100"
                            color="grey"
                            ariaLabel="loading"

                        />}
                    >
                        {blocklist.map(i => (
                            <div className='BlockuserComponnt BlockFlexSpacebtwn'>

                                <div className='BlockFlexSpacebtwn widthAndFont'>
                                    <img src={i.blockedUserProfile ? i.blockedUserProfile : defaultuser} style={{ height: '60px', width: '60px', borderRadius: '50%' }} />
                                    <div className="BlockFlexvertical">
                                        <p>{i.blockedUserName}</p>
                                        <div className='BlockFlexSpacebtwn'>
                                            <div className='BlockFlex'>
                                                <img src={usericon} />
                                                <p className='paddingleftBock'>{i.blockedUserDetails.followers.length}</p>
                                            </div>
                                            <div className='BlockFlex paddingleftBock' >
                                                <img src={blueball} />
                                                <p className='paddingleftBock'>{i.blockedUserDetails.totalRun}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='widthAndFont'>
                                    <Button className='UnblockBtn' onClick={() => handleUnBlock(i.blockedUserId)}>UnBlock</Button>
                                </div>
                            </div>
                        ))}


                    </InfiniteScroll>

                </div>
            }
            {NoblockUser &&
                <div className='MainBlockComponent' style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p>NO Blocked Users</p>
                </div>
            }
        </>
    )
}
