import React, { useEffect, useState } from "react";
import "../voucher/get_vocher/index.scss";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
const AdminPlayer = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = async (_id) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/player/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete player");
      }

      setPlayers(players.filter((player) => player._id !== _id));
      console.log("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartAuction = async (_id) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/player/${_id}/start`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            auctionDuration: 120,
          }),
        }
      );
      toast.success("Player Auction Started Successfully");
      if (!response.ok) {
        throw new Error("Failed to start the player auction");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAuction = async (_id, sendData) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/player/${_id}/update-result`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(sendData),
        }
      );
      toast.success("Player Auction Result Updated Successfully");
      if (!response.ok) {
        throw new Error("Failed to update the player auction results");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAnnounceResult = async (_id) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/player/${_id}/announce-results`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Player Auction Result Announced Successfully");
      if (!response.ok) {
        throw new Error("Failed to Announce the player result");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(
          `${global.config.ROOTURL.prod}/auction/players`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch PLayers List");
        }
        const data = await response.json();
        setPlayers(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  const handleUpdatePlayer = async (id, updatedPlayer) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/player/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedPlayer),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update player");
      }

      const updatedPlayers = players.map((player) =>
        player._id === id ? { ...player, ...updatedPlayer } : player
      );

      setPlayers(updatedPlayers);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="voucher-list">
      {players.map((player) => (
        <PlayerCard
          key={player._id}
          player={player}
          onUpdate={handleUpdatePlayer}
          handleDelete={handleDelete}
          handleAnnounceResult={handleAnnounceResult}
          handleUpdateAuction={handleUpdateAuction}
          handleStartAuction={handleStartAuction}
        />
      ))}
    </div>
  );
};

const PlayerCard = ({
  player,
  onUpdate,
  handleDelete,
  handleStartAuction,
  handleUpdateAuction,
  handleAnnounceResult,
}) => {
  const {
    _id,
    name,
    jerseyNo,
    basePrice,
    image,
    time,
    specialization,
    country,
  } = player;
  const [teams, setTeams] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [editedJerseyNo, setEditedJerseyNo] = useState(jerseyNo);
  const [editedBasePrice, setEditedBasePrice] = useState(basePrice);
  const [editedTime, setEditedTime] = useState(time);
  const [editedSpecialization, setEditedSpecialization] =
    useState(specialization);
  const [editedCountry, setEditedCountry] = useState(country);
  const [updateResult, setUpdateResult] = useState(false);
  const [finalAmount, setFinalAmount] = useState("");
  const [status, setStatus] = useState("SOLD");
  const handleUpdate = async () => {
    const updatedPlayer = {
      //   discount: editedDiscount,
      //   expiry: editedExpiry ? new Date(editedExpiry) : null,
      //   redirectUrl: editedRedirectUrl,
      //   redeemedRun: editedReedemedRun,
      name: editedName,
      basePrice: editedBasePrice,
      country: editedCountry,
      jerseyNo: editedJerseyNo,
      time: editedTime,
      specialization: editedSpecialization,
    };

    try {
      await onUpdate(_id, updatedPlayer);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update player:", error);
    }
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(
          `${global.config.ROOTURL.prod}/auction/teams`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Teams List");
        }
        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTeams();
  }, []);

  return (
    <div className="voucher-card">
      {image && (
        <img
          src={image}
          alt={`${name} player`}
          className="voucher-image"
          style={{ objectFit: "contain" }}
        />
      )}
      <div style={{ marginTop: "160px" }}>
        {isEditing ? (
          <>
            <label>Name</label>
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className="voucher-input"
              placeholder="Name"
            />
            <label>jerseyNo</label>
            <input
              type="number"
              value={editedJerseyNo}
              onChange={(e) => setEditedJerseyNo(Number(e.target.value))}
              className="voucher-input"
              placeholder="Jersey No"
            />
            <label>basePrice</label>
            <input
              type="number"
              value={editedBasePrice}
              onChange={(e) => setEditedBasePrice(Number(e.target.value))}
              className="voucher-input"
              placeholder="Base Price"
            />
            <label>time</label>
            <input
              type="number"
              value={editedTime}
              onChange={(e) => setEditedTime(Number(e.target.value))}
              className="voucher-input"
              placeholder="Time"
            />
            <label>Country</label>
            <input
              type="text"
              value={editedCountry}
              onChange={(e) => setEditedCountry(e.target.value)}
              className="voucher-input"
              placeholder="Country"
            />
            <label>Specialization</label>
            <select
              id="expiry"
              className="ad-creation__form-input"
              placeholder="specialization"
              value={editedSpecialization}
              onChange={(e) => setEditedSpecialization(e.target.value)}
            >
              <option value="batsman">batsman</option>
              <option value="bowler">bowler</option>
              <option value="all-rounder">all-rounder</option>
            </select>

            <div className="voucher-actions">
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <p>Name: {name}</p>
            <p>jerseyNo: {jerseyNo}</p>
            <p>country: {country}</p>
            <p>BasePrice: {basePrice}</p>
            <p>Time: {time}</p>
            <p>specialization: {specialization}</p>

            {updateResult && (
              <div>
                <label>Final Amount</label>
                <input
                  type="number"
                  value={finalAmount}
                  onChange={(e) => setFinalAmount(Number(e.target.value))}
                  className="voucher-input"
                  placeholder="Final Amount"
                />

                <label>Status</label>
                <select
                  id="expiry"
                  className="ad-creation__form-input"
                  placeholder="specialization"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="SOLD">Sold</option>
                  <option value="UNSOLD">Unsold</option>
                  <option value="PENDING IN-AUCTION">Pending in Auction</option>
                </select>
                <label>team Name</label>
                <select
                  id="expiry"
                  className="ad-creation__form-input"
                  placeholder="specialization"
                  value={teamId}
                  onChange={(e) => setTeamId(e.target.value)}
                >
                  {teams.map((team) => (
                    <option value={team._id} key={team._id}>
                      {team.name}
                    </option>
                  ))}
                </select>

                <div className="voucher-actions">
                  <Button
                    onClick={() => {
                      handleUpdateAuction(_id, {
                        finalAmount,
                        status,
                        team: teamId,
                      });
                      setUpdateResult(false);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setUpdateResult(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
            <div
              className="d_u_buttons"
              style={{ flexWrap: "wrap", gap: "4px" }}
            >
              <Button
                onClick={() => setIsEditing(true)}
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Update
              </Button>
              <Button
                onClick={() => handleStartAuction(_id)}
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Start Auction
              </Button>
              <Button
                onClick={() => setUpdateResult(true)}
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Update Auction Player Result
              </Button>
              <Button
                onClick={() => handleAnnounceResult(_id)}
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Announce results
              </Button>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => handleDelete(_id)}
                color="secondary"
                style={{ marginTop: "10px" }}
              >
                Delete
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminPlayer;
