import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import moment from "moment";
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";

const headers = [
  { label: "Deal Name", key: "dealName" },
  { label: "User Name", key: "name" },
  { label: "Redeem Runs", key: "redeemrun" },
  {
    label: "actualRun", key: "actualRun"
  },
  {
    label: "discountPercent", key: "discountPercent"
  },
  { label: "Mobile", key: "mobile" },
  { label: "Redeem Date", key: "dealRedeemDate" },
  { label: "Email", key: "email" },
  { label: "Deal Id", key: "dealId" },
  { label: "User Id", key: "userId" },
];

const columns = [
  { id: "index", label: "S no.", minWidth: 50 },
  { id: "dealName", label: "Deal Name", minWidth: 100 },
  { id: "name", label: "User Name", minWidth: 100 },
  {
    id: "email",
    label: "User Email",
    minWidth: 170,
    align: "right",
  },
  {
    id: "mobile",
    label: "User Mobile",
    minWidth: 170,
    align: "right",
  },
  {
    id: "redeemrun",
    label: "Redeem Runs",
    minWidth: 50,
    align: "center",
  }, {
    id: "actualRun",
    label: "Actual Run",
    minWidth: 100,
    align: "right",
  }, {
    id: "discountPercent",
    label: "Discount Percent",
    minWidth: 100,
    align: "right",
  },
  {
    id: "dealRedeemDate",
    label: "Deal Redeem Date",
    minWidth: 170,
    align: "right",
    format: (value) => moment(value).format("lll"),
  },
];

const RedeemScroll = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [redeemData, setRedeemData] = useState([]);
  const [rows, setRows] = useState([]);
  const [dealFilter, setDealFilter] = useState("");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  useEffect(() => {
    // Fetching all events
    setPage(0);
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url:
        global.config.ROOTURL.prod + `/dealredeemdetails/getAllRedeem/${fromDate}/${toDate}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
        setRedeemData(data);
        // setTotalPageNumber(Math.ceil(data.length / 6));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          // toast.error("Some error occured");
        }
      });
  }, [fromDate, toDate]);

  useEffect(() => {
    setPage(0);
    if (dealFilter) {
      setRows(
        redeemData
          .filter((value, index, array) => value.dealId === dealFilter)
          .map(
            (
              {
                userId,
                userName,
                userEmail,
                mobile,
                dealId,
                redeemedRun,
                actualRun,
                discountPercent,
                dealName,
                createdDate
              },
              index,
              array
            ) => {
              return {
                index: index + 1,
                dealName,
                name: userName,
                mobile,
                redeemrun: redeemedRun,
                dealRedeemDate: moment(createdDate).format("lll"),
                email: userEmail,
                dealId,
                userId,
                actualRun,
                discountPercent,
              };
            }
          )
      );
    } else {
      setRows(
        redeemData.map(
          (
            {
              userId,
              userName,
              userEmail,
              mobile,
              dealId,
              redeemedRun,
              actualRun,
              discountPercent,
              dealName,
              createdDate
            },
            index,
            array
          ) => {
            return {
              index: index + 1,
              dealName,
              name: userName,
              mobile,
              redeemrun: redeemedRun,
              dealRedeemDate: moment(createdDate).format("lll"),
              email: userEmail,
              dealId,
              userId,
              actualRun,
              discountPercent,
            };
          }
        )
      );
    }
  }, [redeemData, dealFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        px={2}
        spacing={2}
        sx={{borderBottom : '1px solid #000000', paddingBottom: '20px'}}
      >
        <Typography variant="h4">Redeem History</Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="baseline"
          spacing={2}
        >

          <CSVLink
            style={{ textDecoration: "none" }}
            data={rows}
            headers={headers}
            filename={`redeem-deals-${fromDate}-to-${toDate}`}
          >
            <Button marginTop={2} variant="contained">
              Download
            </Button>
          </CSVLink>{" "}
        </Stack>
      </Stack>

      <TableContainer style={{ marginTop: '20px', borderRadius: '15px', border: '1px solid #EAECF0' }} component={Paper}>

        <Box style={{ height: '100px', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 25px 0px', alignItems: 'center' }}>
        <TextField
            id="fromDate"
            onChange={(e) => {
              // console.log(
              //   moment(new Date(e.target.value)).format("yyyy-MM-DD")
              // );
              setFromDate(
                moment(new Date(e.target.value)).format("yyyy-MM-DD")
              );
            }}
            label="From"
            type="date"
            // defaultValue="2017-05-24"
            value={fromDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="toDate"
            onChange={(e) => {
              setToDate(moment(new Date(e.target.value)).format("yyyy-MM-DD"));
            }}
            label="To"
            type="date"
            // defaultValue="2017-05-24"
            value={toDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{color:'#667085'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}

                    sx={{ height: '50px !important', color:'#667085' }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default RedeemScroll;
