import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import no1 from '../../assets/images/Events/Raj.png'
import no2 from '../../assets/images/Events/no22.jpg'
import no3 from '../../assets/images/user2.jpeg'
import star from '../../assets/images/landigpage2/star.png'
export default function Example(props)
{
    var items = [{
        name: 'RajAishwary Singh',
        comment: 'I have scored more than 4 million runs in the last 3 months playing various Matchday contest in the last 4 months, which has led me to earn thousands of Rupees. I look forward to playing many more contests on the website and app. ',
        img:no1,
        star:[1,1,1,1]
    }, {
        name: 'Valred Rodrigues',
        comment: 'I have redeemed a MI jersey and an India Jersey just by participating in the matchday contests. I look forward to winning much more things in the forthcoming series.',
        img:no2,
        star:[1,1,1,1,1]
    }, {
        name: 'Ansh Desai',
        comment: 'I enjoy the thrill of winning on matchdays by predicting the outcome of scenarios. It elevates my game experience and gets me some extra bucks as a college student. I love Champhunt.',
        img:no3,
        star:[1,1,1,1]
    },]

    return (
        <Carousel >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
    return (
        <div className='flexbox2' style={{ paddingLeft: 34, paddingRight: 32, paddingTop: 40 }}>
      
            <div className='commentbox'>
                <div style={{ paddingTop: 10 }}>
                    <img src={props.item.img} style={{ borderRadius:'50%',height:'85px'}}/>
                </div>
                <div style={{ paddingTop: 10 }}>
                    <p className='font20ex' > {props.item.name}</p>
                </div>
                <div style={{ paddingTop: 10 }}>
                    {props.item.star.map((i)=>(

                    <img src={star} />
                    ))}
                  {props.item.name==='Ansh Desai' &&  <div style={{display:'inline-block',height:'14px',overflow:'hidden',width:'8px'}}>
                                <img src={star} />
                                 </div>}
                </div>
                <div style={{ padding: 10 }}>
                    <p>{props.item.comment}</p>
                </div>
            </div>

    

    </div>
    )
}
