import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hydrate, render } from "react-dom";

import AppRoutes from "./routes/AppRoutes";
import { CartProvider } from "./context/CartContext";
import { SocketProvider } from "./context/SocketContext";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <SocketProvider>
          <CartProvider>
            <AppRoutes />
          </CartProvider>
        </SocketProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
