import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PaginationRounded from "../PaginationRounded";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const Index = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const startDayRef = useRef();
  const startTimeRef = useRef();
  const runsForEntryRef = useRef();
  const endDayRef = useRef();
  const endTimeRef = useRef();
  const rewardPriceRef = useRef();
  const question1Ref = useRef();
  const option1Ref = useRef();
  const option2Ref = useRef();
  const option3Ref = useRef();
  const option4Ref = useRef();
  const option5Ref = useRef();
  const option6Ref = useRef();
  const [showOption5, setShowOption5] = useState(false);
  const [showOption4, setShowOption4] = useState(false);
  const [showOption3, setShowOption3] = useState(false);

  const handlePredictionCreation = async () => {
    if (!state.id) return toast.error("Something went wrong");
    const question1RefValue = question1Ref?.current?.value;
    const option1RefValue = option1Ref?.current?.value;
    const option2RefValue = option2Ref?.current?.value;
    let option3RefValue;
    let option4RefValue;
    let option5RefValue;
    // let option6RefValue;
    if (showOption3) {
      option3RefValue = option3Ref?.current?.value;
    }
    if (showOption4) {
      option4RefValue = option4Ref?.current?.value;
    }
    if (showOption5) {
      option5RefValue = option5Ref?.current?.value;
    }
    // if (showOption6) {
    //   option6RefValue = option6Ref?.current?.value;
    // }
    if (!question1RefValue) {
      toast.error("Question field is required");
      return;
    }
    if (
      !option1RefValue ||
      !option2RefValue
      // !option3RefValue ||
      // !option4RefValue
    ) {
      toast.error("Atleast 2 options are needed");
      return;
    }
    const predictionQuestionOptions = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        "/contest/predictionQuestion/createPredictionQuestion",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: {
        predictionId: state.id,
        question: question1RefValue,
        options: [
          option1RefValue,
          option2RefValue,
          option3RefValue,
          option4RefValue,
        ],
      },
    };
    if (showOption5 && option5RefValue) {
      predictionQuestionOptions.data.options.push(option5RefValue);
    }

    // if (showOption6 && option6RefValue) {
    //   predictionQuestionOptions.data.options.push(option6RefValue);
    // }

    axios(predictionQuestionOptions)
      .then((data) => {
        toast.success("New Prediction created successfully");
        navigate(`/admin/contest`);
        setName("");
        startDayRef.current.value = "";
        startTimeRef.current.value = "";
        runsForEntryRef.current.value = "";
        endDayRef.current.value = "";
        endTimeRef.current.value = "";
        rewardPriceRef.current.value = "";
        question1Ref.current.value = "";
        option1Ref.current.value = "";
        option2Ref.current.value = "";
        option3Ref.current.value = "";
        option4Ref.current.value = "";
        option5Ref.current.value = "";
        option6Ref.current.value = "";
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    if (name.length > 24) {
      const updatedNameValue = name.slice(0, 24);
      setName(updatedNameValue);
    }
  }, [name]);
  return (
    <div className="contest-admin-create">
      <div className="contest-admin-create-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-header">
            <p className="contest-admin-create-wrapper-header--text">
              {state?.predictionName}
            </p>
            <p
              onClick={() => {
                navigate(`/admin/contest`);
              }}
              className="contest-admin-create-wrapper-header--delete-text"
            >
              back
            </p>
          </div>
          <div className="contest-admin-create-wrapper-form">
            <div className="contest-create-row4-wrapper">
              <p className="admin-contest-creation-general-text">Q1</p>
              <input
                type="text"
                className="contest-create-start-input-full"
                ref={question1Ref}
                required
              />
            </div>
            <div className="contest-create-row5-wrapper">
              <p className="admin-contest-creation-general-text">A1</p>
              <div className="contest-create-radio-button-wrapper">
                <input
                  type="text"
                  className="contest-create-start-input-options"
                  placeholder="Option-1"
                  id="option-1"
                  ref={option1Ref}
                />
              </div>
            </div>
            <div className="contest-create-row5-wrapper">
              <p className="admin-contest-creation-general-text">A2</p>
              <div className="contest-create-radio-button-wrapper">
                <input
                  type="text"
                  className="contest-create-start-input-options"
                  placeholder="Option-2"
                  id="option-2"
                  ref={option2Ref}
                />
              </div>
            </div>
            {showOption3 && (
              <div className="contest-create-row5-wrapper">
                <p className="admin-contest-creation-general-text">A3</p>
                <div className="contest-create-radio-button-wrapper">
                  <input
                    type="text"
                    className="contest-create-start-input-options"
                    placeholder="Option-3"
                    id="option-3"
                    ref={option3Ref}
                  />
                </div>
              </div>
            )}
            {showOption4 && (
              <div className="contest-create-row5-wrapper">
                <p className="admin-contest-creation-general-text">A4</p>
                <div className="contest-create-radio-button-wrapper">
                  <input
                    type="text"
                    className="contest-create-start-input-options"
                    placeholder="Option-4"
                    id="option-4"
                    ref={option4Ref}
                  />
                </div>
              </div>
            )}

            {showOption5 && (
              <div className="contest-create-row5-wrapper">
                <p className="admin-contest-creation-general-text">A5</p>
                <div className="contest-create-radio-button-wrapper">
                  <input
                    type="text"
                    className="contest-create-start-input-options"
                    placeholder="Option-5"
                    id="option-5"
                    ref={option5Ref}
                    required
                  />
                </div>
              </div>
            )}
            {/* {showOption6 && (
              <div className="contest-create-row5-wrapper">
                <p className="admin-contest-creation-general-text">A6</p>
                <div className="contest-create-radio-button-wrapper">
                  <input
                    type="text"
                    className="contest-create-start-input-options"
                    placeholder="Option-6"
                    id="option-6"
                    ref={option6Ref}
                    required
                  />
                </div>
              </div>
            )} */}
            <div className="contest-create-add-more">
              <div className="contest-create-add-more--main">
                <AddCircleOutlineIcon
                  className="contest-create-add-more-icons"
                  onClick={() => {
                    if (!showOption3) {
                      setShowOption3(true);
                    } else if (!showOption4) {
                      setShowOption4(true);
                    } else if (!showOption5) {
                      setShowOption5(true);
                    } else {
                      toast.error("Max limit is 5 options per prediction");
                    }
                  }}
                />

                {/* <RemoveCircleOutlineIcon
                  className="contest-create-add-more-icons"
                  onClick={() => {
                    if (showOption5) {
                      setShowOption5(false);
                    } else if (!showOption5 && showOption4) {
                      setShowOption5(false);
                    } else if (!showOption4 && !showOption5) {
                      return;
                    } else {
                      return;
                    }
                  }}
                /> */}
                <RemoveCircleOutlineIcon
                  className="contest-create-add-more-icons"
                  onClick={() => {
                    if (showOption5) {
                      setShowOption5(false);
                    } else if (showOption4) {
                      setShowOption4(false);
                    } else if (showOption3) {
                      setShowOption3(false);
                    } else {
                      return;
                    }
                  }}
                />
              </div>
            </div>
            <button
              onClick={handlePredictionCreation}
              className="contest-preediction-admin-creation-submit-button"
            >
              Confirm
            </button>
            {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
