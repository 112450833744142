import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DirectMessageSection from "./DirectMessageSeaction";
import Avatar from "@material-ui/core/Avatar";

const FriendChatSection = ({
  friendMessages,
  userId,
  socket,
  receiverDets,
  friendId,
  // loadMoreMessages,
}) => {
  const [typingUsers, setTypingUsers] = useState({});
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [friendMessages]);

  useEffect(() => {
    if (socket) {
      socket.on("user typing", ({ userId, name, profilePic }) => {
        setTypingUsers((prev) => ({ ...prev, [userId]: { name, profilePic } }));
      });

      socket.on("user stop typing", ({ userId }) => {
        setTypingUsers((prev) => {
          const newTypingUsers = { ...prev };
          delete newTypingUsers[userId];
          return newTypingUsers;
        });
      });

      return () => {
        socket.off("user typing");
        socket.off("user stop typing");
      };
    }
  }, [socket]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const { scrollTop } = chatContainerRef.current;
    if (scrollTop === 0 && !isLoading) {
      loadOlderMessages();
    }
  };

  const loadOlderMessages = async () => {
    setIsLoading(true);
    // await loadMoreMessages();
    setIsLoading(false);
  };

  return (
    <div
      ref={chatContainerRef}
      onScroll={handleScroll}
      style={{
        height: "calc(100vh - 200px)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* {isLoading && <div>Loading...</div>} */}
      {friendMessages && friendMessages.length > 0 ? (
        <div>
          {friendMessages.map((chat, index) => (
            <DirectMessageSection
              key={chat._id || index}
              chat={chat}
              ind={index}
            />
          ))}
        </div>
      ) : (
        <p>No messages</p>
      )}
      {Object.entries(typingUsers).map(([userId, user]) => (
        <div
          key={userId}
          style={{ display: "flex", alignItems: "center", padding: "10px" }}
        >
          <Avatar
            alt={user.name}
            src={user.profilePic}
            style={{ width: 40, height: 40, marginRight: 10 }}
          />
          <span>{user.name} is typing...</span>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

FriendChatSection.propTypes = {
  friendMessages: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  socket: PropTypes.object,
  friendId: PropTypes.string.isRequired,
  // loadMoreMessages: PropTypes.func.isRequired,
};

FriendChatSection.defaultProps = {
  friendMessages: [],
  userId: "",
};

export default FriendChatSection;
