
import React, { useEffect, useState } from "react";
import tossball from "../../../../assets/images/rewards/tossball.png";


export default function PredictionQuestion(props) {
 
  const [predictionData, setPrediction] = useState(props?.Predictionquestionpass);
  
  const handleSaveAnswer=(id,txt)=>{
    if(predictionData._id){

      props?.handleSaveAnswerpass(id,txt,predictionData._id)
    }
  }
 
  useEffect(()=>{
   
    setPrediction(props?.Predictionquestionpass);
  },[props?.Predictionquestionpass])


  return (
    <div>
      {predictionData && (
        <div style={{ color: "white" }}>
          <div className="predictiontextAlign predictionFont116 predictionPadding">
            <p>Prediction</p>
          </div>
          <div className="prediQuestionHeading predictionFont10 predictiontextAlign predictionPadding">
            <img alt='toss-ball' src={tossball} />
            <p style={{ paddingLeft: "10px" }}>
              Earn  Runs for each correct prediction
            </p>
          </div>
          <div className="questionPredFlex predictionPadding">
            <div>
              <p className="PredictionQuetionNo"> {props.count}</p>
            </div>
            <p className="predictionFont112" style={{ paddingLeft: "20px" }}>
              {predictionData.question}
            </p>
          </div>
          <div className="predictionPadding" style={{ paddingTop: "20px" }}>
            {predictionData?.options.map(({ _id, text }, index) => (
              <div
                className="predictiontextAlign predictionFont112 predictionOption"
                key={index}
                onClick={()=>handleSaveAnswer(_id,text)}
                >
               
                {text}{" "}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
