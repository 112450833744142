import React, { useState } from "react";
import { Typography, useMediaQuery, Box } from "@mui/material";
import Button from "@mui/material/Button";
import "./DealDescription.scss";
import NewDealModal from "../NewDeals/NewDealModal";
import { useEffect } from "react";
import { getAuthToken } from "../../utils/auth";
import axios from "axios";
import config from "../../config";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useParams, useNavigate } from "react-router-dom";
import RedeemedTodayModal from "../NewDeals/AlreadyRedeemedToday";
import UpdateMobileNumber from "./UpdateMobileNumber";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoanDescription from "./LoanDescription";
import { IoMdClose } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ball from "../../assets/images/marketplace/redball.png";
import RunsBackModal from "./RunsBackModal";
import { useLocation } from "react-router-dom";
import DealGiftBox from "../../assets/images/deals/gift.png";
import ScratchImage from "../../assets/images/deals/Frame 1686552845.png";
import ScratchCard from "react-scratchcard";
import RedirectIcon from "../../assets/images/deals/ion_arrow-up-right-box-outline.png";
import backArrowButton from "../../assets/images/deals/Back.png";
import { width } from "@mui/system";

const DealDescription = (props) => {
  const mobileView = useMediaQuery("(max-width:769px)");
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));
  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;
  const { checkRedeem, redeemedDeals } = props;
  const { deal } = props;
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const navigate = useNavigate();
  const [userRuns, setUserRuns] = useState(0);
  const [eligible, setEligible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalForRedeemed, setShowModalForRedeemed] = useState(false);
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [redeemedToday, setRedeemedToday] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updateMobileNo, setUpdateMobileNo] = useState(false);
  const [openRedeemedModal, setOpenRedeemedModal] = useState(false);
  const [dealDetailsById, setDealDetailsById] = useState("");
  const [voucherDetailsById, setvoucherDetailsById] = useState("");
  const [remainingTime, setRemainingTime] = useState(0);
  const handleCloseCongratulationPage = () => setHasRedeemed(false);
  const handleUpdateMobileNoModal = () => setUpdateMobileNo(!updateMobileNo);
  const [adModal, setAdModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const { dealId } = useParams();
  const [ad, setAd] = useState(null);
  const [adWatched, setAdWatched] = useState(false);
  const [showScratchCard, setShowScratchCard] = useState(false);
  const [scratched, setScratched] = useState(false);
  const [requiredTRun, setRequiredTRun] = useState(0);

  const pageFilter = useLocation();
  const filterName = pageFilter.pathname.split("/")[1];

  const settings = {
    width: mobileView ? 350 : 1200,
    height: 68,
    image: ScratchImage,
    finishPercent: 80,
    objectFit: "cover",
    borderRadius: "10px",
    onComplete: () => redeemedVoucher(),
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(voucherDetailsById.code);
    toast.success("Copied to clipboard!");
  };

  const handleScratch = () => {
    setScratched(true);

    // Send the event to Google Analytics when the card is scratched
    if (window.gtag) {
      window.gtag("event", "scratch_card_interaction", {
        event_category: "Scratch Card",
        event_label: `Voucher ID: ${settings.voucherId}`,
        value: 1, // Optional, can be used to represent a value (like a score or reward)
      });
    }
  };

  const checkUserRuns = (DealRun, TotalRun, discount) => {
    // checking user is eligible to avail deal or not
    let realRuntoAvialDeal = DealRun * ((100 - discount) / 100);
    if (TotalRun < realRuntoAvialDeal) {
      setEligible(false);
    }
  };
  const [runsBack, setRunsBack] = useState(10);
  const [isSubscriber, setIsSubscriber] = useState(false);

  useEffect(() => {
    checkUserIsSubscriber();
  }, []);

  const checkUserIsSubscriber = async () => {
    let checkIsSubcriber = {
      method: "GET",
      url: `${config.ROOTURL.prod}/pay/isSubscriber/${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(checkIsSubcriber)
      .then((response) => {
        //data
        if (response?.data?.status === "active") {
          setIsSubscriber(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openRunsModal, setOpenRunsModal] = useState(false);
  const handleOpenRunModal = () => {
    setOpenRunsModal(true);
  };
  const handleCloseRunModal = () => {
    setOpenRunsModal(false);
  };

  const location = useLocation();
  const code = location.state?.code;
  const { voucherId } = useParams();

  const getVoucherByIdAndCode = async () => {
    // if (!accessToken) return;
    // if (!userId) return console.error("userId required");

    const getDealDetails = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/voucher/details/${voucherId}/${code}`,
      // headers: {
      //   Authorization: "Bearer " + accessToken,
      // },
    };
    return axios(getDealDetails)
      .then((response) => {

        setvoucherDetailsById(response.data);
        setShowScratchCard(response.data.isUsed);
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return 0;
      });
  };
  useEffect(() => {
    getVoucherByIdAndCode();
  }, []);
  const getRuns = async () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    return axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return 0;
      });
  };

  const getDealByDealId = async () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getDealDetails = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/deal/getDealById/" + dealId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    return axios(getDealDetails)
      .then((response) => {
        setDealDetailsById(response.data);
        // setRunsBack(response.data.runsBack)
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return 0;
      });
  };

  const reedemLoanDeal = (name, email, mobile, Loan) => {
    if (mobile === null || mobile === undefined || !mobile) {
      setUpdateMobileNo(true);
    } else {
      const dealRedeemed = {
        method: "post",
        url: global.config.ROOTURL.prod + "/auth/LoanredeemDeal",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          dealId,
          name: name,
          email: email,
          mobile: mobile,
          loanAmount: Loan,
          comingSoon: dealDetailsById?.comingSoon,
        },
        json: true,
      };
      axios(dealRedeemed)
        .then((response) => {
          if (response.data.message === "redeemedtoday") {
            setShowModalForRedeemed(true);
            setHasRedeemed(false);
            setOpenRedeemedModal(false);
          } else if (response.data.message === "redeemed Data required") {
            setUpdateMobileNo(true);
          } else {
            setHasRedeemed(true);
            setRedeemedToday(false);
            navigate("/deal-congo");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };

  const redeemDeal = (name, email, mobile) => {
    if (mobile === null || mobile === undefined || !mobile) {
      setUpdateMobileNo(true);
      // handleToggle();
    }

    // if ((name && email && dealId && mobile!==0 )) {
    else {
      const dealRedeemed = {
        method: "post",
        url: global.config.ROOTURL.prod + "/auth/redeemDeal",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          dealId,
          name: name,
          email: email,
          mobile: mobile,
          comingSoon: dealDetailsById?.comingSoon,
        },
        json: true,
      };

      if (userRuns >= dealDetailsById?.redeemrun) {
        axios(dealRedeemed)
          .then((response) => {
            if (response.data.message === "redeemedtoday") {
              setShowModalForRedeemed(true);
              setHasRedeemed(false);
              setShowModal(!showModal);
              setRedeemedToday(true);
              setOpenRedeemedModal(false);
              // handleCloseRedeemedModal()
            } else if (response.data.message === "redeemed Data required") {
              setUpdateMobileNo(true);
            } else {
              setHasRedeemed(true);
              setRedeemedToday(false);

              if (isSubscriber) {
                setOpenRunsModal(true);
              } else {
                navigate("/deal-congo");
              }
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              clearStorage();
              navigate("/login");
            }
          });
      } else {
        toast.error("You have insufficient runs to redeem this deal");
      }
    }
  };

  const handleOnClick = () => {
    setAdModal(true);
  };

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const handleNavigateDealPage = () => {
    navigate("/deals");
  };

  const handleCloseRedeemedToday = () => {
    setShowModalForRedeemed(!showModalForRedeemed);
  };

  useEffect(() => {
    if (dealDetailsById.categories === "TimeLimit") {
      const calculateRemainingTime = () => {
        // console.log("deal?.startDateAndTime,deal?.endDateAndTime", dealDetailsById?.startDateAndTime, dealDetailsById?.endDateAndTime)
        const startTime = new Date(dealDetailsById?.startDateAndTime).getTime();
        const endTime = new Date(dealDetailsById?.endDateAndTime).getTime();

        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 5);

        currentDate.setMinutes(currentDate.getMinutes() + 30);
        const currentTime = currentDate.getTime();
        if (currentTime < startTime) {
          setRemainingTime(startTime - currentTime);
        } else if (currentTime > endTime) {
          setRemainingTime(0);
        } else {
          setRemainingTime(endTime - currentTime);
        }
      };

      const interval = setInterval(calculateRemainingTime, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dealDetailsById?.startDateAndTime, dealDetailsById?.endDateAndTime]);
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    const formattedDays = days.toString().padStart(2, "0");
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    if (days > 0) {
      return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  };

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 5);

  currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
  const calculateOffPrice = (redeemrun, discount) => {
    return Math.round((redeemrun * discount) / 100);
  };
  const calculateDiscountedPrice = (redeemrun, discount) => {
    return Math.round(redeemrun - (redeemrun * discount) / 100);
  };

  const UseEffectHelper = async () => {
    const DealRun = await getDealByDealId();
    const TotalRun = await getRuns();
    setRequiredTRun(TotalRun);
    setShowFooter(true);
    checkUserRuns(DealRun.redeemrun, TotalRun, DealRun.discount);
  };

  useEffect(() => {
    UseEffectHelper();
    return () => {
      setShowFooter(false);
    };
  }, []);

  useEffect(() => {
    // checkUserRuns();
    return () => {
      setHasRedeemed(false);
    };
  }, [showModal, userRuns]);

  const fetchRandomAd = async () => {
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/ads/fetch-ad-by-deal-id/${dealId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
          },
        }
      );
      // console.log(
      //   `${global.config.ROOTURL.prod}/ads/fetch-ad-by-deal-id/${dealId}`
      // );
      if (response?.status === 200) {
        setAd(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRandomAd();
  }, []);

  const handleWatchFullAd = async () => {
    try {
      // Give 1000 runs
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/auth/given-runs/`,
        {
          userId: userId,
          givenRun: 1000,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      const viewIncreaseResponse = await axios.post(
        `${global.config.ROOTURL.prod}/ads/increaseViewCount`,
        {
          _id: ad?._id,
          userId: userId,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (response.status === 200 && viewIncreaseResponse.status === 200) {
        toast.success("Yipeee! You just got 1000 runs.");
        setVideoModal(false);
        setShowModal(true);
      } else {
        setVideoModal(false);
        toast.error("Some error occured!");
      }
    } catch (error) {
      console.error("Error watching ad:", error);
      setVideoModal(false);
    }
  };

  const redeemedVoucher = async () => {
    let voucherRedeemDetails = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/voucher/redeem",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        code: code,
        voucherId: voucherId,
        userId: userId,
      },
    };
    axios(voucherRedeemDetails)
      .then((response) => {
        console.log("Go Corona Go Corona", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   redeemedVoucher();
  // }, []);

  return (
    <>
      <div
        style={mobileView ? mobilestyle : style}
        className="main-singlegift-container"
      >
        <div className="deal-giftContainer">
          <div
            className="back-button"
            style={{
              display: mobileView ? "flex" : "none",
              width: "100%",
              margin: "0px 0px 20px 0",
            }}
            onClick={() => navigate(-1)}
          >
            <img
              style={{
                width: mobileView ? "20px" : "30px",
                height: mobileView ? "20px" : "30px",
              }}
              src={backArrowButton}
              alt="Back"
            />
          </div>
          {/* only visible when user have insufficient run to reedem deal */}

          {/* start */}
          {!eligible && (
            <LoanDescription
              totalRun={userRuns}
              dealRun={dealDetailsById?.redeemrun}
              discount={dealDetailsById?.discount}
              reedemLoanDeal={reedemLoanDeal}
            />
          )}
          {/* end  */}

          {dealDetailsById.categories === "TimeLimit" &&
          dealDetailsById?.discount > 0 &&
          new Date(dealDetailsById?.discountStartDateAndTime) <=
            currentDateTime &&
          new Date(dealDetailsById?.discountEndDateAndTime) >=
            currentDateTime ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="timer-section">
                <span className="timer-text">Limited Time </span>
                <span className="timer-text">{formatTime(remainingTime)}</span>
              </div>
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text">
                    -
                    {calculateOffPrice(
                      dealDetailsById?.redeemrun,
                      dealDetailsById?.discount
                    )}{" "}
                    OFF
                  </span>
                </div>
              </div>
            </div>
          ) : dealDetailsById.categories === "TimeLimit" ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="timer-section">
                <span className="timer-text">Limited Time </span>
                <span className="timer-text">{formatTime(remainingTime)}</span>
              </div>
            </div>
          ) : dealDetailsById.categories === "Exclusive" &&
            dealDetailsById?.discount > 0 &&
            new Date(dealDetailsById?.discountStartDateAndTime) <=
              currentDateTime &&
            new Date(dealDetailsById?.discountEndDateAndTime) >=
              currentDateTime ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span
                    className="overlay-text"
                    style={{ marginLeft: "-16px" }}
                  >
                    EXCLUSIVE
                  </span>
                  <span className="overlay-text">
                    -
                    {calculateOffPrice(
                      dealDetailsById?.redeemrun,
                      dealDetailsById?.discount
                    )}{" "}
                    OFF
                  </span>
                </div>
              </div>
            </div>
          ) : dealDetailsById.categories === "Exclusive" ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text">EXCLUSIVE</span>
                </div>
              </div>
            </div>
          ) : dealDetailsById?.discount > 0 &&
            new Date(dealDetailsById?.discountStartDateAndTime) <=
              currentDateTime &&
            new Date(dealDetailsById?.discountEndDateAndTime) >=
              currentDateTime ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text">
                    -
                    {calculateOffPrice(
                      dealDetailsById?.redeemrun,
                      dealDetailsById?.discount
                    )}{" "}
                    OFF
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <img
                src={
                  filterName === "deal-description"
                    ? dealDetailsById?.dealImage
                    : voucherDetailsById.voucherImage
                }
                alt=""
                className="deal-giftImage"
              />
            </div>
          )}

          <div className="deal-giftcontent">
            <div className="deal-reamininggiftcontainer">
              <div
                className="deal-reamaning"
                style={{
                  width: `${
                    (dealDetailsById?.count * 100) / dealDetailsById?.totalDeal
                  }%`,
                }}
              ></div>
            </div>

            {filterName === "deal-description" ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "25px 30px",
                  }}
                >
                  <p className="deal-giftFont">{dealDetailsById?.name}</p>
                  {dealDetailsById?.discount > 0 &&
                  new Date(dealDetailsById?.discountStartDateAndTime) <=
                    currentDateTime &&
                  new Date(dealDetailsById?.discountEndDateAndTime) >=
                    currentDateTime ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p className="discount-price1">
                        {dealDetailsById?.redeemrun}
                      </p>
                      <p className="redeem-run-css">
                        {calculateDiscountedPrice(
                          dealDetailsById?.redeemrun,
                          dealDetailsById?.discount
                        )}{" "}
                        runs
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={ball}
                        alt="ball-img"
                        style={{ width: mobileView ? "20px" : "30px" }}
                      />
                      <p
                        className="deal-giftFont"
                        style={{ paddingTop: "0px" }}
                      >
                        {dealDetailsById?.redeemrun} runs
                      </p>
                    </div>
                  )}
                </div>
                <div className="deal-giftbuttoncontent">
                  {/* <p className="deal-giftFont">{dealDetailsById?.redeemrun}</p>
                   */}
                  <div
                    className="deal-name"
                    dangerouslySetInnerHTML={{ __html: dealDetailsById?.desc }}
                  />
                </div>
              </div>
            ) : filterName === "coupons-description" ? (
              <div>
                <p
                  className="deal-giftFont"
                  style={{ color: "rgba(11, 81, 142, 1)", margin: "30px 0px" }}
                >
                  Exclusive Deals & Redeem Points!
                </p>
                <div style={{ width: "90%", margin: "auto" }}>
                  <p
                    style={{
                      color: "rgba(137, 138, 141, 1)",
                      fontWeight: 400,
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    Unlock amazing discounts with coupons now visible right in
                    our deals section! Browse our handpicked offers, and easily
                    spot the best deals where you can apply coupons for extra
                    savings.
                  </p>
                  <div>
                    <div>
                      <h4
                        style={{
                          color: "rgba(102, 102, 102, 1)",
                          fontWeight: 400,
                          fontSize: "16px",
                          marginBottom: "10px",
                        }}
                      >
                        Your Redeem Points: Check your personal profile to view
                        and track your earned points. Redeem these points during
                        your next purchase for even more savings!
                      </h4>
                    </div>

                    <div>
                      <h4
                        style={{
                          color: "rgba(102, 102, 102, 1)",
                          fontWeight: 400,
                          fontSize: "16px",
                          marginBottom: "10px",
                        }}
                      >
                        Redeem at Checkout: While shopping in the marketplace,
                        use the "Redeem Points" option at checkout to apply your
                        available points and lower the total cost of your
                        purchase.!
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ width: "90%", margin: "30px auto" }}>
                  <p
                    style={{
                      color: "rgba(11, 81, 142, 1)",
                      fontSize: "26px",
                      fontWeight: "600",
                    }}
                  >
                    Flat {voucherDetailsById.discount}% Off
                  </p>
                  <p
                    style={{
                      color: "rgba(149, 153, 157, 1)",
                      fontWeight: "500",
                    }}
                  >
                    On your first order from {voucherDetailsById.title}
                  </p>
                </div>

                {/* check run is available or not  */}
                {requiredTRun >= voucherDetailsById.redeemedRun && (
                  <div
                    style={{
                      width: "90%",
                      margin: "20px auto",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    {!showScratchCard && (
                      <ScratchCard {...settings} onScratch={handleScratch}>
                        <div
                          style={{
                            border: "2px solid rgba(242, 242, 243, 1)",
                            padding: "10px 20px",
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "30px",
                              color: "rgba(67, 173, 69, 1)",
                            }}
                          >
                            {voucherDetailsById.code}
                          </p>
                          <button
                            style={{
                              backgroundColor: "rgba(12, 91, 160, 1)",
                              color: "white",
                              padding: "10px 15px",
                              borderRadius: "10px",
                              border: 0,
                              cursor: "pointer",
                            }}
                            onClick={handleCopy}
                          >
                            Copy
                          </button>
                        </div>
                      </ScratchCard>
                    )}
                    {showScratchCard && (
                      <div
                        style={{
                          border: "2px solid rgba(242, 242, 243, 1)",
                          padding: "10px 20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "10px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: mobileView ? "20px" : "30px",
                            color: "rgba(67, 173, 69, 1)",
                          }}
                        >
                          {voucherDetailsById.code}
                        </p>
                        <button
                          style={{
                            backgroundColor: "rgba(12, 91, 160, 1)",
                            color: "white",
                            padding: "10px 15px",
                            borderRadius: "10px",
                            border: 0,
                            cursor: "pointer",
                          }}
                          onClick={handleCopy}
                        >
                          Copy
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div
                  style={{ width: "90%", margin: "auto", marginBottom: "20px" }}
                >
                  <p
                    style={{
                      color: "rgba(102, 102, 102, 1)",
                      fontWeight: 500,
                      fontSize: "16px",
                      marginTop: "30px",
                    }}
                  >
                    Details :
                  </p>
                  <div style={{ padding: "0 20px" }}>
                    {voucherDetailsById?.details &&
                    voucherDetailsById.details.length > 0 ? (
                      <ul>
                        {voucherDetailsById.details.map((detail, index) => (
                          <li className="detailsVoucher_dp" key={index}>
                            {detail}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="detailsVoucher_dp">No details available</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* redeem button */}
            {/* campare */}
            
              <div className="del_btn">
                {filterName === "deal-description" && (
                  <button
                    disabled={!eligible}
                    className={
                      eligible ? " giftButton-deal" : "giftButton-disable"
                    }
                    onClick={handleOnClick}
                    style={{ textTransform: "none" }}
                  >
                    Redeem Deal
                  </button>
                )}
                {
              voucherDetailsById.isUsed == false ? (
                requiredTRun >= voucherDetailsById.redeemedRun ? (
                  filterName === "voucher-description" && (
                    <button
                      className="giftButton-Voucher"
                      onClick={() => {
                        window.open(voucherDetailsById.redirectUrl, "_blank");
                      }}
                      style={{ textTransform: "none" }}
                    >
                      <img
                        src={RedirectIcon}
                        style={{
                          width: mobileView ? "15px" : "20px",
                          height: mobileView ? "15px" : "20px",
                        }}
                      />
                      Redeem Voucher
                    </button>
                  )
                ) : (
                  <div className="">
                  <button
                    className="giftButton-Voucher"
                    style={{ textTransform: "none" }}
                  >
                    
                    Required {voucherDetailsById.redeemedRun} runs
                  </button>
                  </div>
                )
              ) : (
                <div className="">
                  <button className="giftButton-Voucher" style={{ textTransform: "none" }} 
                  onClick={() => {
                    window.open(voucherDetailsById.redirectUrl, "_blank");
                  }}
                  >
                    Already Redeemed
                  </button>
                </div>
              )
            }

                            
            </div>
          </div>
        </div>
        {/* <div className="deal-footer-description"> */}
        {/* <h6 className='deal-name' style={{marginBottom: "10px"}}>{dealDetailsById?.desc}</h6> */}
        {/* <p className='footer-paragraph'>
            {`Deals will be only successfully redeemed if you enter a
              valid email id and phone number.Incorrect data will lead to you
              not receiving your deals.`}
          </p> */}
        {/* </div> */}
      </div>
      {adModal && (
        <div className="deal__ad-modal-wrapper">
          <div className="deal__ad-modal-content">
            {ad?.dealId === dealId ? (
              <div
                className="deal__ad-modal-close-icon"
                onClick={() => setAdModal(false)}
              >
                <IoMdClose />
              </div>
            ) : (
              <div
                className="deal__ad-modal-close-icon"
                onClick={() => {
                  setAdModal(false);
                  setShowModal(true);
                }}
              >
                <IoMdClose />
              </div>
            )}
            <img
              src="https://res.cloudinary.com/dndkskewk/image/upload/v1712200846/pgktiqmvbbnahbmpkxxu.gif"
              alt="gift-voucher"
              className="deal__ad-modal-content-coupon"
            />
            {ad?.dealId === dealId ? (
              <>
                <h1 className="deal__ad-modal-content-heading">
                  This deal is locked
                </h1>
                <p className="deal__ad-modal-content-description">
                  Play advertisement to redeem this deal.
                </p>
              </>
            ) : (
              <>
                <h1 className="deal__ad-modal-content-heading">
                  Unlock 1000 Runs by Watching an Ad!
                </h1>
                <p className="deal__ad-modal-content-description">
                  Get ready to score big! Watch a quick advertisement to claim
                  an exclusive discount of 1000 runs on this amazing deal!
                </p>
              </>
            )}
            <button
              className="deal__ad-modal-content-button"
              onClick={() => {
                setAdModal(false);
                setVideoModal(true);
              }}
            >
              Watch Now
            </button>
          </div>
        </div>
      )}

      {videoModal && (
        <div className="modal-backdrop-ad">
          <div className="modal-content-ad">
            <div className="modal-header-ad">
              <h2>{ad?.title}</h2>
              <button
                className="close-ad"
                onClick={() => {
                  setVideoModal(false);
                }}
              >
                <AiOutlineCloseCircle />
              </button>
            </div>
            <div className="modal-body-ad" onClick={handleNavigateDealPage}>
              <video
                src={ad?.url}
                autoPlay
                // controls
                onEnded={() => {
                  setAdWatched(true);
                  handleWatchFullAd();
                }}
              ></video>
              <p>{ad?.description}</p>

              <button className="back-ad-btn">Back</button>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <NewDealModal
          handleClose={handleToggle}
          deal={deal}
          open={showModal}
          redeemDeal={redeemDeal}
          hasRedeemed={hasRedeemed}
          handleUpdateMobileNoPopup={handleUpdateMobileNoModal}
        />
      )}
      {updateMobileNo && (
        <UpdateMobileNumber
          open={updateMobileNo}
          handleClose={handleUpdateMobileNoModal}
          redeemDeal={redeemDeal}
          dealId={dealId}
        />
      )}
      {showModalForRedeemed && (
        <RedeemedTodayModal
          handleClose={handleCloseRedeemedToday}
          open={showModalForRedeemed}
        />
      )}
      {/* <UnlockModal /> */}
      {openRunsModal && (
        <RunsBackModal
          setOpenRunsModal={setOpenRunsModal}
          handleCloseRunModal={handleCloseRunModal}
          runsBack={runsBack}
        />
      )}
    </>
  );
};

const style = {
  display: "flex",
  position: "relative",
  top: "3rem",
};
const mobilestyle = {
  display: "flex",
  // flexDirection: "column"
};
export default DealDescription;
