import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Card from "../Card";
import "./popular.scss";

import { getStorageItem } from "../../utils/sessionStorage";
// Access Token
const accessToken = getStorageItem("token");

const Index = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(50);
  const [filterModal, setFilterModal] = useState(false);

  // Filters
  const [allSellersChecked, setAllSellersChecked] = useState(false);
  const [verifiedSellersChecked, setVerifiedSellersChecked] = useState(false);
  const [includeOutOfStockChecked, setIncludeOutOfStockChecked] = useState(false);
  const [excludeOutOfStockChecked, setExcludeOutOfStockChecked] = useState(false);
  const [priceLowToHighChecked, setPriceLowToHighChecked] = useState(false);
  const [priceHighToLowChecked, setPriceHighToLowChecked] = useState(false);

  // Function to handle page
  const handlePage = (input) => {
    if (page === 1 && input === -1) {
      return;
    } else if (page === pageCount && input === 1) {
      return;
    } else if (input === 1) {
      setPage(page + input);
    } else if (input === -1) {
      setPage(page - 1);
    }
    setPopularProducts([]);
  };

  // Function to get popular products
  async function fetchPopularProducts() {
    let url = `/marketplace/product/getPopularProducts?limit=${global.config.pagePerSize}&page=${page}`;
    if (allSellersChecked) {
      url += "&isVerified=false";
    }
    if (includeOutOfStockChecked) {
      url += "&includeZeroStock=true";
    }
    if (excludeOutOfStockChecked) {
      url += "&excludeZeroStock=true";
    }
    if (priceLowToHighChecked) {
      url += "&sortByPrice=lowToHigh";
    }
    if (priceHighToLowChecked) {
      url += "&sortByPrice=highToLow";
    }
    try {
      const response = await fetch(global.config.ROOTURL.prod + url, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      });
      const { popularProducts, count } = await response.json();
      setPopularProducts(popularProducts);
      setPageCount(count);
    } catch (err) {
      console.log(err);
    }
  }

  // Function to calculate final product price after discount
  const calculateDiscount = (price, discountPercent) => {
    return price - price * (discountPercent / 100);
  };

  function handleCheckboxChange(event) {
    const { name, checked } = event.target;
    switch (name) {
      case "allSellers":
        setAllSellersChecked(checked);
        break;
      case "verifiedSellers":
        setVerifiedSellersChecked(checked);
        break;
      case "includeOutOfStock":
        setIncludeOutOfStockChecked(checked);
        break;
      case "excludeOutOfStock":
        setExcludeOutOfStockChecked(checked);
        break;
      case "priceHighToLow":
        setPriceHighToLowChecked(checked);
        break;
      case "priceLowToHigh":
        setPriceLowToHighChecked(checked);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    fetchPopularProducts();
  }, [page, verifiedSellersChecked, excludeOutOfStockChecked, priceHighToLowChecked, priceLowToHighChecked]);
  return (
    <div>
      <Helmet>
        <title>Champhunt | MarketPlace-Popular</title>
      </Helmet>
      <div className="main__container">
        <h1 className="page__title">Popular</h1>
        <img
          src="/marketplace/filters.svg"
          alt="filters"
          className="filters"
          onClick={() => {
            setFilterModal(!filterModal);
          }}
        />
        {filterModal ? (
          <div className="fiter__modal">
            {/* <p>
              <input
                type="checkbox"
                name="allSellers"
                checked={allSellersChecked}
                onChange={handleCheckboxChange}
              />
              All Sellers
            </p> */}
            <p>
              <input
                type="checkbox"
                name="verifiedSellers"
                checked={verifiedSellersChecked}
                onChange={handleCheckboxChange}
              />
              Verified Sellers
            </p>
            {/* <p>
              <input
                type="checkbox"
                name="includeOutOfStock"
                checked={includeOutOfStockChecked}
                onChange={handleCheckboxChange}
              />
              Include Out of Stock
            </p> */}
            <p>
              <input
                type="checkbox"
                name="excludeOutOfStock"
                checked={excludeOutOfStockChecked}
                onChange={handleCheckboxChange}
              />
              Exclude Out of Stock
            </p>
            <p>
              <input
                type="checkbox"
                name="priceLowToHigh"
                checked={priceLowToHighChecked}
                onChange={handleCheckboxChange}
              />
              Price{" "}
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 6C10.3725 5.68 8.2549 3.13333 5.90196 1L1 6" stroke="#FDFDFD" strokeLinecap="round" />
              </svg>
            </p>
            <p>
              <input
                type="checkbox"
                name="priceHighToLow"
                checked={priceHighToLowChecked}
                onChange={handleCheckboxChange}
              />
              Price{" "}
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1C1.62745 1.32 3.7451 3.86667 6.09804 6L11 1" stroke="#FDFDFD" strokeLinecap="round" />
              </svg>
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="products">
          {popularProducts
            ? popularProducts.map((product, index) => {
                return <Card product={product} index={index} verifiedSellerChecked={verifiedSellersChecked} />;
              })
            : "No new products"}
        </div>
        {pageCount > 1 ? (
          <div className="pagination">
            <img
              src="/marketplace/back-pagination.svg"
              alt="backward-pagination"
              className="backward-pagination"
              onClick={() => {
                handlePage(-1);
              }}
            />
            <p className="count">
              {page} of {pageCount}
            </p>
            <img
              src="/marketplace/forward-pagination.svg"
              alt="forward-pagination"
              className="forward-pagination"
              onClick={() => {
                handlePage(1);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Index;
