import React from "react";
import { Button } from "@mui/material";
import "./index.scss"

const DeleteModal = ({ isOpen, handleDelete, onClose }) => {
    if(!isOpen){
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Delete Deal</h2>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDelete}
                    >Delete</Button>
                    <Button
                        variant="text"
                        onClick={onClose}
                    >Cancel</Button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal