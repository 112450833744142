import React, { useState, useEffect } from 'react';
import "./ProgressBar.scss";


const ProgressBarComponent = ({ borderColor, backgroundColor, currentStage,
  totalStages, backgroundColors }) => {
  
  const [progressWidth, setProgressWidth] = useState(0);
  

  useEffect(() => {
    const currentProgress = (currentStage / totalStages) * 100;
    setProgressWidth(currentProgress); 
  }, [currentStage, totalStages]);

  return (
    <div className="progress-container">
      {/* <div className="progress-bar" style={{ width: `${progressWidth}%`,  backgroundColor: backgroundColor}}></div> */}
      <div className="progress-labels">
        {Array.from({ length: totalStages }, (_, index) => (
          <div key={index} className="progress-label" style={{ borderColor: borderColor }} >
            {index + 1 < currentStage ? (
              <span className="tick">&#10003;</span>
            ) : (
              index + 1 === currentStage && <span className="dot" style={{ backgroundColor: backgroundColor }} ></span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBarComponent;
