import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import GuestLoginPopup from "../../components/preview/Popup";
import "../carousel/index.scss";
import { useState, useEffect } from "react";

const MyCarousel = () => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  
  const [clickedMedia, setClickedMedia] = useState(null);
  const handleOpen = (media) => {
    setOpen(true);
    setClickedMedia(media);
  };

  const [carousel, setCarousel] = useState(null);

  const getCarousels = async () => {
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/carousel/getAllGuest/PitchCarousel/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then(({ data }) => {
        const sortedCarousel = data.sort((a, b) => a.priority - b.priority); // Sorted based on priority
        setCarousel(sortedCarousel);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    getCarousels();
  }, []);

  return (
    <>
      <div className="fix-width" >
        <Carousel
          dynamicHeight={false} 
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          showThumbs={false}
          
          useKeyboardArrows={true} 
          stopOnHover={true}
        >
          {carousel ? (
            carousel.map((data, index) => (
              <div
                key={index}
                onClick={() => handleOpen(data.name)}
              >
                {(
                  data.name.endsWith('.gif') || data.name.endsWith('.png') || data.name.endsWith('.jpg') ?  ( 
                    <img src={data.name} alt="Pitch Carousel" style={{ height: "35vh", width: "100%", objectFit: "fill" }} />
                  ) : (
                    <video key={index} autoPlay loop muted preload="auto" style={{ width: '100%', height: "35vh", objectFit: 'fill' }}>
                      <source src={data.name} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) 
                )}
              </div>
            ))
          ) : (
            <div>
              <img
                alt="carousel"
                src={""}
                className="carouserImage"
              />
            </div>
          )}
        </Carousel>
      </div>

      {Open && 
        <GuestLoginPopup Open={Open} handleClose={handleClose}>
          {clickedMedia?.endsWith('.gif') || clickedMedia?.endsWith('.png') || clickedMedia?.endsWith('.jpg') ? (
            <img src={clickedMedia} alt="Full Media" style={{ width: "100%", height: "auto" }} />
          ) : (
            <video autoPlay loop muted style={{ width: "100%", height: "auto" }}>
              <source src={clickedMedia} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </GuestLoginPopup>
      }
    </>
  );
};

export default MyCarousel;
