import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import useImageUploader from "../../../utils/useImageUploader";
import "./index.css";

const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const Index = () => {
  const [matchName, setmatchName] = useState("");
  const [team1, setteam1] = useState("");
  const [team2, setteam2] = useState("");
  const [runsRequired, setrunsRequired] = useState(0);
  const [tournamentName, setTournamentName] = useState("");
  const [tournamentId, setTournamentId] = useState("");
  const [tournaments, setAllTournaments] = useState([]);
  const [team1Avatar, setteam1Avatar] = useState(null);
  const [team2Avatar, setteam2Avatar] = useState(null);
  const [team1Logo, setteam1Logo] = useState(null);
  const [team2Logo, setteam2Logo] = useState(null);
  const [team1AvatarPreview, setteam1AvatarPreview] = useState(null);
  const [team2AvatarPreview, setteam2AvatarPreview] = useState(null);
  const [team1LogoPreview, setteam1LogoPreview] = useState(null);
  const [team2LogoPreview, setteam2LogoPreview] = useState(null);
  const { uploading } = useImageUploader();
  const [fantasyAvtar1Url, setFantasyAvtar1Url] = useState("");
  const [fantasyAvtar2Url, setFantasyAvtar2Url] = useState("");
  const [fantasyLogo1Url, setFantasyLogo1Url] = useState("");
  const [fantasyLogo2Url, setFantasyLogo2Url] = useState("");

  //This Function is for upload the image file on S3
  const handleUploadFile = async (file) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append("uploader", file);

    const response = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data && response.data.length > 0) {
      return response.data[0].location;
    } else {
      throw new Error("Error in uploading to S3.");
    }
  };

  const handleFileUploads = async () => {
    try {


      if (team1Avatar) {
        const team1AvatarS3Url = await handleUploadFile(team1Avatar);
        setFantasyAvtar1Url(team1AvatarS3Url);
      }
      if (team2Avatar) {
        const team2AvatarS3Url = await handleUploadFile(team2Avatar);
        setFantasyAvtar2Url(team2AvatarS3Url);
      }
      if (team1Logo) {
        const team1LogoS3Url = await handleUploadFile(team1Logo);
        setFantasyLogo1Url(team1LogoS3Url);
      }
      if (team2Logo) {
        const team2LogoS3Url = await handleUploadFile(team2Logo);
        setFantasyLogo2Url(team2LogoS3Url);
      }
      toast.success("All files uploaded successfully");
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Failed to upload files");
      return false;
    }
  };

  const handlePredictionCreation = async () => {
    if (!accessToken) return;

    try {
      const matchData = {
        matchName,
        runsRequired: runsRequired,
        tournamentId: tournamentId,
        team1,
        team2,
        team1Avatar: fantasyAvtar1Url,
        team2Avatar: fantasyAvtar2Url,
        team1Logo: fantasyLogo1Url,
        team2Logo: fantasyLogo2Url,
      };
      const options = {
        method: "POST",
        url: `${global.config.ROOTURL.prod}/contest/fantasy/createMatch`,
        headers: {
          Accept: "application/json",
          // 'Content-Type': "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
          role: "Admin",
        },
        data: matchData,
      };

      const { data } = await axios(options);
      if (data._id) {
        setmatchName("");
        setFantasyAvtar1Url("");
        setFantasyAvtar2Url("");
        setFantasyLogo1Url("");
        setFantasyLogo2Url("");
        setteam1AvatarPreview(null);
        setteam2AvatarPreview(null);
        setteam1LogoPreview(null);
        setteam2LogoPreview(null);
        toast.success("New Match created successfully");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log("Unauthorized request:", error.response.data);
      } else {
        console.error("Failed to create match:", error);
        toast.error(`Failed to create match: ${error.message}`);
      }
    }
  };

  const getAllTournaments = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/fantasy/getAllTournaments`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        setAllTournaments(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getAllTournaments();
  }, []);

  const handleSubmit = async () => {
    try {
      await handleFileUploads();

      // Wait for images URLs to be set
      if (
        fantasyAvtar1Url &&
        fantasyAvtar2Url &&
        fantasyLogo1Url &&
        fantasyLogo2Url
      ) {
        await handlePredictionCreation();
      } else {
        throw new Error("File upload failed, URLs are missing.");
      }
    } catch (error) {
      console.error("Error during file uploads:", error);
      toast.error("Failed to upload files");
    }
  };


  const handleAvatarChange = (event, team) => {
    const file = event.target.files[0];
    if (team === "team1") {
      setteam1Avatar(file);
      setteam1AvatarPreview(URL.createObjectURL(file));
    } else if (team === "team2") {
      setteam2Avatar(file);
      setteam2AvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleLogoChange = (event, team) => {
    const file = event.target.files[0];
    if (team === "team1") {
      setteam1Logo(file);
      setteam1LogoPreview(URL.createObjectURL(file));
    } else if (team === "team2") {
      setteam2Logo(file);
      setteam2LogoPreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="contest-admin-create">
      <div className="contest-admin-create-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-form">
            <h1>Create Match</h1>
            <div className="contest-create-name-wrapper">
              Match Name-
              <input
                type="text"
                className="contest-create-name"
                value={matchName}
                onChange={(event) => setmatchName(event.target.value)}
                required
              />
            </div>

            <div className="contest-create-name-wrapper">
              Runs Required-
              <input
                type="text"
                className="contest-create-name"
                value={runsRequired}
                onChange={(event) => setrunsRequired(event.target.value)}
                required
              />
            </div>

            <div className="contest-create-name-wrapper">
              Tournament Name -
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Tournament</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={tournamentName}
                  label="Tournament"
                  onChange={(event) => {
                    const selectTournament = tournaments.find((item) => item.tournamentName === event.target.value)
                    setTournamentName(event.target.value);
                    setTournamentId(selectTournament._id);
                  }}
                >
                  <MenuItem value="">
                    <em>Tournament</em>
                  </MenuItem>
                  {tournaments.map((item) => (
                    <MenuItem key={item._id} value={item.tournamentName}>{item?.tournamentName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="contest-create-name-wrapper">
              Team 1 -
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Team1</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={team1}
                  label="Team1"
                  onChange={(event) => setteam1(event.target.value)}
                >
                  <MenuItem value="">
                    <em>Team 1</em>
                  </MenuItem>
                  {[
                    "IND", "PAK", "IRE", "CAN", "USA", "ENG", "AUS", "NAM",
                    "SCO", "OMA", "NZ", "WI", "AFG", "UGA", "PNG", "SA",
                    "SL", "BAN", "NET", "NEP", "ZIM"
                  ].map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="contest-create-name-wrapper">
                Team 1 Avatar -
                <input
                  type="file"
                  accept="image/*"
                  style={{ width: "40%" }}
                  onChange={(event) => handleAvatarChange(event, "team1")}
                />
                {team1AvatarPreview && (
                  <img src={team1AvatarPreview} alt="Preview" style={{ maxWidth: "80px", marginTop: "10px" }} />
                )}
              </div>
              <div className="contest-create-name-wrapper">
                Team 1 Logo -
                <input
                  type="file"
                  accept="image/*"
                  style={{ width: "40%" }}
                  onChange={(event) => handleLogoChange(event, "team1")}
                />
                {team1LogoPreview && (
                  <img src={team1LogoPreview} alt="Preview" style={{ maxWidth: "80px", marginTop: "10px" }} />
                )}
              </div>
            </div>

            <div className="contest-create-name-wrapper">
              Team 2 -
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Team2</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={team2}
                  label="Team2"
                  onChange={(event) => setteam2(event.target.value)}
                >
                  <MenuItem value="">
                    <em>Team 2</em>
                  </MenuItem>
                  {[
                    "IND", "PAK", "IRE", "CAN", "USA", "ENG", "AUS", "NAM",
                    "SCO", "OMA", "NZ", "WI", "AFG", "UGA", "PNG", "SA",
                    "SL", "BAN", "NET", "NEP", "ZIM"
                  ].map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="contest-create-name-wrapper">
                Team 2 Avatar -
                <input
                  type="file"
                  accept="image/*"
                  style={{ width: "40%" }}
                  onChange={(event) => handleAvatarChange(event, "team2")}
                />
                {team2AvatarPreview && (
                  <img src={team2AvatarPreview} alt="Preview" style={{ maxWidth: "80px", marginTop: "10px" }} />
                )}
              </div>
              <div className="contest-create-name-wrapper">
                Team 2 Logo -
                <input
                  type="file"
                  accept="image/*"
                  style={{ width: "40%" }}
                  onChange={(event) => handleLogoChange(event, "team2")}
                />
                {team2LogoPreview && (
                  <img src={team2LogoPreview} alt="Preview" style={{ maxWidth: "80px", marginTop: "10px" }} />
                )}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Button
                variant="contained"
                className="contest-create-btn"
                onClick={handleSubmit}
                disabled={uploading}
              >
                Create Match
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
