import React, { useState } from "react";
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from "react-toastify";
import { TextField, Typography, Box, Button } from "@mui/material";
import axios from "axios";


function GuestPitchSelection(){
    const accessToken = getStorageItem("token");
    const [guestPitchId, setGuestPitchId] = useState("");

    const setPitchForGuest = async () => {
        const createNewEvent = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/pitch/guestPitchSelection`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                pitchId: guestPitchId,
                status: true,
            },
        };

        await axios(createNewEvent)
            .then((response)=>{
                console.log("guest pitch data response message",response);
                if(response.data.data === "Guest Pitch Alerady Exists"){
                    toast.success("Guest Pitch Alerady Exists");
                    return;
                }
                toast.success("Done")
            }).catch((err)=>{
                console.log(err)
            })

    }

    const deletePitchForGuest = async () => {
        const createNewEvent = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/pitch/guestPitchSelection`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                pitchId: guestPitchId,
                status: false,
            },
        };

        await axios(createNewEvent)
            .then((response)=>{
                toast.success("Done")
            }).catch((err)=>{
                console.log(err)
            })
    }

    return (
        <div>
            <Typography>Guest Pitch Selection</Typography>
            <TextField 
                placeholder="Enter PitchId for Guest"
                value={guestPitchId}
                onChange={(e) => setGuestPitchId(e.target.value)}
                required
            />
            <Box>
                <Button variant="contained" color="primary" style={{margin:"10px"}} onClick={()=> setPitchForGuest()}>Set</Button>
                <Button variant="contained"  style={{margin:"10px",backgroundColor:"red"}} onClick={() => deletePitchForGuest()}>Delete</Button>
            </Box>
        </div>
    )
}

export default GuestPitchSelection;