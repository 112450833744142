import { Box } from "@material-ui/core";
import React from "react";
// import EventScroll from "../../components/find-all-events/eventScroll";
import FindOneJob from "../../components/all-jobs-admin/index"

const FindOneJobAdmin = () => {
  return (
    <Box>
      <Box marginTop={2}> <FindOneJob/></Box>
    </Box>
  );
};

export default FindOneJobAdmin;