import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class Area extends Component {
  constructor(props) {
    super(props);

    const groupedData = this.groupData(this.props.data);

    this.state = {
      series: [{
        type: 'area',
        name: 'reach',
        data: groupedData.map(item => item.uniqueVisitors.length)
      }],
      options: {
        chart: {
          height: 300,
          type: 'line',
        },
        yaxis: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        fill: {
          type: 'solid',
          opacity: [0.35, 1],
        },
        colors: ['#1fd655'],
        labels: groupedData.map(item => item._id),
        markers: {
          size: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " views";
              }
              return y;
            }
          }
        },
      },
    };
  }

  groupData(data) {
    if (data.length > 30) {
      return this.groupByMonth(data);
    } else if (data.length >= 15) {
      return this.groupByDateRange(data, 5);
    } else {
      return data;
    }
  }

  groupByMonth(data) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const groupedData = data.reduce((acc, curr) => {
      const [day, month] = curr._id.split('/');
      const monthName = monthNames[parseInt(month, 10) - 1];
      if (!acc[monthName]) {
        acc[monthName] = { _id: monthName, uniqueVisitors: [] };
      }
      acc[monthName].uniqueVisitors = acc[monthName].uniqueVisitors.concat(curr.uniqueVisitors);
      return acc;
    }, {});

    return Object.values(groupedData).sort((a, b) => {
      return monthNames.indexOf(a._id) - monthNames.indexOf(b._id);
    });
  }

  groupByDateRange(data, rangeSize) {
    const groupedData = data.reduce((acc, curr) => {
      const [day, month] = curr._id.split('/');
      const dayNumber = parseInt(day, 10);
      const rangeStart = Math.floor((dayNumber - 1) / rangeSize) * rangeSize + 1;
      const rangeEnd = rangeStart + rangeSize - 1;
      const rangeKey = `${rangeStart}-${rangeEnd}/${month}`;
      if (!acc[rangeKey]) {
        acc[rangeKey] = { _id: rangeKey, uniqueVisitors: [] };
      }
      acc[rangeKey].uniqueVisitors = acc[rangeKey].uniqueVisitors.concat(curr.uniqueVisitors);
      return acc;
    }, {});

    return Object.values(groupedData).sort((a, b) => {
      const [rangeA, monthA] = a._id.split('/');
      const [rangeB, monthB] = b._id.split('/');
      const [startA] = rangeA.split('-').map(Number);
      const [startB] = rangeB.split('-').map(Number);
      return new Date(2000, monthA - 1, startA) - new Date(2000, monthB - 1, startB);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const groupedData = this.groupData(this.props.data);

      this.setState({
        options: {
          ...this.state.options,
          labels: groupedData.map(item => item._id),
        },
        series: [{
          type: 'area',
          name: 'reach',
          data: groupedData.map(item => item.uniqueVisitors.length)
        }]
      });
    }
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={300} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default Area;
