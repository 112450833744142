import React, { useState, useEffect,useContext } from 'react';
import { useMediaQuery } from "@mui/material";
import { Grid, FormControl, TextField, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AddressContext } from '../../marketplace/addressPage/addressContext';
import { toast } from 'react-toastify';
import CartModal from '../cartModal/CartModal';
import "./AddressPage.scss";

const AddressPage = () => {
  const mobileView = useMediaQuery("(max-width:920px)");
  const buttonStyle = {
    backgroundColor: "#0C5BA0",
    height: '40px',
    width: '89%', 
    marginTop: '16px', 
    marginLeft: '23px',
  };
  
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("John Doe");
  const [addressLine1, setAddressLine1] = useState("123 Main St");
  const [addressLine2, setAddressLine2] = useState("Apt 4B");
  const [city, setCity] = useState("Springfield");
  const [state, setState] = useState("IL");
  const [postalCode, setPostalCode] = useState("62704");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const { address, setAddress } = useContext(AddressContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAddressDetails = () => {
    setIsModalOpen(true);
    navigate(-1)
    toast.success("Address edited successfully");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = (code) => {
    setPromoCode(code);
    setIsModalOpen(false);
    alert(`Promo code ${code} confirmed and address details submitted.`);
    navigate(-1);
  };

  return (
    <>
      <div
        style={mobileView ? mobilestyle : style}
        className="form_main_container"
      >
        <p className="address_heading">Address</p>
        <Grid container spacing={2} className="address_form">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Full Name"
                style={{ marginTop: "0px" }}
                InputLabelProps={{ shrink: true }}
                id="fullname"
                name="fullname"
                margin="normal"
                value={address?.fullName}
                // onChange={(e) => setFullName(e.target.value)}
                onChange={(e) => setAddress({ ...address, fullName: e?.target?.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Address Line 1"
                style={{ marginTop: "0px" }}
                InputLabelProps={{ shrink: true }}
                id="addressLine1"
                name="addressLine1"
                margin="normal"
                value={address?.addressLine1}
                // onChange={(e) => setAddressLine1(e.target.value)}
                onChange={(e) => setAddress({ ...address, addressLine1: e?.target?.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Main Street Address Line 2"
                style={{ marginTop: "0px" }}
                InputLabelProps={{ shrink: true }}
                id="addressLine2"
                name="addressLine2"
                margin="normal"
                value={address?.addressLine2}
                onChange={(e) => setAddress({ ...address, addressLine2: e?.target?.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="City"
                style={{ marginTop: "0px" }}
                InputLabelProps={{ shrink: true }}
                id="city"
                name="city"
                margin="normal"
                value={address?.city}
                // onChange={(e) => setCity(e.target.value)}
                onChange={(e) => setAddress({ ...address, city: e?.target?.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="State"
                style={{ marginTop: "0px" }}
                InputLabelProps={{ shrink: true }}
                id="state"
                name="state"
                margin="normal"
                value={address?.state}
                // onChange={(e) => setState(e.target.value)}
                onChange={(e) => setAddress({ ...address, state: e?.target?.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Postal Code"
                style={{ marginTop: "0px" }}
                InputLabelProps={{ shrink: true }}
                id="zip"
                name="zip"
                margin="normal"
                value={address?.postalCode}
                // onChange={(e) => setPostalCode(e.target.value)}
                onChange={(e) => setAddress({ ...address, postalCode: e?.target?.value })}
              />
            </FormControl>
          </Grid>

          {/* Confirmation button */}
          <Button
            className="marketplcae_botn"
            style={buttonStyle}
            variant="contained"
            color="primary"
            onClick={handleAddressDetails}
          >
            Confirm
          </Button>
        </Grid>
      </div>
     {isMobile && (
      <CartModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
      />
       )}
    </>
  );
}

const style = {
  display: "flex",
  position: "relative",
  top: "5rem"
};
const mobilestyle = {
  display: "flex",
  position: "relative",
  top: "5rem"
};

export default AddressPage;
