import React from 'react';
import notfound from "../assets/images/marketplace/notfound.png";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "./notfound.scss";

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <div className='not_found_main'>
            <img className='not_found_img' src={notfound} alt='Not found!' />
            <h3 className='not_found_head'>No Data Found</h3>
            <p className='not_found_text'>Looks like no data is available</p>
            <Button
                className='home_btn'
                onClick={() => navigate("/marketplace")}
            >
                Home
            </Button>
        </div>
    )
};

export default NotFoundPage;
