import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import "./index.scss";

const CheckoutModal = ({
  isModalOpen,
  closeCheckoutModal,
  activePlan,
  plans,
  coupon,
  discountedAmount,
  makePayment,
}) => {
  const selectedPlan = plans.find((p) => p.planName === activePlan);
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeCheckoutModal}
      className="checkout-modal"
    >
      <DialogTitle className="checkout-modal-title">Checkout</DialogTitle>
      <DialogContent className="checkout-modal-content">
        <div className="plan-summary">
          <div className="plan-details">
            <p>
              <p style={{ color: "#666666" }}>Plan:</p>{" "}
              <span className="price-detail">{activePlan}</span>
            </p>
            <p>
              <p style={{ color: "#666666" }}>Original Price:</p>{" "}
              <span className="price-detail">
                &#8377; {selectedPlan?.planAmount}
              </span>
            </p>
            {coupon ? (
              <>
                <p>
                  <p style={{ color: "#666666" }}>Discount:</p>{" "}
                  <span className="price-detail">{coupon.discount}%</span>
                </p>
                <Divider className="divider" />
                <p style={{ fontSize: "1.3rem" }}>
                  <p style={{ color: "#666666" }}>Final Price:</p>{" "}
                  <span style={{ color: "green" }}>
                    &#8377; {discountedAmount}
                  </span>
                </p>
              </>
            ) : (
              <p>No coupon applied</p>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="checkout-modal-actions">
        {/* <Button onClick={closeCheckoutModal} style={{color:'red'}} color="secondary">
                    Cancel
                </Button> */}
        <Button onClick={makePayment} className="pay-now-btn">
          Verify & Pay &#8377;{discountedAmount}
          <DoubleArrowIcon style={{ marginLeft: "8px" }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutModal;
