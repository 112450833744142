
import { useState } from "react";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";

const useGetPlayerById = () => {
    const accessToken = getStorageItem("token");
    const [playerDetails, setPlayerDetails] = useState({});

    const getPlayerById = (playerId) => {
        const getPlayer = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/auction/player/${playerId}`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getPlayer)
            .then((response) => {
                setPlayerDetails(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
    };

    return { playerDetails, getPlayerById };
}

export default useGetPlayerById;