import React from "react";
import "./index.scss";
import giftImg from "../../../assets/images/newLandingPage/thirdSection/giftImg.png";
import ball from "../../../assets/images/newLandingPage/Ball.png";

const ThirdSection = () => {
  return (
    <div className="third_section">
      <div className="img_text_container">
        <div className="text_container">
          <img className="ball" src={ball} alt="No Image" />
          <p className="bold_text">REDEEM RUNS</p>
          <p className="small_text">
            Redeem your runs to enter weekly raffles and unlock a chance to win
            exciting gifts.
          </p>
        </div>
        <img className="gift_img" src={giftImg} alt="No image" />
      </div>
      <div className="gradient_color"></div>
    </div>
  );
};

export default ThirdSection;
