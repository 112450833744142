
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import imagefirst from "./imagefirst.png";
import { useNavigate } from "react-router-dom";

import "./index.scss";


const CarouselItems = () => {

  return <div className="fourth_carousel">
    <Carousel  autoPlay={true} infiniteLoop={true} interval={5000} showThumbs={false}>
      <div className="carousel_container1">

      <div className="carosl_head1">
        <h6 className="carousel_heading1">It’s Raining Runs!</h6>
        <p className="carousel_para1">You score runs for everything you do on Champhunt. From 
        <br/> creating posts to commenting and sharing, everything gets
        <br/> you runs. Plus if other users find your posts good enough,
        <br/> they will give it runs which gets added into your account.
        <br/> How many runs have you scored yet?</p>
        </div>
      <div className="carousel_images1">
      <img src={imagefirst} alt="manimages"/>
      </div>
      </div>

     <div className="carousel_container1">
    <div className="carosl_head1"> 
      <h6 className="carousel_heading1" >Runs = Rewards!</h6>
      <p className="carousel_para1">The Runs you have scored can be redeemed in the deals<br/>section of our website, where you have various deals<br/>ranging from gift cards, wallet money, to Cricket<br/>Merchandise. The deals get refreshed every week.<br />
         Have you checked it out yet?</p>
         </div>
          <div  className="carousel_images1">
         <img src={imagefirst} alt="manimages"/>
         </div>
      </div>
      
    </Carousel>
  </div>
};

export default CarouselItems
