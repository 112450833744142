import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import mobile from '../../assets/images/Events/mobile.png'
import helmet from '../../assets/images/Events/helmet.png'
import pad from '../../assets/images/Events/Padd.png'

export default function Four() {
  return (
    <Box sx={{ backgroundColor: { xs: 'none', md: '#FFFFFF' } }} >
    <Box className='helmetcontainer' >
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
            <Grid item xs={12} md={8} sx={{ backgroundColor: { xs: '#FFFFFF', md: 'none' } }}>
                <Box sx={{ padding: { xs: ' 83px 20px 50px 20px;', md: '45px 0px 0px 0px' } }}>
                    <div style={{ display: 'flex' }}>
                        <p className='MoneyHead'>About Champhunt</p>
                        {/* <img src={helmet} className="helmet" /> */}
                       
                    </div>
                    <div>

                        <p className='MoneyHead2' style={{paddigTop:'10px'}}>Champhunt is a one of its kind community that aims to bring all stakeholders of cricket together on one platform. It is a virtual home of cricket lovers where they can interact with other fans, share their opinions, have discussions, analyze games as well as showcase their talent.</p>
                    </div>
                    <Box className='Padcontainer'>
                        {/* <img src={pad} className="pad1" />
                        <img src={pad} className="pad2" /> */}
                    </Box>
                </Box>

            </Grid>
            <Grid item xs={12} md={4} className='signupcontainer2' >
                <div><img src={mobile} className="MoneyHead3" /> </div>
            </Grid>

        </Grid>
    </Box>
</Box>
  )
}
