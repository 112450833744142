
import React from "react";
import {
    AppBar,
 Toolbar,
 CssBaseline,
Typography,
 makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Logo from '../../../assets/images/header/logo.png';
import { Grid } from "@mui/material";
// "../../assets/images/header/logo.png";
import './index.scss';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
navlinks: {
        // marginLeft: theme.spacing(70),
 marginLeft: "auto",
 display: "flex",
},
 logo: {
 flexGrow: "1",
 cursor: "pointer",
 },
 link: {
 textDecoration: "none",
 marginLeft: theme.spacing(10),
        // fontFamily: 'Inter',
 fontStyle: "normal",
 fontWeight: 400,
 fontSize: "18px",
 lineHeight: "22px",
/* identical to box height */

 textAlign: "right",
 textTransform: "uppercase",

color: "#6B6B6B"

 },
}));

const NavbarSection = () => {

 const classes = useStyles();
 const navigate = useNavigate();
return (
<AppBar position="static" className="navbarRow">
 <CssBaseline />
<Toolbar>
<img src={Logo} alt="Champhunt" />
<label className="firstRows">ChampHunt</label>
 {/* <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
                ABOUT US
            </Link>
            <Link to="/" className={classes.link}>
                PARTNER WITH US
            </Link>
            <Link to="/" className={classes.link}>
                CONTACT US
            </Link>
        </div> */}
     <div className="first_btn">
        <button className="btton" onClick={() => navigate('/login')}>Login</button>
        </div>
 </Toolbar>
</AppBar>
)
}

export default NavbarSection;


