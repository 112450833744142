import React, { useState, useContext, useEffect, useRef } from "react";
import { ListItem, ListItemText, Grid } from "@material-ui/core";
import { Modal, Popover } from "@mui/material";
import { format } from "date-fns";
import { Ellipsis, Smile } from "lucide-react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { getStorageItem } from "../../../utils/sessionStorage";
import { SocketContext } from "../../../context/SocketContext";
import axios from "axios";

import hahaEmoji from "../../../assets/images/chatreactions/haha.gif";
import likeEmoji from "../../../assets/images/chatreactions/like.gif";
import sadEmoji from "../../../assets/images/chatreactions/sad.gif";
import clapEmoji from "../../../assets/images/chatreactions/clap.gif";
import loveEmoji from "../../../assets/images/chatreactions/love.gif";
import celebrateEmoji from "../../../assets/images/chatreactions/celebrate.gif";

const DirectMessageSection = ({ chat, ind }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [socket] = useContext(SocketContext);
  const userId = getStorageItem("user_id");
  const [localReactions, setLocalReactions] = useState(chat.reactions || []);
  const profileImg =
    chat.receiverId?._id == userId
      ? chat.receiverId?.profilePhoto
      : chat.senderId?.profilePhoto;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const accessToken = useState("token");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (socket) {
      socket.on("reaction received", (data) => {
        if (data.messageId === chat._id) {
          setLocalReactions((prevReactions) => {
            const existingReactionIndex = prevReactions.findIndex(
              (r) => r.userId === data.userId
            );
            if (existingReactionIndex !== -1) {
              const updatedReactions = [...prevReactions];
              updatedReactions[existingReactionIndex] = data;
              return updatedReactions;
            } else {
              return [...prevReactions, data];
            }
          });
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("reaction received");
      }
    };
  }, [socket, chat._id]);

  const formattedDate = format(new Date(chat.createdAt), "HH:mm");

  const renderReactions = () => {
    if (localReactions.length > 0) {
      const reactionCounts = localReactions.reduce((acc, reaction) => {
        acc[reaction.reaction] = (acc[reaction.reaction] || 0) + 1;
        return acc;
      }, {});

      const reactionElements = Object.entries(reactionCounts).map(
        ([reaction, count]) => {
          let emojiSrc;
          switch (reaction) {
            case "haha":
              emojiSrc = hahaEmoji;
              break;
            case "like":
              emojiSrc = likeEmoji;
              break;
            case "sad":
              emojiSrc = sadEmoji;
              break;
            case "celebrate":
              emojiSrc = celebrateEmoji;
              break;
            case "love":
              emojiSrc = loveEmoji;
              break;
            case "clap":
              emojiSrc = clapEmoji;
              break;
            default:
              return null;
          }

          return (
            <span
              key={reaction}
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginRight: "5px",
              }}
            >
              <img
                src={emojiSrc}
                alt={reaction}
                style={{ width: "20px", height: "20px" }}
              />
              {count > 2 ? `${count}+` : ""}
            </span>
          );
        }
      );

      return <div style={{ marginTop: "5px" }}>{reactionElements}</div>;
    }
    return null;
  };

  const renderContent = () => {
    switch (chat.messageType) {
      case "text":
        return (
          <div>
            {chat?.senderId?._id !== userId ? (
              <div>
                <div>
                  <div className="recieverchat">
                    <p style={{ fontWeight: "bold" }}>
                      {chat.senderId._id !== userId ? (
                        chat?.senderId?.firstName +
                        " " +
                        chat?.senderId?.lastName
                      ) : (
                        <></>
                      )}
                    </p>
                    <br />
                    {chat.content}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <p>{renderReactions()}</p>
                  <ListItemText
                    align="right"
                    secondary={formattedDate}
                  ></ListItemText>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <span className="senderchats">{chat.content}</span>
                </div>
                <div>
                  <p>{renderReactions()}</p>
                </div>
              </div>
            )}
          </div>
        );
      case "image":
        return (
          <div>
            <p>
              {chat.senderId._id !== userId ? (
                chat?.senderId?.firstName + " " + chat?.senderId?.lastName
              ) : (
                <></>
              )}
            </p>
            <img
              src={chat.content}
              alt="Shared image"
              style={{
                width: "200px",
                height: "200px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
            <div style={{ display: "flex" }}>
              <p>{renderReactions()}</p>
              <ListItemText
                align="right"
                secondary={formattedDate}
              ></ListItemText>
            </div>
          </div>
        );
      case "video":
        return (
          <div
            className="video-container"
            style={{
              width: "300px",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              backgroundColor: "#f0f0f0",
            }}
          >
            <div style={{ position: "relative" }}>
              <video
                ref={videoRef}
                width="100%"
                height="auto"
                onClick={() => setIsPlaying(!isPlaying)}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                muted={isMuted}
              >
                <source src={chat.content} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div
                className="video-controls"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  right: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                <button
                  onClick={() => {
                    isPlaying
                      ? videoRef.current.pause()
                      : videoRef.current.play();
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </button>
                <button
                  onClick={() => setIsMuted(!isMuted)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
              </div>
            </div>
            <div style={{ display: "flex", padding: "10px" }}>
              <p>{renderReactions()}</p>
              <ListItemText
                align="right"
                secondary={formattedDate}
              ></ListItemText>
            </div>
          </div>
        );
      default:
        return <span>Unsupported content type</span>;
    }
  };

  const reactMessage = async (messageId, reaction) => {
    try {
      const newReaction = { messageId, reaction, userId };

      // Optimistically update local state
      setLocalReactions((prevReactions) => {
        const existingReactionIndex = prevReactions.findIndex(
          (r) => r.userId === userId
        );
        if (existingReactionIndex !== -1) {
          const updatedReactions = [...prevReactions];
          updatedReactions[existingReactionIndex] = newReaction;
          return updatedReactions;
        } else {
          return [...prevReactions, newReaction];
        }
      });

      // Emit the reaction event to the server
      socket.emit("reaction", {
        ...newReaction,
        conversationId: chat.conversationId,
      });

      // Make the API call
      const options = {
        method: "put",
        url: global.config.ROOTURL.prod + `/groupchat/reactDirectMessage`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: newReaction,
      };
      await axios(options);
    } catch (error) {
      console.error("Error in reactMessage:", error);
      setLocalReactions((prevReactions) =>
        prevReactions.filter(
          (r) => !(r.messageId === messageId && r.userId === userId)
        )
      );
    }
  };

  return (
    <div key={ind}>
      {chat?.senderId?._id === userId || chat?.senderId?._id === userId ? (
        <ListItem key="1">
          <Grid container>
            <Grid item xs={12}>
              <ListItemText align="right">{renderContent()}</ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                secondary={formattedDate}
              ></ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      ) : (
        <ListItem key="2">
          <Grid container>
            <Grid item xs={12} style={{ display: "flex" }}>
              <div
                align="left"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    src={profileImg}
                    width={35}
                    height={35}
                    style={{
                      borderRadius: "50%",
                      alignItems: "end",
                      justifyContent: "end",
                    }}
                  />
                </div>

                <ListItemText align="left">{renderContent()}</ListItemText>
                <Smile
                  onClick={handleClick}
                  style={{ cursor: "pointer", color: "grey" }}
                  size={20}
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      padding: "5px",
                      backgroundColor: "#832990",
                      paddingLeft: "13px",
                      paddingRight: "13px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt="react"
                      src={likeEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "like");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={celebrateEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "celebrate");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={hahaEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "haha");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={sadEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "sad");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={loveEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "love");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={clapEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "clap");
                      }}
                      width={20}
                      height={20}
                    />
                  </div>
                </Popover>
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </ListItem>
      )}
    </div>
  );
};

export default DirectMessageSection;
