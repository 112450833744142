
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PredictionResult from '../../PredictionMobile/PredictionResult';




export default function PredictinResultPopup(props) {
    const mobileView = useMediaQuery("(max-width:600px)");
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(props.openResult);
    const handleClose = () => {
        props.handleCloseResult(false)
    };
    return (
        <div >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <div className='iamfirstchild'>
                    <PredictionResult handleclosePopup={handleClose} />
                </div>
            </Modal>
        </div>
    );
}
