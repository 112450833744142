import React, { useState } from 'react';
import './ProductCard.scss'
import axios from "axios";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ProductCard = ({ productData }) => {
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const navigate = useNavigate();

    const handleDeleteProduct = async () => {

        if (!accessToken && !productData._id) return
        const options = {
            method: "DELETE",
            url:
                global.config.ROOTURL.prod +
                `/marketplace/product/deleteProductByProductId/${productData._id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then((response) => {
                if (response.data === 'Product deleted successfully') {
                    toast.success('Product deleted successfully');
                    // navigate("/admin/marketplace/all-products");
                    window.location.reload(true);
                }
            })
            .catch((error) => console.log(error));
    }

    const handleProduct = async (data) => {

        if (!accessToken) return
        let priority;
        if (data === "banner") {
            priority = 6;
        }
        else if (data === "first") {
            priority = 1;
        }
        else if (data === "second") {
            priority = 2;
        }
        else if (data === "third") {
            priority = 3;
        }
        else if (data === "fourth") {
            priority = 4;
        }
        else if (data === "fifth") {
            priority = 5;
        }
        if (!accessToken && !priority && !productData._id) return
        const options = {
            method: "PUT",
            url:
                global.config.ROOTURL.prod +
                `/marketplace/product/adminSetPriorityToProductsByProductId`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
            data: {
                productId: productData?._id,
                productPriority: priority
            }
        };
        axios(options)
            .then((response) => {
                if (response.ok) {
                    console.log(response?.data);
                }
            })
            .catch((error) => console.log(error));
    }
    return (
        <div className={"all-products__card"}>
            <h2 className={"all-products__card-title"}>Title: {productData?.title}</h2>
            <h3 className={"all-products__card-category"}>Category: {productData?.category}</h3>
            {
                productData?.images && productData?.images.map((image, index) => {
                    return <img className={"all-products__card-images"} key={index} src={image} alt={image} />
                })
            }
            <p className={"all-products__card-description"}>Description: {productData?.description}</p>
            <p className={"all-products__card-stock"}>Stock: {productData?.stock}</p>
            <p className={"all-products__card-sellerId"}>SellerId: {productData?.sellerId}</p>
            <p className={"all-products__card-contact"}>Contect: {productData?.contactEmail || productData?.contactPhone || productData?.contactWhatsapp}</p>
            <div className={"all-products__card-count"}>
                <p className={"all-products__card-sellerCheckCount"}>SellerCheckCount: {productData?.sellerCheckCount}</p>
                <p className={"all-products__card-wishlistedCount"}>WishlistedCount: {productData?.wishlistedCount}</p>
            </div>
            <div className={"all-products__card-priceSection"}>
                <p className={"all-products__card-price"}>Price: {productData?.price}</p>
                <p className={"all-products__card-discount"}>Discount: {productData?.discount}</p>
            </div>
            <div className={"all-products__card-button-group"}>
                <button className={"all-products__card-button"} onClick={() => { handleProduct("banner") }}>Set as banner product</button>
                <button className={"all-products__card-button"} onClick={() => { handleProduct("first") }}>First Product</button>
                <button className={"all-products__card-button"} onClick={() => { handleProduct("second") }}>Second Product</button>
                <button className={"all-products__card-button"} onClick={() => { handleProduct("third") }}>Third Product</button>
                <button className={"all-products__card-button"} onClick={() => { handleProduct("fourth") }}>Fourth Product</button>
                <button className={"all-products__card-button"} onClick={() => { handleProduct("fifth") }}>Fifth Product</button>
            </div>
            <button className='all-products__card-delete' onClick={handleDeleteProduct}>Delete Product</button>
        </div>
    );
};

export default ProductCard;