import {
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import FindOneEvent from "../../components/find-event";
const FindEvent = () => {
  return (
    <Box>
     
      <Box marginTop={2}>
        <FindOneEvent />
      </Box>
    </Box>
  );}

export default FindEvent