import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useScroller from "../../commons/useScroller_JSON_ARG";
import Event from "./index";
import { BallTriangle } from "react-loader-spinner";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Link from "@mui/material/Link";
import DataEvent from "./DataEvent";


const EventScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const event = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // const [events, setEvents] = useState([]);
  const [eventData, setEventData] = useState([]);

  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleOnClick = () => {
    handleToggle();
  };

  const handleToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const redirectUser = (_id) => {
  //   // Check if the user is already registered

  //   // Redirects User
  //   navigate(`/event-register/${_id}`);
  // };


  // Fetching length of data
  useEffect(() => {
    // Fetching all events
    if (accessToken) {
      const options = {
        method: "get",
        url: global.config.ROOTURL.prod + "/events/getAll/1/20",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      axios(options)
        .then(({ data }) => {
          console.log(data);
          setEventData(data);
          setTotalPageNumber(Math.ceil(data.length / 3));
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error("Some error occured");
          }
        });
    }
  }, []);


  return (
    <>
      <div>
        <Grid
          className="pagination"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            // style={{backgroundColor:'#0c5ba0',color:'white'}}
            onClick={getPreviousPage}
          >
            Prev
          </Button>
          {console.log("events", eventData)};
          <Typography variant="p">
            {pageNumber} of {totalPageNumber}
          </Typography>
          <Button
            variant="contained"
            // style={{backgroundColor:'#0c5ba0',color:'white'}}
            onClick={getNextPage}
          >
            Next
          </Button>
        </Grid>
        <div className="card-wrapper">
          {eventData.map((i, index) => (
            <DataEvent data={i} />
          ))}
        </div>
        {/* <UserModal /> */}
        <ToastContainer />
      </div>
    </>
  );
};

export default EventScroll;

// Ferching Page Data

// useEffect(() => {
//   const options = {
//     method: "get",
//     url: global.config.ROOTURL.prod + `/events/getAll/${pageNumber}/3`,
//     headers: {
//       Authorization: "Bearer " + accessToken,
//     },
//   };

//   axios(options)
//     .then(({ data }) => {
//       console.log(data);
//       setEvents([...data]);
//     })
//     .catch((error) => {
//       if (error?.response?.status === 401) {
//         toast.error("Some error occured");
//       }
//     });
// }, [pageNumber]);