import React from "react";
import "./index.css";

const index = () => {
  // /contest/results/list/:contestid/:listid
  return (
    <div className="contest-admin-list">
      <div className="contest-admin-list-wrapper">
        <div className="contest-admin-inner-wrapper">
          <div className="contest-admin-list-header">
            <p className="contest-admin-list-tags contest-admin-list-tag-1">
              Mail id
            </p>
            <p className="contest-admin-list-tags">Username</p>
          </div>
          <div className="contest-admin-list-main">
            {[...Array(6)].map((user, index) => {
              return (
                <div className="contest-admin-list" key={index}>
                  <p className="contest-admin-list-email">jondoe@gmail.com</p>
                  <div className="contest-admin-list-user-section">
                    <div className="contest-admin-list-user-image"></div>
                    <p className="contest-admin-list-user-name">JonDoe</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
