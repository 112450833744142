import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./index.css";
import config from "../../config";
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from "react-toastify";
const baseURL = config.ROOTURL.prod;

// data provides access to your row data
const ExpandedComponent = ({ data }) => {
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

const Index = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessToken = getStorageItem("token");
  const [filterText, setFilterText] = useState("");

  const columns = [
    {
      name: "User Id",
      selector: (row) => row.userId,
    },
    {
      name: "User First Name",
      selector: (row) => row.userFirstName,
      sortable: true,
    },
    {
      name: "User Last Name",
      selector: (row) => row.userLastName,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.userEmail,
      sortable: true,
    },
    {
      name: "User Profile Photo",
      selector: (row) => row.userProfilePhoto,
    },
    {
      name: "Referral User Id",
      selector: (row) => row.referralUserId,
    },
    {
      name: "Refferal User First Name",
      selector: (row) => row.referralUserFirstName,
      sortable: true,
    },
    {
      name: "Refferal User Last Name",
      selector: (row) => row.referralUserLastName,
      sortable: true,
    },
    {
      name: "Refferal User Email",
      selector: (row) => row.referralUserEmail,
      sortable: true,
    },
    {
      name: "Refferal User Profile Photo",
      selector: (row) => row.referralUserProfilePhoto,
    },
    {
      name: "Created Date",
      selector: (row) => row.referralUserCreatedDate,
      sortable: true,
    },
    {
      name: "Share Mode",
      selector: (row) => row.shareMode,
    },
  ];
  useEffect(() => {
    axios
      .get(`${baseURL}/invite/getAll`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  const filteredData = data?.filter((item) => {
    return (
      item.userFirstName &&
      item.userFirstName.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  return (
    <div className="refer">
      <div className="refer__wrapper">
        <div>
          <input
            type="text"
            className="refer__input"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
          <button
            className="refer__clearButton"
            onClick={() => {
              setFilterText("");
            }}
          >
            Clear
          </button>
        </div>
        <div>
          <h2 className="refers__count"><span className="refers__total">Total: </span>{filteredData.length}</h2>
        </div>
      </div>
      <div className="refer__table">
        {loading ? (
          <div style={{ textAlign: "center" }}>Loading...</div>
        ) : (
          <DataTable
            title="Reffered Users"
            columns={columns}
            data={filteredData}
            selectableRows
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            pagination
            highlightOnHover
            striped
          />
        )}
      </div>
    </div>
  );
};

export default Index;
