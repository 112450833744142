import React, { useEffect, useState } from "react";
import "./treanding.scss";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import GuestLoginPopup from "../../components/preview/Popup";


export default function Treanding() 
{
  const [trendingHashtags, setTrendingHashtags]= useState([])
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
console.log("trendingHashtags",trendingHashtags)

  const getTrendingHashtags = async () => {

    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/hash-profile-tag/getHashtagForGuestPitch",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      
      },
    };
    axios(options)
      .then(({ data }) => {
        console.log("Trending Hashtag",data.data);
        setTrendingHashtags(data.data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getTrendingHashtags();
  }, []);



  return (
    <>
    <Box className="content-box123" onClick={handleOpen}>
      <Typography variant="h6" className="box-1__heading">
        Trending Hashtags
      </Typography>
      <ul className="box-1__list">
        {trendingHashtags.map((hashtag, index) => (
          <li key={hashtag._id}>
            <span>{index + 1}</span>
            <a  className="anchor" >
              #{hashtag.hashTagText}
            </a>
            <span className="fire-icon">&#128293;</span>
          </li>
        ))}
      </ul>
    </Box>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
    </>
  );
}
