import { Container, Box, Typography, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import EventScroll from "../../components/find-all-events/eventScroll";
import CarouselScroll from "../../components/manage-carousel/carouselScroll"
import DealScroll from "../../components/manage-deal/dealScroll"
import { display } from '@mui/system';
import DeleteModal from "./delete-modal";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from "react-toastify";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, FormGroup, FormControlLabel, Checkbox, Radio } from "@mui/material";

const ManageDeal = () => {
  const accessToken = getStorageItem("token");
  const [dealsList, setDealsList] = useState([]);
  const [deals, setDeals] = useState([])
  const page = 1
  const [searchDeal, setSearchDeal] = useState('')
  const [filterDeal, setFilterDeal] = useState([])
  const [sortDeal, setSortDeal] = useState('New')
  const filterOptions = ['All', 'E-Commerce', 'Food and Beverages', 'Exclusive', 'Time Limit', 'Offer']
  const sortOptions = ['New', 'Popular', 'Price Low to High', 'Price High to Low', 'A to Z', 'Z to A']
  const [isOpen, setIsOpen] = useState(false)
  const [dealId, setDealId] = useState('')
  const [deleteDealId, setDeleteDealId] = useState('')
  const [handleFilter, setHandleFilter] = useState(false)
  const [activeFilter, setActiveFilter] = useState('filter')

  const handleChances = (filterName) => {
    if (filterDeal.includes(filterName)) {
      setFilterDeal((prevDeal) => prevDeal.filter((deal) => deal !== filterName))
    } else {
      setFilterDeal((prevDeal) => [...prevDeal, filterName])
    }
  }

  const handleFilterPopup = () => {
    setHandleFilter(!handleFilter)
  }

  const handleSearch = (event) => {
    setSearchDeal(event.target.value)
  }

  const handleDeleteModal = (event) => {
    setDealId(event.currentTarget.getAttribute('data-value'))
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const removeSpecialChararters = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  }

  const fetchDealData = () => {
    if (!accessToken) {
      return;
    }
    let url = global.config.ROOTURL.prod + `/deal/getAllDeals/${page}/${global.config.pagePerSize}`
    const req = {
      method: "GET",
      url: url,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(req).then(response => {
      setDealsList(response.data)
      setDeals(response.data)
    })
      .catch(error => console.log(error));
  }


  const handleDeleteDeal = async () => {
    let url = global.config.ROOTURL.prod + `/deal/deleteById/${dealId}`
    const req = {
      method: "DELETE",
      url: url,
      headers: {
        "Authorization": "Bearer " + accessToken
      }
    }
    await axios(req)
      .then((response) => {
        toast.success("Deal Deleted Successfully")
        setDeleteDealId(dealId)
      })
      .catch((err) => console.log(err))

    setDealId("")
    setIsOpen(false)
  }



  useEffect(() => {
    fetchDealData()
  }, [deleteDealId])

  useEffect(() => {
    let filteredDeal = [...dealsList];
    
    // FILTER, SORT and SEARCH FUNCTIONALITY
    if (filterDeal.length !== 0) {
      filteredDeal = filteredDeal.filter((deal) => {
        //const category = removeSpecialChararters(deal.categories)
        if (filterDeal.includes(deal.categories)) {
          return true
        }
        return false
      })
    }

    if (sortDeal === 'Price Low to High') {
      filteredDeal = [...filteredDeal].sort((a, b) => a.redeemrun - b.redeemrun)
    }
    else if (sortDeal === 'Price High to Low') {
      filteredDeal = [...filteredDeal].sort((a, b) => b.redeemrun - a.redeemrun)
    }
    else if (sortDeal === 'A to Z') {
      filteredDeal = [...filteredDeal].sort((a, b) => a.name.localeCompare(b.name))
    }
    else if (sortDeal === 'Z to A') {
      filteredDeal = [...filteredDeal].sort((a, b) => b.name.localeCompare(a.name))
    }
    else if (sortDeal === 'New') {
      filteredDeal = [...filteredDeal].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
    }
    else {
      filteredDeal = [...filteredDeal].filter(deal => deal.redeemrun > 1000)
    }

    if (searchDeal) {
      filteredDeal = filteredDeal.filter((deal) => {
        let dealName = removeSpecialChararters(deal.name)
        let searchDealName = removeSpecialChararters(searchDeal)
        if (dealName.includes(searchDealName)) {
          return true;
        }
        return false
      })
    }
    setDeals(filteredDeal !== undefined ? filteredDeal : dealsList)
  }, [searchDeal, filterDeal, sortDeal])


  // what is the best way a single api with query or mutliple apis with load balancing
  // filer and sort on the backend or on the frontend which is bettte?
  return (
    <Box style={{ marginTop: '20px', marginBottom: '20px', scrollBehavior: 'smooth' }}>
      <DeleteModal isOpen={isOpen} handleDelete={handleDeleteDeal} onClose={handleModalClose} />
      <Container style={{ display: "flex", justifyContent: 'space-between', borderBottom: '1px solid #dcd1d1' }}>
        <Typography variant="h4">All Deals</Typography>
        <input type="search" placeholder="Search" onChange={handleSearch} style={{
          padding: '18px',
          height: '30px',
          width: '32%',
          fontSize: '16px',
          border: '1px solid #c1c1c1;',
          borderRadius: '4px',

        }} />
        <Box>
          <Typography style={{ paddingRight: '8px' }}>Champhunt Admin</Typography>
          <img src="" />
        </Box>
      </Container>
      <Box style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '12px',
        position: 'relative',
        
        
      }}>
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          style={{ textTransform: 'none', border: '1px solid #cac9c9',marginRight:"30px" }}
          onClick={handleFilterPopup}
        >
          Filter By
        </Button>
        {handleFilter && <div style={{
          width: '28%',
          top: '44px',
          right: '50px',
          position: 'absolute',
          borderRadius: '14px',
          boxShadow:'0px 2px 8px gray'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            backgroundColor: '#0C5BA0'
          }}>
            <Button style={{
              width: '40%',
              backgroundColor: `${activeFilter === 'filter' ? 'white' : '#A5D5FE14'}`,
              color: `${activeFilter === 'filter' ? '' : 'white'}`,
              margin: '10px'
            }}
              onClick={() => { setActiveFilter(activeFilter === 'filter' ? 'sort' : 'filter') }}
            >Filter</Button>
            <Button style={{
              width: '40%',
              backgroundColor: `${activeFilter === 'sort' ? 'white' : '#A5D5FE14'}`,
              color: `${activeFilter === 'sort' ? '' : 'white'}`,
              margin: '10px'
            }}
              onClick={() => { setActiveFilter(activeFilter === 'filter' ? 'sort' : 'filter') }}
            >Sort</Button>
          </div>
          <FormGroup style={{ backgroundColor: 'white', padding: '9px 40px'}}>
            {activeFilter === 'filter' && <>
              {filterOptions.map((filter, index) => (
                <FormControlLabel
                  key={index}
                  checked={filterDeal.includes(filter) ? true : false}
                  onChange={() => handleChances(filter)}
                  control={<Checkbox sx={{ color: '#95999D' }} />}
                  label={filter}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      color: '#95999D', // Text color for 'All'
                    },
                  }}
                />
              ))}
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFilterDeal([])
                  }}
                >Clear All</Button>
              </div>
            </>}
            {activeFilter === 'sort' && <>
              {sortOptions.map((sort, index) => (
                <FormControlLabel
                  key={index}
                  checked={sortDeal === sort}
                  onChange={() => setSortDeal(sort)}
                  control={<Radio sx={{ color: '#95999D' }} />}
                  label={sort}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      color: '#95999D', // Text color for 'All'
                    },
                  }}
                />

              ))}
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSortDeal('New')
                  }}
                >Clear All</Button>
              </div>
            </>}
          </FormGroup>
        </div>}
      </Box>
      <Box marginTop={2}> <DealScroll deals={deals} handleNavigation={handleDeleteModal} /></Box>
    </Box>
  );
};


export default ManageDeal;
