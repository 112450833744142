import React from 'react';
import './EventPreview.scss';
import Header from './../header/header-without-signin';
import { ToastContainer, toast } from 'react-toastify';
import { Box, Button, Container, Typography } from '@mui/material';
import LoadingButton from '../loading-button/LoadingButton';
import { useNavigate, useParams } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import { useEffect } from 'react';
import { useState } from 'react';
import Popup from './Popup';

const EventPreview = () => {
  const navigate = useNavigate();
  const { event_id } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [popup, setPopup] = useState(false);

  const buttonStyle = {
    background: "rgba(254, 204, 8, 0.2)",
    border: "1px solid #FECC08",
    borderRadius: "4px",
    color: "#00247B",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
  };
  useEffect(() => {
    if (event_id) {
      setloading(true);
      // Fetching event
      const options = {
        method: "get",
        url: global.config.ROOTURL.prod + `/events/getEventPreviewByEventId/${event_id}`,
      };
      axios(options)
        .then(({ data }) => {
          setEvent({ ...data });
          setloading(false);
          const accessToken = getStorageItem("token");
          const userId = getStorageItem("user_id");
          if (accessToken && userId) {
            navigate(`/events/${event_id}`);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error("Some error occured");
          }
        });
    }
  }, []);

  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }

  const [Open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (data) => setOpen(data);


  // const startDate = new Date(`${event.startDate}`).toLocaleString("en-us", {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // });
  // const endDate = new Date(`${event.endDate}`).toLocaleString("en-us", {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // });

  const originalDateString = `${event.startDate}`;
  const dateParts = originalDateString.split("-");
  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

  const originalDateString1 = `${event.endDate}`;
  const dateParts1 = originalDateString1.split("-");
  const formattedDate1 = `${dateParts1[2]}-${dateParts1[1]}-${dateParts1[0]}`;

  const startDate = new Date(`${formattedDate}`).toLocaleString("en-us", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const endDate = new Date(`${formattedDate1}`).toLocaleString("en-us", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const startTime = formatTime(event.startTime || "13:45");
  const endTime = formatTime(event.endTime || "13:45");
  return (
    <div>
      <Header linkType="eventRedirect" linkAddress={`/events/${event._id}`} />
      <Container className="main-wrapper">
        <Box className="event-image-wrapper">
          {loading ? (
            <LoadingButton
              setSuccess={setSuccess}
              setLoading={setloading}
              buttonValue={""}
              loading={loading}
              success={success}
              style={buttonStyle}
            ></LoadingButton>
          ) : (
            <img
              src={event.eventBanner}
              alt="event-banner"
              className="event-image"
            />
          )}
        </Box>
        <Box>
          <Typography
            variant="h4"
            textAlign="center"
            fontFamily="Poppins"
            className="event-title"
          >
            {event.eventTitle}
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            fontFamily="Poppins"
            className="event-location"
          >
            {event.eventLocation}
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            fontFamily="Poppins"
            className="event-date"
          >
            {startDate} - {endDate}
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            fontFamily="Poppins"
            className="event-time"
          >
            {startTime} - {endTime}
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            fontFamily="Poppins"
            className="event-fees"
          >
            <span style={{ fontWeight: "bold" }}>Registration Fee:</span> ₹{" "}
            {event.registrationFee}
          </Typography>
          <hr className="hr-single" />
          <div
            dangerouslySetInnerHTML={{ __html: event.eventdescription }}
            textAlign="center"
            className="events-description-wrapper"
          />
        </Box>
        <div
          onClick={handleOpen}
          style={{ display: "inline", background: "red" }}
        >
          <Button
            style={{
              marginLeft: "0px",
              marginTop: "20px",
              marginBottom: "20px",
              backgroundColor: "#0c5ba0",
              color: "white",
            }}
          >
            Register
          </Button>
        </div>
        <ToastContainer />
        {Open && (
          <Popup
            Open={Open}
            handleClose={handleClose}
            message="Sign in to register"
            linkType="eventRedirect"
            linkAddress={`/events/${event._id}`}
          />
        )}
      </Container>
    </div>
  );
}

export default EventPreview