import { Box, Typography } from "@material-ui/core";
import React from "react";

import RedeemScroll from "../../components/redeem-history/redeemScroll";

const RedeemHistory = () => {
  return (
<RedeemScroll/>
  );
};

export default RedeemHistory;
