import Bell from "../../assets/images/header/bell.svg";
import NotifictionList from "../../containers/notification/list";
import { ClickAwayListener } from "@mui/material";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const Notification = (props) => {
  

  return (
    <div className="notification">
      {/* <a href="javascript:void(0)"> */}
      {/* <ClickAwayListener
        onClickAway={() => {
          setBellIconClickCss("");
          setisView(false);
        }}
      > */}

        <div
          className="notBtn" 
          // onClick={() => {
          //   const clickcss = bellIconClickCss === "" ? "bellIconCick" : "";
          //   setBellIconClickCss(clickcss);
          // }}
        >
          { (
            <>
              <div className="dotforNotification"></div>
              <img
                src={Bell}
                alt="notification"
                className="fas fa-bell new-notification"
                // onClick={() => {
                //   updateAllNotificationTillDate();
                //   setisView(true);
                // }}
                style={{ height: "21px", width: "21px" }}
              />
            </>
          ) }
            {/* <img
              src={Bell}
              alt=""
              className="fas fa-bell"
              // onClick={() => {
              //   updateAllNotificationTillDate()
              //   setisView(true)
              // }}
            /> */}
          
          <div className="box">
            <div className="display">
              <NotifictionList
                // list={custNotifications}
                // isViewAllButtonShow={true}
              />

            </div>
            {
              <div className="view-all" style={{ visibility: "hidden" }} >
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="view-more">
                    <Link to="/notification" className=" view-more-text primary" >
                      View All
                    </Link>
                  </div>
                </Grid>
              </div>
            }

          </div>
        </div>
      {/* </ClickAwayListener> */}
      {/* </a> */}
    </div>
  );
};

export default Notification;
