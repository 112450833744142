import React, { useState } from 'react';
import { toast } from 'react-toastify';

const AddCoupon = () => {
  const [description, setDescription] = useState('');
  const [discount, setDiscount] = useState('');
  const [type, settype] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const couponData = {
      description,
      discount,
      type
    };

    try {
      const response = await fetch(global.config.ROOTURL.prod + `/coupons/createCoupon`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Add authorization header if needed
        },
        body: JSON.stringify(couponData),
      });

      if (!response.ok) {
        throw new Error('Failed to create coupon');
      }

      const result = await response.json();
      toast.success('Coupon created successfully');

      setDescription('');
      setDiscount('');
    } catch (error) {
      console.error('Error creating coupon:', error);
      toast.error('Failed to create coupon');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ad-creation-wrapper">
      <h1 className="ad-creation__title">Create Coupon</h1>
      <form className="ad-creation__form" onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="ad-creation-description" className="ad-creation__form-label">
            Description
          </label>
          <input
            id="ad-creation-description"
            type="text"
            className="ad-creation__form-input"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="ad-creation-discount" className="ad-creation__form-label">
            Discount
          </label>
          <input
            id="ad-creation-discount"
            type="number"
            className="ad-creation__form-input"
            placeholder="Enter Discount Amount"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="ad-creation-type" className="ad-creation__form-label">
            Type
          </label>
          <select
            id="ad-creation-type"
            className="ad-creation__form-input"
            value={type}
            onChange={(e) => settype(e.target.value)}
            required
          >
            <option value="" disabled>Select Type</option>
            <option value="Subscription">Subscription</option>
            <option value="Marketplace">Marketplace</option>
          </select>
        </div>
        <div>
          <button
            type="submit"
            className={`${
              loading 
                ? 'ad-creation-form-submit--disabled'
                : 'ad-creation-form-submit--active'
            }`}
            disabled={loading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCoupon;
