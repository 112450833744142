
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import tossball from "../../../../assets/images/rewards/tossball.png";
import { getStorageItem } from "../../../../utils/sessionStorage";
import { ToastContainer, toast } from "react-toastify";

export default function PredictionReviewQuetion(props) {
  const predictionId = useSelector((state) => state.prediction.prediction);
  const accessToken = getStorageItem("token");
  const user_id = getStorageItem("user_id");
  const predictionQuestion = props.predictionQuestion;
  const userPredictionData = props.userPredictionData;
  const prediction = props.prediction;
  const [predictionData, setpredictionData] = useState(null);

  console.log("preview ",props)
  useEffect(() => {
    console.log(1, { userPredictionData });
    console.log(2, { predictionQuestion });
    if (predictionQuestion && prediction) {
      userPredictionData.forEach((data) => {
        if (predictionQuestion._id === data.questionId) {
          console.log(4, { data });
          setpredictionData(data);
        }
      });
    }
  }, [predictionQuestion, userPredictionData]);

  useEffect(() => {
    if (userPredictionData) {
      console.log(3, { userPredictionData });
    }
  }, [userPredictionData]);

  return (
    <div style={{ color: "white" }}>
      {predictionQuestion && (
        <>
          <div className="predictiontextAlign predictionFont116 predictionPadding">
            <p>Prediction</p>
          </div>
          <div className="prediQuestionHeading predictionFont10 predictiontextAlign predictionPadding">
            <img alt="tossball" src={tossball} />
            <p style={{ paddingLeft: "10px" }}>
              Earn Runs for each correct prediction
            </p>
          </div>
          <div className="questionPredFlex predictionPadding">
            <div>
              <p className="PredictionQuetionNo">{props.count+1}</p>
            </div>
            <p className="predictionFont112" style={{ paddingLeft: "20px" }}>
              {predictionQuestion.question}
            </p>
          </div>
          <div className="predictionPadding" style={{ paddingTop: "20px" }}>
            {predictionQuestion.options.map(({ _id, text }, index) => (
              <div
                className={`predictiontextAlign predictionFont112 predictionOption ${
                  predictionData?.answerPredictedByUser?.text === text
                    ? "PreOpteionSelected"
                    : ""
                }`}
                key={index}
              >
                {text}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
