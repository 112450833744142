import React from 'react';
import "./index.scss";

const Thanks = () => {
  const styles = {
    container: {
      width: "500px",
      height: "245px",
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif', 
      backgroundColor: 'white',   
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', 
      margin: "auto",
      position: "relative",
      top: "10rem",
      display: "flex",
      flexDirection: "column",
      alignItem: "center",
      justifyContent: "center"
    },
    heading: {
      fontSize: '24px',
      color: '#333',              
      fontWeight: 'bold',
      paddingBottom: "25px",
    },
  };

  return (
    <div style={styles.container} className='thanks-box'>
      <h1 style={styles.heading}>Thank you for contacting us!</h1>
      <p>We will get back to you within 48 working hours.</p>
    </div>
  );
};

export default Thanks;
