import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import RegistrationBonus from "../../components/registration-bonus";
import {
  useMediaQuery,
  Grid,
  Link,
  Container,
  Button,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PostedJob from "../../components/posted-job";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase, faClipboard } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import SavedJob from "../../components/saved-job";
import AppliedJobs from "../../components/my-job/applied-jobs";
import MyPostedJobs from "../../components/my-job/posted-jobs/MyPostedJobs";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import threedot from "../../assets/images/icons/threedot.png";
import { Helmet } from "react-helmet";
import AppContext from "../../context/AppContext";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { BallTriangle } from "react-loader-spinner";

const Jobs = () => {
  const [RecommendedOpen, setRecommendedOpen] = useState(true);
  const [AppliedOpen, setAPPliedOpen] = useState(false);
  const [MyJobOpen, setMyjobOpen] = useState(false);
  const [SavejobOpen, setSaveJobOpen] = useState(false);
  const [JobHeading, setJonHeading] = useState("Recommended Jobs");
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");

  const [jobs, setJobs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPage] = useState(1);
 

  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const appContext = React.useContext(AppContext);
  const { setShowFooter } = appContext;

  const fetchMoreJobs = async () => {
      if (!accessToken) return
    const req = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/job/getAllWithPagination/${pageNumber}/${global.config.pagePerSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    try {
      const response = await axios(req);
      if (response.data.length) {
        setHasMore(false);
      } else {
        setPage((prevPage) => prevPage + 1);
      }
      if (response.data.length < global.config.pagePerSize) {
        setHasMore(false);
    }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  
  useEffect(() => {
    fetchMoreJobs();
  }, []);

  const navigateToSavedJobs = () => {
    // 👇️ navigate to /saved-job
    navigate("/saved-job");
  };
  const navigateToMyJobs = () => {
    // 👇️ navigate to /my-job
    navigate("/my-job");
  };
  const mobileView = useMediaQuery("(max-width:959px)");
  const theme = createTheme({
    typography: {
      fontFamily: "poppins, Arial",
    },
  });
  const handleMyappliedJobs = () => {
    setJonHeading("Applied Jobs");
    setSaveJobOpen(false);
    setMyjobOpen(false);
    setAPPliedOpen(true);
    setRecommendedOpen(false);
  };
  const handleMyJobs = () => {
    setJonHeading("My Jobs");
    setSaveJobOpen(false);
    setMyjobOpen(true);
    setAPPliedOpen(false);
    setRecommendedOpen(false);
  };
  const handleSavedJobs = () => {
    setSaveJobOpen(true);
    setMyjobOpen(false);
    setAPPliedOpen(false);
    setRecommendedOpen(false);
  };
  const handleAllJobs = () => {
    setJonHeading("Recommended Jobs");
    setSaveJobOpen(false);
    setMyjobOpen(false);
    setAPPliedOpen(false);
    setRecommendedOpen(true);
  };

  useEffect(() => {
    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Champhunt | Jobs</title>
      </Helmet>

      <ThemeProvider theme={theme}>
        <main className="job-main">
          <Grid
            container
            sx={{
              mr: `${mobileView ? "5px !important" : "70px !important"}`,
              ml: `${mobileView ? "5px !important" : "70px !important"}`,
            }}
            spacing={mobileView ? 0 : 2}
          >
            {/* <Grid container sx={{ ml: `${mobileView ? mobile : desktop}`, mr: `${mobileView ? "0px" : "12px"}` }} spacing={mobileView ? 0 : 2}> */}
            {/* <Grid item sm={12} md={3} lg={3} sx={{ display: `${mobileView ? "none" : ""}` }}> */}

            {mobileView ? (
              <Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box
                    sx={{
                      marginTop: 3,
                      p: 2,
                      pt: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      borderRadius: 3,
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        m: 0,
                        color: "#870499",
                        letterSpacing: "0.6px",
                        fontSize: "1.3rem",
                        height: 70,
                      }}
                    >
                      {" "}
                      <strong>Cricket Connect</strong>
                    </Typography>
                    <Typography>
                      {" "}
                      <span sx={{ pt: 0, mt: -1 }}>
                        Want to hire a cricket fan? Want to work in the field of
                        cricket?
                        <br />
                        We’re connecting employers and job seekers to enable you
                        to search, hire or apply for jobs best suited to your
                        needs!
                      </span>
                    </Typography>
                  </Box>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 2,
                      borderRadius: 5,
                      backgroundColor: "#0C5BA0",
                    }}
                    onClick={(event) => (window.location.href = "/post-job")}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} /> &nbsp; Post a Job
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box
                    fullWidth
                    sx={{
                      marginTop: 2,
                      pt: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      textAlign: "left",
                      borderRadius: 3,
                      backgroundColor: "white",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{ height: "80%", borderRadius: "12px 12px 0px 0px" }}
                      backgroundColor={RecommendedOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        active="true"
                        sx={{ mt: 1, color: "#4527a0", mr: "auto", ml: 1 }}
                        onClick={handleAllJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          {" "}
                          <FontAwesomeIcon icon={faBriefcase} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          All Jobs{" "}
                        </Typography>
                      </Button>
                    </Box>

                    <Box
                      sx={{ height: "100%" }}
                      backgroundColor={AppliedOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#4527a0", mr: "auto", ml: 0.8 }}
                        onClick={handleMyappliedJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          {" "}
                          <FontAwesomeIcon icon={faPenToSquare} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          {" "}
                          Applied jobs{" "}
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      sx={{ height: "100%" }}
                      backgroundColor={MyJobOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#4527a0", mr: "auto", ml: 1 }}
                        onClick={handleMyJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          {" "}
                          <FontAwesomeIcon icon={faClipboard} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          {" "}
                          My jobs{" "}
                        </Typography>
                      </Button>
                    </Box>

                    <Box
                      sx={{ height: "80%", borderRadius: "0px 0px 12px 12px" }}
                      backgroundColor={SavejobOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        sx={{ mb: 1, color: "#4527a0", mr: "auto", ml: 1 }}
                        onClick={handleSavedJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          <FontAwesomeIcon icon={faBookmark} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          {" "}
                          Saved Job{" "}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ textAlign: "center", mb: 3 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "600",
                        fontSize: 28,
                        letterSpacing: 0.8,
                      }}
                    >
                      {JobHeading}
                    </Typography>
                  </Box>
                  <InfiniteScroll
                    dataLength={jobs.length}
                    next={fetchMoreJobs}
                    hasMore={hasMore}
                    loader={
                      <BallTriangle
                        height="100"
                        width="100"
                        color="grey"
                        ariaLabel="loading"
                      />
                    }
                  >
                    {RecommendedOpen && <PostedJob />}
                    {AppliedOpen && <AppliedJobs />}
                    {MyJobOpen && <MyPostedJobs />}
                    {SavejobOpen && <SavedJob />}
                  </InfiniteScroll>
                  {/* {RecommendedOpen && <PostedJob />}
                        {AppliedOpen && <AppliedJobs />}
                        {MyJobOpen && <MyPostedJobs />}
                        {SavejobOpen && <SavedJob />} */}
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box
                    sx={{
                      marginTop: 8,
                      p: 2,
                      pt: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      borderRadius: 3,
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        margin: 0,
                        color: "#870499",
                        letterSpacing: "0.6px",
                        fontSize: "1.3rem",
                        height: 70,
                      }}
                    >
                      {" "}
                      <strong>Cricket Connect</strong>
                    </Typography>
                    <Typography>
                      {" "}
                      <span sx={{ pt: 0, mt: -1 }}>
                        Want to hire a cricket fan? Want to work in the field of
                        cricket?
                        <br />
                        We’re connecting employers and job seekers to enable you
                        to search, hire or apply for jobs best suited to your
                        needs!
                      </span>
                    </Typography>
                  </Box>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 2,
                      borderRadius: 5,
                      backgroundColor: "#0C5BA0",
                    }}
                    onClick={(event) => (window.location.href = "/post-job")}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} /> &nbsp; Post a Job
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ textAlign: "center", mb: 3 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "600",
                        fontSize: 28,
                        letterSpacing: 0.8,
                      }}
                    >
                      {JobHeading}
                    </Typography>
                  </Box>
                  {/* {RecommendedOpen && <PostedJob />}
                        {AppliedOpen && <AppliedJobs />}
                        {MyJobOpen && <MyPostedJobs />}
                        {SavejobOpen && <SavedJob />} */}
                  <InfiniteScroll
                    dataLength={jobs.length}
                    next={fetchMoreJobs}
                    hasMore={hasMore}
                    loader={
                      <BallTriangle
                        height="100"
                        width="100"
                        color="grey"
                        ariaLabel="loading"
                      />
                    }
                  >
                    {RecommendedOpen && <PostedJob />}
                    {AppliedOpen && <AppliedJobs />}
                    {MyJobOpen && <MyPostedJobs />}
                    {SavejobOpen && <SavedJob />}
                  </InfiniteScroll>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box
                    fullWidth
                    sx={{
                      marginTop: 8,
                      pt: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      textAlign: "left",
                      borderRadius: 3,
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      sx={{ height: "80%", borderRadius: "12px 12px 0px 0px" }}
                      backgroundColor={RecommendedOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        active="true"
                        sx={{ mt: 1, color: "#4527a0", mr: "auto", ml: 1 }}
                        onClick={handleAllJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          {" "}
                          <FontAwesomeIcon icon={faBriefcase} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          All Jobs{" "}
                        </Typography>
                      </Button>
                    </Box>

                    <Box
                      sx={{ height: "100%" }}
                      backgroundColor={AppliedOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#4527a0", mr: "auto", ml: 0.8 }}
                        onClick={handleMyappliedJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          {" "}
                          <FontAwesomeIcon icon={faPenToSquare} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          {" "}
                          Applied jobs{" "}
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      sx={{ height: "100%" }}
                      backgroundColor={MyJobOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#4527a0", mr: "auto", ml: 1 }}
                        onClick={handleMyJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          {" "}
                          <FontAwesomeIcon icon={faClipboard} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          {" "}
                          My jobs{" "}
                        </Typography>
                      </Button>
                    </Box>

                    <Box
                      sx={{ height: "80%", borderRadius: "0px 0px 12px 12px" }}
                      backgroundColor={SavejobOpen ? "#bbb0b0" : ""}
                    >
                      <Button
                        variant="text"
                        sx={{ mb: 1, color: "#4527a0", mr: "auto", ml: 1 }}
                        onClick={handleSavedJobs}
                      >
                        <Typography sx={{ color: "#870499" }}>
                          <FontAwesomeIcon icon={faBookmark} /> &nbsp;
                        </Typography>
                        <Typography sx={{ color: "#0C5BA0" }}>
                          {" "}
                          Saved Job{" "}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          <RegistrationBonus />
        </main>
      </ThemeProvider>
    </div>
  );
};
export default Jobs;
