import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputAdornment,
  Paper,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";

// jodit-react
import JoditEditor from "jodit-react";

const EditEventComponent = (props) => {
  const [status, setStatus] = useState(true);
  const [eventNameCode, setEventNameCode] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [startTime, setStartTime] = useState("");
  const [eventdescription, setEventdescription] = useState("");
  const [state, setState] = useState("");
  const [startDate, setStartDate] = useState("");
  const [eventBanner, setEventBanner] = useState("");
  const [registrationFee, setRegistrationFee] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isUserInfoApiCall, setIsUserInfoApiCall] = useState(false);
  const [error, setError] = useState(false);
  const accessToken = getStorageItem("token");
  const navigate = useNavigate();
  const styles = {
    paperContainer: {
      height: 300,
      width: "100%",
      backgroundImage: `url(${eventBanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  };
  const { id } = useParams();
  const fetchUserInfo = () => {
    axios
      .get(`${global.config.ROOTURL.prod}/events/getEventById/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setEndDate(response.data.endDate ? response.data.endDate : "");
        setEndTime(response.data.endTime ? response.data.endTime : "");
        setEventBanner(
          response.data.eventBanner ? response.data.eventBanner : ""
        );
        setEventLocation(
          response.data.eventLocation ? response.data.eventLocation : ""
        );
        setEventNameCode(
          response.data.eventNameCode ? response.data.eventNameCode : ""
        );
        setEventTitle(response.data.eventTitle ? response.data.eventTitle : "");
        setContent(
          response.data.eventdescription ? response.data.eventdescription : ""
        );
        setStartDate(response.data.startDate ? response.data.startDate : "");
        setStartTime(response.data.startTime ? response.data.startTime : "");
        setRegistrationFee(
          response.data.registrationFee ? response.data.registrationFee : ""
        );
        // setIsUserInfoApiCall(true);
        console.log("setIsUserInfoApiCall:", isUserInfoApiCall);
      })
      .catch((error) => {
        setError(error);
        console.log("this is error:", error);
      });
  };


  const editor = useRef(null);
  const [content, setContent] = useState("");

  const saveEvent = (event) => {
    if (!id) return;
    event.preventDefault();
    console.log("event", event);
    let eventData = {
      eventLocation: eventLocation,
      eventNameCode: eventNameCode,
      eventTitle: eventTitle,
      eventBanner: eventBanner,
      eventdescription: content,
      endDate: endDate,
      startDate: startDate,
      endTime: endTime,
      startTime: startTime,
      registrationFee: registrationFee,
      id: id,
    };
    console.log("eventData", eventData);

    var saveEventOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/events/update",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: eventData,
    };
    axios(saveEventOptions)
      .then((response) => {
        console.log("response.data", response.data);
      })
      .then(() => navigate("/admin/find-all-events"))
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChangePicture = (event) => {
    const { name, value } = event.target;
    if (name === "image") {
      if (event.target.files[0].type.includes("image")) {

        const file = event.target.files[0];
        const formData = new FormData();
        //...not using compress object to pass service the issue is that after compress object is blob so in service multer is not able to parse it
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            if (compressedResult.size > 5000000) {
              setStatus(true);
            } else {
              formData.append("uploader", file);
              console.log("This is upload img function call:");
              axios
                .post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
                  headers: {
                    Authorization: "Bearer " + accessToken,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    console.log("Image saved successfully");
                    setEventBanner(response.data[0].location);
                  } else {
                    console.log("error occured:");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              setStatus(false);
            }
          },
        });
      }
    }
  };
  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    // isUserInfoApiCall && (
    <Grid container columnSpacing={2}>
      <Grid item sm={6} xs={12}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container columnSpacing={1}>
            {/* Title */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="title"
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  name="title"
                  margin="normal"
                  defaultValue={eventTitle}
                  multiline
                  onChange={(e) => {
                    setEventTitle(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Location */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  InputLabelProps={{ shrink: true }}
                  name="location"
                  label="location"
                  margin="normal"
                  defaultValue={eventLocation}
                  multiline
                  onChange={(e) => {
                    setEventLocation(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Price */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Fee"
                  startAdornment={
                    <InputAdornment position="start">INR</InputAdornment>
                  }
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  defaultValue={registrationFee}
                  multiline
                  onChange={(e) => {
                    setRegistrationFee(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Code */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">Event Code</InputAdornment>
                  }
                  label="event code"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  defaultValue={eventNameCode}
                  multiline
                  onChange={(e) => {
                    setEventNameCode(e.target.value);
                  }}
                //   dangerouslySetInnerHTML={{
                //     __html: eventdescription.substring(0, 200),
                //   }}
                />
              </FormControl>
            </Grid>
            {/* Start Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="start-date"
                  margin="normal"
                  defaultValue={startDate}
                  multiline
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Start Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-time"
                  label="start-time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  defaultValue={startTime}
                  multiline
                  onChange={(e) => {
                    setStartTime(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* End Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-date"
                  label="end-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  defaultValue={endDate}
                  multiline
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* End Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-time"
                  label="end-time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  defaultValue={endTime}
                  multiline
                  onChange={(e) => {
                    setEndTime(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="banner"
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  margin="normal"
                  name="image"
                  accept="image/*"
                  defaultValue={eventBanner}
                  onChange={onChangePicture}
                />

              </FormControl>
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <JoditEditor
                  ref={editor}
                  value={content}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(content) => setContent(content)} // preferred to use only this option to update the content for performance reasons
                  onChange={(content) => { }}
                  fullWidth
                  multiline
                  rows={5}
                  className="editor-main"
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* Button */}
            <Grid item xs={12}>
              <FormControl fullWidth></FormControl>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                margin="normal"
                onClick={saveEvent}
                fullWidth
              >
                Update Event
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Paper component="img" style={styles.paperContainer}></Paper>
      </Grid>
      <ToastContainer />
    </Grid>
    // )
  );
};

export default EditEventComponent;
