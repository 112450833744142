import React, { useEffect, useState } from "react";
// import "./index.scss";
import { useMediaQuery, Box } from "@material-ui/core";
import dealBall from "../../assets/images/rewards/dealBall.png";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import AllChallengesList from "./AllChallengesList";
import ChallengeRules from "./ChallengeRules"
import Run from "../posts/run";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";

export default function Prediction() {
    const mobileView = useMediaQuery("(max-width:600px)");

    const userId = getStorageItem("user_id");
    const [userRuns, setUserRuns] = useState(0);
    const accessToken = getAuthToken();
    const path = window.location.pathname;
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));

    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;
    const getRuns = () => {
        if (!userId) return console.error("userId required");


        const getTotalRun = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getTotalRun)
            .then((response) => {
                setUserRuns(response.data);
            })
            .catch((error) => {
                console.log("3")
                console.log(error)
            });
    };
    useEffect(() => {
        getRuns()
        setShowFooter(true);
        return () => {
            setShowFooter(false);
        };
    }, []);
    return (
        <div className="RewadsMain">
            {mobileView && (
                <Box className="MobileRuns">
                    <img src={dealBall} />
                    <p className="mobilepara">
                        <Run isRunValueNeeded={true} />
                    </p>
                </Box>
            )}
            <ChallengeRules />
            <AllChallengesList runs={userRuns} />
        </div>
    );
}
