import { Box, Typography } from "@material-ui/core";
import React from "react";
// import EventScroll from "../../components/find-all-events/eventScroll";
import JobScroll from "../../components/all-jobs-admin/jobScroll"

const AllJobsAdmin = () => {
  return (
    <Box>
      <Typography variant="h4">Jobs</Typography>
      <Box marginTop={2}> <JobScroll/></Box>
    </Box>
  );
};

export default AllJobsAdmin;
