import React, { useEffect, useState } from 'react'
import Images from "../../../../assets/images/rewards/whitedivball.png";
import ArrowImg from "../../../../assets/images/rewards/backArrow.png";
import Image from "../../../../assets/images/rewards/dealBall.png";
import { useMediaQuery } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../../utils/auth';
import axios from "axios";

export default function PredictionGameComplete(props) {
  const mobileView = useMediaQuery("(max-width:900px)");
  const userId = localStorage.getItem('user_id')
  const accessToken = getAuthToken();

  const [totalQuestion, setTotalQuestion] = useState(0);
  const [totalAttemptedQuestion, setAttemptedTotalQuestion] = useState(0);

  const navigate = useNavigate();
  const predictionid = useSelector((state) => state.prediction.prediction);
  const handlePredictionPage = () => {
    mobileView ? navigate('/prediction') : navigate(0);
  }
  // 
  const checkStatus = async () => {

    if (!userId && !predictionid) return;
    const checkuserStatus = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/userPredictionQuestion/getQuestionMarkedCountByUserIdAndPredictionId/${userId}/${predictionid}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },

    };
    axios(checkuserStatus)
      .then((response) => {
        if (response && response.data) {
          console.log("iam compleetd", response)
          setTotalQuestion(response.data.TotalQuestions);
          setAttemptedTotalQuestion(response.data.QuestionAttemptedByUser);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (userId && predictionid) {
      console.log("hey")
      checkStatus();
    }
  }, [])
  return (
    <div className='endHeader'>
      {mobileView &&
        <> <div className="end_nav" onClick={handlePredictionPage} >
          <img src={ArrowImg} alt="arrowbtn" />
        </div>
          <div className='topbtn'>
            <div className='imgPara'>
              <img src={Image} alt="ball-logo" />
              <p style={{ paddingLeft: "24px" }}>13450</p>
            </div>
          </div>
        </>
      }

      <div className='sevondPara'>
        <p>Prediction</p>
        <h1>{totalAttemptedQuestion}/{totalQuestion}</h1>

        <div className='thirdPara'>
          <p>Runs will be received<br />
            at the end of the contest!</p>
        </div>
        <div className='dot_img'>
          <img src={Images} alt="dotimg" />
          <img src={Images} alt="dotimg" />
          <img src={Images} alt="dotimg" />
          <img src={Images} alt="dotimg" />
          <img src={Images} alt="dotimg" />
          <img src={Images} alt="dotimg" />
        </div>

        <div className='btn_container'>
          <div className='end_contest_btn2' onClick={handlePredictionPage}>
            <button  >close</button>
          </div>
        </div>
      </div>
    </div>
  )
}
