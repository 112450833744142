import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import dealBall from "../../../../assets/images/rewards/dealBall.png";
import whiteball from "../../../../assets/images/rewards/whiteball.png";
import whitedivball from "../../../../assets/images/rewards/whitedivball.png";
import backarrow from "../../../../assets/images/rewards/backArrow.png";
import axios from "axios";
import { getAuthToken } from "../../../../utils/auth";
import "./PredictionPlay.scss";
import { useNavigate, useParams } from "react-router-dom";
import Predictionconutdown from "./Predictionconutdown";
import PredictionQuestion from "./PredictionQuestion";
import { Button } from "@mui/material";
import PredictionGameComplete from "./PredictionGameComplete";
import { useSelector } from "react-redux";
import Run from "../../../posts/run";

export default function PredictionPlayNow(props) {
  const mobileView = useMediaQuery("(max-width:900px)");

  // states

  const [userRuns, setUserRuns] = useState(0);
  const accessToken = getAuthToken();
  const userid = localStorage.getItem("user_id");
  const name = localStorage.getItem("full_name");
  const email = localStorage.getItem("user_email");
  const [AllpredictionData, setPredictionData] = useState(null);
  const [isQuizCompleted, setQuizCompleted] = useState(false);
  const [Question, setPreditionQuestion] = useState({});
  const [countquestion, setcountquestion] = useState(1);

  // fucntions

  const predictionid = useSelector((state) => state.prediction.prediction);
  const navigate = useNavigate();
  const handlePredictionPage = () => {
    mobileView ? navigate("/prediction") : props?.handleclosePopup();
  };

  // handle Next question

  const handleNextQuestion = () => {
    // console.log('hey iam next')
    // console.log('hey iam count', countquestion)
    // console.log('hey iam list', AllpredictionData.length - 1)
    // console.log('hey iam list', AllpredictionData[countquestion])
    // console.log('hey iam list', AllpredictionData)
    setcountquestion((prevKey) => prevKey + 1);
    if (countquestion <= AllpredictionData.length - 1) {
      setPreditionQuestion(AllpredictionData[countquestion]);
    } else {
      setQuizCompleted(true);
    }
  };

  // going to Save Answer

  const handleSaveAswer = (optionid, ans, questionid) => {
    handleNextQuestion();
    if (optionid && ans && questionid) {
      const setanswer = {
        method: "POST",
        url:
          global.config.ROOTURL.prod +
          `/contest/userPredictionQuestion/createUserPredictionQuestion`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userId: userid,
          email: email,
          userName: name,
          predictionId: predictionid,
          questionId: questionid,
          answerPredictedByUser: { _id: optionid, text: ans },
        },
      };
      axios(setanswer)
        .then((response) => { })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // first i need all question id so i use this api
  const fetchPredictionById = () => {
    //
    if (predictionid) {
      const fetchPrediction = {
        method: "GET",
        url:
          global.config.ROOTURL.prod +
          `/contest/predictionquestion/findQuestionsByPredictionId/${predictionid}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios(fetchPrediction)
        .then((response) => {
          // console.log(response.data, 1);
          setPredictionData(response.data);

          setPreditionQuestion(response.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => { }, [isQuizCompleted, AllpredictionData, Question]);

  useEffect(() => {
    // getRuns();
    fetchPredictionById();
    return () => {
      setUserRuns(0);
      setPredictionData(null);
    };
  }, []);
  return (
    <div className="PlayNow_container">
      {isQuizCompleted ? (
        <PredictionGameComplete hadeclosePopup={props?.handleclosePopup} />
      ) : (
        <>
          {Question != null &&
            Question !== "undefiend" &&
            Object.keys(Question).length != 0 && (
              <>
                <div className="predictinbackArrow">
                  <div onClick={handlePredictionPage}>
                    <img src={backarrow} />
                  </div>
                </div>

                <div className="PredictionPlayContainer">
                  <div className="PMobileRuns">
                    <img src={dealBall} />
                    <p className="Pmobilepara">
                      <Run isRunValueNeeded={true} />
                    </p>
                  </div>
                  {/* <Predictionconutdown
                    handleTimer={handleNextQuestion}
                    Predictionquestionpass={Question}
                  /> */}

                  <PredictionQuestion
                    Predictionquestionpass={Question}
                    handleSaveAnswerpass={handleSaveAswer}
                    count={countquestion}
                  />
                  <div className="prediQuestionHeading">
                    <div className="predictPaddingleft">
                      <img src={whitedivball} />
                    </div>
                    <div className="predictPaddingleft">
                      <img src={countquestion > 1 ? whitedivball : whiteball} />
                    </div>
                    <div className="predictPaddingleft">
                      <img src={countquestion > 2 ? whitedivball : whiteball} />
                    </div>
                    <div className="predictPaddingleft">
                      <img src={countquestion > 3 ? whitedivball : whiteball} />
                    </div>
                    <div className="predictPaddingleft">
                      <img src={countquestion > 4 ? whitedivball : whiteball} />
                    </div>
                    <div className="predictPaddingleft">
                      <img src={countquestion > 5 ? whitedivball : whiteball} />
                    </div>
                  </div>
                  <div className="closeContainer">
                    <Button
                      className="predictionFont116 closeButtonPre"
                      onClick={handlePredictionPage}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
}
