import { useState } from "react";
import axios from "axios";
import Compressor from "compressorjs";
import { getStorageItem } from "./sessionStorage";

const useImageUploader = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const accessToken = getStorageItem("token");

  const uploadImage = async (file, quality = 0.8) => {
    setUploading(true);
    setError(null);
    try {
      if (!file) {
        throw new Error("No file selected");
      }
      if (quality < 0 && quality > 1) {
        throw new Error("Incorrect amount of quality selected");
      }
      let compressedFile = file;

      if (!file.type.includes("video")) {
        throw new Error(`File type not video`);
      }

      //   Need to find a soltution to compress videos
      //   if (file.type.includes("video")) {
      //     const compressedResult = await new Promise((resolve, reject) => {
      //       new Compressor(file, {
      //         quality: quality,
      //         success: resolve,
      //         error: reject,
      //       });
      //     });

      //     console.log(2);

      //     console.log("sfadasd",compressedResult);

      //     if (compressedResult.size > 5000000) {
      //       throw new Error("Image size exceeds the limit");
      //     }

      //     compressedFile = compressedResult;
      //   }

      const formData = new FormData();
      formData.append("uploader", compressedFile);

      const response = await axios.post(
        `${global.config.ROOTURL.prod}/upload-file`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
          },
        }
      );

      if (!response.data || response.data.length === 0) {
        throw new Error(`Failed to upload image ${file}`);
      }

      // setImageUrl(response.data[0].location);
      return response.data[0].location;
    } catch (err) {
      if (axios.isCancel(err)) {
        setError("Request canceled");
      } else if (err.response && err.response.status === 401) {
        setError("Unauthorized. Please check your access token.");
      } else {
        setError(err.message || "An unknown error occurred");
      }
    } finally {
      setUploading(false);
    }
  };

  return { uploadImage, uploading, error, imageUrl };
};

export default useImageUploader;
