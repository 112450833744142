import {
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import EventCreationForm from "../../components/event-creation-form";
import { Helmet } from "react-helmet";

const EventsCreate = () => {
  return (
    <div>
      <Helmet>
        <title>Champhunt | Create-Event</title>
      </Helmet>
   
    <Box>
      <Typography variant="h4">Create an event</Typography>
      <Typography variant="p">
        Want to create a new event. Register an event here in few minutes.
      </Typography>
      <Box marginTop={2}>
        <EventCreationForm />
      </Box>
    </Box>
    </div>
  );
};

export default EventsCreate;
