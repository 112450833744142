import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import axios from "axios";
import "./leaderboard.scss";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { Button } from "carbon-components-react";
import Modal from "react-bootstrap/Modal";
import { a, useNavigate } from "react-router-dom";
import Loader from "../../commons/components/Loader";
import Run from "../posts/run";
import GuestLoginPopup from "../../components/preview/Popup";

const user_Id = getStorageItem("user_id");
const LeaderBoard = () => {
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  const [leaderBoard, setLeadBoard]= useState([])
  const [allTimeRun, setAllTimeRun] = useState([]);
  const accessToken = getStorageItem("access_token");
  const [loading, setLoading] = useState(false);
  const [allTimeInTopTen, setAllTimeInTopTen] = useState(false);
  const getleaderBoard = async () => {

    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/leaderboard/thisYear/runsThisYear/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then(({ data }) => {
        setLeadBoard(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  
  useEffect(() => {
    getleaderBoard();
  }, []);
  

  return (
    <>
    <section className="leaderboard" >
      
        <div className="main-wrapper-leaderboard">
          <Box className="pedestal" onClick={handleOpen}>
            <Typography className="pedestal__title">Leaderboard</Typography>
            <Box className="header__buttons">
              <Box
                className="header__buttons--top" 
              >
                Today
              </Box>
              <Box
                className="active-button">
                This Year
              </Box>
            </Box>
            
            {leaderBoard.slice(0, 3).map((item, index) => (
              <div className="pedestal__best" key={index}>
                {/* Render crown only for the first position */}
                {index === 0 && (
                  <svg
                    className="crown-svg"
                    width="25"
                    height="12"
                    viewBox="0 0 25 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0L6.25 4.5L12.5 0L18.75 4.5L25 0L21.7742 12H3.22581L0 0Z"
                      fill="url(#paint0_linear_1769_542)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1769_542"
                        x1="4.5"
                        y1="16"
                        x2="25"
                        y2="2.47954e-06"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FFA44B" />
                        <stop offset="1" stopColor="#FFD046" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}

                <div className={`pedestal--${index + 1}__wrapper`}>
                  <div className={`pedestal--${index + 1}`}>
                    <a style={{ textDecoration: "none" }}>
                      <img
                        src={item?.profilePhoto}
                        alt=""
                      />
                    </a>
                    <span className={`pedestal-${index + 1}__position`}>{index + 1}</span>
                    <div className={`position-${index + 1}__details`}>
                      <a style={{ textDecoration: "none" }}>
                        <h2 className="position__title">
                          {item.userName}
                        </h2>
                      </a>
                      <p className="position__details">{item.runs}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
          </Box>
          
            <Box className="top-rankers" onClick={handleOpen}>
            {leaderBoard.slice(3, 10).map((data, index) => (
                <div
                  className="top-rankers__box"
                  key={index}
                >
                  <span className="user__position">{index + 4}</span>
                  <span className="user-image__wrapper">
                    <a
                      //to={/profile}
                      style={{ textDecoration: "none" }}
                    >
                     {data?.profilePhoto ? (
                      <img src={data.profilePhoto} alt="user" className="user__image" />
                          ) : (
                        <div>No Image</div>
                        )}
                    </a>
                  </span>
                  <div>
                    <a
                      //to={/profile}
                      style={{ textDecoration: "none" }}
                    >
                        <span className="user__name">
                          {data.userName.length > 10
                            ? data.userName.substring(0, 10) + "..."
                            : data.userName}
                        </span>
                      
                    </a>
                  </div>
                  <span className="user__runs">{data.runs}</span>
                </div>
            ))}
              
              
                {/* <div className="top-rankers__box">
                  <span className="user__position">10</span>
                  <span className="user-image__wrapper">
                    <a
                      //to={/profile}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={
                          leaderBoard[9].profilePhoto
                            ? leaderBoard[9].profilePhoto
                            : defaultAvatar
                        }
                        alt="user"
                        className="user__image"
                      />
                    </a>
                  </span>
                  <a
                    to={/profile}
                    style={{ textDecoration: "none" }}
                  >
                    <span className="user__name">
                      {" "}
                      {leaderBoard[9].userName.length > 10
                        ? leaderBoard[9].userName.substring(0, 10) + "..."
                        : leaderBoard[9].userName}
                    </span>
                  </a>
                  <span className="user__runs">{leaderBoard[9].runs}</span>
                </div> */}
              
            </Box>
          
          <Box className="view-all" onClick={handleOpen}>
            <h2 >View All</h2>
          </Box>
        </div>
      
      {/* {isLoading && <Loader isLoading={isLoading} />} */}
      {/* {Today}   */}
      {/* {activeButtonFirst && <Today />} */}
      {/* <LeaderboardModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
      /> */}
    </section>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
    </>
  );
};
export default LeaderBoard;