// Comment.js
import React, { useState } from "react";
import {
  Avatar,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import { ThumbUp, Reply, MoreVert } from "@mui/icons-material";
import "./comment.scss";

const Comment = ({ comment, onReply, onLike, isOptimistic }) => {
  console.log(comment);
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = () => {
    setIsLiked(!isLiked);
    onLike(comment._id);
  };

  const handleReply = () => {
    onReply(comment._id);
  };

  if (!comment) {
    return null;
  }

  return (
    <Box className={`comment ${isOptimistic ? "optimistic" : ""}`}>
      <Avatar
        src={comment.userProfilePhoto}
        alt={comment.userName}
        className="comment-avatar"
      />
      <Box className="comment-content">
        <div>
          <Typography variant="subtitle2" className="comment-username">
            {comment.userName}
          </Typography>
          <Typography variant="body2" className="comment-text">
            {comment.commentText}
          </Typography>
        </div>
        {isOptimistic && (
          <Box className="optimistic-indicator">
            <CircularProgress size={16} />
            <Typography variant="caption" style={{ marginLeft: 8 }}>
              Sending...
            </Typography>
          </Box>
        )}
      </Box>
      {/* {!isOptimistic && (
        <Box className="comment-actions">
          <IconButton onClick={handleLike} size="small">
            <ThumbUp fontSize="small" color={isLiked ? "primary" : "action"} />
          </IconButton>
          <IconButton onClick={handleReply} size="small">
            <Reply fontSize="small" />
          </IconButton>
          <IconButton size="small">
            <MoreVert fontSize="small" />
          </IconButton>
        </Box>
      )} */}
    </Box>
  );
};

export default Comment;