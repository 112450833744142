import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PredictionItem.css";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import PredictionDelete from './predictionDeleteModal'
import PredictionEdit from './create/edit-prediction'
const PredictionItem = (props) => {
  const [data, setData] = useState(props.data)
  const [openDeletePredictionModal, setOpenDeletePredictionModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const accessToken = getAuthToken();
  const navigate = useNavigate();
  const handleOpenDeletePredictionModal = () => setOpenDeletePredictionModal(true);
  const handleCloseDeletePredictionModal = () => setOpenDeletePredictionModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true)
  const handleCloseEditModal = () => setOpenEditModal(false)
  const deletePrediction = (id) => {
    if (!accessToken && !id) return
    var deleteDetails = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/prediction/deletePrediction/${id}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },

    };
    axios(deleteDetails)
      .then(response => {
        if (response) {
          toast.success("prediction deleted ")
          handleCloseDeletePredictionModal()
          props.getAllPredictions()

        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }
  console.log("daat", data)
  const handleRewardDistrubution = () => {
    if (accessToken && data?._id) {

      var getReferral = {
        method: 'POST',
        url: global.config.ROOTURL.prod + `/contest/prediction/updateRewardDistributedStatus/${data?._id}`,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
        },
        data: {

        },

      };
      axios(getReferral)
        .then(response => {
          toast.success("reward started")
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_prediction-item">
        <div className="contest_admin_prediction-header">
          <div className="contest_admin_prediction-item-name">
            {data?.predictionName}
          </div>
          <div>  <FontAwesomeIcon icon={faPenToSquare} style={
            { margin: "5px" }} onClick={handleOpenEditModal} />
            <img
              src="/svg/admin/delete.svg"
              alt="delete"
              className="contest_admin_prediction-item-delete" onClick={handleOpenDeletePredictionModal}
            /></div>
        </div>
        <div className="contest_admin_prediction-sub-header">
          <div className="contest_admin_prediction-sub-header--runs">
            <img
              src="/svg/admin/run-small.svg"
              alt="runs"
              className="contest_admin_prediction-sub-header--runs-svg"
            />
            <p className="contest_admin_prediction-sub-header--runs-entry">
              Entry {data?.redeemRun}
            </p>
          </div>
          <div>
            <p className="contest_admin_prediction-sub-header--reward">
              Reward {data?.rewardRun}
            </p>
          </div>
          <div>
            <p className="contest_admin_prediction-sub-header--played">
              Played 200
            </p>
          </div>
        </div>
        <div className="contest_admin_prediction-sub-schedule">
          <div className="contest_admin_prediction-sub-schedule-status">
            {data.active && (
              <p className="contest_admin_prediction-sub-schedule-status--text">
                Active
              </p>
            )}
          </div>
          <div className="contest_admin_prediction-sub-schedule-timing">
            <p className="contest_admin_prediction-sub-schedule-timing--text">
              Closes at {data.endDate}, {data.endTime}
            </p>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          navigate(`/admin/contest/add-question/${data._id}`, {
            state: { id: data._id, predictionName: data.predictionName },
          });
        }}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Add Question
        </p>
      </div>
      <div
        onClick={() => {
          navigate(`/admin/contest/view-questions/${data._id}`, {
            state: { id: data._id, predictionName: data.predictionName },
          });
        }}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          View Answer
        </p>
      </div>
      <div
        onClick={handleRewardDistrubution}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Reward Distribution
        </p>
      </div>
      <div className="contest_admin_prediction-sub-schedule-button">
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Edit
        </p>
      </div>
      {openDeletePredictionModal && <PredictionDelete handleOpen={handleOpenDeletePredictionModal}
        handleClose={handleCloseDeletePredictionModal} open={openDeletePredictionModal} predictionId={data?._id}
        handleDelete={deletePrediction} />

      }
      {openEditModal && <PredictionEdit handleOpen={handleOpenDeletePredictionModal}
        handleClose={handleCloseEditModal} open={openEditModal} predictionData={data} setData={setData}
      />}
    </div>

  );
};

export default PredictionItem;
