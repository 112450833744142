import React from 'react'
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useScroller from "../../commons/useScroller_JSON_ARG";
import Event from "./index";
import { BallTriangle } from "react-loader-spinner";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Link from "@mui/material/Link";
import UserModal from "./UserModal";


export default function DataEvent(props) {
    const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const data = props.data;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [events, setEvents] = useState([]);
  const [modeldata, setMOdeldata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  console.log("data", data);
  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleOnClick = () => {
    handleToggle();
  };

  const handleToggle = () => {
    setIsModalOpen(!isModalOpen);
  };


  // Ferching Page Data


  // const fetchEventData = async () => {
  //   // Fetch additional data
  //   const req = {
  //     method: "GET",
  //     url: global.config.ROOTURL.prod +`/events/userEvent/getEventRegistrationList/${data._id}`,
  //     headers: {
  //       Authorization: "Bearer " + accessToken,
  //     },
  //   };

  //   axios(req).then(response => {

  //     if (response.data.length === 0) {
  //       setHasMore(false);
  //     } else {
  //       console.log("data", response.data);
  //       setMOdeldata(response.data)
  //       // sets(prevItems => prevItems.concat(response.data));
  //       // setPage(prevPage => prevPage + 1);
  //       // setShouldLoadPage(true);
  //     }
  //   })
  //     .catch(error => console.log(error));
  // };
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   setEvents([]);
  //   fetchEventData();
  // }, []);


  return (
    <>
    <div>
              
              <Card className="card-main">
                <CardMedia
                  component="img"
                  height="140px"
                  image={data.eventBanner}
                  alt={data.event_title}
                  key={data._id}
                  onClick={() => {
                    navigate(`/events/${data._id}`);
                  }}
                />
                <CardContent className="content" 
                   key={data._id}
                  onClick=
                  {() => {
                    navigate(`/events/${data._id}`);
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {data.eventTitle}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <div
                      className="data-description"
                      dangerouslySetInnerHTML={{
                        __html:
                          data.eventdescription.substring(0, 200) + "...",
                      }}
                    />
                  </Typography>
                  <hr style={{ marginTop: "10px", marginBottom: "10x" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <EventIcon />
                      </Box>
                      <Box>
                        <p>
                          {data.startDate} at {data.startTime}
                        </p>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    ></Box>
                    {/* <span>{data.endTime}</span> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <LocationOnIcon />
                      <p>{data.eventLocation}</p>
                    </Box>
                    <div style={{ fontWeight: "bold" }}>
                      <p>RegistrationCount - {data.registrationCount}</p>
                    </div>
                  </Box>
                </CardContent>
                <button
                  className="view-button"
                  style={{width: "25%", justifyContent: "center", backgroundColor: "#b6fbff", display: "flex", margin: "auto", marginBottom: "10px"}}
                  onClick={() => setIsModalOpen(true)}
                >
                  View Count
                </button>
              </Card>
         
    </div>
      {isModalOpen && (
        <UserModal
          handleClose={handleToggle}
          data={data._id}
          open={isModalOpen}
        />
        
      )}
      </>
  )
}



