import React, { useEffect, useState } from "react";
import JobPopup from "./JobPopup";
import emptyreshare from "../../assets/images/icons/emptyReshare.png";
import newshare from "../../assets/images/icons/emptyShare.png";
import ShareToAppsPopupPage from "../posts/shareToOtherApps";
import jobExperience from "../../assets/images/icons/jobExperienceimg.png";
import jobSalarylogo from "../../assets/images/icons/jobsalaryimg.png";
import jobCompanylogo from "../../assets/images/icons/jobcompanypic.png";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  useMediaQuery,
  Card,
  Box,
  Grid,
  Link,
  Container,
  main,
  Button,
  ThemeProvider,
  createTheme,
  Avatar,
} from "@mui/material";
import suitcase from "./../../assets/images/icons/suitcase.png";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as regularFaBookmark } from "@fortawesome/free-regular-svg-icons";
import { getStorageItem } from "../../utils/sessionStorage";
import newreshare from "../../assets/images/icons/newreshare.png";
import config from "../../config";
const baseURL = config.ROOTURL.prod;
const userId = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const pagePerSize = config.pagePerSize;

export default function SingleJob(props) {
  // const navigate = use();
  const mobileView = useMediaQuery("(max-width:959px)");
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [shareToOtherApps, setShareToOtherApps] = useState(false);
  const handleCloseShareToOtherAppsPopup = () => {
    setShareToOtherApps(false);
  };
  const [jobrePostHandler, setJobRePostHandler] = useState(false);
  const [jobData, setJobData] = useState(props.data);
  const jobId = props.data._id;
  // const [isSaved, setIsSaved] = useState(data);
  // const [jobpost, setJobPost] = useState(isSaved);

  // const jobHandler = (data) =>{
  //     setJobPost(data);
  // }

  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPage] = useState(1);
  const [hasShared, setHasShared] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const theme = createTheme({
    typography: {
      fontFamily: "poppins, Arial",
    },
  });
  // const jobDetails = (data) => {
  //     setJobData(data);
  //     console.log("jobDetails", data);

  // }
  const handleShareToOtherAppsPopup = () => {
    let shareUrl = `${global.config.share_url}/job-description/${props.data._id}`;
    setUrl(shareUrl);
    setShareToOtherApps(true);
  };
console.log("jobData", jobData);
  let JobId;
  const getJobId = (id) => {
    JobId = id;
    // console.log("this is getJobId function", JobId);
    window.location.href = `/job-description/${JobId}`;
  };

  const saveJob = (id) => {
    if (!accessToken && !id && !userId) return;
    const saveJobInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/job/saveJob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        jobId: id,
        userId: userId,
      },
    };
    axios(saveJobInfo)
      .then((response) => {
        if (response) {
          setJobData({ ...jobData, isSaved: true });
          // fetchData();
        } else {
          console.log("error occured:");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

 

  const deleteSavedJob = (id) => {
    let jobId = id;
    axios
      .delete(`${baseURL}/job/deleteSavedJob/${jobId}/${userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        if (response.data.deletedCount) {
          setJobData({ ...jobData, isSaved: false });
          // fetchData();
        } else {
          console.log("error occured:");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchData = async (jobId, userId) => {
    const res = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/job/sharejob/getByJobIdAndUserId/${jobId}/${userId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(res)
      .then((response) => {
        if (response) {
          setHasShared({ ...jobData, hasShared: true });
        }
        // console.log("response.data one job", response.data);
        // const isReshare = response.data.isReshare === true;
        // setHasShared(isReshare);                                                                                                                                                                                                                                                                   
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  useEffect(() => {
     fetchData(jobId, userId);
    return () => {
      setIsOpen(false);
      setShareToOtherApps(false);
    };
  }, []);

  console.log("hasShared:", hasShared);
  return (
    <ThemeProvider theme={theme}>
      {/* <main className="pitch-main"> */}
      <Card variant="outlined" sx={{ minWidth: 275, mb: 0.3, borderRadius: 3 }}>
        {/* <React.Fragment> */}
        <Grid container spacing={mobileView ? 0 : 2}>
          {/* spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} */}

          <Grid
            item
            textAlign={"center"}
            xs={3}
            sm={2}
            md={2}
            lg={2}
            sx={{ borderRadius: "12px solid purple" }}
          >
            <img
              src={jobData.companyLogo ? jobData.companyLogo : suitcase}
              alt=""
              width={55}
              height={55}
              style={{
                margin: 23,
                border: "2px solid purple",
                borderRadius: 50,
              }}
            />
          </Grid>
          <Grid item xs={9} sm={10} md={10} lg={10}>
            <CardContent>
              {/* <Link onClick={(event) => window.location.href = '/job-description'} about='_blank' sx={{ textDecoration: 'none' }}> */}
              <Box sx={{ display: "flex", position: "relative" }}>
                <Link
                  onClick={() => getJobId(jobData._id)}
                  about="_blank"
                  underline="hover"
                >
                  {/* {console.log( "id",data._id)} */}
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      display: "inline-block",
                      color: "#0C5BA0",
                      cursor: "pointer",
                    }}
                  >
                    {jobData.jobTitle}
                  </Typography>
                </Link>

                <Typography
                  className=" three_btn"
                  variant="h6"
                  sx={{ color: "#0C5BA0" }}
                  component="div"
                  style={{ gap: "8px", alignItems: "center" }}
                >
                  <Link
                    onClick={() => {
                      jobData.isSaved
                        ? deleteSavedJob(jobData._id)
                        : saveJob(jobData._id);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={jobData.isSaved ? faBookmark : regularFaBookmark}
                    />
                    {/* onClick={() => { data.isSaved ? deleteSavedJob(data._id) : (saveJob(data._id)) }}/>  */}
                  </Link>

                  {/* <img style={{ width: "50%" }} src={emptyreshare} alt='' onClick={() => { setIsOpen(true); setJobData(jobData) }} /> */}
                 
                  <img
                    style={{ width: "50%" }}
                    src={emptyreshare}
                    alt=""
                    onClick={() => {
                      setIsOpen(true);
                      setJobData(jobData);
                    }}
                  />
                  <img
                    style={{ width: "50%" }}
                    onClick={handleShareToOtherAppsPopup}
                    src={newshare}
                    alt=" "
                  />
                </Typography>
              </Box>

              <Box sx={{ fontSize: "0.9rem" }}>
                <Typography sx={{ display: "inline-block", color: "#870499" }}>
                  <img src={jobCompanylogo} alt="" />
                  &nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {" "}
                  {jobData.companyName}{" "}
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  ({jobData.jobLocation})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", fontSize: "0.9rem", height: 50 }}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    sx={{ display: "inline-block", color: "#870499" }}
                  >
                    <img src={jobExperience} alt="" />
                    &nbsp;{" "}
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline-block", color: "#870499" }}
                  >
                    {" "}
                    <img src={jobSalarylogo} alt="" />
                    &nbsp;{" "}
                  </Typography>
                </Box>
                <Box sx={{ display: "inline-block" }}>
                  <Typography sx={{ display: "inline-block" }}>
                    {" "}
                    {jobData.Remuneration}
                  </Typography>
                  <br />
                  <Typography sx={{ display: "inline-block" }}>
                    {" "}
                    {jobData.Experience}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>

      {isOpen && (
        <JobPopup
          jobId={jobData._id}
          handleClose={handleClose}
          sharedData={jobData}
          open={isOpen}
          companyName={jobData.companyName}
          companyEmail={jobData.companyEmail}
          companyContact={jobData.companyContact}
        />
      )}
      {shareToOtherApps && (
        <ShareToAppsPopupPage
          open={shareToOtherApps}
          url={url}
          handleClose={handleCloseShareToOtherAppsPopup}
        />
      )}
    </ThemeProvider>
  );
}
