import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import ProductItems from './ProductItems';


const style = {
  position: 'absolute',
  top: '56%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "328px",
  height: "305px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: "16px",
};
const productItemStyle = {
    position: 'fixed',
    zIndex: 999, // Set the z-index value to ensure it stays on top
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

export default function  BuyerNotification({onClose}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirm = () => {
    onClose();
  };
  const handleReceived = () => {
    onClose(); 
  };
  const buttonRowStyle1 = {
    display: 'flex',
    margin: "16px auto",
    flexDirection: "column",
    width: "234px",
    height: "40px",
    borderRadius: "6px",
    background: "#0C5BA0",
    textTransform: "none",
  };
  const buttonRowStyle2 ={
    display: 'flex',
    margin: "16px auto",
    flexDirection: "column",
    width: "234px",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid #9FCEF7",
    textTransform: "none",
   
  }

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <CloseIcon
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "#0C5BA0"
          }}
          onClick={handleClose}
        />
        <div style={productItemStyle}>
            <ProductItems />
          </div>
          <Typography style={{fontSize: "10px", color: "#1C1B1B", fontWeight: "400",whiteSpace: 'nowrap', textAlign:"center", paddingTop: "8.5rem"}}>
              Have you received this product you ordered?
          </Typography>
          <div style={buttonRowStyle1}>
          <Button onClick={handleConfirm} style={{color: "#FFFFFF"}}>
              Yes, I have
          </Button>
          <Button style={buttonRowStyle2} onClick={handleReceived}>
              I have not Received
          </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
