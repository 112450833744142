import React, { useState, useEffect } from "react";
import axios from "axios";
import { getStorageItem } from "../../../../utils/sessionStorage";
import "./index.scss";

const Carousel1 = () => {

  const accessToken = getStorageItem("token");
  const [deal, setDeal] = useState([]);

  const getDealsCarousels = async () => {

    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/deal/getAllDealsForLandingPage",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        console.log("dealData...",data);
        setDeal(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getDealsCarousels();
  }, []);


  const findDealImage1 = deal.find((deal) => deal.priorityNo === 1);
  const findDealImage2 = deal.find((deal) => deal.priorityNo === 2);
  const findDealImage3 = deal.find((deal) => deal.priorityNo === 3);
  const dealImage1 = findDealImage1 ? findDealImage1.dealImage : null;
  const dealImage2 = findDealImage2 ? findDealImage2.dealImage : null;
  const dealImage3 = findDealImage3 ? findDealImage3.dealImage : null;

  const images = [
    {
      src:dealImage1,
      width: "200px",
      height: "200px",
      mobileWidth: "120px",
      mobileHeight: "120px",
      marginTop: "7rem",
      mobileMarginTop: "6rem",
    },
    {
      src: dealImage2,
      width: "150px",
      height: "150px",
      mobileWidth: "100px",
      mobileHeight: "100px",
      marginTop: "10rem",
      mobileMarginTop: "7rem",
    },
    {
      src: dealImage3,
      width: "100px",
      height: "100px",
      mobileWidth: "80px",
      mobileHeight: "80px",
      marginTop: "13rem",
      mobileMarginTop: "8rem",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleDotClick = (index) => {
    setActiveIndex(index);
    resetAutoSlide();
  };

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const resetAutoSlide = () => {
    startAutoSlide();
  };

  const startAutoSlide = () => {
    const intervalId = setInterval(nextSlide, 3000);
    return intervalId;
  };

  useEffect(() => {
    const intervalId = startAutoSlide();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`Image ${index + 1}`}
            className={index === activeIndex ? "active" : ""}
            style={{
              width: window.innerWidth <= 768 ? image.mobileWidth : image.width,
              height:
                window.innerWidth <= 768 ? image.mobileHeight : image.height,
              marginTop:
                window.innerWidth <= 768
                  ? image.mobileMarginTop
                  : image.marginTop,
            }}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};
export default Carousel1;
