import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { getStorageItem } from "../../utils/sessionStorage";
import useGetPlayerById from './useGetPlayerById';

const PlayerDetailPopup = ({ open, setOpen, playerId }) => {
    const accessToken = getStorageItem("token");
    const mobileView = useMediaQuery("(max-width:768px)");
    const { playerDetails, getPlayerById } = useGetPlayerById();

    useEffect(() => {
        getPlayerById(playerId)
    }, [accessToken, playerId])

    const style = {
        position: 'absolute',
        top: '57%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: mobileView ? '90%' : '40%',
        height: 500,
        bgcolor: '#FFFFFF',
        borderRadius: '25px',
        border: 'none',
        p: 3,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <Typography
                            variant="h4"
                            align="center"
                            sx={{
                                fontWeight: 500,
                                color: '#494D50',
                                fontSize: '22px'
                            }}
                        >
                            Player Details
                        </Typography>
                        <img
                            src={playerDetails?.image || null}
                            alt='banner'
                            style={{
                                width: '100%',
                                height: '185px',
                                borderRadius: '12px'
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 500,
                                    color: '#0C5BA0',
                                    fontSize: '20px'
                                }}
                            >
                                {playerDetails?.name || ""}
                            </Typography>
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 500,
                                    color: '#5E5F60',
                                    fontSize: '18px'
                                }}
                            >
                                {playerDetails?.specialization || ""}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '34px',
                                background: '#0C5BA014',
                                borderRadius: '8px',
                                padding: '0px 10px',
                            }}
                        >
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#666666',
                                    fontSize: '15px'
                                }}
                            >
                                Jersey No
                            </Typography>
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 500,
                                    color: '#0C5BA0',
                                    fontSize: '17px'
                                }}
                            >
                                {playerDetails?.jerseyNo || null}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '34px',
                                background: '#0C5BA014',
                                borderRadius: '8px',
                                padding: '0px 10px',
                            }}
                        >
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#666666',
                                    fontSize: '15px'
                                }}
                            >
                                Previous Team
                            </Typography>
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 500,
                                    color: '#0C5BA0',
                                    fontSize: '17px'
                                }}
                            >
                                {playerDetails?.previousTeam || ""}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '34px',
                                background: '#0C5BA014',
                                borderRadius: '8px',
                                padding: '0px 10px',
                            }}
                        >
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#666666',
                                    fontSize: '15px'
                                }}
                            >
                                Last Bid Amount
                            </Typography>
                            <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                    fontWeight: 500,
                                    color: '#0C5BA0',
                                    fontSize: '17px'
                                }}
                            >
                                {playerDetails?.basePrice || 0} L
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default PlayerDetailPopup;
