import React, { useEffect, useState } from "react";
import "./index.css";
import ViewPlayerItem from "./ViewPlayerItem";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Stack, TextField } from "@mui/material";

import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import moment from "moment";
const accessToken = getStorageItem("token");
const userId = getStorageItem("user_id");

const Index = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [allPlayers, setAllPlayers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { state } = useLocation();
  const navigate = useNavigate();
  

  const getSelectedPlayers = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/fantasyMatch/findPlayersByMatchId/${state?.id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        console.log("Data=",data);
        setAllPlayers(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getSelectedPlayers();
  }, []);
  return (
    <div className="contest_admin-wrapper">
      <div style={{ display: "flex", justifyContent: "right", marginTop: "8px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          
          
        </div>
      </div>
      <div className="contest_admin_head">
      
      <h3 className="contest_admin_head-content--left"> Selected Players List</h3>

      </div>
      <h3 style={{marginLeft:"10px"}}>{state?.matchName}</h3>
      <div className="contest_admin_prediction-list">
      
        {allPlayers?.map((prediction, index) => {
          return (
            <ViewPlayerItem
              key={index}
              data={prediction.playerDetails}
              details={prediction}
              fantasyId={state?.id}
              id={prediction._id}
              getSelectedPlayers={getSelectedPlayers}
              type="view"
            />
          );
        })}
      </div>
     
      <div className="contest_admin_cancel_save">
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            className="contest_admin_cancel_save--cancel"
          >
            Cancel
          </Button>
          <Button onClick={()=>{navigate('/admin/fantasy')}} variant="contained" href="#outlined-buttons">
            Save
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default Index;