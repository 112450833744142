import { Grid, Button, CardMedia, Box, TextField, InputLabel, Select, MenuItem } from "@mui/material";
import React, { useState, useRef } from "react";
import "./index.css";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageBackground from "../../assets/images/Image-Background.png";

const DealCreationForm = () => {
    const accessToken = getStorageItem("token");
    const [status, setStatus] = useState(true);
    const [runsBack, setRunsBack] = useState();
    const [showRunDealAmount, setShowRunDealAmount] = useState(false);
    const [conditionForUnlock, setConditionForUnlock] = useState({
        post: 0,
        comment: 0,
        highlight: 0,
    });
    const [runcondition, setRuncondition] = useState({
        lowerCount: 0,
        upperCount: Infinity,
    });
    const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
    const [image, setImage] = useState(null);
    const [imageBG, setImageBG] = useState(null);
    const [dealInputs, setDealInputs] = useState({
        name: "",
        redeemrun: 0,
        partnerEmailId: "",
        count: 0,
        categories: "All",
        appOnly: false,
        redeemableOnce: false,
        isLocked: false,
        discount: 0,
        active: true,
        startDateAndTime: "",
        endDateAndTime: "",
        priorityNo: 0,
        encrptedcode: "",
        targetUser: "user"
    })

    const [dealError, setDealError] = useState({
        name: "Title",
        redeemrun: "Runs Required",
        partnerEmailId: "Email Id",
        count: "Deal Count",
        categories: "Category",
        appOnly: "App Only",
        redeemableOnce: "Redeem Once",
        isLocked: "Lock Deal",
        discount: "Discount Percentage",
        active: "Run Deal",
        startDateAndTime: "Start Date & Time",
        endDateAndTime: "End Date & Time",
        priorityNo: "Priority No",
        encrptedcode: "Code",
        targetUser: "user"
    })

    const [discountStartDateAndTime, setDiscountStartDateAndTime] = useState("")
    const [discountEndDateAndTime, setDiscountEndDateAndTime] = useState("")
    const [runDealAmount, setRunDealAmount] = useState(0);
    const [desc, setDesc] = useState("")

    const onImageChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageBG(reader.result);
        };
        reader.readAsDataURL(file);
        setImage(file);
    };

    const handleFormSubmit = async () => {

        for (const key in dealInputs) {
            if ((dealInputs[key] ==="" || dealInputs[key] === 0 ) && ["name", "count", "redeemrun", "encrptedcode", "startDateAndTime", "endDateAndTime", "partnerEmailId", "priorityNo"].includes(key)) {
                toast.error(`${dealError[key]} Field is Required`)
                return;
            }
        }

        setSubmitBtnDisable(true);

        new Compressor(image, {
            quality: 0.8,
            success: (compressedResult) => {
                if (compressedResult.size > 5000000) {
                    setImage(compressedResult);
                    setStatus(true);
                } else {
                    setImage(compressedResult.name);
                    setStatus(false);
                }
            },
        });

        const formData = new FormData();
        for (const key in dealInputs) {
            if (dealInputs.hasOwnProperty(key)) {
                formData.append(key, dealInputs[key])
            }
        }

        formData.append("uploader", image)
        formData.append("conditionForUnlock", JSON.stringify(conditionForUnlock));
        formData.append("runcondition", JSON.stringify(runcondition));
        formData.append("desc", desc)
        formData.append("discountStartDateAndTime", discountStartDateAndTime)
        formData.append("discountEndDateAndTime", discountEndDateAndTime)
        formData.append("runDealAmount", runDealAmount);

        const createNewEvent = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/deal/create`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: formData,
        };
        if (formData) {
            axios(createNewEvent)
                .then((response) => {
                    if (response?.status === 200 && response) {
                        toast.success("New Event created successfuly");
                        setDealInputs({
                            name: "",
                            redeemrun: 0,
                            partnerEmailId: "",
                            count: 0,
                            categories: "All",
                            appOnly: false,
                            redeemableOnce: false,
                            isLocked: false,
                            discount: 0,
                            active: true,
                            startDateAndTime: "",
                            endDateAndTime: "",
                            priorityNo: 0,
                            encrptedcode: "",
                            targetUser: "user"
                        })
                        setConditionForUnlock({
                            post: 0,
                            comment: 0,
                            highlight: 0,
                        })
                        setRuncondition({
                            lowerCount: 0,
                            upperCount: Infinity,
                        })
                        setDiscountStartDateAndTime("")
                        setDiscountEndDateAndTime("")
                        setDesc("")
                        setRunDealAmount(0)
                        setImage(null);
                        setImageBG(null);
                        setSubmitBtnDisable(false)
                    }
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        error.message
                            ? toast.error("Error: " + error.message)
                            : toast.error("Error: " + error);
                    }
                    error.message
                        ? toast.error("Error: " + error.message)
                        : toast.error("Error: " + error);
                        setSubmitBtnDisable(false)
                });

        }

    };

    return (
        <Grid container>
            <Grid item xs={12} sx={{ position: "relative" }}>
                <CardMedia
                    component="img"
                    height="300"
                    image={imageBG === null ? ImageBackground : imageBG}
                    alt="No Image"
                    sx={{ borderRadius: "10px" }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{ position: "relative", top: "-8vh", left: "75vh", width: "14%" }}
                >
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                    />
                    Add Images
                </Button>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Title</InputLabel>
                        <TextField className="dealInput"
                            placeholder="Title Name"
                            value={dealInputs.name}
                            onChange={(e) => setDealInputs({ ...dealInputs, name: e.target.value })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Email Id</InputLabel>
                        <TextField className="dealInput"
                            placeholder="Mail ID"
                            value={dealInputs.partnerEmailId}
                            onChange={(e) => setDealInputs({ ...dealInputs, partnerEmailId: e.target.value })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Category</InputLabel>
                        <Select
                            style={{
                                padding: "2px 0px",
                                backgroundColor: "#F3F5F5",
                                fontSize: "14px",
                                color: "#697D95",
                                border: "1px solid #BAC7D5"
                            }}
                            value={dealInputs.categories}
                            onChange={(e) => setDealInputs({ ...dealInputs, categories: e.target.value })}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="E-Commerce">E-Commerce</MenuItem>
                            <MenuItem value="Food and Beverages">Food and Beverages</MenuItem>
                            <MenuItem value="Exclusive">Exclusive</MenuItem>
                            <MenuItem value="TimeLimit">TimeLimit</MenuItem>
                            <MenuItem value="Offers">Offers</MenuItem>
                        </Select>
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Redeem Once</InputLabel>
                        <Select
                            style={{
                                padding: "2px 0px",
                                backgroundColor: "#F3F5F5",
                                fontSize: "14px",
                                color: "#697D95",
                                border: "1px solid #BAC7D5"
                            }}
                            value={dealInputs.redeemableOnce}
                            onChange={(e) => setDealInputs({ ...dealInputs, redeemableOnce: e.target.value })}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </Box>
                    {dealInputs.isLocked &&
                        <Box className="form-styling">
                            <InputLabel style={{ color: "black", margin: "4px 0" }}>Post</InputLabel>
                            <TextField className="dealInput"
                                type="number"
                                value={conditionForUnlock.post}
                                onChange={(e) => setConditionForUnlock({ ...conditionForUnlock, post: parseInt(e.target.value, 10) })}
                            />
                        </Box>
                    }
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Discount Percentage</InputLabel>
                        <TextField className="dealInput"
                            type="number"
                            placeholder="0"
                            value={dealInputs.discount}
                            onChange={(e) => setDealInputs({ ...dealInputs, discount: parseInt(e.target.value) })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Start Date & Time</InputLabel>
                        <TextField
                            className="dealInput"
                            type="datetime-local"
                            value={dealInputs.startDateAndTime}
                            onChange={(e) => setDealInputs({ ...dealInputs, startDateAndTime: e.target.value })}
                        />
                    </Box>
                    {dealInputs.discount > 0 &&
                        <Box className="form-styling">
                            <InputLabel style={{ color: "black", margin: "4px 0" }}>Discount Start Date & Time</InputLabel>
                            <TextField
                                className="dealInput"
                                type="datetime-local"
                                value={discountStartDateAndTime}
                                onChange={(e) => setDiscountStartDateAndTime(e.target.value)}
                            />
                        </Box>
                    }
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Code</InputLabel>
                        <TextField
                            className="dealInput"
                            placeholder="Code"
                            value={dealInputs.encrptedcode}
                            onChange={(e) => setDealInputs({ ...dealInputs, encrptedcode: e.target.value })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Targeted Users</InputLabel>
                        <Select
                            style={{
                                padding: "2px 0px",
                                backgroundColor: "#F3F5F5",
                                fontSize: "14px",
                                color: "#697D95",
                                border: "1px solid #BAC7D5"
                            }}
                            value={dealInputs.targetUser}
                            onChange={(e) => setDealInputs({ ...dealInputs, targetUser: e.target.value })}
                        >
                            <MenuItem value="user">Users</MenuItem>
                            <MenuItem value="subscriber">Subscribers</MenuItem>
                        </Select>
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>RunCondition lowerCount</InputLabel>
                        <TextField
                            className="dealInput"
                            type="number"
                            value={runcondition.lowerCount}
                            placeholder="0"
                            onChange={(e) => setRuncondition({ ...runcondition, lowerCount: parseInt(e.target.value, 10) })}
                        />
                    </Box>
                    {dealInputs.active && (
                        <Box className="form-styling">
                            <InputLabel style={{ color: "black", margin: "4px 0" }}>Run Deal Amount</InputLabel>
                            <TextField
                                className="dealInput"
                                type="number"
                                value={runDealAmount}
                                placeholder="0"
                                onChange={(e) => setRunDealAmount(e.target.value)}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Runs Required</InputLabel>
                        <TextField className="dealInput"
                            placeholder="0"
                            type="number"
                            value={dealInputs.redeemrun}
                            onChange={(e) => setDealInputs({ ...dealInputs, redeemrun: parseInt(e.target.value, 10) })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Deal Count</InputLabel>
                        <TextField className="dealInput"
                            placeholder="0"
                            type="number"
                            value={dealInputs.count}
                            onChange={(e) => setDealInputs({ ...dealInputs, count: parseInt(e.target.value, 10) })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>App Only</InputLabel>
                        <Select
                            style={{
                                padding: "2px 0px",
                                backgroundColor: "#F3F5F5",
                                fontSize: "14px",
                                color: "#697D95",
                                border: "1px solid #BAC7D5"
                            }}
                            value={dealInputs.appOnly}
                            onChange={(e) => setDealInputs({ ...dealInputs, appOnly: e.target.value })}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Lock Deal</InputLabel>
                        <Select
                            style={{
                                padding: "2px 0px",
                                backgroundColor: "#F3F5F5",
                                fontSize: "14px",
                                color: "#697D95",
                                border: "1px solid #BAC7D5"
                            }}
                            value={dealInputs.isLocked}
                            onChange={(e) => setDealInputs({ ...dealInputs, isLocked: e.target.value })}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </Box>
                    {dealInputs.isLocked &&
                        <Box className="form-styling">
                            <InputLabel style={{ color: "black", margin: "4px 0" }}>Comment</InputLabel>
                            <TextField className="dealInput"
                                type="number"
                                value={conditionForUnlock.comment}
                                onChange={(e) => setConditionForUnlock({ ...conditionForUnlock, comment: parseInt(e.target.value, 10) })}
                            />
                        </Box>
                    }
                    {(dealInputs.isLocked && dealInputs.appOnly) &&
                        <Box className="form-styling">
                            <InputLabel style={{ color: "black", margin: "4px 0" }}>Highlight</InputLabel>
                            <TextField className="dealInput"
                                type="number"
                                value={conditionForUnlock.highlight}
                                onChange={(e) => setConditionForUnlock({ ...conditionForUnlock, highlight: parseInt(e.target.value, 10) })}
                            />
                        </Box>
                    }
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Run Deal</InputLabel>
                        <Select
                            style={{
                                padding: "2px 0px",
                                backgroundColor: "#F3F5F5",
                                fontSize: "14px",
                                color: "#697D95",
                                border: "1px solid #BAC7D5"
                            }}
                            value={dealInputs.active}
                            onChange={(e) => setDealInputs({ ...dealInputs, active: e.target.value })}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>End Date & Time</InputLabel>
                        <TextField className="dealInput"
                            type="datetime-local"
                            value={dealInputs.endDateAndTime}
                            onChange={(e) => setDealInputs({ ...dealInputs, endDateAndTime: e.target.value })}
                        />
                    </Box>
                    {dealInputs.discount > 0 &&
                        <Box className="form-styling">
                            <InputLabel style={{ color: "black", margin: "4px 0" }}>Discount End Date & Time</InputLabel>
                            <TextField
                                className="dealInput"
                                type="datetime-local"
                                value={discountEndDateAndTime}
                                onChange={(e) => setDiscountEndDateAndTime(e.target.value)}
                            />
                        </Box>
                    }
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>Priority No</InputLabel>
                        <TextField className="dealInput"
                            type="number"
                            placeholder="0"
                            value={dealInputs.priorityNo}
                            onChange={(e) => setDealInputs({ ...dealInputs, priorityNo: parseInt(e.target.value, 10) })}
                        />
                    </Box>
                    <Box className="form-styling">
                        <InputLabel style={{ color: "black", margin: "4px 0" }}>RunCondition upperCount</InputLabel>
                        <TextField className="dealInput"
                            type="number"
                            placeholder="0"
                            value={runcondition.upperCount}
                            onChange={(e) => setRuncondition({ ...runcondition, upperCount: parseInt(e.target.value, 10) })}
                        />
                    </Box>

                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ position: "relative" }}>
                <div className="form-styling">
                    <label>Description</label>
                    <textarea
                        name="desc"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        style={{
                            borderRadius: "8px",
                            backgroundColor: "#F3F5F5",
                            color: "#697D95",
                            border: "1px solid #BAC7D5",
                            fontSize: "14px",
                            padding: "12px 13px",
                        }}
                        placeholder="Enter a Description"
                    ></textarea>
                </div>
            </Grid>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        position: "relative",
                        top: "4vh",
                        left: "50vh",
                        width: "75vh",
                        padding: "10px 4px",
                        fontSize: "17px",
                        marginBottom: "40px",
                    }}
                    onClick={handleFormSubmit}
                    disabled={submitBtnDisable}
                >
                    Submit
                </Button>
            </div>
        </Grid>
    );
};

export default DealCreationForm;
