import React from "react";
import "./index.scss";
import { Carousel } from "react-responsive-carousel";
import Ball from "../../../assets/images/newLandingPage/Ball2.png";
import Image1 from "../../../assets/images/newLandingPage/fourthSection/image1.png";
import Image2 from "../../../assets/images/newLandingPage/fourthSection/image2.png";
import Image3 from "../../../assets/images/newLandingPage/fourthSection/image3.png";
import Image4 from "../../../assets/images/newLandingPage/fourthSection/image4.png";

const FourthSection = () => {
  return (
    <div className="fourth_section">
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        emulateTouch={true}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
      >
        {[
          {
            image: Image1,
            text: "Redeem your runs to enter weekly raffles and unlock a chance to win exciting gifts.",
            boldText: "HIGHLIGHTS",
          },
          {
            image: Image2,
            text: "Redeem your runs to enter weekly raffles and unlock a chance to win exciting gifts.",
            boldText: "JOBS",
          },
          {
            image: Image3,
            text: "Redeem your runs to enter weekly raffles and unlock a chance to win exciting gifts.",
            boldText: "MARKETPLACE",
          },
          {
            image: Image4,
            text: "Redeem your runs to enter weekly raffles and unlock a chance to win exciting gifts.",
            boldText: "CASH DEALS",
          },
        ].map((item, index) => (
          <div key={index} className="img_text_container">
            <img
              className="carousel_img"
              src={item.image}
              alt={`carousel-${index}`}
            />
            <div className="text_container">
              <img className="ball" src={Ball} alt="ball" />
              <p className="bold_text"> {item.boldText}</p>
              <p className="small_text">{item.text}</p>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="gradient_color"></div>
    </div>
  );
};

export default FourthSection;
