import React from "react";
import { Box, Paper, useMediaQuery } from "@mui/material";
import axios from "axios";
import Masonry from "@mui/lab/Masonry";
import Loader from "../Loader";
import Post from "../../../components/posts/post";
import { useState, useRef, useCallback, useEffect } from "react";
import { getStorageItem } from "../../../utils/sessionStorage";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import Polls from "../../../components/poll/poll";
import Challenge from '../../../components/challenge/FeedChallenge'
import JobMain from "../../../components/pitch-job/JobMain";
import { PathAction } from "../../../redux/reducers/globalPath";



const ProfileList = ({ userId }) => {
  const accessToken = getStorageItem("token");
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const reload = useSelector((state) => state.path.shouldLoad);
  let newCreatedData = useSelector((state) => state.path.createdObject);
  let newDeletedId = useSelector((state) => state.path.deltedId);
  const mobileView = useMediaQuery("(max-width:900px)");
  const dispatch = useDispatch();
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")

  const [locationpermission, setLocationPermission] = useState(localStorage.getItem('locationpermission') === 'true');
  const [visitorLocation, setVisitorLocation] = useState({})
  const updateLocationPermission = () => {
    const update = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/updateLocationPermission",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        locationpermission: false,
      },
    };
    axios(update)
      .then((response) => {

        localStorage.setItem('locationpermission', false)
        if (response.data.modifiedCount === 1) {
          setLocationPermission(false)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
        }
      });
  }
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setVisitorLocation({ latitude, longitude })
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );
            const data = await response.json();
            const { address } = data;
            const { state, country, district } = address
            setState(state);
            setCountry(country);
          } catch (error) {
            updateLocationPermission()
            console.error('Error fetching location:', error);
          }
        },
        (error) => {
          if (error) { updateLocationPermission() }
          console.error(error.message);
        }
      );
    }
  }
  const onEnterViewport = async (postId) => {
    if (locationpermission) {
      getLocation()
    }
    try {
      const req = {
        method: "POST",
        url: global.config.ROOTURL.prod + '/feeds/feedimpression/create', headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          feedId: postId, visitorLocation, state, country
        },
      };
      if (postId) {
        axios(req).then(response => {
          if (response.data) {
            console.log(response.data)
          }
        })
          .catch(error => console.log(error));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };
  const fetchMoreData = async (id) => {
    // Fetch additional data
    const req = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/feeds/getfeedByUserId/${userId}/${id ? id : page}/${global.config.pichPagePerSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    if (userId) {
      axios(req).then(response => {

        if (response.data.length === 0) {

          // No more data to fetch
          setHasMore(false);
        } else {
          console.log(newDeletedId)
          if (newDeletedId) {
            const dataexist = response.data.filter((i) => newDeletedId === i.feed_id);
            if (dataexist.length > 0) {
              const isAnyData = response.data.filter((i) => newDeletedId !== i.feed_id);
              response.data = isAnyData;
              console.log(response.data)
              // and here i remove that data from redux
              dispatch(PathAction.handleDeleteobject({ payload: null }));
            }
          }
          if (id === 1) {
            // if there is created pitch poll repost then this wil trigger otherwise it will null
            console.log(newCreatedData)
            if (newCreatedData) {

              const isAnyData = response.data.filter((i) => newCreatedData._id === i.feed_id);
              // if coming response dont have that new created object then this will trigger
              if (isAnyData.length === 0) {
                const updatedData = { ...newCreatedData, feed_id: newCreatedData._id };
                // here i set new object to response data 
                response.data.unshift(updatedData)
              }
              // and here i remove that data from redux
              dispatch(PathAction.handleCreatedObject({ payload: null }));
            }
            setItems([]);
            setItems((prevItems) => prevItems.concat(response.data));
            if (!shouldLoadPage) {
              setShouldLoadPage(true);
            }
            setPage(2);
          } else {
            setItems((prevItems) => prevItems.concat(response.data));
            setPage((prevPage) => prevPage + 1);
            if (!shouldLoadPage) {
              setShouldLoadPage(true);
            }
          }
        }
      })
        .catch(error => console.log(error));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setItems([]);
    fetchMoreData(1);
    setPage(1);


  }, [reload, userId]);



  return (
    <>
      {shouldLoadPage
        &&
        <Box className="list-profile" >
          <InfiniteScroll
            className="page_profile"
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />}
          >
            {items?.map((post, index) => (
              <div key={index} className="ProfileContainer__">
                {!post?.options && !post?.jobTitle && !post.challengeId && (
                  <Paper className="component posts post-profile" key={index} >
                    <Post key={index} post={post} onEnterViewport={onEnterViewport} />
                  </Paper>

                )}
                {(post?.options && !post?.jobTitle) && !post?.question?.PollMessage && (
                  <Paper className="component posts post-profile" key={index} >

                    <Polls key={index} post={post} postquestion={post?.question} onEnterViewport={onEnterViewport} />
                  </Paper>
                )}
                {!post?.options && post?.jobTitle && (
                  <Paper className="component posts post-profile" key={index} >

                    <JobMain key={index} post={post} onEnterViewport={onEnterViewport} />
                  </Paper>
                )}
                {!post?.options && post?.challengeId && (
                  <Paper className="component posts post-profile" key={index} >

                    <Challenge key={index} postObj={post}
                    // onEnterViewport={onEnterViewport}
                    />

                  </Paper>
                )}
              </div>
            ))}
          </InfiniteScroll>
        </Box>
      }
    </>
  );
};

export default ProfileList;
