
import React, { useState } from 'react';
import { useMediaQuery } from "@mui/material";
import Thanks from './Thanks';
import { useNavigate } from 'react-router-dom';
import { getStorageItem } from "../../../src/utils/sessionStorage";
import axios from "axios";
import "./index.scss";
import { Container, CssBaseline, Box, Typography, TextField, Grid, Button, Select, MenuItem } from '@mui/material';
import { countries } from 'countries-list';
import {toast} from 'react-toastify'
import Img1 from "../../assets/images/contactUs/2.png";
import Img2 from "../../assets/images/contactUs/1.png";
import Img3 from "../../assets/images/contactUs/4.png";
import Img4 from "../../assets/images/contactUs/3.png";
import bg from "../../assets/images/contactUs/bg.png";

const ContactedtUs = () => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const mobileView = useMediaQuery("(max-width:920px)")
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    country: 'Country',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.email === '' || formData.message === '' || formData.phone === '' || formData.country === ''){
      toast.error("Please Fill up the required Information")
    }
    var contact = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/contactus",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        email: formData.email,
        message: formData.message,
        phone: formData.phone,
        country: formData.country,
      },
      json: true,
    };
    axios(contact)
      .then((response) => {
        if (response.data.message === "email sent") {
          navigate('/thank-you');
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const countryOptions = Object.keys(countries).map((countryCode) => ({
    code: countryCode,
    name: countries[countryCode].name,
  }));

  return (
    <div className='main-container-contact-us'>
      {!mobileView && (
        <div className='left-container'>
          <div className='text-container'>
            <h3 className='bold-text'>Get in Touch</h3>
            <p className='small-text'>Have a question or feedback? We'd love to hear from you—just drop us a message!</p>
          </div>
          <div className='img-container'>
            <img className='img1' src={Img1} alt='No Image' />
            <img className='img2' src={Img2} alt='No Image' />
            <img className='img3' src={Img4} alt='No Image' />
            <img className='img4' src={Img3} alt='No Image' />
          </div>
        </div>
      )}
      <div className='right-container'>
        <div>
          {mobileView && (
            <div className='text-container'>
              <h3 className='bold-text'>Get in Touch</h3>
              <p className='small-text'>Have a question or feedback? We'd love to hear from you—just drop us a message!</p>
              
            </div>
          )}
        </div>
        <img className='bg' src={bg} alt='Background' />
        <Container component="main" maxWidth="xs" className='contact-form'>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography component="h1" variant="h5" className='contact-bold-text'>
            Contact Us
            </Typography>
            <Typography component="h1" variant="h5" className='contact-small-text'>
            Provide the details and share your thoughts with us.
            </Typography>
            <Box component="form" noValidate onSubmit={(e) => handleSubmit(e)} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className='name'
                    id="standard-basic"
                    label="Your Name"
                    variant="standard"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className='email'
                    id="standard-basic"
                    label="Your Email"
                    variant="standard"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    variant="standard"
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    label="Country"
                    id="country-select"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    variant="standard"
                    style={{
                      width: "100%",
                      marginTop: "16px",
                      fontWeight: 300
                    }}
                  >
                    <MenuItem value="Country">Country</MenuItem>
                    {countryOptions.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="standard-basic"
                    label="Message"
                    variant="standard"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
                className="btn"
              >
                Send Message
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </div >
  );
};

export default ContactedtUs;
