import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MessageSection from "./MessageSection";
import Avatar from "@material-ui/core/Avatar";

const ChatSection = ({
  groupMessage,
  userId,
  socket,
  groupId,
  loadMoreMessages,
}) => {
  const [typingUsers, setTypingUsers] = useState({});
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.on("typing", (data) => {
        if (data.groupId === groupId && data.userId !== userId) {
          setTypingUsers((prev) => ({ ...prev, [data.userId]: data.user }));
        }
      });

      socket.on("stop typing", (data) => {
        if (data.groupId === groupId) {
          setTypingUsers((prev) => {
            const newTypingUsers = { ...prev };
            delete newTypingUsers[data.userId];
            return newTypingUsers;
          });
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("typing");
        socket.off("stop typing");
      }
    };
  }, [socket, groupId, userId]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (groupMessage.length > 0) {
      const lastMessage = chatContainerRef.current.lastElementChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [groupMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const { scrollTop } = chatContainerRef.current;
    if (scrollTop === 0 && !isLoading) {
      loadOlderMessages();
    }
  };

  const loadOlderMessages = async () => {
    setIsLoading(true);
    await loadMoreMessages();
    setIsLoading(false);
  };

  return (
    <div
      ref={chatContainerRef}
      onScroll={handleScroll}
      style={{
        height: "calc(100vh - 200px)", // Adjust this value based on your layout
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && <div>Loading...</div>}
      {groupMessage && groupMessage.length > 0 ? (
        <div>
          {groupMessage.map((chat, index) => (
            <MessageSection
              key={chat._id || index}
              chat={chat}
              ind={index}
              userId={userId}
            />
          ))}
        </div>
      ) : (
        <p>No messages</p>
      )}
      {Object.values(typingUsers).map((user, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", padding: "10px" }}
        >
          <Avatar
            alt={user.name}
            src={user.profilePic}
            style={{ width: 40, height: 40, marginRight: 10 }}
          />
          <span>{user.name} is typing...</span>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

ChatSection.propTypes = {
  groupMessage: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  socket: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  loadMoreMessages: PropTypes.func.isRequired,
};

ChatSection.defaultProps = {
  groupMessage: [],
  userId: "",
};

export default ChatSection;
