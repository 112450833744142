import React, { useEffect, useState } from "react";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Paper, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Button } from "carbon-components-react";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import axios from "axios";
import { VerifiedRounded, VerifiedUserRounded } from "@mui/icons-material";
import moment from "moment/moment";
import { toast } from "react-toastify";
import ProductCard from "./ProductCard";

const Index = () => {
  const { sellerId } = useParams();
  const navigate = useNavigate();
  const [sellerInfo, setSellerInfo] = useState(null);
  const [products, setProducts] = useState([]);
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [isVerified, setIsVerified] = useState(false);
  const fetchSeller = () => {
    if (!sellerId && !accessToken) return;
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/findInformationOfSingleSeller/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        if (response?.data) {
          setSellerInfo(response?.data[0]);
        }
      })
      .catch((error) => console.log(error));
  };
  const fetchAllProductsBySellerId = () => {
    if (!sellerId && !accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/product/getAllProductsBySellerId/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        if (response?.data) {
          setProducts(response?.data);
        }
      })
      .catch((error) => console.log(error));
  };
  const handleDeleteSeller = () => {
    if (!sellerId && !accessToken) return
    const options = {
      method: "DELETE",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/deleteSellerBySellerId/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    const productDeleteOptions = {
      method: "DELETE",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/product/deleteProductsBySellerId/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        if (response?.data) {
          axios(productDeleteOptions)
            .then((response) => {
              if (response?.data) {
                toast.success("Seller deleted successfully");
                navigate("/admin/marketplace/all-sellers");
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchSeller();
  }, []);
  useEffect(() => {
    fetchAllProductsBySellerId();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Champhunt | Single-Seller</title>
      </Helmet>
      <Box padding={1} style={{ paddingTop: "80px" }}>
        <Paper style={{ padding: "80px" }}>
          <div className="back-button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
          <Typography
            variant="h6"
            align="center"
            className="single-seller-main-heading"
          >
            Single Seller
          </Typography>
          <hr className="single-seller_divider" />
          <h2 className="single-seller_seller-name">
            {sellerInfo?.sellerName}{" "}
            {sellerInfo?.isSellerVerified && (
              <VerifiedUserRounded className="single-seller_seller-verified" />
            )}
          </h2>
          <h3 className="single-seller_seller-email">
            {sellerInfo?.sellerEmail}
          </h3>
          <h4 className="single-seller_seller-joined-on">
            Joined On: {moment(sellerInfo?.createdAt).format("DD/MM/YYYY")}
          </h4>
          <button
            className="single-seller_seller-delete-button"
            onClick={handleDeleteSeller}
          >
            Delete Seller
          </button>
          <div className="single-seller_seller-products">
            {products &&
              products.map((product, index) => {
                return <ProductCard key={index} productData={product} />;
              })}
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default Index;
