import React from "react";
import { Box, p } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logos from "../../assets/images/newLandingPage/logo.png";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./NewFooter.scss";

const NewFooter = () => {
  const navigate = useNavigate();

  const goToRules = () => {
    navigate("/rules");
  };
  const goToPrivacy = () => {
    navigate("/privacy");
  };

  const goToRefundPolicy = () => {
    navigate("/refund-policy");
  };

  const goToAboutus = () => {
    navigate("/about_us");
  };

  return (
    <Box className="Newfooter">
      <div className="logo-new-cont">
        <img src={logos} alt="Champhunt" />
        <label className="logoss_text">CHAMPHUNT</label>
      </div>

      <div></div>
      <div className="footerSection1">
        <div className="content_footer1">
          <div style={{ fontSize: "14px" }}>
            <p onClick={goToRules}>Terms & Conditions</p>
            <p onClick={goToPrivacy}>Privacy Policy</p>
            <p onClick={goToRefundPolicy}>Policies</p>
            <p onClick={goToAboutus}>About us</p>
          </div>
        </div>

        <div className="contact-us-footer">
          <p className="contact-text">CONTACT US</p>
          <div style={{ fontSize: "14px" }}>
            <p>
              <PhoneIcon style={{ verticalAlign: "middle" }} />
              <span>+91 8700301048</span>
            </p>
            <p style={{ display: "flex", alignItems: "center" }}>
              <EmailIcon style={{ marginLeft: "5px" }} />
              <span style={{ marginLeft: "5px" }}>Support@champhunt.com</span>
            </p>
            <p>
              <LocationOnIcon style={{ verticalAlign: "middle" }} />
              <span style={{ marginLeft: "5px" }}>
                H no. 12-53 , Shanti Nagar,{" "}
                <span style={{ paddingLeft: "27px" }}>
                  {" "}
                  Badangpet, Ranga Reddy -{" "}
                  <span style={{ paddingLeft: "27px" }}>501510</span>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="copyrights">champhunt.com All rights reserved 2024</p>
        <p className="poweredBy">Powered by MANA ADDA</p>
      </div>
    </Box>
  );
};

export default NewFooter;
