import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import WhiteBallIcon from "../../../assets/images/rewards/ballwhite.png";
import RedBallIcon from "../../../assets/images/rewards/redBall.png";
import "./index.scss";

const GameModal = ({
  title,
  description,
  wickets,
  gameAnimation,
  open,
  setOpen,
  game,
  handleClose,
}) => {
  const style = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "70vh",
    background: "#07365F",
    border: "3px solid #0B518E",
    boxShadow: 24,
    p: 4,
    borderRadius: "18px",
    color: "#ffffff",
    "@media(width < 768px)": {
      width: "95%",
    },
  };

  const imageContainerStyle = {
    textAlign: "center",
  };
  const imageStyle = {
    width: 100,
  };

  // Calculate the number of red and white balls to display
  const redBalls = game?.playerOutCount || 0;
  const whiteBalls = 3 - redBalls;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={imageContainerStyle}>
            <img style={imageStyle} src={gameAnimation} alt="No Image" />
          </div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={0}
            sx={{ mt: 2 }}
          >
            {[...Array(redBalls)].map((_, index) => (
              <img
                src={RedBallIcon}
                key={`red-${index}`}
                alt="Red Ball Icon"
                className="ball-arena--red"
              />
            ))}
            {[...Array(whiteBalls)].map((_, index) => (
              <img
                src={WhiteBallIcon}
                key={`white-${index}`}
                alt="White Ball Icon"
                className="ball-arena--white"
              />
            ))}
          </Stack>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            fontWeight={600}
            sx={{ mt: 2 }}
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            textAlign="center"
            fontSize="14px"
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              display: "block",
              margin: "20px 0px",
              width: "60%",
              color: "#0C5BA0",
              background: "#FFFFFF",
              padding: "10px 30px",
              borderRadius: "4px",
              "&:hover": {
                background: "#FFFFFF",
                color: "#0C5BA0",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default GameModal;
