
import Footer from "../../components/footer-guest/footer.js";
import Suggestions from "../../components/suggestions-guest";
//import RegistrationBonus from "../../components/registration-bonus";
import "./index.scss";
import { Grid } from "@mui/material";
import SideBar from "../../assets/images/side-bar.png";
import Treanding from "../hashtags-Guest/Treanding.js";
import LeaderBoardGuest from "../../components/leader-board-guest";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/header-guest/index.js";
//import Feeds from "../../components/feeds-guest";
import PostGuest from "../../components/post-guest/index.js";
import MyCarousel from "../../components/carouselGuest";
import Story from "../../components/stories-guest";
import { getStorageItem } from "../../utils/sessionStorage.js";


const LandingPitch = () => {
  const token = getStorageItem("token")
  const mobileView = useMediaQuery("(max-width:959px)");
  return (
    <div>
      <Helmet>
        <title>Champhunt | Pitch</title>
      </Helmet>
      <div>
      <Header />
      </div>

      <div className="page pitch">
        <main className="pitch-main">
          <Grid container spacing={mobileView ? 0 : 2}>
            <Grid item sm={12} md={3} lg={3} sx={{ display: `${mobileView ? "none" : ""}` }}>
              <img alt="" src={SideBar} style={{ width: "100%" }} />
              <Grid style={{ position: 'relative', top: '9px' }}>
                <Treanding />
              </Grid>
              <div>
              <LeaderBoardGuest />
              </div>
            </Grid>
            <Grid item sm={12} md={5} lg={6}  sx={{ padding: `${mobileView ? "0" : ""}` }}>
            <MyCarousel />
            <Story />
            <PostGuest />
            </Grid>
            <Grid item sm={12} md={4} lg={3}>
              <Suggestions />
            </Grid>
          </Grid>
          
        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default LandingPitch;