import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./StatusModal.scss";

export default function StatusModal(props) {
  const { open, handleClose, setFilteredProductsTypes } = props;
  const [filters, setFilters] = useState({
    all: false,
    "not-confirmed": false,
    placed: false,
    dispatched: false,
    "on-its-way": false,
    delivered: false,
    cancelled: false,
  });

  const isMobileView = window.innerWidth <= 768;
  const modalPositionStyle = {
    position: isMobileView ? "fixed" : "absolute",
    top: isMobileView ? "auto" : "124px",
    bottom: isMobileView ? "0" : "auto",
    left: isMobileView ? "50%" : "70%",
    transform: isMobileView ? "translate(-50%)" : "translate(-50%)",
    width: isMobileView ? "100%" : "361px",
    minHeight: "347px",
    bgcolor: "rgb(12, 91, 160)",
    border: "none",
    boxShadow: 24,
    padding: "60px 32px 32px 32px",
    color: "white",
    borderRadius: isMobileView ? "32px 32px 0px 0px" : "32px",
  };

  const handleFilterToggle = (filter) => {
    const updatedFilters = {};

    // Initialize all filters to false
    Object.keys(filters).forEach((key) => {
      updatedFilters[key] = false;
    });

    // Set the clicked filter to true
    updatedFilters[filter] = true;

    setFilters(updatedFilters);
    setFilteredProductsTypes(filter);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalPositionStyle}>
          <div className="All__filter_options">
            {Object.keys(filters).map((filterKey) => (
              <div key={filterKey}>
                <label className="filter-label">
                  <span className="filter-text">{filterKey}</span>
                  <span className="custom-checkbox">
                    <input
                      type="checkbox"
                      checked={filters[filterKey]}
                      onChange={() => handleFilterToggle(filterKey)}
                    />
                    <span className="checkmark"></span>
                  </span>
                </label>
              </div>
            ))}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
