import React, { useState } from "react";
import "./voucher/create/index.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getStorageItem } from "./../utils/sessionStorage";
import { toast } from "react-toastify";

export default function PushNotification() {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [notificationImageUrl, setNotificationImageUrl] = useState(null);
  const [topicUserId, setTopicUserId] = useState("");
  const accessToken = getStorageItem("token");
  const url = useLocation();
  const notifyPath = url.pathname.split("/")[2]

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const uploadUrl = `${global.config.ROOTURL.prod}/upload-file`;
    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("response:", response.data[0].location);
      return response.data[0].location;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Image upload failed. Please try again.");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const notifyData = {
        title,
        message,
        topicUserId,
    }

    if(notificationImageUrl){
        notifyData.notificationImageUrl = await handleImageUpload(notificationImageUrl)
    }

    const notifyRequest = {
      method: "POST",
      url:
        global.config.ROOTURL.prod + `/pushNotification/createPushNotification`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: notifyData
    };

    try {
    
      const response = await axios(notifyRequest);
      console.log(response);
      if (response) {
        toast.success("Push Notification created successfully");
        setTitle("");
        setMessage("");
        setNotificationImageUrl(null);
        setTopicUserId("");
      }
    } catch (error) {
      toast.error("Error in Creating the Notification", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ad-creation-wrapper">
      <h1 className="ad-creation__title">{notifyPath === "pushNotificationToGroup"  ? "Create Push Notification to a Group" : "Create Push Notification to all Devices"}</h1>
      <form className="ad-creation__form" onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="title" className="ad-creation__form-label">
            Title
          </label>
          <input
            id="title"
            type="text"
            className="ad-creation__form-input"
            placeholder="Notification title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="ad-creation__form-label">
            Message
          </label>
          <textarea
            id="description"
            className="ad-creation__form-input"
            placeholder="Notification message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        {notifyPath === "pushNotificationToGroup" && <div>
          <label htmlFor="title" className="ad-creation__form-label">
            userId
          </label>
          <input
            id="title"
            type="text"
            className="ad-creation__form-input"
            placeholder="userId for topic"
            value={topicUserId}
            onChange={(e) => setTopicUserId(e.target.value)}
            required
          />
        </div>}
        <div>
          <label htmlFor="logoImage" className="ad-creation__form-label">
            Notification Image (optional)
          </label>
          <input
            id="logoImage"
            type="file"
            className="ad-creation__form-input"
            onChange={(e) => setNotificationImageUrl(e.target.files[0])}
            accept="image/*"
          />
        </div>
        <div>
          <button
            type="submit"
            className={
              loading
                ? "ad-creation-form-submit--disabled"
                : "ad-creation-form-submit--active"
            }
            disabled={loading}
          >
            {loading ? "Creating..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
