import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DealScroll from "../../components/manage-deal/dealScroll"
import axios from 'axios'
import { getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

const UpdateDeal = () => {
  const accessToken = localStorage.getItem('token')
  const navigate = useNavigate()
  const [deals, setDeals] = useState([])
  const [dealsList, setDealsList] = useState([]);
  const [searchDeal, setSearchDeal] = useState('');


  const page = 1

  const handleSearch = (event) => {
    setSearchDeal(event.target.value)
  }

  const removeSpecialChararters = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  }


  const fetchDealData = () => {
    if (!accessToken) {
      return;
    }
    let url = global.config.ROOTURL.prod + `/deal/getAllDeals/${page}/${global.config.pagePerSize}`
    const req = {
      method: "GET",
      url: url,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(req).then(response => {
      setDealsList(response.data)
      setDeals(response.data)
    })
      .catch(error => console.log(error));
  }

  const handleNavigation = (event) => {
    const dealId = event.currentTarget.getAttribute('data-value')
    navigate(`/admin/edit-deal-admin/${dealId}`)
  }

  useEffect(() => {
    fetchDealData()
  }, [])

  useEffect(() => {
    let filteredDeal = [...dealsList];

    if (searchDeal) {
      filteredDeal = filteredDeal.filter((deal) => {
        let dealName = removeSpecialChararters(deal.name)
        let searchDealName = removeSpecialChararters(searchDeal)
        if (dealName.includes(searchDealName)) {
          return true;
        }
        return false
      })
    }
    setDeals(filteredDeal !== undefined ? filteredDeal : dealsList)
  }, [searchDeal])

  return (
    <Box>
      <Container style={{ display: "flex", justifyContent: 'space-between', borderBottom: '1px solid #dcd1d1' }}>
        <Typography variant="h4">Update Deals</Typography>
        <input type="search" placeholder="Search" onChange={handleSearch} style={{
          padding: '18px',
          height: '30px',
          width: '32%',
          fontSize: '16px',
          border: '1px solid #c1c1c1;',
          borderRadius: '4px',

        }} />
        <Box>
          <Typography style={{ paddingRight: '8px' }}>Champhunt Admin</Typography>
          <img src="" />
        </Box>
      </Container>
      <div style={{ marginTop: '12px' }}></div>
      <DealScroll deals={deals} handleNavigation={handleNavigation} update={'update'}/>
      {/* <Box marginTop={2}> <UpdateDealScroll/></Box> */}
    </Box>
  );
};

export default UpdateDeal;
