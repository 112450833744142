// AdminVoucher.js
import React, { useEffect, useState } from "react";
import "./index.scss";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const AdminVoucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = async (_id) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/voucher/delete/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete voucher");
      }

      setVouchers(vouchers.filter((voucher) => voucher._id !== _id));
      console.log("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const response = await fetch(
          `${global.config.ROOTURL.prod}/voucher/getVouchers`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch vouchers");
        }
        const data = await response.json();
        setVouchers(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVouchers();
  }, []);

  const handleUpdateVoucher = async (id, updatedVoucher) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/voucher/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedVoucher),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update voucher");
      }

      const updatedVouchers = vouchers.map((voucher) =>
        voucher._id === id ? { ...voucher, ...updatedVoucher } : voucher
      );

      setVouchers(updatedVouchers);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="voucher-list">
      {vouchers.map((voucher) => (
        <VoucherCard
          key={voucher._id}
          voucher={voucher}
          onUpdate={handleUpdateVoucher}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

const VoucherCard = ({ voucher, onUpdate, handleDelete }) => {
  const {
    _id,
    title,
    codes = [],
    discount,
    expiry,
    isUsed,
    logoImage,
    voucherImage,
    redirectUrl,
    details = [],
    redeemedRun,
  } = voucher;
  const [isEditing, setIsEditing] = useState(false);
  const [editedCodes, setEditedCodes] = useState(
    codes.map((code) => code.code)
  );
  const [editedReedemedRun, setEditedReedemedRun] = useState(redeemedRun);
  const [editedDiscount, setEditedDiscount] = useState(discount);
  const [editedExpiry, setEditedExpiry] = useState(
    expiry ? new Date(expiry).toISOString().split("T")[0] : ""
  );
  const [editedRedirectUrl, setEditedRedirectUrl] = useState(redirectUrl);

  const handleUpdate = async () => {
    const updatedVoucher = {
      codes: editedCodes.map((code) => ({ code, isUsed: false })),
      discount: editedDiscount,
      expiry: editedExpiry ? new Date(editedExpiry) : null,
      redirectUrl: editedRedirectUrl,
      redeemedRun: editedReedemedRun,
    };

    try {
      await onUpdate(_id, updatedVoucher);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update voucher:", error);
    }
  };

  const handleAddCode = () => {
    setEditedCodes([...editedCodes, ""]);
  };

  const handleRemoveCode = (index) => {
    const newCodes = editedCodes.filter((_, i) => i !== index);
    setEditedCodes(newCodes);
  };

  const handleCodeChange = (value, index) => {
    const newCodes = [...editedCodes];
    newCodes[index] = value;
    setEditedCodes(newCodes);
  };

  return (
    <div className="voucher-card">
      {logoImage && (
        <img src={logoImage} alt={`${title} Logo`} className="voucher-logo" />
      )}
      {voucherImage && (
        <img
          src={voucherImage}
          alt={`${title} Voucher`}
          className="voucher-image"
        />
      )}

      <h2 className="title">{title}</h2>
      {isEditing ? (
        <>
          {editedCodes.map((code, index) => (
            <div key={index} className="voucher-code-edit">
              <input
                type="text"
                value={code}
                onChange={(e) => handleCodeChange(e.target.value, index)}
                className="voucher-input"
                placeholder="Enter code"
              />
              <Button
                onClick={() => handleRemoveCode(index)}
                variant="outlined"
                color="secondary"
              >
                Remove
              </Button>
            </div>
          ))}
          <Button onClick={handleAddCode} variant="contained" color="primary">
            Add Code
          </Button>
          <label>Discount:</label>
          <input
            type="number"
            value={editedDiscount}
            onChange={(e) => setEditedDiscount(Number(e.target.value))}
            className="voucher-input"
            placeholder="Discount (%)"
          />
          <label>Redeemed Run:</label>
          <input
            type="number"
            value={editedReedemedRun}
            onChange={(e) => setEditedReedemedRun(Number(e.target.value))}
            className="voucher-input"
            placeholder="redeemed Run"
          />
          <input
            type="date"
            value={editedExpiry}
            onChange={(e) => setEditedExpiry(e.target.value)}
            className="voucher-input"
          />
          <input
            type="text"
            value={editedRedirectUrl}
            onChange={(e) => setEditedRedirectUrl(e.target.value)}
            className="voucher-input"
            placeholder="Redirect URL"
          />
          <div className="voucher-actions">
            <Button onClick={handleUpdate} variant="contained" color="primary">
              Save
            </Button>
            <Button
              onClick={() => setIsEditing(false)}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="voucher-heading">
            <h3>Voucher Codes:</h3>
          </div>
          <ul className="codes-list">
            {codes.map((codeObj, index) => (
              <li key={index}>{codeObj.code}</li>
            ))}
          </ul>
          <p>Discount: {discount} %</p>
          <p>
            Expiry Date:{" "}
            {expiry ? new Date(expiry).toLocaleDateString() : "No expiry"}
          </p>
          <p>
            {isUsed ? "This voucher has been used." : "This voucher is active."}
          </p>
          <p>
            Redirect URL:{" "}
            <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
              {redirectUrl}
            </a>
          </p>
          <div className="voucher-details">
            <h4>Details:</h4>
            <ul className="details">
              {details.map((detail, index) => (
                <li key={`detail-${index}`}>{detail}</li>
              ))}
            </ul>
          </div>
          <div className="d_u_buttons">
            <Button
              onClick={() => setIsEditing(true)}
              variant="outlined"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => handleDelete(_id)}
              color="secondary"
              style={{ marginTop: "10px" }}
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminVoucher;
