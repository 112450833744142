import React from 'react'
import kiranmore from '../../assets/images/landigpage2/kiranmore.png'
import { useMediaQuery } from '@mui/material';


export default function Secound() {
  const mobileView = useMediaQuery("(max-width:600px)");
  return (
    <div className='maindivforele'>
        <div  className="flexbox2 marginclass1">
            <div className='undermaingr'>
                <p className={mobileView?'font24':'font36'} style={{color:'#323232'}}> Get scouted by<span style={{color:'#E7489D'}}> one of the <br/>
                     biggest Coaches </span>  in Indian <br/>
                     Cricket!</p>
                <p className={mobileView?'font16':'font24popins'}  style={{paddingTop:40}}>Star performers get selected to a one month special training camp at the <span style={{color:'#E7489D'}}> Kiran More <br/> Academy </span>, Baroda and have many doors open <br/>for them.</p>
            </div>
            <div className='undermainwh'>
                <img src={kiranmore} style={{ width:'100%'}}/>
            </div>
        </div>
      
    </div>
  )
}
