import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NewCard from '../card/NewCard';
import { getStorageItem } from "../../utils/sessionStorage";
import "./wishlist.scss";

// User Information
const userId = getStorageItem("user_id");

// Access Token
const accessToken = getStorageItem("token");

const Index = () => {
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const limit = 10;

  // Function to handle page
  const handlePage = (input) => {
    if (page === 1 && input === -1) {
      setPage(1);
    } else if (page === pageCount && input === 1) {
      setPage(pageCount);
    } else {
      setPage(page + input);
    }
    setWishlistProducts([]);
  };

  // Function to get trending products
  async function fetchWishlistProducts() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/findAllWishListedProductsByUserId/${userId}?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const { allWishListByUserId, totalRecords } = await response.json();
      setWishlistProducts(allWishListByUserId);
      setPageCount(Math.floor(totalRecords / limit));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchWishlistProducts();
  }, [page]);

  return (
    <div className='wishlist_card'>
      <Helmet>
        <title>Champhunt | MarketPlace-Wishlist</title>
      </Helmet>
      <p className='page_title'>Wishlist</p>
      <div className="wishlist_cards_box">
        {wishlistProducts
          ? wishlistProducts.map((product, index) => {
            return <NewCard product={product?.productId} index={index} />;
          })
          : "No new products"}
        {/* {products?.map((product, index) => {
            return <NewCard key={index} product={product} index={index} />;
          })} */}
      </div>
      {pageCount > 1 ? (
        <div className="pagination">
          <img
            src="/marketplace/back-pagination.svg"
            alt="backward-pagination"
            className="backward-pagination"
            onClick={() => {
              handlePage(-1);
            }}
          />
          <p className="count">
            {page} of {pageCount}
          </p>
          <img
            src="/marketplace/forward-pagination.svg"
            alt="forward-pagination"
            className="forward-pagination"
            onClick={() => {
              handlePage(1);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Index;

