import * as React from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState, useRef } from "react";
import {
    IconButton, Typography, InputLabel, Select, FormControl, Box, Button, Grid, TextField, Radio, RadioGroup, FormControlLabel
} from "@mui/material";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { getStorageItem } from '../../utils/sessionStorage'
const styleDesktop = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};

const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};
const SelectableBox = ({ value, selectedBox, onClick }) => {
    const isSelected = selectedBox === value;

    return (
        <Box
            width={80}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="1px solid #ccc"
            borderRadius={1}
            textAlign="center"
            style={{ cursor: "pointer" }}
            bgcolor={isSelected ? "#0C5BA0" : "white"}
            color={isSelected ? "white" : "blue"}
            onClick={onClick}
        >
            {value}
        </Box>
    );
};
export default function ActivateDealsModal(props) {
    const matches = useMediaQuery("(min-width:600px)");
    const [style, setStyle] = useState();
    const [active, setActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedBox, setSelectedBox] = useState("");
    const [customRuns, setCustomRuns] = useState("");
    const [createClicked, setCreateClicked] = useState(false);
    const { open, handleClose, runs } = props;
    const userId = getStorageItem("user_id");
    const accessToken = getStorageItem("token");
    const {
        endChallenge, _id, challengeQuestion, challengeOption1, challengeOption2,
        challengeOption3,
        challengeOption4, challengeOption5, hasCreated, expired, startDateAndTime, endDateAndTime
    } = props.data;
    console.log({
        challengeQuestion, challengeOption1, challengeOption2,
        challengeOption3,
        challengeOption4, challengeOption5,
    })

    const handleCustomRuns = (value) => {
        setSelectedBox("");
        setCustomRuns(value)
    }
    const handleCreateUserChallenge = () => {

        if (runs < (selectedBox ? selectedBox : customRuns)) {
            toast.error("Insufficient runs");
            return
        }
        if ((selectedBox ? selectedBox : customRuns) < 500) {
            toast.error("minimum 500 runs to play");
            return;
        }
        let data = {
            challengeQuestion, challengeOption1, challengeOption2,
            challengeOption3,
            challengeOption4, challengeOption5,
            betRuns: selectedBox ? selectedBox : customRuns,
            challengeId: _id,
            answerPredicted: selectedOption, challengeEndDateAndTime: endDateAndTime,
            challengeStartDateAndTime: startDateAndTime
        };
        if (selectedOption === "" ||
            data.betRuns === "" || data.challengeId === '' ||
            data.challengeQuestion === '' || data.challengeOption1 === '' || data.challengeOption2 === ''
        ) {
            toast.error("Error: Data incomplete");
            return;
        }

        setCreateClicked(true);
        var saveUserChallengeOptions = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/contest/userchallenge/createUserChallenge",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: data,
        };
        axios(saveUserChallengeOptions)
            .then((response) => {
                // setPageNum(1);
                if (response.data._id) {
                    props.setCreatedAlready(true)
                    toast.success("shared")
                    handleClose()
                }
                // handleClose();
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    useEffect(() => {
        if (!matches) {
            setStyle(styleMobile);
        } else {
            setStyle(styleDesktop);
        }
    }, [matches]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: "absolute",
                            right: "3%",
                            top: "2%",
                            color: "blue !important",
                            backgroundColor: "white !important",
                            border: "none !important",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box style={{ color: "#1A1919", margin: "5px", fontWeight: "600", textAlign: 'center' }}>Predict the winner!</Box>
                    <Box style={{ margin: "5px", textAlign: 'center' }}>{challengeQuestion}</Box>

                    <Box p={2} boxShadow={3} mt={2} mb={2}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={() => setSelectedOption(challengeOption1)}
                        > <div style={{ marginLeft: '10px' }}>{challengeOption1}</div>
                            <div
                                style={{
                                    width: '20px', // Set the width and height of the outer circle
                                    height: '20px',
                                    borderRadius: '50%', // Create a circle shape for outer circle
                                    border: '2px solid #00f', // Set border style for outer circle
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {selectedOption === challengeOption1 && (
                                    <div
                                        style={{
                                            width: '12px', // Set the width and height of the inner circle (blue filled circle)
                                            height: '12px',
                                            borderRadius: '50%', // Create a circle shape for inner circle
                                            backgroundColor: '#00f', // Blue color for inner circle
                                        }}
                                    />
                                )}
                            </div>

                        </div>
                    </Box>

                    <Box p={2} boxShadow={3} mt={2} mb={2}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={() => setSelectedOption(challengeOption2)}
                        > <div style={{ marginLeft: '10px' }}>{challengeOption2}</div>
                            <div
                                style={{
                                    width: '20px', // Set the width and height of the outer circle
                                    height: '20px',
                                    borderRadius: '50%', // Create a circle shape for outer circle
                                    border: '2px solid #00f', // Set border style for outer circle
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {selectedOption === challengeOption2 && (
                                    <div
                                        style={{
                                            width: '12px', // Set the width and height of the inner circle (blue filled circle)
                                            height: '12px',
                                            borderRadius: '50%', // Create a circle shape for inner circle
                                            backgroundColor: '#00f', // Blue color for inner circle
                                        }}
                                    />
                                )}
                            </div>

                        </div>
                    </Box>
                    {challengeOption3 && <Box p={2} boxShadow={3} mt={2} mb={2}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={() => setSelectedOption(challengeOption3)}
                        > <div style={{ marginLeft: '10px' }}>{challengeOption3}</div>
                            <div
                                style={{
                                    width: '20px', // Set the width and height of the outer circle
                                    height: '20px',
                                    borderRadius: '50%', // Create a circle shape for outer circle
                                    border: '2px solid #00f', // Set border style for outer circle
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {selectedOption === challengeOption3 && (
                                    <div
                                        style={{
                                            width: '12px', // Set the width and height of the inner circle (blue filled circle)
                                            height: '12px',
                                            borderRadius: '50%', // Create a circle shape for inner circle
                                            backgroundColor: '#00f', // Blue color for inner circle
                                        }}
                                    />
                                )}
                            </div>

                        </div>
                    </Box>}
                    {challengeOption4 && <Box p={2} boxShadow={3} mt={2} mb={2}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={() => setSelectedOption(challengeOption4)}
                        > <div style={{ marginLeft: '10px' }}>{challengeOption4}</div>
                            <div
                                style={{
                                    width: '20px', // Set the width and height of the outer circle
                                    height: '20px',
                                    borderRadius: '50%', // Create a circle shape for outer circle
                                    border: '2px solid #00f', // Set border style for outer circle
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {selectedOption === challengeOption4 && (
                                    <div
                                        style={{
                                            width: '12px', // Set the width and height of the inner circle (blue filled circle)
                                            height: '12px',
                                            borderRadius: '50%', // Create a circle shape for inner circle
                                            backgroundColor: '#00f', // Blue color for inner circle
                                        }}
                                    />
                                )}
                            </div>

                        </div>
                    </Box>}
                    {challengeOption5 && <Box p={2} boxShadow={3} mt={2} mb={2}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={() => setSelectedOption(challengeOption5)}
                        > <div style={{ marginLeft: '10px' }}>{challengeOption5}</div>
                            <div
                                style={{
                                    width: '20px', // Set the width and height of the outer circle
                                    height: '20px',
                                    borderRadius: '50%', // Create a circle shape for outer circle
                                    border: '2px solid #00f', // Set border style for outer circle
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {selectedOption === challengeOption5 && (
                                    <div
                                        style={{
                                            width: '12px', // Set the width and height of the inner circle (blue filled circle)
                                            height: '12px',
                                            borderRadius: '50%', // Create a circle shape for inner circle
                                            backgroundColor: '#00f', // Blue color for inner circle
                                        }}
                                    />
                                )}
                            </div>

                        </div>
                    </Box>}
                    <Box><div style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: 500
                    }}>Select runs</div></Box>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <SelectableBox value={500} selectedBox={selectedBox} onClick={() => setSelectedBox(500)} />
                        <SelectableBox value={1000} selectedBox={selectedBox} onClick={() => setSelectedBox(1000)} />
                        <SelectableBox value={1500} selectedBox={selectedBox} onClick={() => setSelectedBox(1500)} />
                    </Box>
                    <Box >
                        <TextField
                            label="Custom Runs"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={customRuns}
                            margin="normal"
                            onChange={(e) => handleCustomRuns(e.target.value)}
                        />
                    </Box>

                    <Box mt={2} >
                        <Button style={{ width: "100%", backgroundColor: "#0C5BA0" }} onClick={handleCreateUserChallenge}
                            variant="contained" disabled={createClicked}
                        >Challenge your friends</Button>
                    </Box>
                    <Box mt={2}>
                        <Button style={{ width: "100%" }} onClick={handleClose}
                            variant="outlined"
                        >Cancel</Button>
                    </Box>
                </Box>
            </Modal >
        </div >
    );
}
