import React, { Component } from "react";
import Chart from "react-apexcharts";

class Bar extends Component {
  constructor(props) {
    super(props);

    const groupedData = this.groupData(this.props.data);

    this.state = {
      options: {
        chart: {
          id: "basic-bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
          }
        },
        colors: ['#FFA500'],
        yaxis: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: groupedData.map(item => item._id)
        }
      },
      series: [
        {
          name: "count",
          data: groupedData.map(item => item.count)
        }
      ]
    };
  }

  groupData(data) {
    if (data.length > 30) {
      return this.groupByMonth(data);
    } else if (data.length >= 15) {
      return this.groupByDateRange(data, 5);
    } else {
      return data;
    }
  }

  groupByMonth(data) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const groupedData = data.reduce((acc, curr) => {
      const [day, month] = curr._id.split('/');
      const monthName = monthNames[parseInt(month, 10) - 1];
      if (!acc[monthName]) {
        acc[monthName] = { _id: monthName, count: 0 };
      }
      acc[monthName].count += curr.count;
      return acc;
    }, {});

    return Object.values(groupedData).sort((a, b) => {
      return monthNames.indexOf(a._id) - monthNames.indexOf(b._id);
    });
  }

  groupByDateRange(data, rangeSize) {
    const groupedData = data.reduce((acc, curr) => {
      const [day, month] = curr._id.split('/');
      const dayNumber = parseInt(day, 10);
      const rangeStart = Math.floor((dayNumber - 1) / rangeSize) * rangeSize + 1;
      const rangeEnd = rangeStart + rangeSize - 1;
      const rangeKey = `${rangeStart}-${rangeEnd}/${month}`;
      if (!acc[rangeKey]) {
        acc[rangeKey] = { _id: rangeKey, count: 0 };
      }
      acc[rangeKey].count += curr.count;
      return acc;
    }, {});

    return Object.values(groupedData).sort((a, b) => {
      const [rangeA, monthA] = a._id.split('/');
      const [rangeB, monthB] = b._id.split('/');
      const [startA] = rangeA.split('-').map(Number);
      const [startB] = rangeB.split('-').map(Number);
      return new Date(2000, monthA - 1, startA) - new Date(2000, monthB - 1, startB);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const groupedData = this.groupData(this.props.data);

      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            categories: groupedData.map(item => item._id)
          }
        },
        series: [
          {
            name: "count",
            data: groupedData.map(item => item.count)
          }
        ]
      });
    }
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
             
              type="bar"
              height="300"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Bar;
