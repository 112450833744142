import React, { useContext, useEffect,useState } from 'react'
import { Helmet } from 'react-helmet';
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import AppContext from "../../context/AppContext";
import Bar1 from "../../components/analytics/bargraph1"
import Bar2 from "../../components/analytics/bargraph2"
import { useLocation, useNavigate } from "react-router-dom";
import { getStorageItem } from '../../utils/sessionStorage';
import Area from "../../components/analytics/areachart"
import axios from "axios";
import Area2 from "../../components/analytics/areachart2"
import Flag from "../../assets/images/flag.png"

import {
  MenuItem,
  FormControl,
  Select,
  Grid,
  InputLabel,
  Container,
  main,Table,TableCell,TableBody,TableHead,TableRow,TableContainer,
  Button,
  styled,
  Paper
} from "@mui/material";
import { Dropdown } from 'carbon-components-react';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const PostAnalytics = () => {
    const [startDate,setStartDate]=useState(new Date()-7);
    const [endDate,setEndDate]=useState(new Date());
    const [range,setRange]=useState(7);
    const [impressionData,setImpressionData]=useState([]);
    const [engagementData,setEngagementData]=useState([]);
    const [performanceData,setPerformanceData]=useState([]);
    const [reachData,setReachData]=useState([]);
    const path = window.location.pathname;
    let [impressCount,setImpressCount]=useState(0);
    let [reachCount,setReachCount]=useState(0);
    let [engageCount,setengageCount]=useState(0);
    const { state } = useLocation();
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));
    const accessToken = getStorageItem("token");
    const getImpression = () => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getTime() 
        - range * 24 * 60 * 60 * 1000);
        const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
        const formattedstartDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`;
        const getinfo = {
            method: "GET",
            
                url: global.config.ROOTURL.prod + `/feeds/feedimpression/getFeedImpressionByDate/${state.post._id}/${formattedstartDate}/${formattedDate}`,            
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getinfo)
            .then(({ data }) => {
                if (data.result) {
                    setImpressionData(data.result);
                }
                if(data.sum){
                    setImpressCount(data.sum)
                }
                
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const getEngagement = () => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getTime() 
        - range * 24 * 60 * 60 * 1000);
        const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
        const formattedstartDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`;
        const getinfo = {
            method: "GET",
            
                url: global.config.ROOTURL.prod + `/feeds/feedimpression/getEngagementByDate/${state.post._id}/${state.post.type}/${formattedstartDate}/${formattedDate}`,            
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getinfo)
            .then(({ data }) => {
              if (data.result) {
                setEngagementData(data.result);
            }
            if(data.sum){
                setengageCount(data.sum)
            }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const getPerformance = () => {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() 
      - range * 24 * 60 * 60 * 1000);
      const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
      const formattedstartDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`;
      const getinfo = {
          method: "GET",
          
              url: global.config.ROOTURL.prod + `/feeds/feedimpression/getPerformanceByFeedId/${state.post._id}/${state.post.type}/${formattedstartDate}/${formattedDate}`,            
              headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
          },
      };
      axios(getinfo)
          .then(({ data }) => {
            setPerformanceData(data);
          })
          .catch((error) => {
              console.log(error)
          });
  }

    const getReach = () => {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() 
      - range * 24 * 60 * 60 * 1000);
      const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
      const formattedstartDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`;
      const getinfo = {
          method: "GET",
          
              url: global.config.ROOTURL.prod + `/feeds/feedimpression/getReachOfFeedId/${state.post._id}/${formattedstartDate}/${formattedDate}`,            
              headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
          },
      };
      axios(getinfo)
          .then(({ data }) => {
              if (data.result) {
                  setReachData(data.result)
              }
              if(data.sum){
                setReachCount(data.sum)
            }
          })
          .catch((error) => {
              console.log(error)
          });
  }
    useEffect(() => {
        getImpression();
        getEngagement();
        getReach();
        getPerformance();
    }, [range])

  return (
    <>
        <Helmet>
          <title>Champhunt | Analytics</title>
        </Helmet>
        
        <div className="MainDealContainer" style={{width:'90%',background:'#F6F5F2',paddingLeft:'10px',paddingRight:'10px',paddingBottom:'15px'}}>
        <Grid container spacing={2}>
            <Grid item xs={8} style={{display: 'flex',flexDirection: 'column'}}>
            <h3>Analytics</h3>
            <p>View performance metrics of your post</p>
            </Grid>
            <Grid item xs={4}>
            <FormControl variant="filled" style={{width:'50%'}}>
                <InputLabel id="demo-simple-select-label" style={{fontWeight:'600'}}>Timeframe:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={range}
                  label="Age"
                  onChange={(event)=>{setRange(event.target.value)}}
                >
                  <MenuItem value={7}>Past week</MenuItem>
                  <MenuItem value={15}>Past 15 days</MenuItem>
                  <MenuItem value={30}>Past month</MenuItem>
                  <MenuItem value={365}>Last 1 Year</MenuItem>
                </Select>
            </FormControl>
            </Grid>
          </Grid>
        <Grid container spacing={2} marginTop={4}>
          <Grid item xs={12} md={3}>
          
            <Item style={{height:'350px',display: 'flex',
            flexDirection: 'column',textAlign:'left'}}>
            <h4 style={{float:'left'}}>ENGAGEMENT<br></br><span style={{float:'left',fontSize:'20px'}}>{engageCount}</span></h4>
              <Bar1 data={engagementData}/></Item>
          </Grid>
          <Grid item xs={12} md={3}>
            <Item style={{height:'350px',display: 'flex',
            flexDirection: 'column',textAlign:'left'}}>
            <h4 style={{float:'left'}}>IMPRESSIONS<br></br><span style={{float:'left',fontSize:'20px'}}>{impressCount}</span></h4>
              <Bar2 data={impressionData}/></Item>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Item style={{height:'350px',paddingLeft:'10px'}}>
            <h4 style={{float:'left'}}>ACCOUNT REACH<br></br><span style={{float:'left',fontSize:'20px'}}>{reachCount}</span></h4>
              <Area data={reachData}/></Item>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <Item>
            <p style={{float:'left',fontSize:'21px',fontWeight:'600',marginBottom:'12px'}}>TOP COUNTRIES</p>
            {impressionData.length>0 && <TableContainer component={Paper} sx={{ maxHeight: 280 }}>
                <Table sx={{ minWidth: 200}} max aria-label="simple table">
                  <TableBody >
                      <TableRow
                        key={1}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        
                      >
                        <TableCell align='left' style={
                          {fontSize: "18px",fontWeight:'500',display: 'flex',
                            // justifyContent: 'center',
                            marginTop:'6px',
                            alignItems: 'center'}
                        }>
                          <img style={{float: 'left',marginRight:'6px'}} width={30} src={Flag}></img>India
                        </TableCell>
                        <TableCell style={
                          {fontSize: "18px",fontWeight:'500'}
                        } align="right">100%</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
            </TableContainer>}
            </Item>
         </Grid>
        </Grid>
        <Grid container spacing={3} marginTop={3}>
          <Grid item xs={12} md={7.5}>
            <Item>
            <p style={{float:'left',fontSize:'21px',fontWeight:'600'}}>CONTENT PERFORMANCE</p>
              <Area2 data={performanceData}/></Item>
          </Grid>
          <Grid item xs={12} md={4.5}>
            <Item>
            <p style={{float:'left',fontSize:'21px',fontWeight:'600',marginBottom:'10px'}}>POST ACTIVITY</p>
            <TableContainer component={Paper} sx={{ maxHeight: 280 }}>
                <Table sx={{ minWidth: 350}} max aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Impressions</TableCell>
                      <TableCell align="right">Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {state.post.type}
                        </TableCell>
                        <TableCell align="right">{impressCount}</TableCell>
                        <TableCell align="right">10%</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
            </TableContainer>
            </Item>
          </Grid>
        </Grid>
        </div>
    </>
  )
}

export default PostAnalytics;