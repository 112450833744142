import React from 'react'
import { Helmet } from "react-helmet";
import First from './First'
import Five from './Five'
import Footer from './Footer'
import Four from './Four'
import './index.scss'
import Secound from './Secound'
import Third from './Third'
export default function IndVSNz() {
  return (
    <div>
       <Helmet>
        <title>Champhunt | India vs New Zealand</title>
      </Helmet>
     <First/>
     <Secound/>
    <Third/>
    <Four/>
    <Five/>
    <Footer/>
    </div>
  )
}
