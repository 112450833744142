import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "./NewDealModal.scss";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 361,
  height: '25vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  border: '1px solid #CBCBCB',
  borderRadius: '6px',
};

export default function NewDealModal(props) {
  // const [open, setOpen] = React.useState(false);
  // const [mobile, setMobile] = useState(0);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const id = getStorageItem("user_id")
  const email = getStorageItem("user_email");
  const name = getStorageItem("full_name");
  const [isRedeemed, setRedeemed] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleConfirm = () => {
    if (!id || !accessToken || isLoading) return;
    setLoading(true);
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: id,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        props.redeemDeal(name, email, response.data.mobileNo)

      }
      )
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); 
      });

  }

  function handleCancel() {
    props.handleClose();
  }

  useEffect(() => {
    setLoading(false); // Reset loading state when the modal is closed
  }, [props.open]);

  return (
    <div className='dealsMain-modal-popup'>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='deals-content'>
          <Typography component="h2" className='deals-modal-para' style={{ width: "100%", textAlign: "center", fontSize: "14px" }}>
            Are you sure you you want to redeem the <br /> Gift Card?
          </Typography>

          <div className='dealsModal-both-btn'>
            <Button className='confirm-btn' onClick={handleConfirm} disabled={isLoading}>Confirm</Button>
            <Button className="cancel-btn" onClick={handleCancel}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}