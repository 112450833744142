import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import "./index.scss";

import { useParams } from "react-router-dom";

const RegistrationWelcome = () => {
  const navigate = useNavigate();

  const { jersey_number } = useParams();
  // console.log(jersey_number);
  if (!jersey_number || jersey_number === "undefined") {
    navigate("/all-events");
  }

  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    email: "",
  });

  const fetchUserInfo = () => {
    if (!accessToken && !userId) return
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);
  const handleClick = () => {
    // navigate("/registration-welcome");
  };
  return (
    <Container>
      <Box className="register-wrapper-welcome">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "50px",
          }}
        >
          <img
            src="/android-chrome-192x192.png"
            alt="logo"
            height="50px"
            width="50px"
          />
          <Typography variant="p" className="title-heading">
            CHAMPHUNT
          </Typography>
        </Box>
        <Typography
          variant="h5"
          fontWeight={600}
          className="w-heading"
          fontSize="40px"
          textAlign="center"
        >
          Welcome to Event 1
        </Typography>
        <Typography
          variant="h6"
          fontWeight={600}
          marginTop="25px"
          fontSize="20px"
          textAlign="center"
        >
          Your Unique Jeresy Number
        </Typography>
        <Typography
          variant="h6"
          fontWeight={600}
          marginTop="10px"
          fontSize="40px"
          textAlign="center"
        >
          {jersey_number}
        </Typography>
        <Typography
          variant="h6"
          fontWeight={600}
          marginTop="10px"
          marginBottom="10px"
          fontSize="16px"
          textAlign="center"
        >
          Instructions
        </Typography>
        <Typography
          variant="p"
          fontWeight={400}
          marginTop="10px"
          fontSize="16px"
          marginBottom="10px"
          textAlign="center"
        >
          Welcome to the Marathwada Talent Hunt! Here are the basic instructions
          for all registered participants attending the event
          <br />
          <br />
          <div className="rules__list--post-registration">
            <p>
              1. All Registered Candidates are expected to arrive at the venue
              at the given time. Anyone who comes late will be disqualified.
            </p>
            <p>
              2. Every player is supposed to carry a photo ID which also
              mentions his/her date of birth. Anyone who does not have a ID
              proof will not be allowed to take part in the Talent Hunt.
            </p>
            <p>
              3. Sports shoes are a must. Any player without appropriate
              footwear will not be allowed to complete their registration on the
              day.
            </p>
            <p>
              4. If you are a Batter or a Wicketkeeper, you are expected to
              carry your equipment and kit along with you. We will not be
              providing any equipment like bats or helmets. Balls will be
              provided by us at the venue.
            </p>
            <p>
              5. You are allowed to share equipments with other candidates at
              the venue provided the flow of the event is not hampered.
            </p>
            <p>
              6. Selection of students is solely at the discretion of the
              Coaches present at the Venue. If any candidate is seen misbehaving
              with the Coaches, Champhunt Team or the volunteers, they may be
              disqualified from the event.
            </p>
            <p>
              7. Date, Time and Venue of the Event may change due to
              unforecasted reasons like bad weather and it will be informed to
              the Users via Email. So gear up, champs ! Let’s make this Talent
              Hunt a day to remember!
            </p>
          </div>{" "}
        </Typography>
      </Box>
    </Container>
  );
};

export default RegistrationWelcome;
