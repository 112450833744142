import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import ExclamationIcon from "../../assets/images/179386.png"; // Replace with your tick icon path
import "../userCoupons/coupon.scss";
import { useNavigate } from "react-router-dom";

const Expirationmodel = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={onClose} className="success-modal">
      <DialogContent className="success-modal-content">
        <img src={ExclamationIcon} alt="Success" />
        <p>Subscription Expired!!</p>
        <p className="note">
          Your Champhunt-Plus Subscription has Expired. Please Renew Your
          Subscription to continue enjoying our special services.
        </p>
        <DialogActions className="btn-container">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/champhunt-plus")}
          >
            Go to Subscription Page
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default Expirationmodel;
