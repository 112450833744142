import React, { useEffect, useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import { SendHorizontal } from "lucide-react";
import { SocketContext } from "../../../context/SocketContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
};

const StickerModal = ({
  openStickerModal,
  handleCloseStickerModal,
  groupId,
  friendId,
  receiverId,
  setGroupMessage,
  setFriendMessage,
}) => {
  const [selectedURL, setSelectedURL] = useState(null);
  const [stickers, setStickers] = useState([]);
  const [loading, setLoading] = useState(false);
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [socket, setSocket] = useContext(SocketContext);

  const handleStickerClick = (stickerURL) => {
    setSelectedURL(stickerURL);
  };

  const getAllStickers = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllSticker`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setStickers(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  useEffect(() => {
    getAllStickers();
  }, []);

  const sendSticker = async () => {
    if (selectedURL !== null) {
      setLoading(true);
      try {
        let endpoint = "";
        let data = {};

        if (groupId) {
          endpoint = "/groupchat/sendMessage";
          data = {
            groupId,
            senderId: userId,
            messageType: "image",
            content: selectedURL,
          };
        } else if (friendId) {
          endpoint = "/groupchat/sendDirectMessage";
          data = {
            conversationId: friendId,
            senderId: userId,
            receiverId: receiverId,
            messageType: "image",
            content: selectedURL,
          };
        }

        const { data: responseData } = await axios.post(
          global.config.ROOTURL.prod + endpoint,
          data,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (groupId) {
          socket.emit("new message", responseData);
          // setGroupMessage((prevMessages) => [...prevMessages, responseData]);
        } else if (friendId) {
          socket.emit("new direct message", responseData);
          // setFriendMessage((prevMessages) => [...prevMessages, responseData]);
        }

        handleCloseStickerModal();
      } catch (error) {
        console.error("Error sending sticker:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={openStickerModal}
      onClose={handleCloseStickerModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0" }}>
          <p style={{ margin: 0 }}>Stickers</p>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2 }}>
          <Grid container spacing={2}>
            {stickers.map((sticker, index) => (
              <Grid item xs={4} key={index}>
                <img
                  src={sticker.stickerURL}
                  alt={`Sticker ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    border:
                      selectedURL === sticker.stickerURL
                        ? "3px solid #007bff"
                        : "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleStickerClick(sticker.stickerURL)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            p: 2,
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {loading ? (
            <p>Sending...</p>
          ) : (
            <SendHorizontal
              style={{ cursor: "pointer" }}
              onClick={sendSticker}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default StickerModal;
