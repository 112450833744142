import React from 'react';
import FollowersList from './FollowersList';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './index.scss'
const FollowerPage = ({ userId }) => {

  const navigate = useNavigate()

  const containerStyle = {
    backgroundColor: "#e5e5e5",
    minHeight: "100vh",
    paddingTop: "120px",
    overflow: "hidden", 
  };

  const rowStyle = {
    display: 'flex',
    margin: 'auto',
    paddingBottom: '100px',
    width: '100%', 
    alignItems: 'flex-start', 
    justifyContent: 'flex-start',
    overflow: 'hidden',
  };
  return (
    <div style={containerStyle}>
     <h3 style={{display:"flex", borderBottom: "1px solid #aaa", margin: "12px 30px 40px", padding: "0", fontSize: "20px", fontWeight: "500"}}><p style={{padding:'2px 6px', cursor:'pointer'}} onClick={()=>navigate(-1)}><ArrowBackIcon /></p>Followers Page</h3>
      <div style={rowStyle} className='infiniteScrollConatinerToChangeCustomCss'>
      <FollowersList userId={userId}/>
      </div>
    </div>
  );
}

export default FollowerPage;
