import React, { useRef, useEffect, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import {
  Favorite,
  ChatBubbleOutline,
  Share,
  MusicNote,
  ArrowBack,
  CloudUpload,
  BookmarkBorder,
  Bookmark,
} from "@mui/icons-material";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import runLogo from "../../assets/images/fiveRuns-image.svg";
import whiteball from "../../assets/images/icons/clickopennewball.png";
import VideoUploaderWithTrimmer from "./VideoUploaderWithTrimmer";
import ShareToAppsPopupPage from "./shareToOtherApps";
import "./index.scss";
import "./comment.scss";
import savedHighlightsicon from "../../assets/images/posts/savedpost-icon.png";
import unsavedHighkightsicon from "../../assets/images/posts/unsavedpost-icon.png";


const ReelItem = ({ reel, onComment, setCurrentReelIndex, commentCount}) => {
  const highLightsId = reel._id;
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [runCount, setRunCount] = useState(reel.highlightRunCount);
  const [show6RunsIcon, setShow6RunsIcon] = useState(false);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [url, setUrl] = useState("");
  const [shareToOtherApps, setShareToOtherApps] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isSaved, setIsSaved] = useState(false); // Track saved state

  const handleCloseShareToOtherAppsPopup = () => {
    setShareToOtherApps(false);
  };

  // Toggle video play/pause on click
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  // Set up the observer to play/pause based on visibility
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          setCurrentReelIndex();
          videoRef.current.play().catch((error) => console.error("Playback failed:", error));
        } else {
          setIsVisible(false);
          videoRef.current.pause();
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [setCurrentReelIndex]);

  
  useEffect(() => {
    if (isVisible) {
      setIsMuted(false); 
      videoRef.current?.play().catch((error) => console.error("Playback failed:", error));
    } else {
      setIsMuted(true);   
      videoRef.current?.pause();
    }
  }, [isVisible]);

  
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleShareToOtherAppsPopup = () => {
    let shareUrl = `${global.config.share_url}/highlight-preview/${highLightsId}`;
    setUrl(shareUrl);
    setShareToOtherApps(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleBack = () => {
    navigate("/pitch");
  };

  useEffect(() => {
    const fetchRunStatus = async () => {
      try {
        const response = await axios.get(
          `${global.config.ROOTURL.prod}/highlight/highlightRun/getRunByUserIdAndHighlightId/${userId}/${highLightsId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        if (response.data > 0) {
          setShow6RunsIcon(true);
        }
      } catch (error) {
        console.error("Error fetching run status:", error);
      }
    };

    fetchRunStatus();
  }, [userId, highLightsId, accessToken]);

  const handleSave = async () => {
    try {
      await axios.post(
        `${global.config.ROOTURL.prod}/highlight/highlights/saveHighlights`,
        { highlightId: highLightsId, userId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setIsSaved(true);
    } catch (error) {
      console.error("Error saving highlight:", error);
    }
  };

  const handleUnsave = async () => {
    try {
      await axios.delete(
        `${global.config.ROOTURL.prod}/highlight/highlights/unsaveHighlights/${userId}/${highLightsId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setIsSaved(false);
    } catch (error) {
      console.error("Error unsaving highlight:", error);
    }
  };

  useEffect(() => {
    const fetchSavedHighlights = async () => {
      try {
        const response = await axios.get(
          `${global.config.ROOTURL.prod}/highlight/highlights/checkSaveHighlights/${userId}/${highLightsId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsSaved(response.data.saved);
      } catch (error) {
        console.error("Error checking if highlight is saved:", error);
      }
    };
  
    fetchSavedHighlights();
  }, [userId, highLightsId, accessToken]);
  
  const handleRunsClick = () => {
    axios({
      method: "POST",
      url: `${global.config.ROOTURL.prod}/highlight/highlightRun/addHighlightRun`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        highlightId: highLightsId,
        profilephoto: reel.profilePhoto,
        username: reel.userName,
        userid: userId,
        totalrun: process.env.HIGHLIGHT_RUN,
      },
    })
      .then((response) => {
        setRunCount(response.data.updatedRunCount);
        setShow6RunsIcon(true);
      })
      .catch((error) => {
        console.error("Error adding highlight run:", error);
      });
  };

  const navigatetoprofile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  return (
    <div className="reel-item">
      <div className="reel-header">
        <IconButton className="back-button" onClick={handleBack} aria-label="back">
          <ArrowBack style={{ color: "black" }} />
        </IconButton>
        <IconButton className="upload-button" onClick={handleOpenUploadModal} aria-label="upload">
          <CloudUpload style={{ color: "black" }} />
        </IconButton>
      </div>
      <div className="video-container">
        <video
          ref={videoRef}
          src={reel.HighlightURL}
          loop
          playsInline
          muted={isMuted}
          onClick={togglePlayPause} 
        />
      </div>
      <div className="reel-overlay">
        <div className="reel-actions">
          <IconButton onClick={handleRunsClick}>
            {show6RunsIcon ? (
              <img src={runLogo} width={40} alt="run logo" style={{ backgroundColor: "white" }} />
            ) : (
              <img src={whiteball} width={25} alt="white ball icon" style={{ backgroundColor: "white" }} />
            )}
          </IconButton>
          <Typography style={{ color: "black" }}>{runCount}</Typography>
          <IconButton onClick={onComment} style={{ backgroundColor: "white" }}>
            <ChatBubbleOutline />
          </IconButton>
          {/* <Typography style={{color: "black"}}>
            {reel.comments?.length || 0}
            <p>{commentCount}</p>
          </Typography> */}
          <Typography style={{ color: "black" }}>
            {commentCount || 0}
          </Typography>

          <IconButton
            onClick={isSaved ? handleUnsave : handleSave}
            style={{ backgroundColor: "white" }}
          >
            <img
              src={isSaved ? savedHighlightsicon : unsavedHighkightsicon}
              alt={isSaved ? "Saved highlight icon" : "Unsaved highlight icon"}
              width={25}
            />
          </IconButton>

          <IconButton onClick={handleShareToOtherAppsPopup} style={{ backgroundColor: "white" }}>
            <Share />
          </IconButton>
          <Typography style={{ color: "black" }}>
              0
          </Typography>
        </div>
        <div className="reel-user-info">
          <img
            src={reel.profilePhoto}
            alt="user-profile"
            className="user-avatar"
          />
          <Typography
            variant="subtitle2"
            className="username"
            onClick={() => navigatetoprofile(reel.userId)}
          >
            {reel.userName}
          </Typography>
        </div>
        <div className="reel-description">
          <Typography variant="body2">{reel.highlightTitle}</Typography>
        </div>
        <div className="reel-audio">
          <MusicNote fontSize="small" />
          <Typography variant="caption">Original Audio</Typography>
        </div>
      </div>

      <VideoUploaderWithTrimmer
        open={isUploadModalOpen}
        handleClose={handleCloseUploadModal}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
      {shareToOtherApps && (
        <ShareToAppsPopupPage
          open={shareToOtherApps}
          url={url}
          handleClose={handleCloseShareToOtherAppsPopup}
        />
      )}
    </div>
  );
};

export default ReelItem;
