import React from "react";

const GroupItem = ({
  name,
  description,
  modifiedDate,
  unreadCount,
  groupProfile,
  onClick,
}) => {
  const dateOnly = new Date(modifiedDate).toLocaleDateString();

  return (
    <div className="friend-item" onClick={onClick} style={{ height: "100px" }}>
      <img src={groupProfile} alt={name} className="avatar" />
      <div className="content" style={{ width: "100px" }}>
        <div className="name-time">
          <span className="name">{name}</span>
          <span className="time">{dateOnly}</span>
        </div>
        <p className="message" style={{ wordWrap: "break-word" }}>
          {description}
        </p>
      </div>
      {unreadCount > 0 && <div className="unread-count">{unreadCount}</div>}
    </div>
  );
};

export default GroupItem;
