import React from 'react';
import { useMediaQuery } from '@mui/material';
// import commntpic from '../../assets/images/landigpage2/commentpic.png';
import testopic1 from "../../assets/images/usplandingpage/testopic1.png";
import testopic2 from "../../assets/images/usplandingpage/testopic2.png";
import testopic3 from "../../assets/images/usplandingpage/testopic3.png";
// import Carusel from "../Landingpage2/Carouselll";
import Carusel from "./Carusel";

const dummmy = [{
    name: 'Pranshu Pal',
    comment: 'I never thought winning a Smartwatch would be this easy. All it took was signing up on Champhunt.',
    img: testopic1,
}, {
    name: 'Manjeet Raj',
    comment: 'I have won a Fire-Boltt smart watch and many more rewards by signing up on champhunt and being active on the platform.',
    img: testopic2,
}, {
    name: 'Ansh Desai',
    comment: 'I have won a giveaway as well as multiple rewards from Champhunt. I am a regular on the website and constantly winning rewards on the matchday contests.',
    img: testopic3,
},]

const UspThirdBlock = () => {
  const mobileView = useMediaQuery("(max-width:600px)");

  return (
    <div style={{ height: 480 }} className="uspfooter_main">
    <div style={{ paddingTop: 37 }}>

        <div style={{ textAlign: 'center' }}>
            <p className='para36'>What Our Users say about us</p>
        </div>
        {
            mobileView 
            ?
            <Carusel />

        :
        
         <div className='flex2_usp' style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 40 }}>
        {dummmy.map((i,index) => (
                <div className='comment_usp' key={index}>
                <div style={{ paddingTop: 10, paddingLeft: 66, paddingRight: 66}}>
                        <img src={i.img} alt="" />
                    </div>
                    <div style={{ paddingTop: 10, paddingLeft: 66, paddingRight: 66}}>
                        <p className='font20ex_usp' > {i.name}</p>
                    </div>
                    <div style={{ paddingTop: 10, paddingLeft: 66, paddingRight: 66 }}>
                        <p>{i.comment}</p>
                    </div>
                </div>
                

                
            ))}
        
        </div> 
       
    }


    </div>
</div>
)
}
export default UspThirdBlock;


