import React, { useState, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";
import { Search, X } from "lucide-react";
import { Divider } from "@mui/material";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  height: "700px",
  display: "flex",
  flexDirection: "column",
};

const InviteUsers = ({ openInvite, handleCloseInviteModal, groupId }) => {
  const accessToken = getStorageItem("token");
  const [query, setQuery] = useState("");
  const [followers, setFollowers] = useState([]);
  const [filteredFollowers, setFilteredFollowers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const pageSize = 20;
  const pageNo = 1;
  const userId = getStorageItem("user_id");

  const getFollowers = useCallback(() => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: `${global.config.ROOTURL.prod}/auth/get-followers-user/${pageNo}/${pageSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setFollowers(data);
        setFilteredFollowers(data);
      })
      .catch((error) => {
        console.log("Error fetching followers:", error);
      });
  }, [accessToken, pageNo, pageSize]);

  useEffect(() => {
    getFollowers();
  }, [getFollowers]);

  const handleInputChange = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);

    const filtered = followers.filter(
      (follower) =>
        follower.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        follower.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFollowers(filtered);
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const inviteFriends = () => {
    if (!accessToken || selectedUsers.length === 0) return;

    const options = {
      method: "post",
      url: `${global.config.ROOTURL.prod}/groupchat/invitefriends`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        groupId,
        invitedUsers: selectedUsers,
        userId: userId,
      },
    };

    axios(options)
      .then(({ data }) => {
        handleCloseInviteModal();
      })
      .catch((error) => {
        console.log("Error inviting friends:", error);
      });
  };

  return (
    <Modal
      open={openInvite}
      onClose={handleCloseInviteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <p style={{ fontSize: "20px", margin: 0 }}>Invite Friends</p>
          <X onClick={handleCloseInviteModal} style={{ cursor: "pointer" }} />
        </div>

        <p style={{ color: "#8B8D97", marginBottom: "20px" }}>
          Search a user to invite to the group
        </p>

        <div
          style={{
            display: "flex",
            padding: "10px",
            border: "0.5px solid gray",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          <Search />
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            style={{
              outline: "none",
              border: "none",
              marginLeft: "10px",
              width: "100%",
            }}
            placeholder="Search followers..."
          />
        </div>

        <div style={{ flexGrow: 1, overflowY: "auto", marginBottom: "20px" }}>
          {filteredFollowers.length > 0 ? (
            filteredFollowers.map((follower) => (
              <React.Fragment key={follower._id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(follower._id)}
                    onChange={() => handleUserSelect(follower._id)}
                    style={{ width: "15px" }}
                  />
                  <img
                    src={
                      follower.profilePhoto ||
                      "https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                    }
                    width={48}
                    height={48}
                    style={{ borderRadius: "10px" }}
                    alt={`${follower.firstName}'s profile`}
                  />
                  <p style={{ color: "#45464E", margin: 0 }}>
                    {follower.firstName || "Unknown"} {follower.lastName}
                  </p>
                </div>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <p style={{ textAlign: "center", color: "#8B8D97" }}>
              No followers found
            </p>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={inviteFriends}
            disabled={selectedUsers.length === 0}
            style={{
              alignItems: "center",
              padding: "10px 20px",
              backgroundColor: selectedUsers.length > 0 ? "#2C9207" : "#cccccc",
              color: "white",
              border: "none",
              width: "230px",
              borderRadius: "5px",
              cursor: selectedUsers.length > 0 ? "pointer" : "not-allowed",
            }}
          >
            Invite Friends ({selectedUsers.length})
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default InviteUsers;
