
import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useScroller from "../../commons/useScroller_JSON_ARG";
import Event from "./index";
import { BallTriangle } from "react-loader-spinner";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateEvent = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [events, setEvents] = useState([]);

  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  // const redirectUser = (_id) => {
  //   // Check if the user is already registered

  //   // Redirects User
  //   navigate(`/event-register/${_id}`);
  // };

  // Fetching length of data
  useEffect(() => {
    // Fetching all events
    if (!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + "/events/getAll/1/20",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
        setTotalPageNumber(Math.ceil(data.length / 3));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  }, []);

  // Ferching Page Data
  useEffect(() => {
    if (accessToken) {
      const options = {
        method: "get",
        url: global.config.ROOTURL.prod + `/events/getAll/${pageNumber}/3`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios(options)
        .then(({ data }) => {
          console.log(data);
          setEvents([...data]);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error("Some error occured");
          }
        });
    }
  }, [pageNumber]);

  return (
    <div>
      <p style={{ fontSize: "2.125rem" }}>Update Events</p>
      <Grid
        className="pagination"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <Button variant="contained"
          // style={{backgroundColor:'#0c5ba0',color:'white'}} 
          onClick={getPreviousPage}>
          Prev
        </Button>
        <Typography variant="p">
          {pageNumber} of {totalPageNumber}
        </Typography>
        <Button variant="contained"
          // style={{backgroundColor:'#0c5ba0',color:'white'}} 
          onClick={getNextPage}>
          Next
        </Button>
      </Grid>
      <div className="card-wrapper">
        {events.map((event) => {
          return (
            <Card className="card-main"
              key={event._id}
              onClick={() => {
                navigate(`/admin/edit-event-admin/${event._id}`);
              }}>
              <CardMedia
                component="img"
                height="140px"
                image={event.eventBanner}
                alt={event.event_title}
              />
              <CardContent className="content">
                <Typography gutterBottom variant="h5" component="div">
                  {event.eventTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <div className="event-description"
                    dangerouslySetInnerHTML={{
                      __html: event.eventdescription.substring(0, 200) + "...",
                    }}
                  />
                </Typography>
                <hr style={{ marginTop: "10px", marginBottom: "10x" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <EventIcon />
                    </Box>
                    <Box>
                      <p>
                        {event.startDate} at {event.startTime}
                      </p>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                  </Box>
                  {/* <span>{event.endTime}</span> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <LocationOnIcon />
                    <p>{event.eventLocation}</p>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <ToastContainer />
    </div>
  );
};

export default UpdateEvent;
