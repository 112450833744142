import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// Mui Icons
import CheckIcon from "@mui/icons-material/Check";

import "./index.scss";

const RegistrationSuccessful = () => {

  const navigate = useNavigate();

  const { jersey_number } = useParams();
  // console.log(jersey_number);
  if (!jersey_number || jersey_number === "undefined") {
    navigate("/all-events");
  }

  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    email: "",
  });

  const fetchUserInfo = () => {
    if (!accessToken && !userId) return
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);
  const handleClick = () => {
    navigate(`/registration-welcome/${jersey_number}`);
  };
  return (
    <Box className="wrapper-sc">
      <Box className="main">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <img
            src="/android-chrome-192x192.png"
            alt="logo"
            height="50px"
            width="50px"
          />
          <Typography variant="p" className="title-heading">
            CHAMPHUNT
          </Typography>
        </Box>
        <Typography variant="p" className="title">
          Registration successful
        </Typography>
        <Typography variant="p" className="sub-title">
          Your Unique Jeresy Number
        </Typography>
        <Typography variant="h3" className="code" fontWeight={700}>
          {jersey_number}
        </Typography>
        <Typography variant="p" className="sub-title">
          An email with jersey number has been sent to you
        </Typography>
        <Box>
          <CheckIcon
            className="icon"
            style={{
              color: "#00298E",
              backgroundColor: "#EBEBEB",
              borderRadius: "50%",
            }}
          />
        </Box>
        <Button
          variant="contained"
          style={{
            marginTop: "20px",
            width: "130px",
            backgroundColor: "#0C5BA0",
          }}
          onClick={handleClick}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default RegistrationSuccessful;
