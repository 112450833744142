import React, { useState, useRef, useEffect } from "react";
import "./edit.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
// User Information
const userId = getStorageItem("user_id");
const userName = getStorageItem("full_name");
const userAvatar = getStorageItem("avatar");
const userEmail = getStorageItem("user_email");

// Access Token
const accessToken = getStorageItem("token");

const Index = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  // Product Form
  const titleRef = useRef();
  const priceRef = useRef();
  const stockRef = useRef();
  const discountRef = useRef();
  const [showEmail, setShowEmail] = useState(true);
  const [showPhone, setShowPhone] = useState(true);
  const [showWhatsapp, setShowWhatsapp] = useState(true);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const descriptionRef = useRef();
  const [files, setFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [imagesChanged, setImagesChanged] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const title = titleRef.current.value;
    const category = selectedOption;
    const price = priceRef.current.value;
    const description = descriptionRef.current.value;
    const stock = stockRef.current.value;
    const discount = discountRef.current.value;
    const email = userEmail;
    const name = userName;
    const profile = userAvatar;
    const createdBy = userId;
    let contactWhatsapp;
    let contactPhone;
    let contactEmail;
    if (phone) {
      contactPhone = phone;
    }
    if (whatsapp) {
      contactWhatsapp = whatsapp;
    }
    if (email) {
      contactEmail = email;
    } else {
      toast.error("Phone/ Whatsapp/ Email is required");
      return;
    }
    if (imagesChanged) {
      await uploadImages();
    } else {
      try {
        let data = {
          title: title,
          category: category,
          price: price,
          stock: stock,
          discount: discount,
          images: imageUrls,
          description: description,
          createdBy: createdBy,
          name: name,
          email: email,
          profile: profile,
          checkSellerRuns: 50, // Hard-Coded for now
        };
        if (contactPhone) {
          data.contactPhone = phone;
        }
        if (contactWhatsapp) {
          data.contactWhatsapp = whatsapp;
        }
        if (contactEmail) {
          data.contactEmail = email;
        }
        if (!accessToken) return
        const response = await fetch(
          global.config.ROOTURL.prod + `/marketplace/product/updateProductByProductId/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          setLoading(false);
          toast.success("Product updated successfully");
          setImages([]);
          setFiles([]);
          navigate("/marketplace");
        } else {
          throw new Error(`Failed to update object: ${response.statusText}`);
          toast.error("Some error occured");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Some error occured");
      }
    }
  };

  // Function to get product using id
  const fetchProduct = async () => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + `/marketplace/product/getSingleProductByProductId/${id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setProduct(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fillDetails = () => {
    titleRef.current.value = product?.title;
    priceRef.current.value = product?.price;
    stockRef.current.value = product?.stock;
    discountRef.current.value = product?.discount;
    if (product?.contactPhone) {
      setPhone(product?.contactPhone);
    }
    if (product?.contactWhatsapp) {
      setWhatsapp(product?.contactWhatsapp);
    }
    if (product?.contactEmail) {
      setEmail(product?.contactEmail);
    }
    descriptionRef.current.value = product?.description;
    setImages(product?.images);
    setSelectedOption(product?.category);
    setImageUrls(product?.images);
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  useEffect(() => {
    fillDetails();
  }, [product]);

  const handleFilesChange = (event) => {
    setImagesChanged(true);
    // setFiles([]);
    const selectedFiles = event.target.files;
    const tempImages = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = (event) => {
        tempImages.push(event.target.result);
        if (tempImages.length === selectedFiles.length) {
          setImages([...images, ...tempImages]);
          setFiles([...files, ...selectedFiles]);
          // setImageUrls([]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    if (imageUrls.length > 0) {
      imageUrls.pop();
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles.pop();
        return updatedFiles;
      });
    } else {
      setImagesChanged(true);
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages.pop();
        return updatedImages;
      });
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles.pop();
        return updatedFiles;
      });
    }
  };

  const [imageIsUploading, setImageIsUploading] = useState(false);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const response = await axios.post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    if (response.data) {
      console.log("Image saved successfully");
      return response.data[0].location;
    } else {
      console.log("error occured:");
      throw new Error(`Failed to upload image ${file}`);
    }
  };

  const uploadImages = async () => {
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i].size / 1024 > 5120) {
        toast.error(`File ${files[i]} size exceeded 5 mb`);
        setLoading(false);
        return;
      }
      promises.push(uploadImage(files[i]));
    }

    try {
      const urlArray = await Promise.all(promises);
      const urls = urlArray.concat(imageUrls);
      console.log("Images uploaded successfully");
      const title = titleRef.current.value;
      const category = selectedOption;
      const price = priceRef.current.value;
      const description = descriptionRef.current.value;
      const stock = stockRef.current.value;
      const discount = discountRef.current.value;
      const email = userEmail;
      const name = userName;
      const profile = userAvatar;
      const createdBy = userId;
      try {
        let data = {
          title: title,
          category: category,
          price: price,
          stock: stock,
          discount: discount,
          images: urls,
          description: description,
          createdBy: createdBy,
          name: name,
          email: email,
          profile: profile,
          checkSellerRuns: 50, // Hard-Coded for now
        };
        if (phone) {
          data.contactPhone = phone;
        }
        if (whatsapp) {
          data.contactWhatsapp = whatsapp;
        }
        if (email) {
          data.contactEmail = email;
        }
        const response = await fetch(
          global.config.ROOTURL.prod + `/marketplace/product/updateProductByProductId/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          setLoading(false);
          toast.success("Product updated successfully");
          setImages([]);
          setFiles([]);
          navigate("/marketplace");
        } else {
          throw new Error(`Failed to update object: ${response.statusText}`);
        }
      } catch (error) {
        // handle error
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setImageIsUploading(false);
    }
  };

  return (
    <div className="main__wrapper">
      <Helmet>
        <title>Champhunt | MarketPlace-Edit</title>
      </Helmet>
      {/* Banner */}
      <div className="main__banner">
        <div className="product__banner">
          <div className="symbols__wrapper">
            {images?.map((image, index) => {
              return (
                <div
                  key={index}
                  className={imageIndex === index ? "symbols-elongated" : "symbols"}
                  onClick={() => {
                    setImageIndex(index);
                  }}
                ></div>
              );
            })}
          </div>
          <div className="part-1">
            {images && images.length > 0
              ? images?.map((image, index) => {
                return (
                  <div
                    key={index}
                    className="box-1"
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img src={image} alt="product" />
                  </div>
                );
              })
              : [...Array(2)].map((image, index) => {
                return (
                  <div
                    key={index}
                    className="box-1"
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img
                      src="https://www.maxpixel.net/static/photo/1x/Add-Add-New-Icon-Add-Media-Icon-Add-New-New-2935429.png"
                      alt="product"
                    />
                  </div>
                );
              })}
          </div>
          <div className="part-2">
            <img
              src={
                images && images[imageIndex]
                  ? images && images[imageIndex]
                  : "https://www.maxpixel.net/static/photo/1x/Add-Add-New-Icon-Add-Media-Icon-Add-New-New-2935429.png"
              }
              alt="product"
            />
          </div>
          <div className="add-remove__files">
            <label htmlFor="files">
              <img src="/marketplace/camera.svg" alt="add" className="add-remove__icons" />
              <input
                type="file"
                name="files"
                id="files"
                multiple
                accept="image/*"
                className="add__images"
                onChange={handleFilesChange}
              />
            </label>
            <img
              src="/marketplace/remove.svg"
              alt="add"
              className="add-remove__icons"
              onClick={() => {
                handleRemoveImage();
              }}
            />
          </div>
        </div>
      </div>
      {/* Form */}
      <div className="main__form">
        <div className="form__container">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" className="form__input" ref={titleRef} required />
        </div>
        {product && (
          <div className="form__container">
            <label htmlFor="category">Category</label>
            <select
              name="category"
              id="category"
              defaultValue={product?.category}
              onChange={handleSelectChange}
              required
            >
              <option value="Sporting Goods">Sporting Goods</option>
              <option value="Merchandise">Merchandise</option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Health Supplements">Health Supplements</option>
              <option value="Lifestyles">Lifestyles</option>
              <option value="Miscellaneous">Miscellaneous</option>
            </select>
          </div>
        )}
        <div className="form__container">
          <label htmlFor="description">Description</label>
          <input type="text" id="description" className="form__input" ref={descriptionRef} required />
        </div>
        <div className="form__container--half">
          <div className="form__half">
            <label htmlFor="price">Price</label>
            <input type="number" id="price" className="form__input" ref={priceRef} required />
          </div>
          <div className="form-half">
            <label htmlFor="discount">Discount</label>
            <input type="number" id="discount" className="form__input" ref={discountRef} required />
          </div>
        </div>
        <div className="form__container">
          <label htmlFor="stock">Stock</label>
          <input type="number" id="stock" className="form__input" ref={stockRef} required />
        </div>
        {showPhone ? (
          <div className="form__container">
            <label htmlFor="phone">Phone</label>
            <input
              type="number"
              id="phone"
              className="form__input"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              pattern="^\d{10}$"
              required
            />
          </div>
        ) : (
          ""
        )}
        {showWhatsapp ? (
          <div className="form__container">
            <label htmlFor="phone">Whatsapp</label>
            <input
              type="number"
              id="whatsapp"
              className="form__input"
              value={whatsapp}
              onChange={(e) => {
                setWhatsapp(e.target.value);
              }}
              pattern="^\d{10}$"
              required
            />
          </div>
        ) : (
          ""
        )}
        {showEmail ? (
          <div className="form__container">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              className="form__input"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required
            />
          </div>
        ) : (
          ""
        )}
        <div className="form__container">
          {loading ? (
            <button className="sell__button--loading">Submitting</button>
          ) : (
            <button className="sell__button" onClick={handleSubmitForm}>
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
