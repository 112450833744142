import Header from "../../components/header";
import { useEffect, useState, useRef, useCallback, useContext } from "react";
import Followings from "../../components/followers/followers";
import { Grid } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CommentUseScroller from "./CommentUseScroller";
import { BallTriangle } from "react-loader-spinner";
import "./index.scss";
import axios from "axios";
import AppContext from "../../context/AppContext";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import CommentBox from "../comments/CommentBox";
// import useScroller from "../../commons/useScroller";


const Commentpagination = (props) => {
  const { id, type, setIsNestedComment, setNestedCommentData, setReplyUser, setComments,
    commentCreated, nId, setRecentNestedComment, setNestedCommentCreated, recentNestedComment } = props
  const navigate = useNavigate()
  const userId = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const [suggestions, setSuggestions] = useState([]);
  const [followings, setFollowings] = useState([]);

  const [query, setQuery] = useState("");
  const [pageNum, setPageNum] = useState(1);

  let req = {
    method: "GET",
    url:
      global.config.ROOTURL.prod +
      `/pitch/comment/getByPitchId/${id}/${pageNum}/${global.config.pichPagePerSize}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  if (type === 'shareJob') {
    req = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/job/sharejob/comment/getByShareJobId/${id}/${pageNum}/${global.config.pichPagePerSize}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
  }

  const { isLoading, error, list, hasMore } = CommentUseScroller(pageNum, req, id);

  // const { isLoading, error, list, hasMore } = useScroller(query, pageNum, req);
  // useEffect(() => {
  //   setPageNum(1); // reset pageNum to 1 when numComments changes
  // }, [newcomment]);
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  // useEffect(()=>{
  // console.log("observer.current.forceUpdate",observer.current)},[newcomment])
  // useEffect(()=>{},[])
  return (

    <div className="comment__pagination__Section">

      {
        error
          ?
          <div style={{ color: "red" }}>{error && "Error"}</div>
          :
          <>

            {list.map((comment, index) => {
              if (list.length === index + 1) {
                return (
                  <Grid
                    ref={lastElementRef}
                    key={index}
                    className="comment-wrapper"
                    item

                  >
                    {
                      <CommentBox key={index} comment={comment} setComments={setComments} type={type}
                        recentNestedComment={recentNestedComment} setRecentNestedComment={setRecentNestedComment}
                        // setReplyUser={setReplyUser}
                        setIsNestedComment={setIsNestedComment} setNestedCommentCreated={setNestedCommentCreated} nId={nId} setNestedCommentData={setNestedCommentData} commentCreated={commentCreated} />
                    }
                  </Grid>
                );
              } else {
                return (
                  <Grid item key={index} className="comment-wrapper "  >
                    {
                      <CommentBox key={index} comment={comment} type={type} setNestedCommentCreated={setNestedCommentCreated}
                        recentNestedComment={recentNestedComment} setRecentNestedComment={setRecentNestedComment}
                        // setComments={setComments}  setReplyUser={setReplyUser}
                        setIsNestedComment={setIsNestedComment} nId={nId} setNestedCommentData={setNestedCommentData} commentCreated={commentCreated} />
                    }
                  </Grid>
                );
              }
            })}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "40px",
              }}
            >
              {isLoading && (
                <BallTriangle
                  height="100"
                  width="100"
                  color="grey"
                  ariaLabel="loading"
                />
              )}
            </div>
          </>
      }
    </div>
  );
};
export default Commentpagination;