import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Slider,
} from "@mui/material";
import { getStorageItem } from "../../utils/sessionStorage";
import "./videotrimmer.scss";

const ffmpeg = new FFmpeg();
const MAX_DURATION = 15; // 15 seconds

const VideoUploaderWithTrimmer = ({
  open,
  handleClose,
  setIsSuccessModalOpen,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [duration, setDuration] = useState(0);
  const [trimRange, setTrimRange] = useState([0, MAX_DURATION]);
  const [currentTime, setCurrentTime] = useState(0);
  const [thumbnails, setThumbnails] = useState([]);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);

  const videoRef = useRef(null);
  const timelineRef = useRef(null);

  useEffect(() => {
    loadFfmpeg();
  }, []);

  const loadFfmpeg = async () => {
    await ffmpeg.load();
    setFfmpegLoaded(true);
  };

  useEffect(() => {
    if (videoFile && ffmpegLoaded) {
      const videoElement = videoRef.current;
      videoElement.src = URL.createObjectURL(videoFile);
      videoElement.onloadedmetadata = () => {
        setDuration(videoElement.duration);
        setTrimRange([0, Math.min(MAX_DURATION, videoElement.duration)]);
        generateThumbnails(videoElement);
      };
    }
  }, [videoFile, ffmpegLoaded]);

  const generateThumbnails = async (videoElement) => {
    const thumbnailCount = 10;
    const interval = videoElement.duration / thumbnailCount;
    const newThumbnails = [];

    for (let i = 0; i < thumbnailCount; i++) {
      const time = i * interval;
      const thumbnail = await extractThumbnail(videoElement, time);
      newThumbnails.push({ time, image: thumbnail });
    }

    setThumbnails(newThumbnails);
  };

  const extractThumbnail = (video, time) => {
    return new Promise((resolve) => {
      video.currentTime = time;
      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = 80;
        canvas.height = 45;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      };
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setVideoFile(file);
      setError("");
    } else {
      setError("Please select a valid video file.");
    }
  };

  const handleSliderChange = (event, newValue) => {
    if (newValue[1] - newValue[0] <= MAX_DURATION) {
      setTrimRange(newValue);
    } else {
      const difference = newValue[1] - newValue[0] - MAX_DURATION;
      if (newValue[1] !== trimRange[1]) {
        setTrimRange([newValue[1] - MAX_DURATION, newValue[1]]);
      } else {
        setTrimRange([newValue[0], newValue[0] + MAX_DURATION]);
      }
    }
    if (videoRef.current) {
      videoRef.current.currentTime = newValue[0];
    }
  };

  const handleTimelineClick = (e) => {
    const { left, width } = timelineRef.current.getBoundingClientRect();
    const position = (e.clientX - left) / width;
    const newTime = position * duration;
    setCurrentTime(newTime);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time % 1) * 100);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}.${milliseconds.toString().padStart(2, "0")}`;
  };

  const trimVideo = async (inputFile, start, end) => {
    const inputFileName = "input.mp4";
    const outputFileName = "output.mp4";

    await ffmpeg.writeFile(inputFileName, await fetchFile(inputFile));

    await ffmpeg.exec([
      "-i",
      inputFileName,
      "-ss",
      `${start}`,
      "-to",
      `${end}`,
      "-c",
      "copy",
      outputFileName,
    ]);

    const data = await ffmpeg.readFile(outputFileName);
    const trimmedBlob = new Blob([data.buffer], { type: "video/mp4" });
    return new File([trimmedBlob], "trimmed_video.mp4", { type: "video/mp4" });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");

    if (!videoFile) {
      setError("Please select a video file to upload.");
      return;
    }

    if (trimRange[1] - trimRange[0] > MAX_DURATION) {
      setError(
        "The selected portion of the video exceeds 15 seconds. Please adjust the trim range."
      );
      return;
    }

    try {
      setIsLoading(true);
      setError("");

      const trimmedVideo = await trimVideo(
        videoFile,
        trimRange[0],
        trimRange[1]
      );

      const formData = new FormData();
      formData.append("uploader", trimmedVideo);

      const uploadResponse = await axios.post(
        `${global.config.ROOTURL.prod}/upload-file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.data && uploadResponse.data.length > 0) {
        const videoUrl = uploadResponse.data[0].location;

        const data = {
          userId,
          highlightTitle: title,
          description,
          HighlightURL: videoUrl,
        };

        const config = {
          method: "POST",
          url: `${global.config.ROOTURL.prod}/highlight/highlights/createHighlight`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data),
        };

        const res = await axios(config);
        console.log("Highlight created successfully:", res.data);
        handleClose();
        setIsSuccessModalOpen(true);
      } else {
        setError("Error occurred: No data returned from upload.");
      }
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      console.error("Error creating highlight:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal className="video-uploader-modal" open={open} onClose={handleClose}>
      <Box className="modal-content">
        <Typography variant="h5" className="modal-title">
          Upload and Trim Highlight (Select any 15 seconds)
        </Typography>
        <form onSubmit={handleUpload} className="upload-form">
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={3}
            fullWidth
          />
          <div className="file-input-wrapper">
            <label htmlFor="file-input" className="file-input-button">
              Choose Video File
            </label>
            <input
              id="file-input"
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              className="file-input"
            />
          </div>
          {videoFile && (
            <Typography className="file-name-display">
              Selected: {videoFile.name}
            </Typography>
          )}
          {videoFile && ffmpegLoaded && (
            <div className="video-trimmer">
              <video ref={videoRef} controls className="video-player">
                Your browser does not support the video tag.
              </video>
              <div
                ref={timelineRef}
                className="timeline"
                onClick={handleTimelineClick}
              >
                <div className="thumbnails">
                  {thumbnails.map((thumbnail, index) => (
                    <img
                      key={index}
                      src={thumbnail.image}
                      alt={`Thumbnail ${index}`}
                      className="thumbnail"
                    />
                  ))}
                </div>
                <div
                  className="trim-indicator"
                  style={{
                    left: `${(trimRange[0] / duration) * 100}%`,
                    width: `${
                      ((trimRange[1] - trimRange[0]) / duration) * 100
                    }%`,
                  }}
                />
                <div
                  className="current-time-indicator"
                  style={{
                    left: `${(currentTime / duration) * 100}%`,
                  }}
                />
              </div>
              <Slider
                value={trimRange}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTime}
                min={0}
                max={duration}
                step={0.01}
              />
              <div className="time-display">
                <Typography>Start: {formatTime(trimRange[0])}</Typography>
                <Typography>End: {formatTime(trimRange[1])}</Typography>
                <Typography>
                  Duration: {formatTime(trimRange[1] - trimRange[0])}
                </Typography>
              </div>
            </div>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || !videoFile || !ffmpegLoaded}
            fullWidth
            className="submit-button"
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Upload and Create Highlight"
            )}
          </Button>
        </form>
        {error && <Typography className="error-message">{error}</Typography>}
      </Box>
    </Modal>
  );
};

export default VideoUploaderWithTrimmer;