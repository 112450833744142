import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PaginationRounded from "../PaginationRounded";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = () => {
  const [name, setName] = useState("");
  const startDayRef = useRef();
  const startTimeRef = useRef();
  const runsForEntryRef = useRef();
  const endDayRef = useRef();
  const endTimeRef = useRef();
  const rewardPriceRef = useRef();
  // const question1Ref = useRef();
  // const option1Ref = useRef();
  // const option2Ref = useRef();
  // const option3Ref = useRef();
  // const option4Ref = useRef();
  // const option5Ref = useRef();
  // const option6Ref = useRef();
  // const [showOption5, setShowOption5] = useState(false);
  // const [showOption6, setShowOption6] = useState(false);
  const handlePredictionCreation = async () => {
    if (!accessToken) return
    const nameValue = name;
    const startDayRefValue = startDayRef?.current?.value;
    const startTimeRefValue = startTimeRef?.current?.value;
    const runsForEntryRefValue = runsForEntryRef?.current?.value;
    const endDayRefValue = endDayRef?.current?.value;
    const endTimeRefValue = endTimeRef?.current?.value;
    const rewardPriceRefValue = rewardPriceRef?.current?.value;
    // const question1RefValue = question1Ref?.current?.value;
    // const option1RefValue = option1Ref?.current?.value;
    // const option2RefValue = option2Ref?.current?.value;
    // const option3RefValue = option3Ref?.current?.value;
    // const option4RefValue = option4Ref?.current?.value;
    // let option5RefValue;
    // let option6RefValue;
    // if (showOption5) {
    //   option5RefValue = option5Ref?.current?.value;
    // }
    // if (showOption6) {
    //   option6RefValue = option6Ref?.current?.value;
    // }
    // if (!question1RefValue) {
    //   toast.error("Question field is required");
    //   return;
    // }
    // if (
    //   !option1RefValue ||
    //   !option2RefValue ||
    //   !option3RefValue ||
    //   !option4RefValue
    // ) {
    //   toast.error("Atleast 4 options are needed");
    //   return;
    // }
    if (
      !startDayRefValue ||
      !startTimeRefValue ||
      !endDayRefValue ||
      !endTimeRefValue
    ) {
      toast.error("Unfilled required fields");
      return;
    }
    const data = {
      predictionName: name,
      rewardRun: rewardPriceRefValue,
      startTime: startTimeRefValue,
      startDate: moment(startDayRefValue).format("YYYY-MM-DD"),
      endTime: endTimeRefValue,
      redeemRun: runsForEntryRefValue,
      endDate: moment(endDayRefValue).format("YYYY-MM-DD"),
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/prediction/createPrediction",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data,
    };
    axios(options)
      .then(({ data }) => {

        if (data._id) {

          setName("");
          startDayRef.current.value = null;
          startTimeRef.current.value = null;
          runsForEntryRef.current.value = null;
          endDayRef.current.value = null;
          endTimeRef.current.value = null;
          rewardPriceRef.current.value = null;
          toast.success("New Prediction created successfully");

        }


        // const predictionQuestionOptions = {
        //   method: "POST",
        //   url:
        //     global.config.ROOTURL.prod +
        //     "/contest/predictionQuestion/createPredictionQuestion",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     Authorization: "Bearer " + accessToken,
        //     role: "Admin",
        //   },
        //   data: {
        //     predictionId: data._id,
        //     question: question1RefValue,
        //     options: [
        //       option1RefValue,
        //       option2RefValue,
        //       option3RefValue,
        //       option4RefValue,
        //       showOption5 && option5RefValue,
        //       showOption6 && option6RefValue,
        //     ],
        //   },
        // };
        // axios(predictionQuestionOptions)
        //   .then((data) => {
        //     toast.success("New Prediction created successfully");
        //     setName("");
        //     startDayRef.current.value = "";
        //     startTimeRef.current.value = "";
        //     runsForEntryRef.current.value = "";
        //     endDayRef.current.value = "";
        //     endTimeRef.current.value = "";
        //     rewardPriceRef.current.value = "";
        //     question1Ref.current.value = "";
        //     option1Ref.current.value = "";
        //     option2Ref.current.value = "";
        //     option3Ref.current.value = "";
        //     option4Ref.current.value = "";
        //     option5Ref.current.value = "";
        //     option6Ref.current.value = "";
        //   })
        //   .catch((error) => {
        //     if (error?.response?.status === 401) {
        //       console.log(error);
        //     }
        //   });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    if (name.length > 17) {
      const updatedNameValue = name.slice(0, 17);
      setName(updatedNameValue);
    }
  }, [name]);
  return (
    <div className="contest-admin-create">
      <div className="contest-admin-create-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-header">
            <p className="contest-admin-create-wrapper-header--text">Name</p>
            <p className="contest-admin-create-wrapper-header--delete-text">
              Delete
            </p>
          </div>
          <div className="contest-admin-create-wrapper-form">
            <div className="contest-create-name-wrapper">
              <input
                type="text"
                className="contest-create-name"
                placeholder="Prediction 1"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                required
              />
              <p className="contest-create-name-extra">Max 17 letters</p>
              <p className="contest-create-name-count-indicator">
                {name.length < 17 ? 17 - name.length : 0}
              </p>
            </div>
            <div className="contest-create-row2-wrapper">
              <div className="contest-create-row2-block1">
                <p className="admin-contest-creation-general-text">Start</p>
                <input
                  type="date"
                  className="contest-create-start-date"
                  ref={startDayRef}
                  required
                />
                <input
                  type="time"
                  className="contest-create-start-time"
                  ref={startTimeRef}
                  required
                />
              </div>
              <div className="contest-create-row2-block2">
                <p className="admin-contest-creation-general-text">
                  Runs for Entry
                </p>
                <input
                  type="text"
                  className="contest-create-start-input"
                  ref={runsForEntryRef}
                  required
                />
              </div>
            </div>
            <div className="contest-create-row3-wrapper">
              <div className="contest-create-row3-block1">
                <p className="admin-contest-creation-general-text">End</p>
                <input
                  type="date"
                  className="contest-create-start-date"
                  ref={endDayRef}
                  required
                />
                <input
                  type="time"
                  className="contest-create-start-time"
                  ref={endTimeRef}
                  required
                />
              </div>
              <div className="contest-create-row3-block2">
                <p className="admin-contest-creation-general-text">Reward</p>
                <input
                  type="text"
                  className="contest-create-start-input"
                  ref={rewardPriceRef}
                  required
                />
              </div>
            </div>
            {/* <div className="contest-create-row4-wrapper">
              <p className="admin-contest-creation-general-text">Q1</p>
              <input
                type="text"
                className="contest-create-start-input-full"
                ref={question1Ref}
                required
              />
            </div>
            <div className="contest-create-row5-wrapper">
              <p className="admin-contest-creation-general-text">A1</p>
              <div className="contest-create-radio-button-wrapper">
                <input
                  type="text"
                  className="contest-create-start-input-options"
                  placeholder="Option-1"
                  id="option-1"
                  ref={option1Ref}
                />
              </div>
            </div>
            <div className="contest-create-row5-wrapper">
              <p className="admin-contest-creation-general-text">A2</p>
              <div className="contest-create-radio-button-wrapper">
                <input
                  type="text"
                  className="contest-create-start-input-options"
                  placeholder="Option-1"
                  id="option-2"
                  ref={option2Ref}
                />
              </div>
            </div>
            <div className="contest-create-row5-wrapper">
              <p className="admin-contest-creation-general-text">A3</p>
              <div className="contest-create-radio-button-wrapper">
                <input
                  type="text"
                  className="contest-create-start-input-options"
                  placeholder="Option-3"
                  id="option-3"
                  ref={option3Ref}
                />
              </div>
            </div>
            <div className="contest-create-row5-wrapper">
              <p className="admin-contest-creation-general-text">A4</p>
              <div className="contest-create-radio-button-wrapper">
                <input
                  type="text"
                  className="contest-create-start-input-options"
                  placeholder="Option-4"
                  id="option-4"
                  ref={option4Ref}
                />
              </div>
            </div>
            {showOption5 && (
              <div className="contest-create-row5-wrapper">
                <p className="admin-contest-creation-general-text">A5</p>
                <div className="contest-create-radio-button-wrapper">
                  <input
                    type="text"
                    className="contest-create-start-input-options"
                    placeholder="Option-5"
                    id="option-5"
                    ref={option5Ref}
                    required
                  />
                </div>
              </div>
            )}
            {showOption6 && (
              <div className="contest-create-row5-wrapper">
                <p className="admin-contest-creation-general-text">A6</p>
                <div className="contest-create-radio-button-wrapper">
                  <input
                    type="text"
                    className="contest-create-start-input-options"
                    placeholder="Option-6"
                    id="option-6"
                    ref={option6Ref}
                    required
                  />
                </div>
              </div>
            )}
            <div className="contest-create-add-more">
              <div className="contest-create-add-more--main">
                <AddCircleOutlineIcon
                  className="contest-create-add-more-icons"
                  onClick={() => {
                    if (!showOption5) {
                      setShowOption5(true);
                    } else if (showOption5 && !showOption6) {
                      setShowOption6(true);
                    } else if (showOption5 && showOption6) {
                      toast.error("Max limit is 6 options per prediction");
                    } else {
                      return;
                    }
                  }}
                />
                <RemoveCircleOutlineIcon
                  className="contest-create-add-more-icons"
                  onClick={() => {
                    if (showOption6) {
                      setShowOption6(false);
                    } else if (!showOption6 && showOption5) {
                      setShowOption5(false);
                    } else if (!showOption5 && !showOption6) {
                      return;
                    } else {
                      return;
                    }
                  }}
                />
              </div>
            </div> */}
            <button
              onClick={handlePredictionCreation}
              className="contest-preediction-admin-creation-submit-button"
            >
              Confirm
            </button>
            {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
