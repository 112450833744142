import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import cancel from "../../assets/images/home/cancel.png"
import blockconfirm from "../../assets/images/home/okBlock.png"
import { getStorageItem } from '../../utils/sessionStorage';
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '11px'
};

export default function Reportpopup({ open, handlereport, userId, userName, blockAccount }) {
    const [report, setreport] = useState(false);
    const accessToken = getStorageItem("token");
    const handleClose = () => {
        handlereport()
    }
    // console.log('userdid'.userId)
    const reportuser = (id) => {

        if (userId && !open && !accessToken) return;
        const getList = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/block/report-user",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                reportedUserId: userId,
                reportedReason: open
            }
        };
        axios(getList)
            .then((res) => {
                setreport(true);
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const handleReport = () => {
        // in open there are a report reason 

        reportuser()
    }
    const handleblockAccount = () => {
        blockAccount()
    }

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={report ? "Popup_profile3" : 'Popup_profile2'}>
                    <div style={{ textAlign: "end" }}>
                        <img src={cancel} className="profile_cancel" onClick={handleClose} />
                    </div>


                    {
                        !report ?
                            <>
                                <p className='profile_block-Message'>Are you sure you want to Report {userName}?</p>
                                <div className='profile_Popupu_button'>

                                    <Button variant="contained" className='profile_popup_block' onClick={handleReport}>Report</Button>
                                    <Button variant="text" className='profile_popup_cancel' onClick={handleClose}>Cancel</Button>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={blockconfirm} className='profile-ok' /></div>
                                <div className='profile_done_message'>
                                    <p>Your report has been noted. Our team will investgate and take actions soon </p>
                                </div>
                                <p className="Report_para_block">Do you want to block this user as well?</p>
                                <p onClick={handleblockAccount} className="Report_block">Yes, Block this Account</p>
                            </>
                    }


                </Box>
            </Modal>
        </div>
    );
}