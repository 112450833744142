import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import money from '../../assets/images/Events/money1.png'
import Bat from '../../assets/images/Events/bat.png'
import Ball from '../../assets/images/Events/ball.png'
import pad from '../../assets/images/Events/Padd.png'
export default function Secound() {
    return (
        <Box sx={{ backgroundColor: { xs: 'none', md: '#FFFFFF' } }}>
            <Box className='paddingHeader2' >
                <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                    <Grid item xs={12} md={6} className="headerLet2">
                        <Box sx={{ paddingTop: { xs: 0, md: '91px' } }}>
                            <div style={{ display: 'flex' }}>
                                <p className='MoneyHead'>Your chance to shine!!</p>
                                {/* <img src={Bat} className="bat" />
                                <img src={Ball} className='Ball' /> */}
                            </div>
                            <div>

                                <p className='MoneyHead2'>This <span style={{fontWeight:'700'}}>
                                    India vs New Zealand  </span> series, win rewards every matchday by taking part in our contests. Win upto <span style={{fontWeight:'700'}}>&#8377; 5000</span> in rewards!</p>
                            </div>
                            {/* <div style={{ textAlign: 'center', marginTop: '-48px', marginRight: '-111px' }}>
                                <img src={pad} className="pad1" />
                                <img src={pad} className="pad2" />
                            </div> */}
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} className='signupcontainer2' >
                        <div><img src={money} className="MoneyHead3" /> </div>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    )
}
