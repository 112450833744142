import React from "react";
import {Modal, Button } from "@mui/material";
import ChallengeAnimation from "../../assets/images/rewards/ChallengeAnimation.json";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import "./NoChallenge.scss";
const NoChallengeDataModel = ({ open, onClose }) => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/rewards"); //Navigate to rewards page
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="success-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="main-box">
        <div className="animation">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: ChallengeAnimation,
            }}
            width="300px"
            height="200px"
            marginTop="55px"
          />
        </div>
        <div className="box-1">
          <div className="Heading">
            <h2>COMING SOON</h2>
          </div>
          <div className="Text">
            <p>
              No challenges are available right now.
              <span> Stay tuned for new ones!</span>
            </p>
          </div>
        </div>
        <Button className="BTN" variant="contained" onClick={handleBackClick}>
          Back
        </Button>
      </div>
    </Modal>
  );
};

export default NoChallengeDataModel;
