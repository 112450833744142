//import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ChampButton from "../../commons/form/button";
import ReactTimeAgo from "react-time-ago";
import runs from "../../assets/images/icons/newBall3.png";
import emptyreshare from "../../assets/images/icons/mdi_repost.png";
import newshare from "../../assets/images/icons/majesticons_share.png";
import newcomment from "../../assets/images/icons/iconamoon_comment-light.png";
import newreshare from "../../assets/images/icons/newreshare.png";
import DropdownMenu from "./../dropdown-menu/dropdownMenu";
import "./index.scss";
import { Button } from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import GuestLoginPopup from "../../components/preview/Popup";


const Post = () => {
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  const [posts, setPosts] = useState([]);

  const [readMore, setReadMore] = useState(false);
  const [count, setCount] = useState(0);
  const [description, setDescription] = useState("");
  const [sharedDescription, setSharedDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [sharedShortDescription, setSharedShortDescription] = useState("");
  const [sharedTextReadMore, setSharedTextReadMore] = useState(false);
  const getGuestPost = async () => {
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getPostWithoutAuth",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setPosts(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getGuestPost();
  }, []);
  return (
    <>
    <div className="posts">
      {posts.map((item,index)=>(
        <div className="post" key={index}>

        <div className="post-header" onClick={handleOpen}>
          <div className="leftPost">
            <div className="avatar" >
              <img
                className="avatar-image"
                src={item.userProfilePhoto}
                alt={""}
              />
            </div>
            <div className="avatar-cnt">
              <p className="shared-link" style={{
                textDecoration: "none",
                color: "#0C5BA0",
                fontFamily: "Inter",
                fontWeight: 600,
                lineHeight: "22px",
              }}>

               {item.userName }
              </p>
              <p className="date-time">

                
                <br />
              </p>
            </div>
          </div>
          <div className="right_post">

            <Button
              variant="contained"
              className="Pitch_follow_button"

            >
              + Follow
            </Button>

            <div className="post-edit">

              <DropdownMenu
                type="post-menu"

              />
            </div>
          </div>
        </div>
        <div className="post-content shared-border" onClick={handleOpen}
        style={{
          margin: `${item?.postMessage? "" : 0}`,
          padding: `${item?.postMessage? "" : 0}`,
        }}>
         {/* {item.postMessage.length < 200 ? ( */}
            <div
              dangerouslySetInnerHTML={{
                __html: item.postMessage,
              }}
            />
          {/*) : !sharedTextReadMore ? (
            <div>
              {parse(sharedShortDescription)}
              <span
                className="read-more"
                onClick={() => {
                  setSharedTextReadMore(!sharedTextReadMore);
                }}
              >
                &nbsp; &nbsp; read more
              </span>
              ...{" "}
            </div>
          ) : (
            <div>
              {parse(sharedDescription)}
              <span
                className="read-more"
                onClick={() => {
                  setSharedTextReadMore(!sharedTextReadMore);
                }}
              >
                &nbsp; &nbsp; show less
              </span>
            </div>
          )} */}
          <img
            className="post-image"
            src={item.postImageURLs}
            alt=""
          />
        </div>
      

        <div className="runs-comment-container" onClick={handleOpen}>
          <div style={{ display: "flex", alignItems: "center" }}>

            <div className="post-header border post__width">
              <div className="runs-hld ">
                <div className="displayFlex run_icon_post">
                  <div>

                    <div className="">
                      <div
                        style={{
                          margin: "auto",
                          display: "flex",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={runs}
                          style={{

                            padding: "0px",
                            paddingRight: "3px",
                          }}
                          alt="img"
                          className="post_icon_image"

                        />
                      </div>
                    </div>

                    <div className="">
                      <div
                        style={{
                          margin: "auto",
                          display: "flex",
                          textAlign: "center",
                        }}
                      >

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <p className="post_count hover_css" >
              <span className="post__totalrunscount" style={{ color: "448bca" }}>
                {" "}
                {item.postRunCount} Runs
              </span>
            </p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="comments-stat post_count"
              style={{ marginRight: "20px" }}
            >
              <span
                style={{ color: "#448bca", cursor: "pointer" }}
              >
                {item.postCommentCount} Comments{" "}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", gap: "4px" }} >
              <div className="share-btn-container">

              </div>
              <div style={{ color: "#448bca", cursor: "pointer", fontWeight: "500" }}>{item.postReportCount} Repost</div>


            </div>
          </div>
        </div>
        <div className="post-footer post-header border post__width" onClick={handleOpen}>
          <div className="runs-hld ">
            <div className="displayFlex run_icon_post">
              <div>


                <div className="">
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={runs}
                      style={{

                        padding: "0px",
                        paddingRight: "3px",
                      }}
                      alt="img"
                      className="post_icon_image"

                    />
                  </div>
                </div>

                <div className="">
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >

                  </div>
                </div>

              </div>
            </div>
            <div className="icon_holder_style">Runs</div>
          </div>


          <div className="comments-hld  " >
            <div className="displayFlex">
              <img
                src={newcomment}
                className="icon-btn post_icon_image"
                alt=""
                role="button"
              />
              <span className="comment-count">  </span>

            </div>
            <div className="icon_holder_style">Comments</div>
          </div>


          <div className="share-hld">
            <div className="share-btn-container">


              <img
                src={emptyreshare}
                className="icon-btn post_icon_image"
                alt=""
                role="button"

              />


              {" "}
              <span className="post__share_count">  </span>
            </div>
            <div className="icon_holder_style">Repost</div>
            <div className="share-modal ">
              <div className="share-modal__container">
                <div className="share-modal__container__form">
                  <div className="input-textarea">

                  </div>
                  <div className="content leftPost">
                    <div className="avatar">
                      <div className="avatar-cnt">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="share-modal__container__actions">

                </div>
              </div>
            </div>


          </div>

          <div
            className="comments-hld displayFlex"
          >
            <div>
              <img
                src={newshare}
                className="icon-btn post_icon_image"
                alt=""
                role="button"
              />
            </div>
            <div className="icon_holder_style">Share</div>
          </div>
        </div>
      </div>
      ))}
    </div>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
    </>
  )
};

export default Post
