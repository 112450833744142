// import profileAvatar from "../../assets/images/header/Ellipse_73@2x.png";
// import editIcon from "../../assets/images/profile/edit_icon.svg";
import editIcon from "../../assets/images/icons/edit.svg";
import Followings from "../followers/followers";
import StateDropDown from "../state-dropdown/stateDropDown";
import {
  Button,
  Grid,
} from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import validator from "validator";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import tossball from "../../assets/images/profile/bxs_cricket-ball.png";
import threedot from "../../assets/images/icons/threedots_vertical.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../loading-button/LoadingButton";
import { useRef } from "react";
import Posts from "../posts";
import { BallTriangle } from "react-loader-spinner";
import FollowerPanel from "./get-follower";
import FollowingPanel from "./get-following";
import BlockUserImage from "../../assets/images/home/blockuser.png";
import Logo from "../../assets/images/home/logo/logo@2x.png";
import Run from "../posts/run";
import SubmenuInLine from "../submenu_inline/index";
import Post from "../posts/post";
import Highlights from "../../components/Highlights/Highlights";
// import Highlights from "../../components/Highlights";
import UserCard from "../followers/followers";
import ProfileList from "../../commons/components/ProfileList";
import {
  clearStorage,
  getStorageItem,
  setStorageItem,
} from "../../utils/sessionStorage";
import ImageModal from "../../commons/components/image-modal";
import { Helmet } from "react-helmet";
import ProfileDropdown from "./ProfileDropdown";
import About from "./About/About";
import { useDispatch, useSelector } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import ReelItem from "../Highlights/ReelItem";
const loggedUserId = getStorageItem("user_id");

const styleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFFF",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  py: 1,
  px: 4,
};

const styleMobile = {
  position: "absolute",
  width: "90%",
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  py: 1,
  px: 4,
  height: "100%",
  width: "100%",
};

const buttonMobile = {
  borderRadius: 2,
  width: "70%",
  height: "35px",
  // m: "2.5%",
  backgroundColor: "transparent",
  border: "1px solid",
  // mt: "18px",
  color: "white",
  display: "flex",
  alignItems: "flex-start",
  minWidth: "136px",
  // maxWidth: "100%"
};

const buttonDesktop = {
  borderRadius: 2,
  width: "70%",
  height: "35px",
  m: "2.5%",
  backgroundColor: "transparent",
  border: "1px solid",
  mt: "18px",
  color: "white",
  display: "flex",
  alignItems: "flex-start",
  minWidth: "136px",
  // maxWidth: "100%"
};

const Profile = ({ handlecoverPhoto, Params }) => {
  const [style, setStyle] = useState();
  const [coverid, setCoverid] = useState("");
  const [profileRole, setProfileRole] = useState("");
  const [profiledropOpen, setProfiledrop] = useState(false);
  const [OpenPostStoryHighlight, setOpenPostStoryHighlight] = useState("POST");
  // const [OpenStoryAbout, setOpenStoryAbout] = useState(true);
  // const [OpenHighlightAbout, setOpenHighlightAbout] = useState(true);
  const [profileInitialData, setProfileInitialData] = useState({
    first_name: "",
    last_name: "",
    full_name: "",
    address: "",
    address2: "",
    zip_code: "",
    state: "",
    about_me: "",
    email: getStorageItem("user_email"),
    avatar: defaultAvatar,
    followersCount: 0,
    followingCount: 0,
    totalRun: 0,
    gender: "",
    country: "",
    countryFlag: "",
    DOB: "",
    showDob: false,
  });
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [avatarFile, setAvatarFile] = useState();
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const localStorageUserId = getStorageItem("user_id");

  let canEditProfile = true;
  const matches = useMediaQuery("(min-width:600px)");
  const mobile = useMediaQuery("(max-width: 460px)");
  const [profileInfo, setProfileInfo] = useState([]);
  const [ownProfile, setOwnProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [buttonValue, setButtonValue] = useState("Loading..");
  const [ownFollowings, setOwnFollowings] = useState([]);
  const [buttonStyle, setButtonStyle] = useState({});
  const timer = useRef();
  const [followingPanel, setFollowingPanel] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState("mypost");
  const [nameSearchResults, setNameSearchResults] = useState([]);
  const [Followed, setFollwed] = useState(false);
  const [Block, setBlock] = useState(false);
  const PitchCountFromRedux = useSelector((state) => state.path.PostCount);
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedHighlights, setSavedHighlights] = useState([]);
  const [currentReelIndex, setCurrentReelIndex] = useState(0);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);


  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  if (Params) {
    canEditProfile = false;
  }

  if (Params === loggedUserId) {
    canEditProfile = true;
  }

  const fetchOwnFollowing = () => {
    if (!accessToken) return;
    const getOwnFollowing = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/get-following-user-id/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getOwnFollowing)
      .then((response) => {
        setOwnFollowings(response.data ? response.data : []);
      })
      .then(() => checkFollower())
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const fetchUserInfo = (id, pathName) => {
    if (!id && !accessToken) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + `/auth/${pathName}/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: id,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        setStorageItem("coverPhotoid", response?.data?.coverphoto);
        handlecoverPhoto(response.data.coverphoto);
        setCoverid(response.data.coverphoto);
        dispatch(
          PathAction.handlePostCount({ payload: response?.data?.pitchCount })
        );
        setProfileInitialData({
          first_name: response.data.firstName ? response.data.firstName : "",
          last_name: response.data.lastName ? response.data.lastName : "",
          full_name: response.data.firstName + " " + response.data.lastName,
          address: "",
          address2: "",
          zip_code: "",
          state: response.data.state ? response.data.state : "",
          about_me: response.data.aboutMe ? response.data.aboutMe : "",
          gender: response.data.Gender ? response.data.Gender : "",
          country: response.data.Country ? response.data.Country : "",
          countryFlag: response.data.countryFlag
            ? response.data.countryFlag
            : "",
          DOB: response.data.DOB ? response.data.DOB : "",
          showDob: response.data.showDob,
          email: response.data.email ? response.data.email : "",
          avatar: response.data.profilePhoto ? response.data.profilePhoto : "",
          followingCount: response.data.followingCount
            ? response.data.followingCount
            : 0,
          followersCount: response.data.followersCount
            ? response.data.followersCount
            : 0,
          totalRun: response.data.totalRun ? response.data.totalRun : 0,
          pitchCount: response.data.pitchCount ? response.data.pitchCount : 0,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };


  const fetchSavedPosts = async (userId) => {
    try {
      if (!userId) return;

      const pageNumber = 1;
      const pagePerSize = 10;

      const response = await axios.get(
        `${global.config.ROOTURL.prod}/feeds/getAllSavedPosts/${userId}/${pageNumber}/${pagePerSize}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      });

      if (response.data.success) {
        setSavedPosts(response.data.data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching saved posts:", error.message);
    }
  };

  const fetchSavedHighlights = async (userId) => {
    try {
      if (!userId) return;

      const pageNumber = 1;
      const pagePerSize = 10;

      const response = await axios.get(
        `${global.config.ROOTURL.prod}/highlight/highlights/getAllSavedHighlights/${userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      });
      setSavedHighlights(response?.data);
    } catch (error) {
      console.error("Error fetching saved posts:", error.message);
    }
  };

  const handleUnfollow = () => {
    if (!Params && !accessToken) return;
    const unfollow = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/remove-following-user/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        followingUserId: Params,
      },
    };

    if (Params != null) {
      axios(unfollow)
        .then(() => setIsFollowing(false))
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };

  const handleFollow = () => {
    if (!Params && !accessToken) return;
    const follow = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/add-following-user/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        followingUserId: Params,
      },
    };

    if (Params != null) {
      axios(follow)
        .then(() => setIsFollowing(true))
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };

  const checkFollower = () => {
    if (ownFollowings?.length > 0) {
      for (let i = 0; i < ownFollowings.length; i++) {
        if (Params == ownFollowings[i]?._id) {
          setIsFollowing(true);
        }
      }
    }
  };
  const handleOpenPostStoryHighlight = (OpenPOstStoryHighlight) => {
    setOpenPostStoryHighlight(OpenPOstStoryHighlight);
    if (OpenPOstStoryHighlight === "POST") {
      fetchUserInfo(Params, "profile");
    } else if (OpenPOstStoryHighlight === "STORY") {
      fetchUserInfo(Params, "story");
    } else if (OpenPOstStoryHighlight === "SAVED POST") {
      fetchSavedPosts(localStorageUserId);
    } else if (OpenPOstStoryHighlight === "SAVED HIGHLIGHTS") {
      fetchSavedHighlights(localStorageUserId);
    }
    else {
      fetchUserInfo(Params, "highlight");
    }
  };

  const handleDropdown = () => {
    setProfiledrop(!profiledropOpen);
  };

  useEffect(() => {
    if (!matches) {
      setStyle(styleMobile);
      setButtonStyle(buttonMobile);
    } else {
      setStyle(styleDesktop);
      setButtonStyle(buttonDesktop);
    }
  }, [matches]);

  useEffect(() => {
    fetchOwnFollowing();
    if (Params === getStorageItem("user_id")) {
      setOwnProfile(true);
    }
    if (!Params) {
      setOwnProfile(true);
    }
    fetchUserInfo(Params, "profile");
    getBlockuserList();
  }, [Params]);

  const handleupdateblockuser = () => {
    setBlock(true);
  };
  const hanldeunblockuser = () => {
    setBlock(false);
  };

  const checkblockUser = (blocklist) => {
    const isblock = blocklist.filter((i) => i.blockedUserId == Params);
    if (isblock.length > 0) {
      setBlock(true);
    }
  };
  const getBlockuserList = () => {
    if (!Params && !accessToken) return;
    const getList = {
      method: "POST",
      url:
        global.config.ROOTURL.prod + "/auth/block/get-blocked-users-by-userID",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        blockUserId: Params,
      },
    };

    if (Params != null) {
      axios(getList)
        .then((res) => {
          console.log(res, "block");
          setBlock(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => { }, [PitchCountFromRedux]);

  useEffect(() => {
    getBlockuserList();
  }, [Block]);

  useEffect(() => {
    checkFollower();
  }, [ownFollowings]);

  return (
    <>
      <div>
        <Helmet>
          <title>Champhunt | Profile</title>
        </Helmet>

        {/* profile contaner  */}
        <div className="profile_container">
          <div className="img_container">
            <div>
              <img
                src={
                  profileInitialData?.avatar
                    ? profileInitialData?.avatar
                    : defaultAvatar
                }
                className="margintopProfile"
                onClick={() => handleOpen()}
              />
            </div>

            {/* User Profile name*/}
            <p className="Font16Px">{profileInitialData?.full_name}</p>

            {/* DOB for mobile view */}
            <div className="mobile__dobcontainer">
              <p className="mobile__dobdata"> {profileInitialData.DOB} </p>
            </div>
          </div>

          {/* if its not ownPofile then this component trigger  */}
          {profiledropOpen && (
            <ProfileDropdown
              userId={Params}
              userName={
                profileInitialData.first_name +
                " " +
                profileInitialData.last_name
              }
              Block={Block}
              hadleUserBlockToparent={handleupdateblockuser}
              hanldeunblockuser={hanldeunblockuser}
              ownProfile={ownProfile}
            />
          )}

          {/* profile runs score count */}
          {ownProfile ? (
            <Button className="secoundButton" onClick={() => {
              navigate("/runs-transaction");
            }}>
              <div className="displayflexCommonClass">
                <img className="runStyle-img" src={tossball} />
                <p className="runStyle">{profileInitialData.totalRun} </p>
              </div>
            </Button>
          ) : (
            <Button className="secoundButton" disabled>
              <div className="displayflexCommonClass">
                <img className="runStyle-img" src={tossball} alt="Tossball" />
                <p className="runStyle">{profileInitialData.totalRun}</p>
              </div>
            </Button>
          )}

          {/* mobile Post,folow count container  */}
          <div>
            <div className="mobile_MainPostContainer">
              <div className="mobile_childContainer">
                <p className="mobile_underline-hover">{PitchCountFromRedux} </p>
                <p className="mobile_profileDataCount">Posts</p>
              </div>

              <div
                className="mobile_childContainer"
                onClick={() => navigate(`/followers-page/${Params}`)}
              >
                <p className="mobile_underline-hover">
                  {profileInitialData.followersCount}{" "}
                </p>
                <p className="mobile_profileDataCount">Followers</p>
              </div>

              <div
                className="mobile_childContainer"
                onClick={() => navigate(`/following-page/${Params}`)}
              >
                <p className="mobile_underline-hover">
                  {profileInitialData.followingCount}{" "}
                </p>
                <p className="mobile_profileDataCount">Following</p>
              </div>

              <img
                className="mobile_profile__threedots"
                src={threedot}
                onClick={handleDropdown}
              />
            </div>
          </div>
        </div>

        {/* about for mobile view */}
        <div className="mobile_aboutcontainer">
          <p className="mobile_aboutdata"> {profileInitialData.about_me} </p>
        </div>

        {/* location for mobile view */}
        <div className="mobile_locationcontainer">
          <p className="mobile_locationdata"> {profileInitialData.country} </p>
        </div>

        {/* follow button container  */}
        <div className="follow1_container">
          {!ownProfile && !isFollowing && (
            <Button
              className="fierstButton"
              style={{ marginRight: "24px" }}
              onClick={handleFollow}
            >
              follow
            </Button>
          )}

          {!ownProfile && isFollowing && (
            <Button
              className="fierstButton"
              style={{ marginRight: "24px" }}
              onClick={handleUnfollow}
            >
              Unfollow
            </Button>
          )}

          {/* analytics button */}
          {ownProfile && (
            <Button
              className="ownprofile_analyticsButton"
              style={{ marginLeft: "24px" }}
              onClick={() => {
                navigate("/analytics", { state: { id: Params } });
              }}
            >
              {" "}
              View Analytics
            </Button>
          )}

          {/* edit button */}
          {ownProfile && (
            <Button
              className="ownprofile_editButton"
              style={{ marginLeft: "24px" }}
              onClick={() => {
                navigate(`/profile_edit`);
              }}
            >
              {" "}
              Edit
            </Button>
          )}

          {/* {ownProfile && (
            <Button
              className="secoundButton"
              style={{ marginLeft: "24px" }}
              onClick={() => {
                navigate(`/advertisments/${Params}`);
              }}
            >
              {" "}
              Advertisments
            </Button>
          )} */}

          {/* share, report, block button */}
          <img
            className="profile__threedots"
            src={threedot}
            onClick={handleDropdown}
          />
        </div>

        {/* Post,folow count container  */}
        <div>
          <div className="MainPostContainer">
            <div className="childContainer">
              <p className="underline-hover">{PitchCountFromRedux} </p>
              <p className="profileDataCount">posts</p>
            </div>

            <div
              className="childContainer"
              onClick={() => navigate(`/followers-page/${Params}`)}
            >
              <p className="underline-hover" style={{ "margin-left": "40px" }}>
                {profileInitialData.followersCount}{" "}
              </p>
              <p className="profileDataCount">followers</p>
            </div>

            <div
              className="childContainer"
              onClick={() => navigate(`/following-page/${Params}`)}
            >
              <p className="underline-hover" style={{ "margin-left": "40px" }}>
                {profileInitialData.followingCount}{" "}
              </p>
              <p className="profileDataCount">following</p>
            </div>
          </div>
        </div>
      </div>

      {/* profile and about section new design */}
      <div className="profile__feedcontainer">
        {/* about section */}
        <div className="about__feedcontainer">
          <div className="about-firstchild">
            <div className="about-firstcontent">
              <h3
                style={{
                  color: "#0F6CBD",
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "18px",
                  fontFamily: "inter",
                  paddingBottom: "16px",
                }}
              >
                ABOUT
              </h3>
              <p
                style={{
                  color: "#95999D",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontFamily: "inter",
                }}
              >
                {" "}
                {profileInitialData.about_me}{" "}
              </p>
            </div>
          </div>

          <div className="about-secondchild">
            <div className="about-secondcontent">
              <div className="about-secondcontainer">
                <h3
                  style={{
                    color: "#0F6CBD",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    fontFamily: "inter",
                    paddingBottom: "16px",
                  }}
                >
                  GENDER
                </h3>
                <h5
                  style={{
                    color: "#95999D",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "inter",
                    paddingBottom: "15px",
                  }}
                >
                  {" "}
                  {profileInitialData.gender}{" "}
                </h5>
              </div>

              <div className="about-secondcontainer">
                <h3
                  style={{
                    color: "#0F6CBD",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    fontFamily: "inter",
                    paddingBottom: "16px",
                  }}
                >
                  DATE OF BIRTH
                </h3>
                <h5
                  style={{
                    color: "#95999D",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "inter",
                    paddingBottom: "15px",
                  }}
                >
                  {" "}
                  {profileInitialData.DOB}{" "}
                </h5>
              </div>

              <div className="about-secondcontainer">
                <h3
                  style={{
                    color: "#0F6CBD",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    fontFamily: "inter",
                    paddingBottom: "16px",
                  }}
                >
                  COUNTRY
                </h3>
                <h5
                  style={{
                    color: "#95999D",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "inter",
                    paddingBottom: "16px",
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={profileInitialData.countryFlag}
                    style={{ height: "36px", width: "36px", borderRadius: "50%", marginRight: '8px' }}
                  />
                  {profileInitialData.country}
                </h5>
              </div>
            </div>
          </div>
        </div>

        {/* post section */}
        <div className="post__feedcontainer">
          <div className="first-childcontainer">
            <div
              className="childContainer"
              onClick={() => handleOpenPostStoryHighlight("POST")}
            >
              <p
                className={
                  OpenPostStoryHighlight === "POST" ? "active_container" : ""
                }
                style={{ fontFamily: "inter" }}
              >
                POSTS
              </p>
            </div>

            {/* story tab */}
            {/* <div
              className="childContainer"
              onClick={() => handleOpenPostStoryHighlight("STORY")}
            >
              <p
                className={
                  OpenPostStoryHighlight === "STORY" ? "active_container" : ""
                }
                style={{ fontFamily: "inter" }}
              >
                STORY
              </p>
            </div> */}

            {/* highlight tab */}
            {/* <div
              className="childContainer"
              onClick={() => handleOpenPostStoryHighlight("HIGHLIGHTS")}
            >
              <p
                className={
                  OpenPostStoryHighlight === "HIGHLIGHTS"
                    ? "active_container"
                    : ""
                }
                style={{ fontFamily: "inter" }}
              >
                HIGHLIGHTS
              </p>
            </div> */}


            {/* saved post tab */}
            <div
              className="childContainer"
              onClick={() => handleOpenPostStoryHighlight("SAVED POST")}
            >
              <p
                className={
                  OpenPostStoryHighlight === "SAVED POST"
                    ? "active_container"
                    : ""
                }
                style={{ fontFamily: "inter" }}
              >
                SAVED POSTS
              </p>
            </div>

            {/* saved HIghlights tab */}
            <div
              className="childContainer"
              onClick={() => handleOpenPostStoryHighlight("SAVED HIGHLIGHTS")}
            >
              <p
                className={
                  OpenPostStoryHighlight === "SAVED HIGHLIGHTS"
                    ? "active_container"
                    : ""
                }
                style={{ fontFamily: "inter" }}
              >
                SAVED HIGHLIGHTS
              </p>
            </div>
          </div>

          <div className="second-childcontainer">
            {Block ? (
              <Grid container className="block_image_place">
                <img src={Logo} className="block_logo" />
                <div className="block_image">
                  <img src={BlockUserImage} style={{ width: "100px" }} />
                  <p className="block_para">
                    To see their pitches and runs you need to Unblock them.
                  </p>
                </div>
              </Grid>
            ) : OpenPostStoryHighlight === "SAVED POST" ? (
              // saved posts
              savedPosts.length > 0 ? (
                <div className="posts" style={{ width: "95%" }}>
                  {savedPosts.map((post) => (
                    <Post key={post._id} post={post} />
                  ))}
                </div>
              ) : (
                <p>No saved posts found.</p>
              )
            ) : OpenPostStoryHighlight === "SAVED HIGHLIGHTS" ? (
              savedHighlights.length > 0 ? (
                <div style={{ width: "95%" }}>
                  {savedHighlights?.map((reel, index) => (
                    <div
                      key={reel._id}
                    >
                      <ReelItem
                        reel={reel}
                        onComment={() => setIsCommentModalOpen(true)}
                        setCurrentReelIndex={() => setCurrentReelIndex(index)}
                        commentCount={reel.highlightCommentCount}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p>No saved highlights found.</p>
              )
            ) : OpenPostStoryHighlight === "POST" ? (
              <ProfileList userId={Params} page="profile" />
            ) : (
              <About userData={profileInitialData} />
            )}

            {/* Modals  */}
            <ImageModal
              avatar={profileInitialData?.avatar}
              open={open}
              handleClose={handleClose}
            />
          </div>
        </div>
      </div>

      {/* post and About conatiner  */}

      {/* <div className="PostAboutContainer">
          <div
            className="childContainer"
            style={{ borderRight: "1px solid #D9D9D9" }}
            onClick={handleOpenPostStoryHighlight}
          >
            <p className={OpenPostStoryHighlight ? "active_container" : ""}
            >
              {" "}
              Posts
            </p>
          </div>
          
          <div className="childContainer" onClick={handleOpenPostStoryHighlight}>
            <p
              className={!OpenPostStoryHighlight ? "active_container" : ""}              
            >
              {" "}
              About Me
            </p>
          </div>
        </div> */}
      {/* Container that hold  Posts and About  */}
      {/* if you are block then this container  */}
    </>
  );
};

export default Profile;
