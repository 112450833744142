import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  prediction:null
}

export const Predtictionslice = createSlice({
  name: 'prediction',
  initialState,
  reducers: {
   
    setPredictionId (state, action) {
      state.prediction=action.payload.payload
    }
  
  },
})

// Action creators are generated for each case reducer function
export const PredictionAction= Predtictionslice.actions

export default Predtictionslice
