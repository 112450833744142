import React from "react";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { toast } from 'react-toastify';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material"
import config from "../../config";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import JobDelete from "./job-delete-modal";
const baseURL = config.ROOTURL.prod;
const pagePerSize = config.pagePerSize;
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const FindOneJobComponent = (props) => {
  const { id } = useParams();
  const [job, setJob] = useState("");
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openJobDeleteModal, setOpenJobDeleteModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenJobDeleteModal = () => setOpenModal(true);
  const handleCloseJobDeleteModal = () => {
    console.log("yes")
    setOpenJobDeleteModal(false);
  }
  const navigate = useNavigate();
  const navigateToJobs = () => {
    navigate('/admin/all-jobs');
  };
  const navigateToAppliedUserList = (jobId) => {
    navigate(`/admin/applied-user-list/${jobId}`);
  };
  const handleDeleteFunCall = (id) => {
    console.log("This is delete function call:");
    console.log("this is post ID:", id);
    axios.delete(`${baseURL}/job/delete/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      }
    }).then((response) => {
      console.log("this is post response:", response)
      if (response) {
        toast.success("Job deleted successfully", { theme: "colored" });
        console.log("Job deleted successfully");
        setTimeout((e) => {
          window.location.href = "/admin/all-jobs";
        }, 2000)
      }
      else {
        toast.error("Some error occured", { theme: "colored" });
        console.log("error occured:");
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const closeApplicationFunCall = (jobId, userId) => {
    console.log("This is close app function call:");
    if (!jobId && !userId) return
    axios.post(`${baseURL}/job/closeJobApplication`, { jobId, userId }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      }
    }).then((response) => {
      console.log("this is post response:", response)
      if (response) {
        toast.success("Job closed successfully", { theme: "colored" });
        setTimeout((e) => {
          window.location.href = `/admin/job/${jobId}`;
        }, 1000)
      }
      else {
        toast.error("Some error occured", { theme: "colored" });
        console.log("error occured:");
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const handleDelete = (event) => {
    console.log(event)
    // handleClose(event);
    setOpenJobDeleteModal(true);
  };
  const fetchData = async () => {
    let pageNumber = 1;
    let pagePerSize = 10;
    const getAllEvents = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/job/getByJobId/${id} `,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getAllEvents)
      .then((response) => {
        console.log("response.data one job", response.data);
        setJob(response.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };
  const fetchUserInfo = () => {
    if (!userID) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userID,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        if (
          response.data.role !== "SuperAdmin" &&
          response.data.role !== "Admin"
        ) {
          navigate("/pitch");
        }
        else {
          setIsAdmin(true)
        }

      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error)
        }
      });
  };
  useEffect(() => {
    if (!accessToken) return;
    fetchUserInfo()
    fetchData();
  }, []);
  if (error) return `Error: ${error.message}`;
  if (!setJob) return "No post!";


  return (
    <>
      <Grid container spacing={4} justifyItems="center" marginTop={2}>
        <Grid item xs={9} sm={10} md={10} lg={10} >

          <Card fullWidth variant="outlined" sx={{ borderRadius: 2, }}  >
            <CardMedia
              component="img"
              height="140px"
              image={job.companyLogo}
              alt={job.jobTitle}
            />
            <Box sx={{
              p: 3, display: 'flex', flexDirection: 'column', alignItems: 'left',
              borderRadius: 3, backgroundColor: 'white'
            }}>
              <Typography variant="h5" sx={{ mb: 1, }} style={{ textAlign: "center" }} > <b>{job.jobTitle} </b></Typography>
              <Typography variant="h6" component="div" sx={{ display: 'inline-block', color: '#0C5BA0', cursor: "pointer" }}>
                Job Description
              </Typography>

              <Typography sx={{ mb: 1, }} > <b>Company Name:</b>&nbsp;{job.companyName} </Typography>
              <Typography sx={{ mb: 1, }}  ><b>Location:</b>&nbsp;{job.jobLocation}</Typography>
              <Typography sx={{ mb: 1, }}  ><b>Remuneration:</b>&nbsp;{job.Remuneration} </Typography>
              <Typography sx={{ mb: 1, }}  ><b>Experience: </b>&nbsp;{job.Experience}</Typography>
              <Typography sx={{ mb: 1, }}  ><b>Employment Type:</b>&nbsp;{job.employmentType} </Typography>
              <Typography sx={{ mb: 1, }}  ><b>Workplace Type:</b>&nbsp;{job.workPlaceType} </Typography>
              <Typography sx={{ mb: 1, }}  ><b>Qualification:</b>&nbsp;{job.Qualification}</Typography>
              <Typography sx={{ mb: 1, }}  ><b>Other Recuirements:</b>&nbsp;{job.OtherJobrequirements}</Typography>
              <Typography variant="h6" sx={{ mb: 1, color: '#0C5BA0' }}  > <b>Description:</b> </Typography>
              {console.log(job.jobDescription, "helllooooo")}
              <p>

                {job.jobDescription ? parse(job.jobDescription) : ""}
              </p>


            </Box>
            <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
              <CardActions >
                <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', alignItems: "left", ml: 'auto' }} onClick={navigateToJobs}> Back</Button>
              </CardActions>
              {isAdmin &&
                <CardActions>

                  {job.closeApplication ? <Button size="small" variant="outlined" sx={{ ml: 'auto', borderColor: "#fecc08", border: "2px solid #fecc08", color: "black", padding: "2px" }} >Closed</Button>
                    : <Button size="small" variant='contained' sx={{ ml: 'auto', borderColor: "#fecc08", backgroundColor: "#fecc08", border: "2px solid #fecc08", color: "black", padding: "2px" }} onClick={() => closeApplicationFunCall(job._id, job.userId)}>Close Application</Button>}
                  <Button size="small" variant='contained' sx={{ ml: 'auto', borderColor: "#fecc08", backgroundColor: "#fecc08", border: "2px solid #fecc08", color: "black", padding: "2px" }} onClick={() => navigateToAppliedUserList(job._id)}>Applied User List</Button>
                  <Button size="small" color="error" variant='contained' sx={{ ml: 'auto' }} onClick={handleDelete}>Delete</Button>
                </CardActions>
              }

            </Box>
          </Card></Grid></Grid>
      <JobDelete handleOpen={handleOpenJobDeleteModal}
        handleClose={handleCloseJobDeleteModal}
        open={openJobDeleteModal}
        handleDeleteJob={handleDeleteFunCall}
        jobId={id}
      />
    </>

  );
};
export default FindOneJobComponent;
