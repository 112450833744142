import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useMediaQuery,
  Grid,
  Button,
  Box,
  Card,
  CardActions,
  Typography,
  Link,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PostedJob from "../posted-job";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import config from "../../config";
import parse from "html-react-parser";
import EasyApply from "./EasyApply";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import suitcase from "../../assets/images/icons/suitcase.png";
import Jobs from "./Jobs";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const baseURL = config.ROOTURL.prod;

const JobDescription = (props) => {
  const navigate = useNavigate();
  const navigateToJobs = () => {
    navigate("/jobs");
  };
  const { id } = useParams();
  const mobileView = useMediaQuery("(max-width:920px)");
  const [post, setPost] = useState("");
  const [error, setError] = useState(null);
  const [jobData, setjobData] = useState(null);
  const [isopen, setisopen] = useState(false);
  const [popupData, setpopupData] = useState(false);

  const [applied, setApplied] = useState(false);
  // console.log("This is the job id from posted job component:", id);

  const deleteFunCall = (id) => {
    console.log("This is delete function call:");
    let postId = id;
    console.log("this is post ID:", postId);
    axios
      .delete(`${baseURL}/job/delete/${postId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        console.log("this is post response:", response);
        if (response) {
          toast.success("Job deleted successfully", { theme: "colored" });
          console.log("Job deleted successfully");
          setTimeout((e) => {
            window.location.href = "/jobs";
          }, 2000);
        } else {
          toast.error("Some error occured", { theme: "colored" });
          console.log("error occured:");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const closeApplicationFunCall = (jobId) => {
    console.log("This is close app function call:");
    let userId = userID;
    axios
      .post(
        `${baseURL}/job/closeJobApplication`,
        { jobId, userId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        console.log("this is post response:", response);
        if (response) {
          toast.success("Job closed successfully", { theme: "colored" });
          setTimeout((e) => {
            window.location.href = `/job-description/${jobId}`;
          }, 2000);
        } else {
          toast.error("Some error occured", { theme: "colored" });
          console.log("error occured:");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/job/getAll`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        // handlejobDescription(response);
        // console.log("this is base url:", `${baseURL}`);
        // console.log("this is response data:", response.data)
        setPost(response.data.data);
      })
      .catch((error) => {
        setError(error);
      });
    //
    axios
      .get(`${baseURL}/job/getJobApplicationByUserIdAndJobId/${id}/${userID}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        if (response.data.data === "applied") setApplied(true);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) return `Error: ${error.message}`;
  if (!post) return "No post!";
  const theme = createTheme({
    typography: {
      fontFamily: "poppins, Arial",
    },
  });

  const handleClose = () => {
    setisopen(false);
    setpopupData(false);
  };
  const handlePOpup = () => {
    setisopen(true);
    setpopupData(true);
  };

  return (
    <div style={mobileView ? mobilestyle : style}>
      {post.map(
        (data, index) =>
          data._id === id && (
            <Grid item xs={12} sm={12} md={8} lg={8} key={index}>
              <Card
                fullWidth
                variant="outlined"
                sx={{
                  mb: 0,
                  m: 1,
                  borderRadius: 2,
                  mt: mobileView ? "6rem" : "2.5rem",
                }}
              >
                <Grid container spacing={mobileView ? 0 : 2}>
                  <Grid
                    item
                    textAlign={"center"}
                    xs={3}
                    sm={2}
                    md={2}
                    lg={2}
                    sx={{ mt: 5 }}
                  >
                    {/* {console.log("data", data)} */}
                    <img
                      src={data.companyLogo ? data.companyLogo : suitcase}
                      alt=""
                      width={40}
                      height={40}
                      style={{
                        margin: "-7px 0px 0px 20px",
                        border: "2px solid purple",
                        borderRadius: 50,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={10} md={10} lg={10}>
                    <CardContent style={{ paddingLeft: "25px" }}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          display: "inline-block",
                          color: "#0C5BA0",
                          cursor: "pointer",
                        }}
                      >
                        <b>{data.jobTitle}</b>
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "right",
                          float: "right",
                          display: "inline-block",
                          ml: "auto",
                          color: "#870499",
                        }}
                        component="div"
                      ></Typography>
                      <br />
                      <span>
                        <Typography
                          sx={{ display: "inline-block", color: "#870499" }}
                          color="text.secondary"
                        >
                          {" "}
                          <FontAwesomeIcon
                            sx={{ color: "#870499" }}
                            icon={faBuilding}
                          />
                          &nbsp;{" "}
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {" "}
                          {data.companyName}{" "}
                        </Typography>
                      </span>
                      <br />
                      <span>
                        <Typography
                          sx={{ display: "inline-block", color: "#870499" }}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faLocationDot} />
                          &nbsp;
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {" "}
                          {data.jobLocation}{" "}
                        </Typography>
                      </span>
                      <br />
                      <span>
                        <Typography
                          sx={{ display: "inline-block", color: "#870499" }}
                        >
                          <FontAwesomeIcon icon={faMoneyBill} />
                          &nbsp;{" "}
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {" "}
                          {data.Remuneration}
                        </Typography>
                      </span>{" "}
                      <br />
                      <span>
                        <Typography
                          sx={{ display: "inline-block", color: "#870499" }}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faLightbulb} />
                          &nbsp;{" "}
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {" "}
                          {data.Experience}
                        </Typography>
                      </span>
                      {data.userId === userID && (
                        <CardActions>
                          <Button
                            size="small"
                            color="error"
                            variant="contained"
                            sx={{ ml: "auto" }}
                            onClick={() => deleteFunCall(data._id)}
                          >
                            Delete
                          </Button>
                          {data.closeApplication ? (
                            <Button
                              size="small"
                              variant="outlined"
                              sx={{
                                ml: "auto",
                                borderColor: "#fecc08",
                                border: "2px solid #fecc08",
                                color: "black",
                                padding: "2px",
                              }}
                            >
                              Closed
                            </Button>
                          ) : (
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                ml: "auto",
                                borderColor: "#fecc08",
                                backgroundColor: "#fecc08",
                                border: "2px solid #fecc08",
                                color: "black",
                                padding: "2px",
                              }}
                              onClick={() => closeApplicationFunCall(data._id)}
                            >
                              Close Application
                            </Button>
                          )}
                        </CardActions>
                      )}
                      {data.userId !== userID &&
                        !applied &&
                        !data.closeApplication && (
                          <CardActions>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                ml: "auto",
                                backgroundColor: "#fecc08",
                                color: "#00247b",
                              }}
                              onClick={handlePOpup}
                            >
                              Easy Apply
                            </Button>
                          </CardActions>
                        )}
                      {data.userId !== userID &&
                        applied &&
                        !data.closeApplication && (
                          <CardActions>
                            <Button
                              size="small"
                              variant="outlined"
                              sx={{
                                ml: "auto",
                                borderColor: "#fecc08",
                                border: "2px solid #fecc08",
                                color: "black",
                                padding: "5px",
                              }}
                            >
                              Applied
                            </Button>
                          </CardActions>
                        )}
                      {data.userId !== userID && data.closeApplication && (
                        <CardActions>
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{
                              ml: "auto",
                              borderColor: "#fecc08",
                              border: "2px solid #fecc08",
                              color: "black",
                              padding: "5px",
                            }}
                          >
                            Closed
                          </Button>
                        </CardActions>
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>

              <Card
                fullWidth
                variant="outlined"
                sx={{ mb: 0, m: 1, borderRadius: 2 }}
              >
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    borderRadius: 3,
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      display: "inline-block",
                      color: "#0C5BA0",
                      cursor: "pointer",
                    }}
                  >
                    Job Description
                  </Typography>

                  <Typography sx={{ mb: 1 }}>
                    {" "}
                    <b>Company Name:</b>&nbsp;{data.companyName}{" "}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Location:</b>&nbsp;{data.jobLocation}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Remuneration:</b>&nbsp;{data.Remuneration}{" "}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Experience: </b>&nbsp;{data.Experience}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Employment Type:</b>&nbsp;{data.employmentType}{" "}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Workplace Type:</b>&nbsp;{data.workPlaceType}{" "}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Qualification:</b>&nbsp;{data.Qualification}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    <b>Other Recuirements:</b>&nbsp;{data.OtherJobrequirements}
                  </Typography>
                  <Typography variant="h6" sx={{ mb: 1, color: "#0C5BA0" }}>
                    {" "}
                    <b>Description:</b>{" "}
                  </Typography>

                  <p>{parse(data.jobDescription)}</p>
                </Box>
                <Box
                  component="span"
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        ml: "auto",
                      }}
                      onClick={navigateToJobs}
                    >
                      {" "}
                      Back
                    </Button>
                  </CardActions>

                  {popupData && (
                    <EasyApply
                      open={isopen}
                      setOpen={setisopen}
                      handleclose={handleClose}
                    />
                  )}
                </Box>
              </Card>
            </Grid>
          )
      )}

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={5}
        sx={{
          backgroundColor: "white",
          pl: 2,
          pr: 2,
          pb: mobileView ? "20px" : "0px",
          mt: 5,
          borderRadius: 2,
          height: mobileView ? "auto" : "110vh",
          width: mobileView ? "100%" : "60%",
        }}
      >
        <Box sx={{ pl: 5, mt: 3, color: "#0C5BA0", height: 30 }}>
          {" "}
          <h4>Similar Jobs for you</h4>
        </Box>
        <Jobs />
      </Grid>
    </div>
  );
};

const style = {
  display: "flex",
  margin: "0vh 15vh 7vh 15vh",
  position: "relative",
  top: "6rem",
};
const mobilestyle = {
  display: "flex",
  flexDirection: "column",
};
export default JobDescription;
