import { useMediaQuery } from '@material-ui/core';
import React from 'react'
import { Carousel } from "react-responsive-carousel";
import carouselimage from '../../assets/images/rewards/PredictionImage.png'
import carouselimage2 from '../../assets/images/rewards/PredictionImage2.png'
import carouselimage3 from '../../assets/images/rewards/PredictionImage3.png'
import NoPredictionModel from './NoPredictionModel';


export default function PredictionCarousel() {
    const mobileView = useMediaQuery("(max-width:900px)");
    return (
        <>
        <div className="predictioncarousel">
            <Carousel
                // dynamicHeight={true}
                autoPlay={true}
                infiniteLoop={true}
                interval={5000}
                showThumbs={false}
            >

                <div >
                

                    <img
                        alt="carousel"
                        src={carouselimage}
                        className=""
                    />
                </div>
                <div >
                

                    <img
                        alt="carousel"
                        src={carouselimage2}
                        className=""
                    />
                </div>
                <div >
                

                    <img
                        alt="carousel"
                        src={carouselimage3}
                        className=""
                    />
                </div>

            </Carousel>
        </div>
        <NoPredictionModel /> </>
    )
}
