import React from "react";
import { getStorageItem } from "../../../../utils/sessionStorage";

const FriendItem = ({ receiver, sender, conversationId, onClick }) => {
  const userId = getStorageItem("user_id");

  const displayUser = userId === receiver._id ? sender : receiver;

  return (
    <div className="friend-item" onClick={onClick} style={{ height: "90px" }}>
      <img
        src={displayUser.profilePhoto}
        alt={displayUser.firstName}
        className="avatar"
      />
      <div className="content">
        <div className="name-time">
          <span className="name">
            {displayUser.firstName} {displayUser.lastName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FriendItem;
