import { Box, Typography, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import axios from "axios";
import "./indexmodal.scss";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { Button } from "carbon-components-react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMessage } from "@fortawesome/free-regular-svg-icons";
import LeaderboardModal from "./leaderboard-modal";
import Today from "./todayMainPage";
import Loader from "../../commons/components/Loader";
import { NoPhotographySharp } from "@mui/icons-material";
const user_Id = getStorageItem("user_id");
const ThisYearRunPopup = () => {
  const [thisYearRun, setThisYearRun] = useState("");
  const [thisYearRunsByUserId, setThisYearRunsByUserId] = useState({
    userId: "",
    userName: "",
    runs: 0,
    runIndex: 0,
    profilePhoto: "",
    followersCount: 0,
    pitchCount: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [thisYearInTopTen, setThisYearInTopTen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const accessToken = getStorageItem("token");
  const thisYearRunsOfYouFunction = () => {
    if (!user_Id && !accessToken) return;
    const getThisYearRuns = {
      method: "GET",
      url:
        global.config.ROOTURL.prod + `/leaderboard/thisYear/findUserDetailsOfThisYearByUserId/${user_Id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getThisYearRuns)
      .then((response) => {
        if (response.data) {
          setThisYearRunsByUserId({
            userId: response.data.userId,
            firstName: response.data.userName,
            runs: response.data.runs,
            runIndex: response.data.runIndex,
            profilePhoto: response.data.profilePhoto,
            followersCount: response.data.followersCount,
            pitchCount: response.data.pitchCount,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const thisYearRunsFunction = () => {
    if (!accessToken) return;
    const getThisYearRuns = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        "/leaderboard/thisYear/runsThisYear/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getThisYearRuns)
      .then((response) => {


        if (response.data) {
          setThisYearRun(response.data);
        }

        const allTimeData = response.data;
        const allTimeDataIds = allTimeData.map((s) => s.userId);
        if (allTimeDataIds.slice(0, 9).includes(user_Id.toString())) {
          setThisYearInTopTen(true);
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    thisYearRunsFunction();
    thisYearRunsOfYouFunction();
  }, []);
  if (!thisYearRun) return "Loading...";
  // if (!Array.isArray(allTimeRun)) return 'results are not array'

  return (
    <section className="leaderboard-modal">
      <Box className="pedestal">
      </Box>
      {/* Table */}
      <table className="data__table" cellPadding="0" cellSpacing="0" border="0" style={{ backgroundColor: "#f5f5f5" }}>
        <thead>
          <tr className="table__headings">
            <th>Rank</th>
            <th>Name</th>
            {/* <th class="one">Followers</th> */}
            <th class="one">Pitch</th>
            <th>Runs</th>
          </tr>
        </thead>
        <tbody>
          {thisYearRun[0].userId === user_Id ?
            <tr className="table__data highlighted__user">
              <div className="crownx">
                <svg
                  width="25"
                  height="12"
                  viewBox="0 0 25 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0L6.25 4.5L12.5 0L18.75 4.5L25 0L21.7742 12H3.22581L0 0Z"
                    fill="url(#paint0_linear_1769_2115)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1769_2115"
                      x1="4.5"
                      y1="16"
                      x2="25"
                      y2="2.47954e-06"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFA44B" />
                      <stop offset="1" stopColor="#FFD046" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <td className="imagex__data">
                <span className="pedestal-1__rank">
                  <span>
                    <p>1</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-1__wrapper">
                    <img
                      src={
                        thisYearRun[0].profilePhoto
                          ? thisYearRun[0].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-1x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisYearRun[0].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    className="user__name"
                    style={{ color: "white" }}
                  >
                    YOU
                  </span>

                </Link>
              </td>

              <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
              </svg>

              </div>{thisYearRun[0].pitchCount}</td>
              <td>{thisYearRun[0].runs}</td>
            </tr> : <tr className="table__data">
              <div className="crownx">
                <svg
                  width="25"
                  height="12"
                  viewBox="0 0 25 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0L6.25 4.5L12.5 0L18.75 4.5L25 0L21.7742 12H3.22581L0 0Z"
                    fill="url(#paint0_linear_1769_2115)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1769_2115"
                      x1="4.5"
                      y1="16"
                      x2="25"
                      y2="2.47954e-06"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFA44B" />
                      <stop offset="1" stopColor="#FFD046" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <td className="imagex__data">
                <span className="pedestal-1__rank">
                  <span>
                    <p>1</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-1__wrapper">
                    <img
                      src={
                        thisYearRun[0].profilePhoto
                          ? thisYearRun[0].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-1x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisYearRun[0].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span className="user__name">{thisYearRun[0].userName.length > 10 ? thisYearRun[0].userName.substring(0, 10) + "..." : thisYearRun[0].userName}</span>

                </Link>
              </td>
              <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
              </svg></div>
                {thisYearRun[0].pitchCount}</td>
              <td>{thisYearRun[0].runs}</td>
            </tr>}
          {thisYearRun[1].userId === user_Id ?
            <tr className="table__data highlighted__user">
              <td className="imagex__data">
                <span className="pedestal-2__rank">
                  <span>
                    <p>2</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-2__wrapper">
                    <img
                      src={
                        thisYearRun[1].profilePhoto
                          ? thisYearRun[1].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-2x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisYearRun[1].userId}`}
                  style={{ textDecoration: "none" }}
                >

                  <span
                    className="user__name"
                    style={{ color: "white" }}
                  >
                    YOU
                  </span>

                </Link>
              </td>
              <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
              </svg>

              </div>{thisYearRun[1].pitchCount}</td>
              <td>{thisYearRun[1].runs}</td>
            </tr> :
            <tr className="table__data">
              <td className="imagex__data">
                <span className="pedestal-2__rank">
                  <span>
                    <p>2</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-2__wrapper">
                    <img
                      src={
                        thisYearRun[1].profilePhoto
                          ? thisYearRun[1].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-2x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisYearRun[1].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span className="user__name">{thisYearRun[1].userName.length > 10 ? thisYearRun[1].userName.substring(0, 10) + "..." : thisYearRun[1].userName}</span>
                </Link>
              </td>

              <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
              </svg></div>{thisYearRun[1].pitchCount}</td>
              <td>{thisYearRun[1].runs}</td>
            </tr>}
          {thisYearRun[2].userId === user_Id ?
            <tr className="table__data highlighted__user">
              <td className="imagex__data">
                <span className="pedestal-3__rank">
                  <span>
                    <p>3</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-3__wrapper">
                    <img
                      src={
                        thisYearRun[2].profilePhoto
                          ? thisYearRun[2].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-3x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisYearRun[2].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    className="user__name"
                    style={{ textDecorationColor: "Highlight" }}
                  >
                    YOU
                  </span>

                </Link>
              </td>

              <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
              </svg>

              </div>{thisYearRun[2].pitchCount}</td>
              <td>{thisYearRun[2].runs}</td>
            </tr> :
            <tr className="table__data">
              <td className="imagex__data">
                <span className="pedestal-3__rank">
                  <span>
                    <p>3</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-3__wrapper">
                    <img
                      src={
                        thisYearRun[2].profilePhoto
                          ? thisYearRun[2].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-3x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisYearRun[2].userId}`}
                  style={{ textDecoration: "none" }}
                >

                  <span className="user__name">{thisYearRun[2].userName.length > 10 ? thisYearRun[2].userName.substring(0, 10) + "..." : thisYearRun[2].userName}</span>

                </Link>
              </td>

              <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
              </svg></div>{thisYearRun[2].pitchCount}</td>
              <td>{thisYearRun[2].runs}</td>
            </tr>}
          {thisYearRun.slice(3, 9).map((data, index) => (
            data.userId === user_Id ?
              <tr className="table__data highlighted__user" key={index}>
                <td className="imagex__data">
                  {index + 4}
                  <span className="user-image__wrapperx">
                    <img
                      src={data.profilePhoto ? data.profilePhoto : defaultAvatar}
                      alt="user"
                      className="user__imagex"
                    />
                  </span>
                </td>
                <td>
                  <Link
                    to={`/profile/${data.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className="user__name"
                      style={{ color: "white" }}
                    >
                      YOU
                    </span>
                  </Link>
                </td>

                <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                  <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
                </svg>

                </div>{data.pitchCount}</td>
                <td>{data.runs}</td>
              </tr> : <tr className="table__data" key={index}>
                <td className="imagex__data">
                  {index + 4}
                  <span className="user-image__wrapperx">
                    <img
                      src={data.profilePhoto ? data.profilePhoto : defaultAvatar}
                      alt="user"
                      className="user__imagex"
                    />
                  </span>
                </td>
                <td>
                  <Link
                    to={`/profile/${data.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className="user__name">{data.userName.length > 10 ? data.userName.substring(0, 10) + "..." : data.userName}</span>

                  </Link>
                </td>

                <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                  <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
                </svg></div>{data.pitchCount}</td>
                <td>{data.runs}</td>
              </tr>
          ))}
          {thisYearInTopTen && <tr className="table__data ">
            <td className="imagex__data row_threenine">
              10
              <span className="user-image__wrapperx">
                <img
                  src={thisYearRun[9].profilePhoto ? thisYearRun[9].profilePhoto : defaultAvatar}
                  alt="user"
                  className="user__imagex"
                />
              </span>
            </td>
            <td>
              <Link
                to={`/profile/${thisYearRun[9].userId}`}
                style={{ textDecoration: "none" }}
              >
                <span className="user__name">{thisYearRun[9].userName.length > 10 ? thisYearRun[9].userName.substring(0, 10) + "..." : thisYearRun[9].userName}</span>
              </Link>
            </td>

            <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
              <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
            </svg></div>{thisYearRun[9].pitchCount}</td>
            <td>{thisYearRun[9].runs}</td>
          </tr>}
          {!thisYearInTopTen && <tr className="table__data highlighted__user">
            <td className="imagex__data row_threenine">
              {thisYearRunsByUserId.runIndex}
              <span className="user-image__wrapperx">
                <img
                  src={thisYearRunsByUserId.profilePhoto ? thisYearRunsByUserId.profilePhoto : defaultAvatar}
                  alt="user"
                  className="user__imagex"
                />
              </span>
            </td>
            <td>
              <Link
                to={`/profile/${thisYearRunsByUserId.userId}`}
                style={{ textDecoration: "none" }}
              >


                <span className="user__name" style={{ color: "white" }}>YOU</span>

              </Link>
            </td>

            <td class="one"><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
              <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
            </svg>

            </div> {thisYearRunsByUserId.pitchCount}</td>
            <td>{thisYearRunsByUserId.runs}</td>
          </tr>}
        </tbody>
      </table>

      {/* {Today}   */}

    </section>
  );
};
export default ThisYearRunPopup;
