import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PaginationRounded from "../PaginationRounded";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import {MenuItem,InputLabel,Select} from "@mui/material";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = () => {
  const [name, setName] = useState("");
  const [role, setRole]= useState("");
  const [country, setCountry]=useState("");
  const [team, setTeam]=useState("");
  const [img, setImg]=useState("");
 
  const handlePredictionCreation = async () => {
    if (!accessToken) return
   
    
    const data = {
      playerName: name ,
      role: role ,
      country: country,
      team: team ,
      playerImage:img,
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/fantasyPlayers/createPlayer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data,
    };
    axios(options)
      .then(({ data }) => {

        if (data._id) {

          setName("");
          setRole("");
          setCountry("");
          setTeam("");
          setImg("");
          toast.success("New Player created successfully");

        }


        // const predictionQuestionOptions = {
        //   method: "POST",
        //   url:
        //     global.config.ROOTURL.prod +
        //     "/contest/predictionQuestion/createPredictionQuestion",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     Authorization: "Bearer " + accessToken,
        //     role: "Admin",
        //   },
        //   data: {
        //     predictionId: data._id,
        //     question: question1RefValue,
        //     options: [
        //       option1RefValue,
        //       option2RefValue,
        //       option3RefValue,
        //       option4RefValue,
        //       showOption5 && option5RefValue,
        //       showOption6 && option6RefValue,
        //     ],
        //   },
        // };
        // axios(predictionQuestionOptions)
        //   .then((data) => {
        //     toast.success("New Prediction created successfully");
        //     setName("");
        //     startDayRef.current.value = "";
        //     startTimeRef.current.value = "";
        //     runsForEntryRef.current.value = "";
        //     endDayRef.current.value = "";
        //     endTimeRef.current.value = "";
        //     rewardPriceRef.current.value = "";
        //     question1Ref.current.value = "";
        //     option1Ref.current.value = "";
        //     option2Ref.current.value = "";
        //     option3Ref.current.value = "";
        //     option4Ref.current.value = "";
        //     option5Ref.current.value = "";
        //     option6Ref.current.value = "";
        //   })
        //   .catch((error) => {
        //     if (error?.response?.status === 401) {
        //       console.log(error);
        //     }
        //   });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  
  return (
    <div className="contest-admin-create">
      <div className="contest-admin-create-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-form">
            <h1>Create Player</h1>
            <div className="contest-create-name-wrapper">
              Name-
              <input
                type="text"
                className="contest-create-name"

                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                required
              />
              
            </div>
            <div className="contest-create-name-wrapper">
                  Country-
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={country}
                    style={{width:"100px",marginLeft:"5px"}}
                    onChange={(event) => {
                      setCountry(event.target.value);
                    }}
                  >
                  <MenuItem value="">
                    <em>Country</em>
                  </MenuItem>
                   {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                      </Select>
                  </div>
                  <div className="contest-create-name-wrapper">
                  Image Link-
                  <input
                    type="text"
                    className="contest-create-name"
    
                    value={img}
                    onChange={(event) => {
                      setImg(event.target.value);
                    }}
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Role-
                  <input
                    type="text"
                    className="contest-create-name"
    
                    value={role}
                    onChange={(event) => {
                      setRole(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  IPL Team-
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={team}
                    style={{width:"120px",marginLeft:"5px"}}
                    onChange={(event) => {
                      setTeam(event.target.value);
                    }}
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Bangalore'}>Bangalore</MenuItem>
                  <MenuItem value={'Chennai'}>Chennai</MenuItem>
                  <MenuItem value={'Mumbai'}>Mumbai</MenuItem>
                  <MenuItem value={'Gujarat'}>Gujarat</MenuItem>
                  <MenuItem value={'Lucknow'}>Lucknow</MenuItem>
                  <MenuItem value={'Kolkata'}>Kolkata</MenuItem>
                  <MenuItem value={'Rajasthan'}>Rajasthan</MenuItem>
                  <MenuItem value={'Hyderabad'}>Hyderabad</MenuItem>
                  <MenuItem value={'Punjab'}>Punjab</MenuItem>
                  <MenuItem value={'Delhi'}>Delhi</MenuItem>
                  </Select>
                  
                 
                </div>
            
            
            
            <button
              onClick={handlePredictionCreation}
              className="contest-preediction-admin-creation-submit-button"
            >
              Confirm
            </button>
            {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
