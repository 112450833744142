import React from "react";
import { useState, useEffect, useContext } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Rank1 from "../../assets/images/rewards/AuctionAce/Rank1.png";
import Rank2 from "../../assets/images/rewards/AuctionAce/Rank2.png";
import Rank3 from "../../assets/images/rewards/AuctionAce/Rank3.png";
import crown from "../../assets/images/rewards/AuctionAce/Medal.png";


import { SocketContext } from '../../context/SocketContext';
import { getStorageItem } from "../../utils/sessionStorage";
import useGetPlayerById from "./useGetPlayerById";

const LeaderBoardRank = (props) => {
    const { playerId } = props;
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const mobileView = useMediaQuery("(max-width:768px)");
    const [socket, setSocket] = useContext(SocketContext);
    const [socketConnected, setSocketConnected] = useState(false);
    const { playerDetails, getPlayerById } = useGetPlayerById();
    const hasWinners = playerDetails?.winner && playerDetails?.winner?.length > 0;

    useEffect(() => {
        getPlayerById(playerId);
    }, [accessToken, playerId]);


    useEffect(() => {
        if (!socket) return;

        const handleWinnerAnnounced = (data) => {
            if (data.playerId === playerId) {
                getPlayerById(playerId);
            }
        };

        socket.on("connect", () => setSocketConnected(true));
        socket.on("winnersAnnounced", handleWinnerAnnounced);


        return () => {
            socket.off("connect");
            socket.off("winnersAnnounced", handleWinnerAnnounced);
        };
    }, [socket, playerId]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            width: mobileView ? '100%' : '30%',
            height: '100vh',
            background: '#EEF7FF',
            borderRadius: '30px',
            padding: '15px 0px',
            position: 'relative'
        }}>
            <Typography
                variant="h2"
                align="center"
                sx={{
                    color: '#0C5BA0',
                    fontWeight: 600,
                    fontSize: '25px'
                }}
            >
                Leaderboard
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        marginTop: '60px'
                    }}
                >
                    <Avatar
                        src={hasWinners && playerDetails.winner.length > 0 ? playerDetails.winner[1]?.userId?.profilePhoto : null}
                        alt='Rank2'
                        onClick={() => navigate(`/profile/${hasWinners && playerDetails.winner[1].userId._id}`)}
                        style={{ cursor: 'pointer' }}
                    />
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{
                            fontWeight: 400,
                            color: '#313335',
                            fontSize: '1rem',
                            cursor: 'pointer'
                        }}
                    >
                        {hasWinners && playerDetails.winner[1] ? `${playerDetails.winner[1].userId.firstName || ""} ${playerDetails.winner[1].userId.lastName || ""}` : ""}
                    </Typography>
                    <Box sx={{
                        width: '90%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0C5BA0',
                        borderRadius: '8px',
                        padding: '5px'
                    }}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                fontWeight: 400,
                                color: '#FFFFFF',
                                fontSize: '12px',
                            }}
                        >
                            {hasWinners && playerDetails.winner[1] ? `${playerDetails.winner[1].prediction || 0}` : ""}L
                        </Typography>
                    </Box>
                    <img src={Rank2} alt='rank1' style={{ width: '100%' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        position: 'relative',
                    }}
                >
                    <Avatar
                        src={hasWinners && playerDetails.winner.length > 0 ? playerDetails.winner[0]?.userId?.profilePhoto : null}
                        alt='Rank1'
                        onClick={() => navigate(`/profile/${hasWinners && playerDetails.winner[0].userId._id}`)}
                        style={{ cursor: 'pointer' }}
                    />
                    <img src={crown} alt='crown' style={{ width: '25px', height: '25px', position: 'absolute', top: '-20px' }} />
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{
                            fontWeight: 400,
                            color: '#313335',
                            fontSize: '15px',
                            cursor: 'pointer'
                        }}
                    >
                        {hasWinners && playerDetails.winner[0] ? `${playerDetails.winner[0].userId.firstName || ""} ${playerDetails.winner[0].userId.lastName || ""}` : ""}
                    </Typography>
                    <Box sx={{
                        width: '90%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0C5BA0',
                        borderRadius: '8px',
                        padding: '5px'
                    }}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                fontWeight: 400,
                                color: '#FFFFFF',
                                fontSize: '12px',
                            }}
                        >
                            {hasWinners && playerDetails.winner[0] ? `${playerDetails.winner[0].prediction || 0}` : ""}L
                        </Typography>
                    </Box>
                    <img src={Rank1} alt='rank1' style={{ width: '100%' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        marginTop: '80px'
                    }}
                >
                    <Avatar
                        src={hasWinners && playerDetails.winner.length > 0 ? playerDetails.winner[2]?.userId?.profilePhoto : null}
                        alt='Rank3'
                        onClick={() => navigate(`/profile/${hasWinners && playerDetails.winner[1].userId._id}`)}
                        style={{ cursor: 'pointer' }}
                    />
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{
                            fontWeight: 400,
                            color: '#313335',
                            fontSize: '15px',
                            cursor: 'pointer'
                        }}
                    >
                        {hasWinners && playerDetails.winner[2] ? `${playerDetails.winner[2].userId.firstName || ""} ${playerDetails.winner[2].userId.lastName || ""}` : ""}
                    </Typography>
                    <Box sx={{
                        width: '90%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0C5BA0',
                        borderRadius: '8px',
                        padding: '5px'
                    }}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                fontWeight: 400,
                                color: '#FFFFFF',
                                fontSize: '12px',
                            }}
                        >
                            {hasWinners && playerDetails.winner[2] ? `${playerDetails.winner[2].prediction || 0}` : ""}L
                        </Typography>
                    </Box>
                    <img src={Rank3} alt='rank3' style={{ width: '100%' }} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '285px',
                    background: '#FFFFFF',
                    borderRadius: '12px',
                    marginTop: '10px',
                    overflow: 'auto',
                    padding: '20px 15px 0px 15px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    position: 'absolute',
                    top: '350px',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
            >
                {playerDetails?.winner?.slice(3).map((item, index) => (
                    <Box
                        key={index + 4}
                        sx={{
                            width: '100%',
                            border: '1px solid var(--Neutral-Stroke-1-Rest, #D1D1D1)',
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '8px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#4F4F4F',
                                    fontSize: '14px',
                                    cursor: 'pointer'
                                }}
                            >
                                {index + 4}
                            </Typography>
                            <Avatar
                                src={item?.userId?.profilePhoto || null}
                                sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                                onClick={() => navigate(`/profile/${item?.userId?._id}`)}
                                style={{ cursor: 'pointer' }}
                            />
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#4F4F4F',
                                    fontSize: '14px',
                                    cursor: 'pointer'
                                }}
                            >
                                {`${item?.userId?.firstName || ""} ${item?.userId?.lastName || ""}`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    fontWeight: 400,
                                    color: '#494D50',
                                    fontSize: '13.4px'
                                }}
                            >
                                {item.prediction || 0}L
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default LeaderBoardRank;
