import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery, Grid, Container, Link, Button, TextField, Input, Avatar } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import Modal from '@mui/material/Modal';
import jobsuccess from "../../assets/images/icons/jobsuccess.png"
import success from "../../assets/images/icons/sucess.png"

import { useRef } from 'react';

const baseURL = config.ROOTURL.prod;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius:"10px",
    boxShadow: 24,
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    p: 4,
};

const EasyApply = ({ open, setOpen, handleclose }) => {
    const aRef = useRef(null);
    const { id } = useParams();
    const mobileView = useMediaQuery("(max-width:959px)");
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const userName = getStorageItem("full_name");
    const profilePhotoUrl = getStorageItem('avatar');
    const [post, setPost] = useState("");
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [resume, setResume] = useState("");
    const [fileName, setFileName] = useState("");


    const [isSubscriber, setIsSubscriber] = useState(false);
    const [count, setCount] = useState()
    const [submitMessage, setSubmitMessage] = useState("")

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const handleOpenSuccessModal = () => setOpenSuccessModal(true);
    const handleCloseSuccessModal = () => setOpenSuccessModal(false);

    // console.log("this is id:", id);
    // console.log("this is UserId:", userId);


    const getJobApplicationCount = async()=>{
        let getCount = {
            method: "GET", // Change method to POST for passing userId in the body
            url: `${baseURL}/job/getJobApplicationCountFromLastMonth/${userId}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getCount).then((response) => {
            // console.log(response)
            setCount(response.data.count)
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(()=>(
        checkUserIsSubscriber()
    ),[])
    console.log(userId)

    const checkUserIsSubscriber = async()=>{
         let checkIsSubcriber = {
            method: "GET",
            url: `${baseURL}/pay/isSubscriber/${userId}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(checkIsSubcriber).then((response) => {
            //data
            // console.log(response.data.status)
            if(response?.data?.status==="active"){
                setIsSubscriber(true)
            }else {
                getJobApplicationCount()  
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const uploadImage = (event) => {
        setResume("");
        const MAX_FILE_SIZE=5120;
        const resume1 = event.target.files[0];
        const formData = new FormData();
        const fileSize = resume1.size / 1024 ; // in MiB
        console.log("fileSize",fileSize)
        if (resume1.type === "application/pdf" || resume1.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            if (fileSize < MAX_FILE_SIZE ) {
                formData.append("uploader", resume1);
                console.log("This is upload img function call:");
                axios.post(`${baseURL}/upload-file`, formData, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    }
                }).then((response) => {
                    if (response.data) {
                        setResume(response.data[0].location);
                        setFileName(response.data[0].originalname);
                    }
                    else {
                        console.log("error occured:");
                    }

                }).catch((error) => {
                    console.log(error);
                });
              
            } else {
                toast.error('File size exceeds 5 MB', { theme: "colored" });
            }

        }
        else {
            toast.error("only pdf and word files are accepted", { theme: "colored" });
        }
    }
    const handleClose = () => {
        setOpen(false);
        handleclose();
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if(!isSubscriber && count>=50) {
            setSubmitMessage("You Can't Apply To This Job ")
            setOpenModal(true);
            return ;
        }
        if (!email || !mobile || !resume) {
            toast.error("Field is required!");
            return;
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!email.match(validRegex)) {

            toast.error("Invalid email address!");
            return;

        }
        if (mobile.length != 10) {
            toast.error("Invalid Phone Number")
            return;
        }
        //...if we want to use file upload then call aws file  microservice api and then pass
        const option = {
            emailId: email,
            jobId: id,
            userId,
            contactNo: mobile,
            userName, resume
        }

        let postData = {
            method: "POST",
            url: `${baseURL}/job/applyJob`,
            data: option,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(postData).then((response) => {
            console.log("this is post response:", response)
            if (response.data !== "false" && response.data) {
                toast.success("Job Submited successfully", { theme: "colored" });

                if (response) {
                    // toast.success("Job Posted Successfully", { theme: "colored" });
                    setSubmitMessage("Your Application Submitted")
                    // if(isSubscriber){
                    //     setOpenSuccessModal(true);
                    // }else{
                    //     // increament count;
                    //     setCount(count + 1);
                    //     setOpenModal(true)
                    // }
                    
                    // setTimeout(() => {
                    //     setOpenModal(false)
                        
                    //     window.location.href = "/jobs";
                    // }, 3000);
    
                }

                console.log("Job posted successfully");
                // send email 
                const details = {
                    jobId: id,
                    applicantUserId: userId,
                    applicantEmailId: email,
                    applicantContactNo: mobile,
                    applicantUserName: userName,
                    resume, fileName
                }
                let jobDetails = {
                    method: "POST",
                    url: `${baseURL}/job/getEmailByJobIdAndSendEmail`,
                    data: details,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                };
               
                axios(jobDetails).then((response) => {
                     if (response) {
                        console.log("email sent successfully");
                    } else {
                        console.log("error occured:");
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setEmail("");
                setMobile("");
                aRef.current.value = null;
                // setTimeout((e) => {
                //     window.location.href = "/jobs";
                // }, 4000)
            }
            else if (response.data === "false") {
                toast.error("already applied", { theme: "colored" });
            }
            else {
                toast.error("Some error occured", { theme: "colored" });
                
            }
        }).catch((error) => {
            console.log(error);
        });
    };
    useEffect(() => {
        axios.get(`${baseURL}/job/getByjobId/${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            }
        }).then((response) => {
            setPost(response.data.data);
            // console.log("this is response data:", response.data)
        }).catch(error => {
            setError(error);
        });
    }, [id, accessToken]);
    if (error) return `Error: ${error.message}`;
    // if (!post) return "No post!"
    const theme = createTheme(
        {
            typography: {
                fontFamily: 'poppins, Arial',
            },
        }
    );

    return (

        // <Button onClick={handleOpen}>Open modal</Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <ThemeProvider theme={theme}>
                <React.Fragment>
                    {(post._id === id) &&
                        <Grid container spacing={mobileView ? 0 : 2} >
                            <Grid item sm={12} md={3} lg={3} columns={{ xs: 4, sm: 8, md: 8, }} sx={{ mx: 'auto' }}>
                                <Card container variant="outlined" sx={{ minWidth: 275, p: 3, mt: 10 }}>
                                    <Typography sx={{ fontSize: 20, fontWeight: 'regular', color: 'black', borderBottom: '2px solid #a7a7a8' }} color="text.dark" gutterBottom>
                                        {post.jobTitle}
                                    </Typography>

                                    <Typography sx={{ fontSize: 20, fontWeight: 'regular', color: 'black' }} color="text.dark" gutterBottom>
                                        Contact Info
                                    </Typography>
                                    <Box display="inline-block" >
                                        <img src={profilePhotoUrl} alt='' width={60} height={60} sx={{ border: '1px solid red', ml: 2, width: '60px', height: '60px', }} />
                                    </Box>
                                    <Box display="inline-block" sx={{ ml: 2 }} >    <span sx={{ fontWeight: 'bold' }}>{userName}</span><br />
                                    </Box>
                                    {/* <CardContent> */}
                                    <Box>
                                        <Typography variant="regular"
                                            color="text.secondary"
                                            sx={{ fontSize: 15, fontWeight: 'regular' }}
                                            component="div">

                                            <TextField
                                                id="standard-basic"
                                                label="Email*"
                                                size="small"
                                                variant="outlined"
                                                fullWidth sx={{ marginBottom: 1 }}
                                                onChange={e => setEmail(e.target.value)}
                                                value={email} />
                                        </Typography>
                                        <Typography variant="regular" color="text.secondary" sx={{ fontSize: 15, fontWeight: 'regular' }} component="div">

                                            <TextField id="standard-basic"
                                                type="number"
                                                label="Phone Number*"
                                                size="small" variant="outlined"
                                                fullWidth sx={{ marginBottom: 1 }}
                                                onChange={(e) => setMobile(e.target.value)}
                                                value={mobile} />
                                        </Typography>
                                        <Typography variant="regular" color="text.secondary" sx={{ fontSize: 15, fontWeight: 'regular' }} component="div">
                                            <TextField
                                                type="file"
                                                size="small" variant="outlined"
                                                fullWidth sx={{ marginBottom: 1 }}
                                                margin="normal"
                                                inputRef={aRef}
                                                onChange={uploadImage}
                                                required
                                            /> </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'right', borderTop: '2px solid ' }}>
                                        <div style={{ marginTop: 6, display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <Button size="small" variant='contained' onClick={handleClose}>Cancel</Button>
                                            </div>
                                            <div>
                                              {(email && mobile && resume) ? <Button size="small" variant='contained' onClick={handleSubmit}>Submit</Button>:<Button size="small" variant='contained' onClick={handleSubmit} disabled>Submit</Button>}
                                             
                                            </div>
                                        </div>

                                    </Box>

                                </Card >
                            </Grid>
                        </Grid>
                    }

{   
                    openModal && (
                        <div>
                            {/* <Button onClick={handleOpenModal}>Open modal</Button> */}
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={jobsuccess} width={130} alt="Job Success" />
                                    </div>
                                    <p style={{ color: "#313335", fontWeight: "600", fontSize: "20px", marginBottom: "8px" }}>ATTENTION</p>

                                    <p>
                                        {submitMessage}
                                    </p>
                                    <p style={{ color: "#F37121", fontWeight: "bold", marginBottom: "8px" }}>Only <span style={{ color: "#C1510B" }}> {50 - count} FREE </span>Jobs available</p>
                                    <p style={{ color: "#62666A" }}>Subscribe now for unlimited</p>
                                    <p style={{ fontWeight: "bold", color: "#62666A", marginBottom: "17px" }}>Job Posting & Applying</p>
                                    <Typography>
                                        <button style={{ backgroundColor: "#0C5BA0", border: "none", padding: "10px", borderRadius: "7px", width: "100%", width: "350px", margin: "0 auto" }} >
                                            <a href="/champhunt-plus" style={{ color: "white", textDecoration: "none", marginLeft: '7px', fontWeight: "500" }}>
                                                Go to subscription page
                                            </a>
                                        </button>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    )
                }
                {   
                    openSuccessModal && (
                        <div>
                            {/* <Button onClick={handleOpenModal}>Open modal</Button> */}
                            <Modal
                                open={openSuccessModal}
                                onClose={handleCloseSuccessModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={success} width={130} alt="Job Success" />
                                    </div>
                                    <p style={{ color: "#313335", fontWeight: "600", fontSize: "20px", marginBottom: "8px" }}>CONGRATULATIONS</p>

                                    <p style={{color:"#62666A", marginBottom:"10px"}}>
                                        {submitMessage}
                                    </p>
                                    <Typography>
                                        <button style={{ backgroundColor: "#0C5BA0", border: "none", padding: "10px", borderRadius: "7px", width: "100%", width: "350px", margin: "0 auto" }} >
                                            <a href="/" style={{ color: "white", textDecoration: "none", marginLeft: '7px', fontWeight: "500" }}>
                                            Go to pitch page
                                            </a>
                                        </button>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    )
                }
                </React.Fragment >
            </ThemeProvider>
        </Modal>
    );
};
export default EasyApply;
