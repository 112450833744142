import {
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import EditEventComponent from "../../components/edit-event-admin";

const EditEvent = () => {
  return (
    <Box>
      <Typography variant="h4">Update Event</Typography>
      <Box marginTop={2}>
        <EditEventComponent />
      </Box>
    </Box>
  );}

export default EditEvent