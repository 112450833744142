import React, { useState } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { getStorageItem } from "../../../../utils/sessionStorage";
import useImageUploader from "../../../../utils/useImageUploader";

const AdminCreateAdd = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isCompulsory, setIsCompulsory] = useState(false);
  const [pitch, setPitch] = useState(false);
  const [url, setUrl] = useState("");
  const [urlTitle, setUrlTitle] = useState("");
  const [adUrl, setAdUrl] = useState("");
  const [story, setStory] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [dealId, setDealId] = useState(null);
  const { uploadImage, uploading, error, imageUrl } = useImageUploader();
  const accessToken = getStorageItem("token");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileUpload = async () => {
    try {
      if (file) {
        const videoUrl = await uploadImage(file, 0.8);
        setUrl(videoUrl);
        setUploadSuccess(true);
      } else {
        toast.error("No file selected");
        setUploadSuccess(false);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("Failed to upload video");
      return false;
    }
  };

  const handleAdCreation = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (file && !url) {
      await handleFileUpload();
      if (!uploadSuccess) {
        setLoading(false);
        return;
      }
    }

    if (!url) {
      toast.error("No URL for video. Failed to upload video.");
      setLoading(false);
      return;
    }

    // Create data object for ad creation
    const data = {
      title,
      description,
      url,
      urlTitle,
      adUrl,
      isCompulsory,
      dealId,
      pitch,
      story,
    };

    // Validate input
    if (!title || !description || !url ) {
      toast.error("Incomplete information");
      setLoading(false);
      return;
    }

    // Proceed with ad creation request
    try {
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/ads/create-ad`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
          },
        }
      );
      console.log("Advertisement created successfully:", response.data);
      toast.success("Advertisement created successfully!");
    } catch (err) {
      console.error("Error creating advertisement:", err);
      toast.error("Failed to create advertisement");
    } finally {
      // Reset form states and loading status
      setLoading(false);
      setTitle("");
      setDescription("");
      setUrl("");
      setUrlTitle("");
      setAdUrl("");
      setFile(null);
      setFileName("");
      setDealId(null);
      setIsCompulsory(false);
    }
  };

  // Handle file change
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  return (
    <div className="ad-creation-wrapper">
      <h1 className="ad-creation__title">Upload Videos</h1>
      <form className="ad-creation__form" onSubmit={handleAdCreation}>
        <div>
          <label htmlFor="ad-title" className="ad-creation__form-label">
            Title
          </label>
          <input
            id="ad-title"
            type="text"
            className="ad-creation__form-input"
            placeholder="Weekend Highlights"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="ad-description" className="ad-creation__form-label">
            Description
          </label>
          <textarea
            id="ad-description"
            className="ad-creation__form-textarea"
            placeholder="Enter a description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        {/* <div>
          <label htmlFor="ad-url" className='ad-creation__form-label'>URL</label>
          <input id="ad-url" type="string" className='ad-creation__form-input' placeholder='Ad Url' value={adUrl} onChange={(e) => setAdUrl(e.target.value)} required />
        </div> */}

        <div>
          <label htmlFor="ad-url" className='ad-creation__form-label'>URL</label>
          <input id="ad-url" type="string" className='ad-creation__form-input' placeholder='Ad-Url' value={adUrl} onChange={(e) => setAdUrl(e.target.value)} required />
        </div>

        <div>
          <label htmlFor="button-title" className="ad-creation__form-label">
            Url Title
          </label>
          <input
            id="button-title"
            type="text"
            className="ad-creation__form-input"
            placeholder="Url Button Name"
            value={urlTitle}
            onChange={(e) => setUrlTitle(e.target.value)}
            required
          />
        </div>

        <div style={{display:"flex", alignItems:"center"}}>
        <input
            id="ad-checkbox"
            type="checkbox"
            className="ad-creation__form-checkbox"
            checked={isCompulsory}
            onChange={() => setIsCompulsory(!isCompulsory)}
          />
          <label htmlFor="ad-checkbox" className="ad-creation__form-label">
            Make compulsory
          </label>
        </div>
        <div>
          <label htmlFor="deal-id" className="ad-creation__form-label">
            Deal Id
          </label>
          <input
            id="deal-id"
            type="text"
            className="ad-creation__form-input"
            placeholder="Enter deal id"
            value={dealId}
            onChange={(e) => setDealId(e.target.value)}
          />
        </div>
        <div style={{display:"flex", alignItems:"center"}}>
          <input
            id="ad-checkbox1"
            type="checkbox"
            className="ad-creation__form-checkbox"
            checked={pitch}
            onChange={() => setPitch(!pitch)}
          />
          <label htmlFor="ad-checkbox1" className="ad-creation__form-label">
            Show on Pitch
          </label>
        </div>

        <div style={{display:"flex", alignItems:"center"}}>
          <input
            id="ad-checkbox1"
            type="checkbox"
            className="ad-creation__form-checkbox"
            checked={story}
            onChange={() => setStory(!story)}
          />
          <label htmlFor="ad-checkbox1" className="ad-creation__form-label">
            Show on Story
          </label>
        </div>
        <div>
          <label htmlFor="ad-file" className="ad-creation__form-file-label">
            <div>
              <span>Browse files...</span>
              <input
                id="ad-file"
                type="file"
                className="ad-creation__form-file"
                accept="video/*"
                onChange={handleFileChange}
              />
              {fileName && <span>{fileName}</span>}
            </div>
          </label>
        </div>
        <div>
          <button
            type="submit"
            className={`${loading || uploading
              ? "ad-creation-form-submit--disabled"
              : "ad-creation-form-submit--active"
              }`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminCreateAdd;
