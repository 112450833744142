import React from "react";
import { Box } from "@mui/system";
import "./index.scss";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const AllEvents = ({ allEvents }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([...allEvents]);

  return (
    <Box >

      {allEvents.length > 0 && allEvents.map((event) => {
        return (
          <Box key={event._id}>
            {/* Check wether the event registration is going on or coming soon*/}
            <Box height="150px" width="100%" className="event-small" onClick={
              () => {
                if(event.eventNameCode !== ""){
                  navigate(`/tournament/${event._id}`)
                }else{
                  navigate(`/events/${event._id}`)
                }
              }
            }>
              <img
                src={event.eventBanner}
                alt="event-banner"
                height="150px"
                width="100%"
                className="event-banner-small"
              />
              <Box className="event-small-overlay"></Box>
              <Typography
                variant="h5"
                fontFamily="Poppins"
                fontWeight={600}
                fontSize="22px"
                color="#fff"
                textAlign="center"
                className="event-small-heading"
              >
                {event.eventLocation}
              </Typography>
              <Typography
                variant="p"
                fontFamily="Poppins"
                fontWeight={600}
                fontSize="14px"
                color="#fff"
                textAlign="center"
                className="event-small-subheading"
              >
                {event.eventTitle}
              </Typography>
            </Box>
          </Box>
        );
      })}
      {allEvents.length === 0 &&

        <Box>
          {/* Check wether the event registration is going on or coming soon*/}

          <Box height="150px" width="100%" className="event-small">
            {/* <img
                src={event.eventBanner}
                alt="event-banner"
                height="150px"
                width="100%"
                className="event-banner-small"
              /> */}
            <Box className="event-small-overlay"></Box>
            <Typography
              variant="p"
              fontFamily="Poppins"
              fontWeight={600}
              fontSize="20px"
              color="#fff"
              textAlign="center"
              className="event-small-subheading-soon"
            >
              Coming Soon
            </Typography>
          </Box>
          <Box className="event-small-read-more" onClick={() => {
            navigate('/all-events');
          }}>
            <Typography
              fontFamily="Poppins"
              fontWeight={600}
              fontSize="16px"
              color=" #0c5ba0"
              className="e-s-read-more"
            >
              Show More
            </Typography>
          </Box>
        </Box>
      }
      {allEvents.length > 0 &&
        <Box className="event-small-read-more" onClick={() => {
          navigate('/all-events');
        }}>
          <Typography
            fontFamily="Poppins"
            fontWeight={600}
            fontSize="16px"
            color=" #0c5ba0"
            className="e-s-read-more"
          >
            Show More
          </Typography>
        </Box>
      }
    </Box>

  );
};

export default AllEvents;
