import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import axios from "axios";
import "./index.scss";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { Button, Loading } from "carbon-components-react";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
// import { faPerson } from '@fortawesome/pro-thin-svg-icons'
import Index from "./index";
import LeaderboardModal from "./leaderboard-modal";
import Loader from "../../commons/components/Loader";
const user_Id = getStorageItem("user_id")
const Today = () => {
  const mobileView = useMediaQuery('(max-width:900px)');
  const [activeButtonFirst, setActiveButtonFirst] = useState(true);
  const [activeButtonSecond, setActiveButtonSecond] = useState(false);
  const [count, setCount] = useState(0);
  const [TodayRunData, setTodayRunData] = useState("");
  const [TodayRunsByUserIdData, setTodayRunsByUserIdData] = useState({ userId: "", userName: "", runs: 0, runIndex: 0, profilePhoto: "" });
  const [showModal, setShowModal] = useState(false);
  const [TodayDataOfUserInTopTen, setTodayDataOfUserInTopTen] = useState(false)
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const accessToken = getStorageItem("token");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick1 = () => {
    setActiveButtonFirst(true);
    setActiveButtonSecond(false);
    setCount(1)
  }
  const handleButtonClick2 = () => {
    setActiveButtonFirst(false);
    setActiveButtonSecond(true);
    setCount(1);
  }

  const style = {
    paddingTop: count > 0 ? "0px" : (mobileView ? "80px" : {})
  };
  const todayRunsOfYou = () => {
    if (!user_Id) return;
    setLoading(true)
    const getTodayRuns = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/leaderboard/today/findUserDetailsOfTodayByUserId/${user_Id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }
    axios(getTodayRuns)
      .then((response) => {
        console.log("setAllTimeRunsOfUserId", response);
        console.log("response.data", response.data)
        // setIsLoaded(true);
        if (response.data) {
          setLoading(false)
          setTodayRunsByUserIdData({
            userId: response.data.userId,
            userName: response.data.userName,
            runs: response.data.runs,
            runIndex: response.data.runIndex,
            profilePhoto: response.data.profilePhoto,
          }
          )
        }
      })
      .catch((err) => {
        console.log("error", err)
      });
  };

  const todayRuns = () => {
    if (!accessToken) return;
    setLoading(true)
    const getTodayRuns = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/leaderboard/today/runsToday/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }
    axios(getTodayRuns)
      .then((response) => {
        console.log("resp otday main page", response.data)
        if (response.data) {
          setLoading(false)
          setTodayRunData(response.data);
        }
        const TodayData = response.data;
        const TodayDataIds = TodayData.map(s => (s.userId))
        console.log("----TodayDataIds--", TodayDataIds);
        const todayRuns = TodayData.map(d => {
          // console.log("----TodayDataIds.includes(d.userId--",TodayDataIds.includes(d.userId));
          if (TodayDataIds.slice(0, 9).includes(user_Id.toString())) {
            d.isSaved = true;
            setTodayDataOfUserInTopTen(true)
          }
          return d;
        })
      })
      .catch((err) => {
        console.log("error", err)
      });

  };
  useEffect(() => {

    todayRunsOfYou();
    todayRuns();
  }, [])
  // if (!TodayRunData) return <div style={{margin:'auto'}}>Loading.....</div>

  return (
    <section className="leaderboard" style={style}>
      {activeButtonFirst && !isLoading && (


        <div className="main-wrapper-leaderboard">
          <Box className="pedestal">
            <Typography className="pedestal__title">Leaderboard</Typography>
            <Box className="header__buttons">
              <Box
                className={
                  activeButtonFirst ? "active-button" : "header__buttons--top"
                }
                onClick={handleButtonClick1}
              >
                Today
              </Box>
              <Box
                className={
                  activeButtonSecond
                    ? "active-button"
                    : "header__buttons--recent"
                }
                onClick={handleButtonClick2}
              >
                This Year
              </Box>
            </Box>
            {TodayRunData && TodayRunData.length > 0 && <div className="pedestal__best">
              <svg
                className="crown-svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0L6.25 4.5L12.5 0L18.75 4.5L25 0L21.7742 12H3.22581L0 0Z"
                  fill="url(#paint0_linear_1769_542)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1769_542"
                    x1="4.5"
                    y1="16"
                    x2="25"
                    y2="2.47954e-06"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFA44B" />
                    <stop offset="1" stopColor="#FFD046" />
                  </linearGradient>
                </defs>
              </svg>

              <div className="pedestal--1__wrapper">
                <div className="pedestal--1">
                  <Link
                    to={`/profile/${TodayRunData[0].userId}`}
                    style={{ textDecoration: "none" }}
                  >  <img
                      src={
                        TodayRunData[0].profilePhoto
                          ? TodayRunData[0].profilePhoto
                          : defaultAvatar
                      }
                      alt=""
                    /></Link>
                  <span className="pedestal-1__position">1</span>
                  <div className="position-1__details">
                    <Link
                      to={`/profile/${TodayRunData[0].userId}`}
                      style={{ textDecoration: "none" }}
                    >
                      {TodayRunData[0].userId === user_Id && (
                        <h2 className="position__title" style={{ color: "blue" }}>YOU</h2>
                      )}
                      {TodayRunData[0].userId !== user_Id && (
                        <h2 className="position__title">
                          {TodayRunData[0].userName.length > 10 ? TodayRunData[0].userName.substring(0, 10) + "..." : TodayRunData[0].userName}

                        </h2>
                      )}
                    </Link>{" "}
                    <p className="position__details">{TodayRunData[0].runs}</p>
                  </div>
                </div>
              </div>
              <div className="pedestal--2__wrapper">
                <div className="pedestal--2">
                  <Link
                    to={`/profile/${TodayRunData[1].userId}`}
                    style={{ textDecoration: "none" }}
                  >     <img
                      src={
                        TodayRunData[1].profilePhoto
                          ? TodayRunData[1].profilePhoto
                          : defaultAvatar
                      }
                      alt=""
                    /></Link>
                  <span className="pedestal-2__position">2</span>
                  <div className="position-2__details">
                    <Link
                      to={`/profile/${TodayRunData[1].userId}`}
                      style={{ textDecoration: "none" }}
                    >
                      {TodayRunData[1].userId === user_Id && (
                        <h2 className="position__title you" style={{ color: "blue" }}>YOU</h2>
                      )}
                      {TodayRunData[1].userId !== user_Id && (
                        <h2 className="position__title">
                          {TodayRunData[1].userName.length > 10 ? TodayRunData[1].userName.substring(0, 10) + "..." : TodayRunData[1].userName}
                        </h2>
                      )}
                    </Link>
                    <p className="position__details">{TodayRunData[1].runs}</p>
                  </div>
                </div>
              </div>
              <div className="pedestal--3__wrapper">
                <div className="pedestal--3">
                  <Link
                    to={`/profile/${TodayRunData[2].userId}`}
                    style={{ textDecoration: "none" }}
                  >      <img
                      src={
                        TodayRunData[2].profilePhoto
                          ? TodayRunData[2].profilePhoto
                          : defaultAvatar
                      }
                      alt=""
                    /></Link>
                  <span className="pedestal-3__position">3</span>
                  <div className="position-3__details">
                    <Link
                      to={`/profile/${TodayRunData[2].userId}`}
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      {TodayRunData[2].userId === user_Id && (
                        <h2 className="position__title you" style={{ color: "crimson" }}>YOU</h2>
                      )}
                      {TodayRunData[2].userId !== user_Id && (
                        <h2 className="position__title">
                          {TodayRunData[2].userName.length > 9 ? TodayRunData[2].userName.substring(0, 9) + "..." : TodayRunData[2].userName}
                        </h2>
                      )}
                    </Link>
                    <p className="position__details">{TodayRunData[2].runs}</p>
                  </div>
                </div>
              </div>

            </div>}
          </Box>
          {TodayRunData && TodayRunData.length > 0 && <Box className="top-rankers">
            {TodayRunData.slice(3, 9).map((data, index) => (
              <div
                className={
                  data.userId === user_Id ? " ranker-user" : "top-rankers__box"
                }
                key={index}
              >
                <span className="user__position">{index + 4}</span>
                <span className="user-image__wrapper">
                  <Link
                    to={`/profile/${data.userId}`}
                    style={{ textDecoration: "none" }}
                  >  <img
                      src={data.profilePhoto ? data.profilePhoto : defaultAvatar}
                      alt="user"
                      className="user__image"
                    /></Link>
                </span>
                <div>
                  <Link
                    to={`/profile/${data.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    {data.userId === user_Id && (
                      <span
                        className="user__name"
                        style={{ textDecorationColor: "Highlight" }}
                      >
                        YOU
                      </span>
                    )}
                    {data.userId !== user_Id &&
                      <span className="user__name">
                        {data.userName.length > 10 ? data.userName.substring(0, 10) + "..." : data.userName}
                      </span>
                    }
                  </Link>
                </div>
                <span className="user__runs">{data.runs}</span>
              </div>
            ))}
            {!TodayDataOfUserInTopTen && (
              <div className="user__box">
                <span className="user__position">
                  {TodayRunsByUserIdData.runIndex}
                </span>
                <span className="user-image__wrapper">
                  <Link
                    to={`/profile/${TodayRunsByUserIdData.userId}`}
                    style={{ textDecoration: "none" }}
                  >   <img
                      src={
                        TodayRunsByUserIdData.profilePhoto
                          ? TodayRunsByUserIdData.profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="user__image"
                    /></Link>
                </span>
                <span className="user__name">You</span>
                <svg
                  className="user-runs__svg"
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* <path d="M4.5 0L8.39711 6.75L0.602886 6.75L4.5 0Z" fill="#4EFF8B" /> */}
                </svg>

                <span className="user__runs">{TodayRunsByUserIdData.runs}</span>
              </div>
            )}
            {TodayDataOfUserInTopTen && (
              <div className="top-rankers__box">
                <span className="user__position">10</span>
                <span className="user-image__wrapper">
                  <Link
                    to={`/profile/${TodayRunData[9].userId}`}
                    style={{ textDecoration: "none" }}
                  >       <img
                      src={
                        TodayRunData[9].profilePhoto
                          ? TodayRunData[9].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="user__image"
                    /></Link>
                </span>
                <Link
                  to={`/profile/${TodayRunData[9].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span className="user__name">{TodayRunData[9].userName.length > 10 ? TodayRunData[9].userName.substring(0, 10) + "..." : TodayRunData[9].userName}</span>
                </Link>
                <span className="user__runs">{TodayRunData[9].runs}</span>
              </div>
            )}
          </Box>}
          <Box className="view-all">
            <h2 onClick={handleOpen}>View All</h2>
          </Box>

        </div>


      )}
      {isLoading &&
        <Loader isLoading={isLoading} />}
      {/* {Today}   */}
      {activeButtonSecond && <Index />}
      <LeaderboardModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
      />
    </section>
  );
};
export default Today;
