import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import AppContext from "../../context/AppContext";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
// Mui
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import { div } from '@material-ui/core';
const Index = () => {
  const navigate = useNavigate();

  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }))

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    email: "",
  });

  const [events, setEvents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [shouldload, setShouldload] = useState(false);

  const fetchUserInfo = () => {
    if (!accessToken && !userId) return
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;



  const fetchMoreData = () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/events/getAll/${page}/${global.config.pagePerSize}?ongoing=true`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(response => {

        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          // Append new data to existing items
          setEvents(prevItems => prevItems.concat(response.data));
          setPage(prevPage => prevPage + 1);
          setShouldload(true)
        }
      })
      .catch(error => console.log(error));
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserInfo();
    // Fetching all events
    fetchMoreData();
    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);

  const redirectUser = (url, _id) => {
    // Check if the user is already registered

    // Redirects User
    navigate(`${url}${_id}`);
  };

  const formatDate = (d) => {

    const originalDateString = `${d}`;
    const dateParts = originalDateString.split("-");
    const formattedDate1 = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    const formattedDate = new Date(`${formattedDate1}`).toLocaleString("en-us", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <div>
      <Helmet>
        <title>Champhunt | All-Events</title>
      </Helmet>
      {shouldload &&
        <div padding={1} style={{ paddingTop: '120px', display: 'block' }}>
          <div style={{display:'flex', justifyContent:'space-between', margin:"0px 10px 0px 30px"}}>
            <h6
              variant="h6"
              align="center"
              className="all-events-main-heading"
            >
              Events
            </h6>
            <div className="back-button">
              <Button variant="contained" color="primary"
                style={{ margin: "18px" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div >
            <InfiniteScroll
              style={{ overflow: "none" }}
              dataLength={events.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"

              />}
            >
              <Grid container>
                {events.map((event, index) => (
                  <Grid item xs={12} sm={6} md={6} key={index}>
                    <div className="event-banner-small-all">
                      <img
                        src={event.eventBanner}
                        alt="event-banner"
                        height="200px"
                        width="100%"
                        className="event-image-small"
                      />
                      <div className="main-overlay-small-all"></div>

                      <div className="centered-text">
                        <div className="event-location-centered">
                          <p>{event.eventLocation}</p>
                        </div>

                        <div className="event-date-centered">
                          <p>
                            {`${formatDate(event.startDate)} - ${formatDate(
                              event.endDate
                            )}`}
                          </p>
                        </div>
                      </div>

                      <div className="hidden-box-small">
                        <div>
                          <p className="event-name-small">
                            {event.eventTitle}
                          </p>
                        </div>
                        <div className="event-location-small">
                          <p variant="p" style={{fontSize:'18px', fontWeight:'500'}}>{event.eventLocation}</p>
                        </div>
                        <div className="event-date-small">
                          <p>
                            {`${formatDate(event.startDate)} - ${formatDate(
                              event.endDate
                            )}`}
                          </p>
                        </div>
                        <div className="button-wrapper-small-all">
                          <Button
                            variant="contained"
                            className="button-small-all"
                            onClick={() => {
                              if (event.eventNameCode !== "") {
                                navigate(`/tournament/${event._id}`)
                              } else {
                                navigate(`/events/${event._id}`)
                              }
                            }}
                          >
                            Register Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </div>
        </div>
      }
    </div>
  );
};

export default Index;
