import {
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import DealUpdateForm from "../../components/edit-deal-admin"
import { Container} from "@mui/material";
// import EditEventComponent from "../../components/edit-event-admin";

const EditDeal = () => {
  return (
    <Box>
      <Container style={{ display: "flex", justifyContent: 'space-between', borderBottom: '1px solid #dcd1d1' }}>
        <Typography variant="h4">Update Deals</Typography>
        <Box>
          <Typography style={{ paddingRight: '8px' }}>Champhunt Admin</Typography>
        </Box>
      </Container>
      <Box marginTop={2}>
        <DealUpdateForm />
      </Box>
    </Box>
  );}

export default EditDeal