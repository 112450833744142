import React from "react";
import "./index.scss";
import CartItem from "./CartItem";
import { Button } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { CartContext } from "../../context/CartContext";
import { AddressContext } from "../addressPage/addressContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";

import locationIcon from "../../assets/images/marketplace/location2.png";
import ballIcon from "../../assets/images/marketplace/redball.png";
import vanIcon from "../../assets/images/marketplace/van.png";

export default function Cart() {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileView = useMediaQuery("(max-width:900px)");
  // const [iscart,setIsCart]=useState(true);
  const [cart, setCart] = useContext(CartContext);
  const { address } = useContext(AddressContext);
  const [totalItems, setTotalItems] = useState(0);
  const isMobile = useMediaQuery("(max-width:768px)");
  const [totalRuns, setTotalRuns] = useState(0);
  const userId = getStorageItem("user_id");
  const userName = getStorageItem("full_name");
  const userAvatar =
    getStorageItem("avatar") ||
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
  const userEmail = getStorageItem("user_email");
  const accessToken = getStorageItem("token");


  const countTotalItems = (cart) => {
    const totalItemCount = cart.reduce((accumulator, product) => {
      if (product?.productInfo?.quantity !== undefined) {
        return accumulator + product.productInfo.quantity;
      }
      return accumulator;
    }, 0); // Initialize accumulator with 0
    setTotalItems(totalItemCount);
  };


  const countTotalRuns = (cart) => {
    const totalItemRuns = cart.reduce((accumulator, product) => {
      if (
        product?.productInfo?.quantity !== undefined &&
        product?.productInfo?.runs !== undefined
      ) {
        return (
          accumulator +
          product?.productInfo?.runs * product.productInfo.quantity
        );
      }
      return accumulator;
    }, 0); // Initialize accumulator with 0
    setTotalRuns(totalItemRuns);
  };

  const handleCreateBuyer = async (product) => {
    if (!userId && !accessToken) return
    try {
      const totalRunsResponse = await axios({
        method: "GET",
        url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      if (totalRunsResponse.data >= totalRuns) {
        if (!localStorage.getItem("buyer-address")) {
          toast.error("Buyer Address does not exist");
          navigate("/marketplace/address");
          return;
        }


        const address = JSON.parse(localStorage.getItem("buyer-address"));

        const buyerInformationData = {
          buyerId: userId,
          buyerName: userName,
          buyerEmail: userEmail,
          buyerProfile: userAvatar,
          productId: product?._id,
          buyerAddress: address,
          productQuantity: product?.quantity,
          sellerId: product?.sellerId,

        };

        const opt = {

          method: "POST",

          url:

            global.config.ROOTURL.prod + `/marketplace/buyer/createBuyer`,

          headers: {

            Authorization: "Bearer " + accessToken,

            "Content-Type": "application/json",

          },

          data: buyerInformationData,

        };

        axios(opt)

          .then((data) => {

          })

          .catch((error) => {

            console.log(error);

          });
        return { success: true };
      } else {
        toast.error("You are not eligible to buy this product");
      }

    }
    catch (err) {
      console.error("An error occurred:", err);
      // Optionally, rethrow the error to indicate that the promise was rejected.
      throw err;
    }
  };

  const runHandleCreateBuyer = async () => {
    try {
      // console.log('cart', cart)
      const promiseArray = cart.map((product) => {
        return handleCreateBuyer(product?.productInfo);
      });

      // console.log(promiseArray);

      // Wait for all promises to complete before navigating.
      const results = await Promise.all(promiseArray);

      const hasErrors = results.some(result => !result || !result.success);

      if (!hasErrors) {
        navigate(`/marketplace/order_status/${userId}`);
        toast.success("Order placed successfully")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  const handleByAddress = () => {
    navigate("/marketplace/address");
  }

  useEffect(() => {
    if (cart.length >= 0) {
      countTotalItems(cart);
      countTotalRuns(cart);
    }
  }, [cart]);

  const calculateSubtotal = (cart) => {
    let subtotal = 0;

    cart.forEach(item => {
      const { price, discount, quantity } = item.productInfo;
      const effectivePrice = price - discount;
      const totalPrice = effectivePrice * quantity;
      subtotal += totalPrice;
    });
    return subtotal;
  }


  return (
    <div className="main_cart_container">
      {/* <div className="address_main_container">
        <div className="address_container">
          <img className="location_icon" src={locationIcon} alt="" />
          <p className="address_text">{address?.fullName}, {address?.addressLine1}, {address?.addressLine2}, {address?.city},   {address?.state}, {address?.postalCode}</p>
        </div>
        <p
          className="change_address"
          onClick={() => {
            navigate("/marketplace/address");
          }}
        >
          Change
        </p>
      </div> */}
      <div className="cart_summary_container">
        <div className="cart_container">
          <h4 className="my_cart">My Cart</h4>
          {cart.length > 0
            ? cart.map((product, index) => (
              <CartItem key={index} isCart={true} product={product} index={index} />
            ))
            : "You dont have anything in cart"}
        </div>
        <div className="summary_container">
          <h4 className="order_summary">Order Summary</h4>
          {/* <div className="discount_container">
            <label className="label" htmlFor="discountCode">
              Discount code / Promo code
            </label>
            <input className="input_value" type="text" placeholder="Code" id="discountCode" />
          </div> */}
          <div className="subtotal_container">
            <p className="total_text">Subtotal ({totalItems})</p>
            <p className="total_text">&#8377;{calculateSubtotal(cart)}</p>
          </div>
          <div className="runs_container">
            {!isMobile && (
              <p className="total_run_text">Total Runs</p>
            )}
            <div className="runs_inner_contaianer">
              <img src={ballIcon} alt="" />
              <p className="total_run">{totalRuns} Runs</p>
            </div>
          </div>
          {cart?.length > 0 && (
            <Button className="buy_button" onClick={runHandleCreateBuyer}>
              <img className="vanIcon" src={vanIcon} alt="" />
              {isMobile ? (
                <p className="buy_text">Place Order</p>
              ) : (
                <div className="buy_text"><p style={{ fontWeight: 400 }}>Buy for</p> <storng style={{ color: "#FFFFFF" }}>{totalRuns} Runs</storng></div>
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
