import React, { useContext, useRef } from "react";
import "./index.css";
import { NavLink, useNavigate } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useAdCount } from '../../components/Rewards/Context';
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Link } from "@mui/material";
import { toast } from "react-toastify";
import Run from "../../components/posts/run";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { PathAction } from "../../redux/reducers/globalPath";
import { useLocation } from 'react-router-dom';
import AppContext from "../../context/AppContext";
import coin1 from "../../assets/images/coin-toss/5r.png";
import coin2 from "../../assets/images/coin-toss/5h.png";
import coin3 from "../../assets/images/coin-toss/5k.png";
import heads from "../../assets/images/coin-toss/head.png";
import tails from "../../assets/images/coin-toss/tail.png";
import char1 from "../../assets/images/coin-toss/character1.png";
import char2 from "../../assets/images/coin-toss/character2.png";
import ball from "../../assets/images/coin-toss/ball.png";
import ball2 from "../../assets/images/coin-toss/ball2.png";
import crown1 from "../../assets/images/coin-toss/crown1.gif";
import crown2 from "../../assets/images/coin-toss/crown2.gif";
import sadface from "../../assets/images/coin-toss/sadface.gif";

const Coin = () => {
  const navigate = useNavigate();
  // User Information
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  // Runs
  const [runs, setRuns] = useState(500);
  const [displayRuns, setDisplayRuns] = useState(0);
  const [insufficientRuns, setInsufficientRuns] = useState(false);
  const [notEligible, setNotEligible] = useState(false);
  const [choice, setChoice] = useState(false);
  const [spin, setSpin] = useState(false);
  const [result, setResult] = useState({
    coinResultOption: "",
    userSelectedOption: "",
    entryRun: "",
    rewardedRun: "",
  });
  // const [nextGameTime, setNextGameTime] = useState("");
  const [output, setOutput] = useState(false);
  const audioRef = useRef(null);
  const [audio, setAudio] = useState(false);
  const [showAdModal, setShowAdModal] = useState(false);
  const [ad, setAd] = useState(null);
  const [adWatched, setAdWatched] = useState(false);
  const [outputModalOpen, setOutputModalOpen] = useState(false);
  const [adPermissionModal, setAdPermessionModal] = useState(false);
  const [coinResult, setCoinResult] = useState(null);
  const [isCoinResultVisible, setIsCoinResultVisible] = useState(false);
  const { adCount, incrementAdCount } = useAdCount();
  const mobileView = useMediaQuery("(max-width:768px)")


  const handleCoinFlip = () => {
    setAudio(true);
    setTimeout(() => {
      setSpin(false);
      setOutput(true);
    }, 4000);
  };

  useEffect(() => {
    if (result?.coinResultOption) {
      setIsCoinResultVisible(false);
      setTimeout(() => {
        setCoinResult(result.coinResultOption);
        setIsCoinResultVisible(true);
      }, 2000); // Set the delay here (3000 milliseconds = 3 seconds)
    }
  }, [result]);
  // Check User Runs
  const getRuns = () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");
    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setDisplayRuns(response.data);
      })
      .catch((error) => {
        console.log("6")
        console.log(error)
      });
  };
  // Check Eligibility
  const checkEligibility = async () => {
    if (!runs || typeof runs !== "number") {
      toast.error("Invalid entry runs");
      return;
    }
    if (runs < 50 || runs > 5000) {
      toast.error("Runs should be between 50 to 5000");
      return;
    }

    if (runs > displayRuns) {
      setInsufficientRuns(true);
      return;
    }
    if (!accessToken) return;
    let eligibilityOptions = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/contest/coinToss/checkEligibility`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(eligibilityOptions)
      .then((response) => {
        if (response.data.eligible === false) {
          setNotEligible(true);
          // handleTimeLeft(response?.data.lastTimePlayed);
          return;
        }
        if (response?.status == 200) {
          setChoice(true);
        }
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 500) {
          setNotEligible(true);
          console.log(error);
        }
      });
  };

  // Choice
  const handleChoice = async (choice) => {
    if (!runs || typeof runs !== "number") {
      console.log("Invalid entry runs");
      return;
    }
    if (runs < 50 || runs > 5000) {
      toast.error("Runs should be between 50 to 5000");
      return;
    }
    if (runs > displayRuns) {
      setInsufficientRuns(true);
      return;
    }
    if (runs && choice) {
      let choiceOptions = {
        method: "POST",
        url: global.config.ROOTURL.prod + `/contest/coinToss/start-playing`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userSelectedOption: choice,
          entryRun: runs,
        },
      };
      await axios(choiceOptions)
        .then((response) => {
          if (response?.status === 200) {
            setChoice(false);
            setOutputModalOpen(true);
            setResult({
              coinResultOption: response?.data.coinResultOption,
              userSelectedOption: response?.data.userSelectedOption,
              entryRun: response?.data.entryRun,
              rewardedRun: response?.data.rewardedRun,
            });
            setSpin(true);
            handleCoinFlip();
          }
        })
        .catch((error) => {
          console.log("Creation error" + error);
          if (error?.response?.status === 500) {
            console.log(error);
          }
        });
    }
  };
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;

  useEffect(() => {
    getRuns();
    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);
  // useEffect

  useEffect(() => {
    if (insufficientRuns || notEligible || choice || spin || output) {
      document.body.style.overflow = "hidden"; // Prevent scrolling of body
      window.scrollTo(0, 0); // Set scroll position to top
    } else {
      document.body.style.overflow = "auto"; // Set body overflow back to normal
    }

    return () => {
      document.body.style.overflow = "auto"; // Set body overflow back to normal when unmounting
    };
  }, [insufficientRuns, notEligible, choice, spin, output]);
  useEffect(() => {
    const audioFile = new Audio("/audio/coin-toss.mp3");
    if (audio) {
      audioFile.play();
      setTimeout(() => {
        setAudio(false);
      }, 2000);
    }
  }, [audio]);

  useEffect(() => {
    if (output) {
      const audioFile = new Audio(
        result && result?.coinResultOption === result?.userSelectedOption
          ? "/audio/coin-toss-win.wav"
          : "/audio/coin-toss-loose.wav"
      );
      audioFile.play();
    }
  }, [output], result);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (audioRef.current && !audioRef.current.contains(event.target)) {
        audioRef.current.pause();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const fetchRandomAd = async () => {
    try {
      const response = await axios.get(`${global.config.ROOTURL.prod}/ads/fetch-random`, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      });
      if (response?.status === 200) {
        setAd(response?.data);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchRandomAd();
  }, []);

  const closeAdModal = () => {
    setShowAdModal(false);
  };

  const handleWatchFullAd = async () => {
    try {
      // Call the reset-time API here to reset the time and redirect the user back to /coin
      const response = await axios.get(`${global.config.ROOTURL.prod}/contest/coinToss/reset-time/${userId}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      const viewIncreaseResponse = await axios.post(
        `${global.config.ROOTURL.prod}/ads/increaseViewCount`,
        {
          _id: ad?._id,
          userId: userId
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          }
        }
      );
      if (response.status === 200 && viewIncreaseResponse.status === 200) {
        setShowAdModal(false);
        navigate("/rewards");
      }
      else {
        setShowAdModal(false);
        toast.error('Some error occured!');
        setTimeout(() => {
          navigate("/rewards");
        }, [1000]);
      }
    } catch (error) {
      console.error("Error watching ad:", error);
      setShowAdModal(false);
      setTimeout(() => {
        navigate("/rewards");
      }, [1000]);
    }
  };

  return (
    <div className="wrapper-coin">
      {/* Modal--insufficient */}
      {insufficientRuns ? (
        <div className="modal--insufficient-coin">
          <div className="modal__wrapper--insufficient-coin">
            <div>
              <img
                src={sadface}
                alt="sad-face"
                className="face--sad-coin"
              />
            </div>
            <p className="output__message-greeting">OOPS!!</p>
            <p className="output__message--last-coin">Sorry , You have insuffiecient Runs</p>
            <button
              onClick={() => navigate("/rewards")}
              className="output__next-coin">
              Back
            </button>
          </div>
        </div>
      ) : null}
      {/* Modal--notEligible */}
      {notEligible ? (
        <div className="modal--insufficient-coin">
          <div className="modal__wrapper--insufficient-coin">
            <div className="out__wrapper-coin">
              <img
                src={crown2}
                alt="insuffiecient-runs"
                className="crown-icon"
              />
            </div>
            <p className="output__message-greeting">OOPS!!</p>
            <p className="output__message--last-coin">
              You’ve played once in last 4 hours.
            </p>
            <p className="try-again-text">Please try again</p>
            <button
              onClick={() => navigate("/rewards")}
              className="output__next-coin">Back</button>
          </div>
        </div>
      ) : null}
      {/* Modal--advertisment */}
      <div>
        {showAdModal && (
          <div className="modal-backdrop-ad">
            <div className="modal-content-ad">
              <div className="modal-header-ad">
                <h2>{ad?.title}</h2>
                <button className="close-ad" onClick={closeAdModal}>
                  <AiOutlineCloseCircle />
                </button>
              </div>
              <div className="modal-body-ad">
                <video
                  src={ad?.url}
                  autoPlay
                  // controls
                  onEnded={() => {
                    setAdWatched(true);
                    handleWatchFullAd();
                  }} // Call handleWatchFullAd when the video ends
                ></video>
                <p>{ad?.description}</p>
              </div>
            </div>
          </div>
        )}

        {/* Output */}
        {adPermissionModal && outputModalOpen && (
          <div className="unique-output-modal-background">
            <div className="unique-output-modal-container">
              <button
                className="unique-output-close-button"
                onClick={() => {
                  setOutputModalOpen(false);
                  navigate("/rewards");
                }}
              >
                <AiOutlineCloseCircle />
              </button>
              <div className="bored__video">
                <img src="https://res.cloudinary.com/dndkskewk/image/upload/v1712180263/cqtde7zpdnvyswd2vsaj.gif" alt="Play Again Ad" />
              </div>
              <div className="unique-output-card">
                <h3 className="unique-output-title">Oohnoo</h3>
                <p className="unique-output-message">
                  Want another chance to play again
                </p>
                <button
                  className="unique-output-next-button"
                  onClick={() => {
                    // Trigger the ad modal here
                    setOutputModalOpen(false);
                    setShowAdModal(true);
                    incrementAdCount();
                  }}
                >
                  WATCH AD
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Output */}
      {output ? (
        <div className="modal--output-coin">
          <div className="output__main-coin">
            <div className="output__center-coin">
              {result &&
                result?.coinResultOption === result?.userSelectedOption ? (
                <img
                  src={crown1}
                  alt="pass"
                  className="output__face-coin"
                />
              ) : (
                <img
                  src={sadface}
                  alt="fail"
                  className="output__sadface"
                />
              )}
              <div className="output__message-greeting">
                {result && result?.coinResultOption === result?.userSelectedOption
                  ? <p>Congratulations !</p>
                  : <p>SORRY! <small style={{ color: "#71B7F4" }}>Well Played</small></p>}
              </div>
              <p className="output__message-coin">
                {result && result?.coinResultOption === result?.userSelectedOption
                  ? "You Won"
                  : "You Lose"}
              </p>
              {result && result?.coinResultOption === result.userSelectedOption
                ? (
                  <audio id="coin-sound" ref={audioRef}>
                    <source src="/audio/coin-toss-win.wav" type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                ) : (
                  <audio id="coin-sound" ref={audioRef}>
                    <source src="/audio/coin-toss-loose.mp3" type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                )
              }
              <div className="run-ball">
                <img className="ball2" src={ball2} alt="ball" />
                <p className="output__runs-coin">
                  {result &&
                    result?.coinResultOption === result?.userSelectedOption
                    ? result?.rewardedRun
                    : 0}
                </p>
              </div>
              <p className="output__message--last-coin">Try again after 4 hours</p>
              <button
                onClick={() => {
                  setOutput(false);
                  setAdPermessionModal(true);
                  if (adCount >= 2) {
                    setAdPermessionModal(false);
                    navigate("/rewards");
                  }
                }}
                className="output__next-coin">Back</button>
            </div>
          </div>
        </div>
      ) : null}
      {choice ? (
        <div className="content-coin">
          <div className="innner-wrapper">
            <img className="char2" src={char2} alt="No Image" />
            <div className="heading__box-coin">
              <h1 className="toss__heading-coin">Toss Ka Boss</h1>
              {mobileView ? (
                <h3 className="toss__subheading-coin">CHOOSE YOUR SIDE</h3>
              ) : (
                <h3 className="toss__subheading-coin">Win 2x Runs</h3>
              )}
              <div className="subheading__underline-coin"></div>
            </div>
            {!mobileView && (
              <div className="user__runs--total-coin">
                <div className="dot--red-coin">
                  <img src={ball} alt="ball" style={{ width: "16px", height: "16px" }} />
                </div>
                <div className="user-run-text">
                  <Run isRunValueNeeded={true} />
                </div>
              </div>
            )}
          </div>
          <div className="head-tail">
            <div className="head-wrapper"
              onClick={() => {
                handleChoice("Heads");
              }}
            >
              <img className="head-icon" src={heads} alt="head" />
              <p className="head-text">Heads</p>
            </div>
            <div className="tail-wrapper"
              onClick={() => {
                handleChoice("Tails");
              }}
            >
              <img className="tail-icon" src={tails} alt="tail" />
              <p className="tail-text">Tails</p>
            </div>
          </div>
          <div className="char1-wrapper">
            <img className="char1-head-tail" src={char1} alt="No Image" />
          </div>
        </div>
      ) : spin ? (
        <div className="content-coin-spin">
          <div className="innner-wrapper">
            <img className="char2" src={char2} alt="No Image" />
            <div className="heading__box-coin">
              <h1 className="toss__heading-coin">Toss Ka Boss</h1>
              {!mobileView && (
                <h3 className="toss__subheading-coin">Win 2x Runs</h3>
              )}
              <div className="subheading__underline-coin"></div>
            </div>
            {!mobileView && (
              <div className="user__runs--total-coin">
                <div className="dot--red-coin">
                  <img src={ball} alt="ball" style={{ width: "16px", height: "16px" }} />
                </div>
                <div className="user-run-text">
                  <Run isRunValueNeeded={true} />
                </div>
              </div>
            )}
          </div>
          <div className="head-tail-spin">
            <div>
              {isCoinResultVisible && (
                <p className="spin__text-coin">{coinResult}</p>
              )}
            </div>
            <div>
              {result?.coinResultOption === "Heads" && (
                <div className="coin--main">
                  <audio id="coin-sound">
                    <source src="/audio/coin-toss.mp3" type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <img
                    src={tails}
                    alt="coin-back"
                    className="coin-back"
                  />
                  <img
                    src={heads}
                    alt="coin-front"
                    className="coin-front"
                  />
                </div>
              )}
              {result?.coinResultOption === "Tails" && (
                <div className="coin--main">
                  <audio id="coin-sound">
                    <source src="/audio/coin-toss.mp3" type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <img
                    src={heads}
                    alt="coin-front"
                    className="coin-front"
                  />
                  <img
                    src={tails}
                    alt="coin-back"
                    className="coin-back"
                  />
                </div>
              )}
            </div>
          </div>
          <img className="char1" src={char1} alt="No Image" />
        </div>
      ) : (
        <div className="content-coin">
          <div className="innner-wrapper">
            <img className="char2" src={char2} alt="No Image" />
            <div className="heading__box-coin">
              <h1 className="toss__heading-coin">Toss Ka Boss</h1>
              <h3 className="toss__subheading-coin">Win 2x Runs</h3>
              <div className="subheading__underline-coin"></div>
            </div>
            <div className="user__runs--total-coin">
              <div className="dot--red-coin">
                <img src={ball} alt="ball" style={{ width: "16px", height: "16px" }} />
              </div>
              <div className="user-run-text">
                <Run isRunValueNeeded={true} />
              </div>
            </div>
          </div>
          <div className="runs-coin-inner-container">
            <div className="runs-coin">
              {mobileView && (
                <img className="mobile-coin-icon" src={heads} alt="No Image" />
              )}
              <p className="runs__detail-coin">Enter Run</p>
              <input
                type="number"
                className="runs__entered-coin"
                value={runs.toString()}
                onChange={(event) => {
                  setRuns(parseInt(event.target.value));
                }}
                min={50}
                max={5000}
              />
              <div className="minmax-coin">
                <p className="runs--min-coin">Min 50</p>
                <p className="runs--max-coin">Max 5000</p>
              </div>
            </div>
            <div className="choose-coin">
              {
                [{ num: 50, image: coin1 },
                { num: 500, image: coin2 },
                { num: 5000, image: coin3 }].map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setRuns(item.num);
                      }}
                    >
                      <img
                        src={item.image}
                        className="runs__box-coin"
                        alt={`coin-${index}`}
                      />
                    </div>
                  );
                })
              }
            </div>
            <div className="slider-bg">
              <input
                type="range"
                className="slider-coin"
                min="0"
                max="100"
                value={Math.round(((runs - 50) / 4950) * 100)}
                onChange={(event) =>
                  setRuns(
                    Math.round((parseInt(event.target.value) / 100) * 4950 + 50)
                  )
                }
              />
            </div>
          </div>
          <div>
            <img className="coin-icon" src={heads} alt="No Image" />
            <div className="runs__slider-coin">
              {mobileView ? (
                <button className="submit__toss-coin" onClick={checkEligibility}>
                  Flip Coin
                </button>
              ) : (
                <button className="submit__toss-coin" onClick={checkEligibility}>
                  Play Now
                </button>
              )}
            </div>
            <img className="char1" src={char1} alt="No Image" />
          </div>
        </div>
      )
      }
    </div >
  );
};

export default Coin;
