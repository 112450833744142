import "./index.scss";
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';

const NameCard = (props) => {
  const { postObj } = props;
  const [isImg, setIsImg] = useState(false);

  const isImgLink = (url) => {
    if (typeof url !== 'string') {
      return
    }
    setIsImg((url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) !== null));
  }

  useEffect(() => {
    isImgLink(postObj.postImageURLs[0]
      ? postObj.postImageURLs[0]
      : "");    
  }, [postObj.postImageURLs[0]
  ])


  return (
    <div className="post-continer">
    <div className="post_dummy">
      <div className="post-header">
        <div className="left">
          <div className="avatar">
            <img
              className="avatar-image"
              src={postObj.userProfilePhoto ? postObj.userProfilePhoto : defaultAvatar}
              alt={""}
            />
          </div>
          <div className="avatar-cnt">
            <p>
              <Link
                className="shared-link"
                to={`/profile/${postObj.userId}`}
                style={{ textDecoration: "none" }}
              >
                {postObj.userName}
              </Link>
            </p>
            <p className="date-time">
            
              {postObj.createdDate && (
                <ReactTimeAgo date={Date.parse(postObj.createdDate)} locale="en-US" />
              )}
              <br />
            </p>
          </div>
        </div>
        
      </div>
      <div className="post-text">{ parse(postObj.postMessage)}</div>
      <div className="share-attachment-content">
      {postObj.postImageURLs && postObj.postImageURLs[0] && (
            isImg ? (
              <img className="attch responsive" src={postObj.postImageURLs[0]} alt="" />
            ) : (
              <video width="400" className="attch responsive" controls>
                <source src={postObj.postImageURLs[0]} />
                Your browser does not support HTML5 video.
              </video>
            )
          )}
        {/* {postObj.postImageURLs ? (
          isImg ? (
            <img className="attch responsive" src={postObj.postImageURLs[0]} alt="" />
          ) : (
            <video width="400" className="attch responsive" controls>
              <source src={postObj.postImageURLs[0]} />
              Your browser does not support HTML5 video.
            </video>
          )
        ) : (
          " "
        )} */}
      </div>
    </div>
    </div>
  );
};

export default NameCard;
