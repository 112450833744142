import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/header/logo.png";
import Plus from "../../assets/images/header/plus.svg";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";

import { Tooltip } from "carbon-components-react";
import axios from "axios";
import SearchBar from "./searchBar";
import Runcard from "../posts/run";
import "./withoutsignin.scss";
import { resetLogin } from "../../redux/reducers/login";
import { useDispatch, useSelector } from "react-redux";
import { clearStorage, getStorageItem, setStorageItem } from "../../utils/sessionStorage";
import MenuIcon from "@mui/icons-material/Menu";
import Switch from "react-switch";
import { PathAction } from "../../redux/reducers/pitchselection";
import { Box, Button, Container } from "@material-ui/core";
import { Grid } from "@mui/material";
import post from "../preview/postPreview"
const Header = ({linkType, linkAddress}) => {
  const navigate = useNavigate();
  return (
    <header className="header1">
    <div className="header-cnt1">
      <div className="logo-block1">  <img src={Logo} alt="Champhunt" />
         <span className="label11" style={{paddingLeft: "15px", fontWeight: "600"}}>CHAMPHUNT</span></div>
         <div className="logo-block1">     
         <Box >
          <Grid >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FECC08",
                color: '#00247B',borderRadius:"5px",
                textTransform:'none'
              }}
              onClick={() => {
                clearStorage();
                linkAddress !=="" && linkType === "eventRedirect" && setStorageItem('eventRedirect',linkAddress);
                navigate('/login');
              }}
            >
              Log in
            </Button>
              <Button
                variant="contained"

                style={{
                  marginLeft: "10px",
                  backgroundColor: "#0c5ba0",
                  color: '#FECC08', float:"right",
                  textTransform:'none'
                }}
                onClick={() => {
                    linkAddress !=="" && linkType === "eventRedirect" && setStorageItem("eventRedirect", linkAddress);
                    navigate('/signup');
                  }}
              >
                    Sign Up
              </Button>
           
         
          </Grid></Box></div>
      </div></header>
   
   
  );
};

export default Header;
