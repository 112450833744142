import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate,Link } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppliedUserListScroll = (props) => {
  const { classes } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [applications, setApplications] = useState([]);
  const navigateToJob= () => {
    navigate(`/admin/job/${id}`);
  };
  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  // const redirectUser = (_id) => {
  //   // Check if the user is already registered

  //   // Redirects User
  //   navigate(`/event-register/${_id}`);
  // };

  // Fetching length of data
  useEffect(() => {
    // Fetching all events
    if(!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/job/getJobApplicationsOfJobId/${id}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then((response) => {
        console.log("response.data.data",response.data)
        console.log("response.data.length",response.data.length)
        setTotalPageNumber(Math.ceil(response.data.length / 6));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  }, []);

  // Ferching Page Data
  useEffect(() => {
    if(!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/job/getJobApplicationsByJobIdWithPagination/${id}/${pageNumber}/6`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then((response) => {
        console.log("response.data",response.data);
        console.log("response.data.data",response.data.data);
        setApplications([...response.data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  }, [pageNumber]);
  return (
    <div>
      <Grid
        className="pagination"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
          <Button variant="contained" 
      sx={{  flexDirection: 'row', alignItems: "left", ml: 'auto' }}
        onClick={navigateToJob}>
          back
        </Button>
        <Button variant="contained" 
        // style={{backgroundColor:'#0c5ba0',color:'white'}} 
        onClick={getPreviousPage}>
          Prev
        </Button>
        <Typography variant="p">
          {pageNumber} of {totalPageNumber}
        </Typography>
        <Button variant="contained"
          // style={{backgroundColor:'#0c5ba0',color:'white'}} 
         onClick={getNextPage}>
          Next
        </Button>
      </Grid>
        
      

            <Grid item xs={12} > 
          
            <Paper className="container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:"bolder"}}>sNo.</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>userId</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>userName</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>email</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>contactNo</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>resume</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((job,index) => (
            <TableRow key={id}>
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell>{job.applicationJobUserId}</TableCell>
              <TableCell>{job.applicationJobUserName}</TableCell>
              <TableCell>{job.applicationJobdUserEmailId}</TableCell>
              <TableCell>{job.applicationJobdUserContactNo}</TableCell>
              <TableCell> <a href={job.resume}>click to download</a></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
          </Grid>
          
      
      {/* </div> */}
      <ToastContainer />
    </div>
   
  );
};

export default AppliedUserListScroll;
