import { Box, Typography } from "@material-ui/core";
import React from "react";
import CarouselCreationForm from "../../components/carousel-creation-form";
// import EventCreationForm from "../../components/event-creation-form";

const CarouselCreate = () => {
  return (
    <Box>
      <Typography variant="h4">Create new carousel</Typography>
      {/* <Typography variant="p">
        Want to create a new event. Register an event here in few minutes.
      </Typography> */}
      <Box marginTop={6}>
        <CarouselCreationForm />
      </Box>
    </Box>
  );
};

export default CarouselCreate;
