import { Box, Typography } from "@material-ui/core";
import React from "react";
import CarouselCreationForm from "../../components/carousel-creation-form";
import DealCreationForm from "../../components/deal-creation-form"
import champHuntIcon from '../../assets/images/postimg.png'

// import EventCreationForm from "../../components/event-creation-form";

const DealCreate = () => {
  return (
    <Box>
      <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid #e4e1e1'}}>
        <Typography variant="h4">Create Deals</Typography>
        <div style={{display:'flex',alignItems:'center'}}>
          <Typography variant='p' style={{ fontSize: '14px', marginRight: '6px' }}>Champhunt Admin</Typography>
          <img style={{ width: "26px", height: "26px" }} src={champHuntIcon} alt='adminIcon' />
        </div>
      </div>
      {/* <Typography variant="p">
        Want to create a new event. Register an event here in few minutes.
      </Typography> */}
      <Box marginTop={2}>
        {/* <CarouselCreationForm />
         */}
        <DealCreationForm />
      </Box>
    </Box>
  );
};

export default DealCreate;
