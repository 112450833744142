import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Box, Grid, Card, CardContent, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useMediaQuery } from "@mui/material";

import indianFlag from '../../assets/images/rewards/AuctionAce/india.png'
import flightIcon from '../../assets/images/rewards/AuctionAce/flight.png'
import { getStorageItem } from "../../utils/sessionStorage";
import { SocketContext } from '../../context/SocketContext';

const PlayerList = (props) => {
    const accessToken = getStorageItem("token");
    const mobileView = useMediaQuery("(max-width:768px)");
    const { selectedPlayer, setSelectedPlayer, setPlayerId, setIsPlayerSold, handleCardClick, handleOpenPlayerDetails } = props;
    const [allPlayers, setAllPlayers] = useState([]);
    const [socket, setSocket] = useContext(SocketContext);
    const [socketConnected, setSocketConnected] = useState(false);

    const getAllPlayers = () => {
        const getPlayers = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/auction/players",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getPlayers)
            .then((response) => {
                setAllPlayers(response.data);
                setSelectedPlayer(false)
            })
            .catch((error) => {
                console.log(error)
            });
    };

    useEffect(() => {
        getAllPlayers();
    }, [accessToken])


    useEffect(() => {
        if (!socket) return;

        const handleAuctionComplete = (data) => {
            getAllPlayers();
        };

        socket.on("connect", () => setSocketConnected(true));
        socket.on("auctionResultUpdated", handleAuctionComplete);

        return () => {
            socket.off("connect");
            socket.off("auctionResultUpdated", handleAuctionComplete);
        };
    }, [socket])

    return (
        <Box
            sx={{
                width: selectedPlayer && !mobileView ? '70%' : '100%',
                transition: 'width 0.3s ease-in-out',
            }}
        >
            <Box>
                <Typography
                    variant="h5"
                    align="center"
                    sx={{
                        color: '#0B518E',
                        fontWeight: 500,
                        margin: '10px 0px'
                    }}
                >
                    Players
                </Typography>
                <Grid container spacing={2} justifyContent="center"
                    sx={{ padding: mobileView ? '0px 10px' : 'default-padding-value' }}
                >
                    {allPlayers?.map((player, index) => (
                        <Grid
                            key={index}
                            item
                            xs={6}
                            sm={6}
                            md={selectedPlayer ? 4 : 3}
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Card
                                onClick={() => {
                                    handleCardClick();
                                    setPlayerId(player._id);
                                    if (player.status === 'SOLD') {
                                        setIsPlayerSold(true)
                                    }
                                }}
                                sx={{
                                    width: '100%',
                                    height: '236px',
                                    maxWidth: '250px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    padding: 0,
                                    margin: 0,
                                    opacity: player.status === 'SOLD' ? 0.6 : 1,
                                    boxShadow: '2px 3px 57px 0px #00000014'
                                }}
                            >
                                <CardContent sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                    padding: 0,
                                    '&:last-child': {
                                        paddingBottom: '2px',
                                    },
                                }}>
                                    {player.status === 'SOLD' && (
                                        <img
                                            src={player?.team?.image || null}
                                            alt='logo'
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                zIndex: 2,
                                                borderRadius: '50%',
                                                opacity: 1
                                            }}
                                        />
                                    )}
                                    <Box sx={{
                                        width: '100%',
                                        height: '160px',
                                        position: 'relative',
                                        padding: 0,
                                        margin: 0
                                    }}>
                                        <img
                                            src={player?.country === 'India' ? indianFlag : flightIcon}
                                            alt='Icon'
                                            style={{
                                                position: 'absolute',
                                                top: '10px', left: '10px'
                                            }}
                                        />
                                        <Tooltip title={player.status === 'SOLD' ? "Player is sold" : "Player Information"} arrow>
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    top: '2px',
                                                    right: '5px',
                                                    zIndex: 3,
                                                }}
                                                onClick={(e) => {
                                                    handleOpenPlayerDetails(e)
                                                    setPlayerId(player?._id)
                                                }}
                                            >
                                                <InfoIcon sx={{ color: '#FFFFFF' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <img src={player?.image || null} alt='Player' style={{ width: '100%', height: '100%' }} />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            padding: '10px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{
                                                    color: '#0C5BA0',
                                                    fontWeight: 500,
                                                    fontSize: mobileView ? '12px' : '16px'
                                                }}
                                            >
                                                {player?.name || ""}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{
                                                    color: '#3AC309',
                                                    fontWeight: 400,
                                                    fontSize: mobileView ? '11px' : '14px'
                                                }}
                                            >
                                                {player?.specialization || ""}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{
                                                    color: '#62666A',
                                                    fontWeight: 400,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                Base Price
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{
                                                    color: '#62666A',
                                                    fontWeight: 400,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {player.basePrice || 0} L
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}

export default PlayerList;