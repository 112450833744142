import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, styled } from "@mui/styles";
import Styled from "@emotion/styled";
import { InputAdornment, Button, Box } from "@mui/material";
import axios from "axios";

import {
  CTextField,
  CFormControlLabel,
  CCheckbox,
} from "../../../commons/components/mui-c-components";

import LogoWithTitle from '../logo.component';
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import { Alert } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Helmet } from 'react-helmet';
import { getStorageItem } from "../../../utils/sessionStorage";
import VerficationLogin from "../verfication/VerficationLogin";
import { useRef } from "react";

import { Link } from '@mui/material';
import GoogleSignup from "../signup/GoogleSignup";

const RegisterHere = Styled.div(`
    width: 80%;
    max-width: 300px;
    margin: 20px auto 5px auto;
    padding: 10px 20px;
    border-radius: 20px;
    background: white;
    font-size: 15px;
    color: black;
    text-align: center;
`);
const pageFontColor = "white";

const LogInWith = Styled.div(`
    display: flex;
    flex-direction: column;
    align-items: center;
`);

const useStyles = makeStyles({
  root: {},
  inputProps: {
    color: "black",
    borderRadius: 15,
    border: 10,
    fontSize: 18,
  },
});

const FgPassContainer = Styled.div(`
    marging: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 5px;
`);

const FgPass = Styled.a(
  ({ align }) => `
    font-size: 15px;
    color: black;
    margin: 10px;
    cursor: pointer;
    text-decoration: none;
    text-align:${align || "left"}
`
);

const HeaderLabel = Styled.h1(({ align }) => `
    font-size: 40px;
    color: black;
    margin: 0;
    text-align:${align || 'left'};
    font-weight:500
`);

const InfoLabel = Styled.p(({ align }) => `
    font-size: 15px;
    color: black;
    margin: 0;
    margin-bottom: 15px;
    text-align:${align || 'left'}
`);

const SubmitButton = styled(Button)({
  background: "#0c5ba0",
  borderRadius: 8,
  color: pageFontColor,
  height: 45,
  fontWeight: 600
});

export default function SignInForm({ breakPoint }) {
  const [alert, setAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [OtpOpen, setOtpOpen] = useState(false);
  const [error, setError] = useState(false);
  const reRef = useRef();
  const [PropsDataForVerfication, SetPropsData] = useState('');
  const classes = useStyles();
  const [authenticatedFailedMsg, setauthenticatedFailedMsg] = useState("");
  // const [userActivationMsg, setUserActivationMsg] = useState('');
  const [cookies, setCookie] = useCookies(["user"]);
  const accessToken = getStorageItem("token");
  const { state } = useLocation();
  // const { activationMsg } = state;
  // call login api
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = useSelector((state) => state.login)
  const { loginData } = login


  var initialValues = {
    userid: "",
  };
  if (
    cookies.hasOwnProperty("username")

  ) {
    initialValues = {
      userid: cookies["username"],
      // password: cookies["password"],
    };
  }




  const handleOnChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const loginSubmit = async (event) => {
setError(false)
    const data = {
      username: email.toLowerCase(),

    };



    if (validator.isEmail(data["username"])) {
      data["email"] = data["username"];
      delete data["username"];
    }
    const isMailExistRes = await axios.post(
      `${global.config.ROOTURL.prod}/auth/check-email-exist`,
      { email: email.toLowerCase() }
    );

    if (!isMailExistRes.data) {

      setErrorMessage("Email Not Exist");
      setError(true);
      return
    }

    const sendOTPRes = await axios.post(`
    ${global.config.ROOTURL.prod}/auth/sendOTPForLogin`,
      //capthsactoken add or not
      { email: email }
    );

    if (sendOTPRes.status === 200) {
      let data = { email: email, };

      SetPropsData(data);
      setOtpOpen(true);
    }

  };




  // api call end
  return (
    <>
      <div>
        <Helmet>
          <title>Champhunt | Login </title>

        </Helmet>
        {!OtpOpen &&
          <>
            <LogoWithTitle fontSize={"120"} />
            <HeaderLabel align={'center'}>Champhunt</HeaderLabel>
            <InfoLabel align={'center'}>For member login use your ID </InfoLabel>
            {error && (
              <Stack sx={{ width: "100%", height: 20 }} >

                <Alert severity="error" sx={{ height: 60, margin: 0, padding: 0 , '& .css-1pxa9xg-MuiAlert-message': {padding: '3px 0'}}}>
                  <AlertTitle>
                    {" "}

                  </AlertTitle>
                  {errorMessage}
                </Alert>
              </Stack>
            )}

            <Box component="form" noValidate>
              <CTextField
                defaultValue={initialValues.userid}
                margin="normal"
                required
                fullWidth
                id="userid"
                name="userid"
                placeholder="Email Address"
                autoFocus
                value={email}
                onChange={handleOnChange}
                className={classes.root}
                InputProps={{

                }}
              />

              {authenticatedFailedMsg && (
                <p style={{ color: "red" }}> {authenticatedFailedMsg} </p>
              )}
            </Box>


            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "none", fontSize: 18 }}
              onClick={loginSubmit}
            >
              Verify Me
            </SubmitButton>

            <GoogleSignup />
            <RegisterHere>
              <span>
                Not a member yet? <Link href="/signup" style={{ textDecoration: 'none' }}>&nbsp;Register here</Link>
              </span>
            </RegisterHere>
          </>
        }
        {OtpOpen && <VerficationLogin state={PropsDataForVerfication} />}

      </div>
    </>
  );
}
