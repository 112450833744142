import { Box, Typography, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import axios from "axios";
import "./indexmodal.scss";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { Button } from "carbon-components-react";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import LeaderboardModal from "./leaderboard-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMessage } from "@fortawesome/free-regular-svg-icons";
import Today from "./todayMainPage";
import { NoPhotographySharp } from "@mui/icons-material";
const user_Id = getStorageItem("user_id")
const ThisWeekPitchPopup = () => {
  const [thisWeekPitch, setThisWeekPitch] = useState("");
  const [thisWeekPitchByUserId, setThisWeekPitchByUserId] = useState({ userId: "", userName: "", runs: 0, pitchIndex: 0, profilePhoto: "" });
  const [showModal, setShowModal] = useState(false);
  const [weeklyPitchInTopTen, setWeeklyPitchInTopTen] = useState(false)
  const [open, setOpen] = useState(false);
  const accessToken = getStorageItem("token");
  const thisWeekPitchfYouFunction = () => {
    if (!user_Id) return;
    const getThisWeekPitchOfYou = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/leaderboard/thisWeek/findUserDetailsOfThisWeekByUserId/${user_Id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }
    axios(getThisWeekPitchOfYou)
      .then((response) => {
        // console.log(response.data);
        // console.log(response.data[0]);
        if (response.data) {
          setThisWeekPitchByUserId({
            userId: response.data.userId,
            userName: response.data.userName,
            runs: response.data.runs,
            pitchIndex: response.data.pitchIndex,
            profilePhoto: response.data.profilePhoto,
            followersCount: response.data.followersCount,
            pitchCount: response.data.pitchCount
          }
          )
        }
      })
      .catch((err) => {
        console.log("error", err)
      });
  };
  const thisWeekPitchFunction = () => {
    if (!accessToken) return;
    const getthisWeekPitch = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/leaderboard/thisWeek/pitchThisWeek/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }
    axios(getthisWeekPitch)
      .then((response) => {
        if (response.data) {
          setThisWeekPitch(response.data);
        }
        const allTimeData = response.data;
        const allTimeDataIds = allTimeData.map(s => (s.userId))
        if (allTimeDataIds.slice(0, 9).includes(user_Id.toString())) {
          setWeeklyPitchInTopTen(true)
        }
      })

      .catch((err) => {
        console.log("error", err)
      });
  }

  useEffect(() => {
    thisWeekPitchFunction();
    thisWeekPitchfYouFunction();
  }, [])
  if (!thisWeekPitch) return "Loading...";
  // if (!Array.isArray(allTimeRun)) return 'results are not array'


  return (
    <section className="leaderboard-modal">
      <Box className="pedestal">
      </Box>
      {/* Table */}
      <table className="data__table" cellPadding="0" cellSpacing="0" border="0" style={{ backgroundColor: "#f5f5f5" }}>
        <thead>
          <tr className="table__headings">
            <th>Rank</th>
            <th>Name</th>
            {/* <th class="one">Followers</th> */}
            <th>Pitch</th>
            <th class="one">Runs</th>
          </tr>
        </thead>
        <tbody>
          {thisWeekPitch[0].userId === user_Id ?
            <tr className="table__data highlighted__user">
              <div className="crownx">
                <svg
                  width="25"
                  height="12"
                  viewBox="0 0 25 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0L6.25 4.5L12.5 0L18.75 4.5L25 0L21.7742 12H3.22581L0 0Z"
                    fill="url(#paint0_linear_1769_2115)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1769_2115"
                      x1="4.5"
                      y1="16"
                      x2="25"
                      y2="2.47954e-06"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFA44B" />
                      <stop offset="1" stopColor="#FFD046" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              

              <td className="imagex__data">
                <span className="pedestal-1__rank">
                  <span>
                    <p>1</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-1__wrapper">
                    <img
                      src={
                        thisWeekPitch[0].profilePhoto
                          ? thisWeekPitch[0].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-1x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisWeekPitch[0].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    className="user__name"
                    style={{ color: "white" }}
                  >
                    YOU
                  </span>

                </Link>
              </td>
              {/* <td class="one"><div className="svg__icon"><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78301 1.73585C5.44479 1.73585 5.10989 1.80247 4.79741 1.9319C4.48494 2.06133 4.20103 2.25104 3.96187 2.49019C3.72271 2.72935 3.53301 3.01326 3.40358 3.32574C3.27415 3.63821 3.20753 3.97311 3.20753 4.31133C3.20753 4.64954 3.27415 4.98445 3.40358 5.29692C3.53301 5.60939 3.72271 5.89331 3.96187 6.13246C4.20103 6.37162 4.48494 6.56133 4.79741 6.69076C5.10989 6.82019 5.44479 6.8868 5.78301 6.8868C6.46607 6.8868 7.12115 6.61546 7.60414 6.13246C8.08714 5.64947 8.35848 4.99439 8.35848 4.31133C8.35848 3.62827 8.08714 2.97319 7.60414 2.49019C7.12115 2.00719 6.46607 1.73585 5.78301 1.73585ZM2.47168 4.31133C2.47168 3.43311 2.82055 2.59086 3.44154 1.96987C4.06254 1.34887 4.90479 1 5.78301 1C6.66123 1 7.50347 1.34887 8.12447 1.96987C8.74546 2.59086 9.09433 3.43311 9.09433 4.31133C9.09433 5.18955 8.74546 6.03179 8.12447 6.65279C7.50347 7.27378 6.66123 7.62265 5.78301 7.62265C4.90479 7.62265 4.06254 7.27378 3.44154 6.65279C2.82055 6.03179 2.47168 5.18955 2.47168 4.31133Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11.6697C1 10.4012 1.50392 9.18463 2.40092 8.28763C3.29791 7.39064 4.51449 6.88672 5.78303 6.88672C7.05157 6.88672 8.26815 7.39064 9.16514 8.28763C10.0621 9.18463 10.5661 10.4012 10.5661 11.6697V11.8758C10.5661 12.2459 10.307 12.5653 9.945 12.6411L8.65652 12.9126C7.36364 13.1849 6.04131 13.271 4.73003 13.1716C4.63378 13.1629 4.54484 13.1166 4.48243 13.0428C4.42003 12.969 4.38916 12.8736 4.39651 12.7772C4.40385 12.6809 4.44882 12.5913 4.52169 12.5278C4.59456 12.4643 4.68949 12.432 4.78595 12.438C6.02807 12.5322 7.28048 12.4505 8.50494 12.1922L9.79341 11.9214C9.8038 11.9194 9.81316 11.9138 9.81987 11.9056C9.82658 11.8974 9.83024 11.8871 9.83021 11.8765V11.6697C9.83021 10.5964 9.40381 9.56695 8.64482 8.80796C7.88582 8.04897 6.85641 7.62257 5.78303 7.62257C4.70965 7.62257 3.68023 8.04897 2.92124 8.80796C2.16225 9.56695 1.73585 10.5964 1.73585 11.6697V11.8758C1.73585 11.8979 1.75057 11.9163 1.77264 11.9214L3.06112 12.1922C3.10842 12.2022 3.1533 12.2213 3.19319 12.2486C3.23308 12.2759 3.26721 12.3108 3.29362 12.3513C3.32003 12.3918 3.3382 12.4371 3.34711 12.4846C3.35602 12.5321 3.35548 12.5809 3.34552 12.6282C3.33557 12.6755 3.3164 12.7204 3.2891 12.7603C3.2618 12.8002 3.22692 12.8343 3.18643 12.8607C3.14594 12.8871 3.10065 12.9053 3.05314 12.9142C3.00563 12.9231 2.95683 12.9226 2.90953 12.9126L1.62106 12.6411C1.4454 12.6042 1.28777 12.508 1.17468 12.3687C1.06158 12.2293 0.999896 12.0553 1 11.8758V11.6697Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
              </svg>
              </div>{thisWeekPitch[0].followersCount}</td> */}
              <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
              </svg>

              </div>{thisWeekPitch[0].pitchCount}</td>
              <td class="one">{thisWeekPitch[0].runs}</td>
            </tr> :
            <tr className="table__data">
              <div className="crownx">
                <svg
                  width="25"
                  height="12"
                  viewBox="0 0 25 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0L6.25 4.5L12.5 0L18.75 4.5L25 0L21.7742 12H3.22581L0 0Z"
                    fill="url(#paint0_linear_1769_2115)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1769_2115"
                      x1="4.5"
                      y1="16"
                      x2="25"
                      y2="2.47954e-06"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFA44B" />
                      <stop offset="1" stopColor="#FFD046" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <td className="imagex__data">
                <span className="pedestal-1__rank">
                  <span>
                    <p>1</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-1__wrapper">
                    <img
                      src={
                        thisWeekPitch[0].profilePhoto
                          ? thisWeekPitch[0].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-1x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisWeekPitch[0].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span className="user__name">{thisWeekPitch[0].userName.length > 10 ? thisWeekPitch[0].userName.substring(0, 10) + "..." : thisWeekPitch[0].userName}</span>

                </Link>
              </td>
              {/* <td class="one"><div className="svg__icon"><svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z" fill="#718193" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36364 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z" fill="#718193" />
              </svg></div>
                {thisWeekPitch[0].followersCount}</td> */}
              <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
              </svg></div>
                {thisWeekPitch[0].pitchCount}</td>
              <td class="one">{thisWeekPitch[0].runs}</td>
            </tr>}
          {thisWeekPitch[1].userId === user_Id ?
            <tr className="table__data highlighted__user">
              <td className="imagex__data">
                <span className="pedestal-2__rank">
                  <span>
                    <p>2</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-2__wrapper">
                    <img
                      src={
                        thisWeekPitch[1].profilePhoto
                          ? thisWeekPitch[1].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-2x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisWeekPitch[1].userId}`}
                  style={{ textDecoration: "none" }}
                >

                  <span
                    className="user__name"
                    style={{ color: "white" }}
                  >
                    YOU
                  </span>

                </Link>
              </td>
              {/* <td class="one"><div className="svg__icon"><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78301 1.73585C5.44479 1.73585 5.10989 1.80247 4.79741 1.9319C4.48494 2.06133 4.20103 2.25104 3.96187 2.49019C3.72271 2.72935 3.53301 3.01326 3.40358 3.32574C3.27415 3.63821 3.20753 3.97311 3.20753 4.31133C3.20753 4.64954 3.27415 4.98445 3.40358 5.29692C3.53301 5.60939 3.72271 5.89331 3.96187 6.13246C4.20103 6.37162 4.48494 6.56133 4.79741 6.69076C5.10989 6.82019 5.44479 6.8868 5.78301 6.8868C6.46607 6.8868 7.12115 6.61546 7.60414 6.13246C8.08714 5.64947 8.35848 4.99439 8.35848 4.31133C8.35848 3.62827 8.08714 2.97319 7.60414 2.49019C7.12115 2.00719 6.46607 1.73585 5.78301 1.73585ZM2.47168 4.31133C2.47168 3.43311 2.82055 2.59086 3.44154 1.96987C4.06254 1.34887 4.90479 1 5.78301 1C6.66123 1 7.50347 1.34887 8.12447 1.96987C8.74546 2.59086 9.09433 3.43311 9.09433 4.31133C9.09433 5.18955 8.74546 6.03179 8.12447 6.65279C7.50347 7.27378 6.66123 7.62265 5.78301 7.62265C4.90479 7.62265 4.06254 7.27378 3.44154 6.65279C2.82055 6.03179 2.47168 5.18955 2.47168 4.31133Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11.6697C1 10.4012 1.50392 9.18463 2.40092 8.28763C3.29791 7.39064 4.51449 6.88672 5.78303 6.88672C7.05157 6.88672 8.26815 7.39064 9.16514 8.28763C10.0621 9.18463 10.5661 10.4012 10.5661 11.6697V11.8758C10.5661 12.2459 10.307 12.5653 9.945 12.6411L8.65652 12.9126C7.36364 13.1849 6.04131 13.271 4.73003 13.1716C4.63378 13.1629 4.54484 13.1166 4.48243 13.0428C4.42003 12.969 4.38916 12.8736 4.39651 12.7772C4.40385 12.6809 4.44882 12.5913 4.52169 12.5278C4.59456 12.4643 4.68949 12.432 4.78595 12.438C6.02807 12.5322 7.28048 12.4505 8.50494 12.1922L9.79341 11.9214C9.8038 11.9194 9.81316 11.9138 9.81987 11.9056C9.82658 11.8974 9.83024 11.8871 9.83021 11.8765V11.6697C9.83021 10.5964 9.40381 9.56695 8.64482 8.80796C7.88582 8.04897 6.85641 7.62257 5.78303 7.62257C4.70965 7.62257 3.68023 8.04897 2.92124 8.80796C2.16225 9.56695 1.73585 10.5964 1.73585 11.6697V11.8758C1.73585 11.8979 1.75057 11.9163 1.77264 11.9214L3.06112 12.1922C3.10842 12.2022 3.1533 12.2213 3.19319 12.2486C3.23308 12.2759 3.26721 12.3108 3.29362 12.3513C3.32003 12.3918 3.3382 12.4371 3.34711 12.4846C3.35602 12.5321 3.35548 12.5809 3.34552 12.6282C3.33557 12.6755 3.3164 12.7204 3.2891 12.7603C3.2618 12.8002 3.22692 12.8343 3.18643 12.8607C3.14594 12.8871 3.10065 12.9053 3.05314 12.9142C3.00563 12.9231 2.95683 12.9226 2.90953 12.9126L1.62106 12.6411C1.4454 12.6042 1.28777 12.508 1.17468 12.3687C1.06158 12.2293 0.999896 12.0553 1 11.8758V11.6697Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
              </svg>
              </div>{thisWeekPitch[1].followersCount}</td> */}
              <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
              </svg>

              </div>{thisWeekPitch[1].pitchCount}</td>
              <td class="one">{thisWeekPitch[1].runs}</td>
            </tr> :
            <tr className="table__data">
              <td className="imagex__data">
                <span className="pedestal-2__rank">
                  <span>
                    <p>2</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-2__wrapper">
                    <img
                      src={
                        thisWeekPitch[1].profilePhoto
                          ? thisWeekPitch[1].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-2x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisWeekPitch[1].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span className="user__name">{thisWeekPitch[1].userName.length > 10 ? thisWeekPitch[1].userName.substring(0, 10) + "..." : thisWeekPitch[1].userName}</span>
                </Link>
              </td>
              {/* <td class="one"><div className="svg__icon"><svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z" fill="#718193" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36364 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z" fill="#718193" />
              </svg></div> {thisWeekPitch[1].followersCount}</td> */}
              <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
              </svg></div>{thisWeekPitch[1].pitchCount}</td>
              <td class="one">{thisWeekPitch[1].runs}</td>
            </tr>}
          {thisWeekPitch[2].userId === user_Id ?
            <tr className="table__data highlighted__user">
              <td className="imagex__data">
                <span className="pedestal-3__rank">
                  <span>
                    <p>3</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-3__wrapper">
                    <img
                      src={
                        thisWeekPitch[2].profilePhoto
                          ? thisWeekPitch[2].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-3x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisWeekPitch[2].userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    className="user__name"
                    style={{ color: "white" }}
                  >
                    YOU
                  </span>

                </Link>
              </td>

                        
              {/* <td class="one"><div className="svg__icon"><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78301 1.73585C5.44479 1.73585 5.10989 1.80247 4.79741 1.9319C4.48494 2.06133 4.20103 2.25104 3.96187 2.49019C3.72271 2.72935 3.53301 3.01326 3.40358 3.32574C3.27415 3.63821 3.20753 3.97311 3.20753 4.31133C3.20753 4.64954 3.27415 4.98445 3.40358 5.29692C3.53301 5.60939 3.72271 5.89331 3.96187 6.13246C4.20103 6.37162 4.48494 6.56133 4.79741 6.69076C5.10989 6.82019 5.44479 6.8868 5.78301 6.8868C6.46607 6.8868 7.12115 6.61546 7.60414 6.13246C8.08714 5.64947 8.35848 4.99439 8.35848 4.31133C8.35848 3.62827 8.08714 2.97319 7.60414 2.49019C7.12115 2.00719 6.46607 1.73585 5.78301 1.73585ZM2.47168 4.31133C2.47168 3.43311 2.82055 2.59086 3.44154 1.96987C4.06254 1.34887 4.90479 1 5.78301 1C6.66123 1 7.50347 1.34887 8.12447 1.96987C8.74546 2.59086 9.09433 3.43311 9.09433 4.31133C9.09433 5.18955 8.74546 6.03179 8.12447 6.65279C7.50347 7.27378 6.66123 7.62265 5.78301 7.62265C4.90479 7.62265 4.06254 7.27378 3.44154 6.65279C2.82055 6.03179 2.47168 5.18955 2.47168 4.31133Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11.6697C1 10.4012 1.50392 9.18463 2.40092 8.28763C3.29791 7.39064 4.51449 6.88672 5.78303 6.88672C7.05157 6.88672 8.26815 7.39064 9.16514 8.28763C10.0621 9.18463 10.5661 10.4012 10.5661 11.6697V11.8758C10.5661 12.2459 10.307 12.5653 9.945 12.6411L8.65652 12.9126C7.36364 13.1849 6.04131 13.271 4.73003 13.1716C4.63378 13.1629 4.54484 13.1166 4.48243 13.0428C4.42003 12.969 4.38916 12.8736 4.39651 12.7772C4.40385 12.6809 4.44882 12.5913 4.52169 12.5278C4.59456 12.4643 4.68949 12.432 4.78595 12.438C6.02807 12.5322 7.28048 12.4505 8.50494 12.1922L9.79341 11.9214C9.8038 11.9194 9.81316 11.9138 9.81987 11.9056C9.82658 11.8974 9.83024 11.8871 9.83021 11.8765V11.6697C9.83021 10.5964 9.40381 9.56695 8.64482 8.80796C7.88582 8.04897 6.85641 7.62257 5.78303 7.62257C4.70965 7.62257 3.68023 8.04897 2.92124 8.80796C2.16225 9.56695 1.73585 10.5964 1.73585 11.6697V11.8758C1.73585 11.8979 1.75057 11.9163 1.77264 11.9214L3.06112 12.1922C3.10842 12.2022 3.1533 12.2213 3.19319 12.2486C3.23308 12.2759 3.26721 12.3108 3.29362 12.3513C3.32003 12.3918 3.3382 12.4371 3.34711 12.4846C3.35602 12.5321 3.35548 12.5809 3.34552 12.6282C3.33557 12.6755 3.3164 12.7204 3.2891 12.7603C3.2618 12.8002 3.22692 12.8343 3.18643 12.8607C3.14594 12.8871 3.10065 12.9053 3.05314 12.9142C3.00563 12.9231 2.95683 12.9226 2.90953 12.9126L1.62106 12.6411C1.4454 12.6042 1.28777 12.508 1.17468 12.3687C1.06158 12.2293 0.999896 12.0553 1 11.8758V11.6697Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
              </svg></div>{thisWeekPitch[2].followersCount}</td> */}
              <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
              </svg>


              </div>{thisWeekPitch[2].pitchCount}</td>
              <td class="one">{thisWeekPitch[2].runs}</td>
            </tr> :
            <tr className="table__data">
              <td className="imagex__data">
                <span className="pedestal-3__rank">
                  <span>
                    <p>3</p>
                  </span>
                </span>
                <span className="user-image__wrapperx">
                  <div className="imagex-3__wrapper">
                    <img
                      src={
                        thisWeekPitch[2].profilePhoto
                          ? thisWeekPitch[2].profilePhoto
                          : defaultAvatar
                      }
                      alt="user"
                      className="image-3x"
                    />
                  </div>
                </span>
              </td>
              <td>
                <Link
                  to={`/profile/${thisWeekPitch[2].userId}`}
                  style={{ textDecoration: "none" }}
                >

                  <span className="user__name">{thisWeekPitch[2].userName.length > 10 ? thisWeekPitch[2].userName.substring(0, 10) + "..." : thisWeekPitch[2].userName}</span>

                </Link>
              </td>
             
              {/* <td class="one"><div className="svg__icon"><svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z" fill="#718193" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36364 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z" fill="#718193" />
              </svg></div> {thisWeekPitch[2].followersCount}</td> */}
              
              <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
              </svg></div> {thisWeekPitch[2].pitchCount}</td>
              <td class="one">{thisWeekPitch[2].runs}</td>
            </tr>}
          {thisWeekPitch.slice(3, 9).map((data, index) => (
            data.userId === user_Id ?
              <tr className="table__data highlighted__user" key={index}>
                <td className="imagex__data">
                  {index + 4}
                  <span className="user-image__wrapperx">
                    <img
                      src={data.profilePhoto ? data.profilePhoto : defaultAvatar}
                      alt="user"
                      className="user__imagex"
                    />
                  </span>
                </td>
                <td>
                  <Link
                    to={`/profile/${data.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className="user__name"
                      style={{ color: "white" }}
                    >
                      YOU
                    </span>
                  </Link>
                </td>
                {/* <td class="one"><div className="svg__icon"><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78301 1.73585C5.44479 1.73585 5.10989 1.80247 4.79741 1.9319C4.48494 2.06133 4.20103 2.25104 3.96187 2.49019C3.72271 2.72935 3.53301 3.01326 3.40358 3.32574C3.27415 3.63821 3.20753 3.97311 3.20753 4.31133C3.20753 4.64954 3.27415 4.98445 3.40358 5.29692C3.53301 5.60939 3.72271 5.89331 3.96187 6.13246C4.20103 6.37162 4.48494 6.56133 4.79741 6.69076C5.10989 6.82019 5.44479 6.8868 5.78301 6.8868C6.46607 6.8868 7.12115 6.61546 7.60414 6.13246C8.08714 5.64947 8.35848 4.99439 8.35848 4.31133C8.35848 3.62827 8.08714 2.97319 7.60414 2.49019C7.12115 2.00719 6.46607 1.73585 5.78301 1.73585ZM2.47168 4.31133C2.47168 3.43311 2.82055 2.59086 3.44154 1.96987C4.06254 1.34887 4.90479 1 5.78301 1C6.66123 1 7.50347 1.34887 8.12447 1.96987C8.74546 2.59086 9.09433 3.43311 9.09433 4.31133C9.09433 5.18955 8.74546 6.03179 8.12447 6.65279C7.50347 7.27378 6.66123 7.62265 5.78301 7.62265C4.90479 7.62265 4.06254 7.27378 3.44154 6.65279C2.82055 6.03179 2.47168 5.18955 2.47168 4.31133Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11.6697C1 10.4012 1.50392 9.18463 2.40092 8.28763C3.29791 7.39064 4.51449 6.88672 5.78303 6.88672C7.05157 6.88672 8.26815 7.39064 9.16514 8.28763C10.0621 9.18463 10.5661 10.4012 10.5661 11.6697V11.8758C10.5661 12.2459 10.307 12.5653 9.945 12.6411L8.65652 12.9126C7.36364 13.1849 6.04131 13.271 4.73003 13.1716C4.63378 13.1629 4.54484 13.1166 4.48243 13.0428C4.42003 12.969 4.38916 12.8736 4.39651 12.7772C4.40385 12.6809 4.44882 12.5913 4.52169 12.5278C4.59456 12.4643 4.68949 12.432 4.78595 12.438C6.02807 12.5322 7.28048 12.4505 8.50494 12.1922L9.79341 11.9214C9.8038 11.9194 9.81316 11.9138 9.81987 11.9056C9.82658 11.8974 9.83024 11.8871 9.83021 11.8765V11.6697C9.83021 10.5964 9.40381 9.56695 8.64482 8.80796C7.88582 8.04897 6.85641 7.62257 5.78303 7.62257C4.70965 7.62257 3.68023 8.04897 2.92124 8.80796C2.16225 9.56695 1.73585 10.5964 1.73585 11.6697V11.8758C1.73585 11.8979 1.75057 11.9163 1.77264 11.9214L3.06112 12.1922C3.10842 12.2022 3.1533 12.2213 3.19319 12.2486C3.23308 12.2759 3.26721 12.3108 3.29362 12.3513C3.32003 12.3918 3.3382 12.4371 3.34711 12.4846C3.35602 12.5321 3.35548 12.5809 3.34552 12.6282C3.33557 12.6755 3.3164 12.7204 3.2891 12.7603C3.2618 12.8002 3.22692 12.8343 3.18643 12.8607C3.14594 12.8871 3.10065 12.9053 3.05314 12.9142C3.00563 12.9231 2.95683 12.9226 2.90953 12.9126L1.62106 12.6411C1.4454 12.6042 1.28777 12.508 1.17468 12.3687C1.06158 12.2293 0.999896 12.0553 1 11.8758V11.6697Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
                </svg> </div>{data.followersCount}</td> */}
                <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
                  <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
                </svg>


                </div>{data.pitchCount}</td>
                <td class="one">{data.runs}</td>
              </tr> : <tr className="table__data" key={index}>
                <td className="imagex__data">
                  {index + 4}
                  <span className="user-image__wrapperx">
                    <img
                      src={data.profilePhoto ? data.profilePhoto : defaultAvatar}
                      alt="user"
                      className="user__imagex"
                    />
                  </span>
                </td>
                <td>
                  <Link
                    to={`/profile/${data.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className="user__name">{data.userName.length > 10 ? data.userName.substring(0, 10) + "..." : data.userName}</span>

                  </Link>
                </td>

                <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
                  <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
                </svg></div>{data.pitchCount}</td>
                <td class="one">{data.runs}</td>
              </tr>
          ))}
          {weeklyPitchInTopTen && <tr className="table__data ">
            <td className="imagex__data row_threenine">
              10
              <span className="user-image__wrapperx">
                <img
                  src={thisWeekPitch[9].profilePhoto ? thisWeekPitch[9].profilePhoto : defaultAvatar}
                  alt="user"
                  className="user__imagex"
                />
              </span>
            </td>
            <td>
              <Link
                to={`/profile/${thisWeekPitch[9].userId}`}
                style={{ textDecoration: "none" }}
              >
                <span className="user__name">{thisWeekPitch[9].userName.length > 10 ? thisWeekPitch[9].userName.substring(0, 10) + "..." : thisWeekPitch[9].userName}</span>
              </Link>
            </td>
            {/* <td class="one"><div className="svg__icon"><svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z" fill="#718193" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36364 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z" fill="#718193" />
            </svg></div>{thisWeekPitch[9].followersCount}</td> */}
            <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="#718193" stroke-width="0.5" />
              <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="#718193" stroke-width="0.5" />
            </svg></div>{thisWeekPitch[9].pitchCount}</td>
            <td class="one">{thisWeekPitch[9].runs}</td>
          </tr>}
          {!weeklyPitchInTopTen && <tr className="table__data highlighted__user">
            <td className="imagex__data row_threenine">
              {thisWeekPitchByUserId.pitchIndex}
              <span className="user-image__wrapperx">
                <img
                  src={thisWeekPitchByUserId.profilePhoto ? thisWeekPitchByUserId.profilePhoto : defaultAvatar}
                  alt="user"
                  className="user__imagex"
                />
              </span>
            </td>
            <td>
              <Link
                to={`/profile/${thisWeekPitchByUserId.userId}`}
                style={{ textDecoration: "none" }}
              >


                <span className="user__name" style={{ color: "white" }}>YOU</span>

              </Link>
            </td>
            {/* <td class="one"><div className="svg__icon"><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78301 1.73585C5.44479 1.73585 5.10989 1.80247 4.79741 1.9319C4.48494 2.06133 4.20103 2.25104 3.96187 2.49019C3.72271 2.72935 3.53301 3.01326 3.40358 3.32574C3.27415 3.63821 3.20753 3.97311 3.20753 4.31133C3.20753 4.64954 3.27415 4.98445 3.40358 5.29692C3.53301 5.60939 3.72271 5.89331 3.96187 6.13246C4.20103 6.37162 4.48494 6.56133 4.79741 6.69076C5.10989 6.82019 5.44479 6.8868 5.78301 6.8868C6.46607 6.8868 7.12115 6.61546 7.60414 6.13246C8.08714 5.64947 8.35848 4.99439 8.35848 4.31133C8.35848 3.62827 8.08714 2.97319 7.60414 2.49019C7.12115 2.00719 6.46607 1.73585 5.78301 1.73585ZM2.47168 4.31133C2.47168 3.43311 2.82055 2.59086 3.44154 1.96987C4.06254 1.34887 4.90479 1 5.78301 1C6.66123 1 7.50347 1.34887 8.12447 1.96987C8.74546 2.59086 9.09433 3.43311 9.09433 4.31133C9.09433 5.18955 8.74546 6.03179 8.12447 6.65279C7.50347 7.27378 6.66123 7.62265 5.78301 7.62265C4.90479 7.62265 4.06254 7.27378 3.44154 6.65279C2.82055 6.03179 2.47168 5.18955 2.47168 4.31133Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11.6697C1 10.4012 1.50392 9.18463 2.40092 8.28763C3.29791 7.39064 4.51449 6.88672 5.78303 6.88672C7.05157 6.88672 8.26815 7.39064 9.16514 8.28763C10.0621 9.18463 10.5661 10.4012 10.5661 11.6697V11.8758C10.5661 12.2459 10.307 12.5653 9.945 12.6411L8.65652 12.9126C7.36364 13.1849 6.04131 13.271 4.73003 13.1716C4.63378 13.1629 4.54484 13.1166 4.48243 13.0428C4.42003 12.969 4.38916 12.8736 4.39651 12.7772C4.40385 12.6809 4.44882 12.5913 4.52169 12.5278C4.59456 12.4643 4.68949 12.432 4.78595 12.438C6.02807 12.5322 7.28048 12.4505 8.50494 12.1922L9.79341 11.9214C9.8038 11.9194 9.81316 11.9138 9.81987 11.9056C9.82658 11.8974 9.83024 11.8871 9.83021 11.8765V11.6697C9.83021 10.5964 9.40381 9.56695 8.64482 8.80796C7.88582 8.04897 6.85641 7.62257 5.78303 7.62257C4.70965 7.62257 3.68023 8.04897 2.92124 8.80796C2.16225 9.56695 1.73585 10.5964 1.73585 11.6697V11.8758C1.73585 11.8979 1.75057 11.9163 1.77264 11.9214L3.06112 12.1922C3.10842 12.2022 3.1533 12.2213 3.19319 12.2486C3.23308 12.2759 3.26721 12.3108 3.29362 12.3513C3.32003 12.3918 3.3382 12.4371 3.34711 12.4846C3.35602 12.5321 3.35548 12.5809 3.34552 12.6282C3.33557 12.6755 3.3164 12.7204 3.2891 12.7603C3.2618 12.8002 3.22692 12.8343 3.18643 12.8607C3.14594 12.8871 3.10065 12.9053 3.05314 12.9142C3.00563 12.9231 2.95683 12.9226 2.90953 12.9126L1.62106 12.6411C1.4454 12.6042 1.28777 12.508 1.17468 12.3687C1.06158 12.2293 0.999896 12.0553 1 11.8758V11.6697Z" fill="white" stroke="black" stroke-opacity="0.2" stroke-width="0.5" />
            </svg>
            </div>{thisWeekPitchByUserId.followersCount}</td> */}
            <td><div className="svg__icon"><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H11.4815C12.448 0.25 13.2315 1.0335 13.2315 2V7.62963C13.2315 8.59613 12.448 9.37963 11.4815 9.37963H6.74074C6.36047 9.37963 6.00082 9.55249 5.76326 9.84943L4.14794 11.8686C3.94904 12.0541 3.62037 11.914 3.62037 11.6382V10.5926V10.1111C3.62037 9.70713 3.29288 9.37963 2.88889 9.37963H2C1.0335 9.37963 0.25 8.59613 0.25 7.62963V2Z" stroke="white" stroke-width="0.5" />
              <rect x="2.17578" y="2.17578" width="5.27778" height="5.27778" rx="0.75" stroke="white" stroke-width="0.5" />
            </svg>

            </div> {thisWeekPitchByUserId.pitchCount}</td>
            <td class="one">{thisWeekPitchByUserId.runs}</td>
          </tr>}
        </tbody>
      </table>
      {/* {Today}   */}
    </section>
  )

}
export default ThisWeekPitchPopup;