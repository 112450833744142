import React from 'react';
import { useEffect } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useMediaQuery } from "@mui/material";

import sold from '../../assets/images/rewards/AuctionAce/sold.svg'

import { getStorageItem } from "../../utils/sessionStorage";
import useGetPlayerById from './useGetPlayerById';

const Leaderboard = (props) => {
    const { playerId } = props;
    const accessToken = getStorageItem("token");
    const mobileView = useMediaQuery("(max-width:768px)");
    const { playerDetails, getPlayerById } = useGetPlayerById();

    useEffect(() => {
        getPlayerById(playerId)
    }, [accessToken, playerId])

    return (
        <Box
            sx={{
                width: mobileView ? '100%' : '70%',
                height: mobileView ? '70vh' : '100vh',
                background: '#0C5BA0',
                borderRadius: '20px',
                position: 'relative',
                paddingTop: '60px'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img
                    src={sold}
                    alt='sold'
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    marginTop: '35px'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: mobileView ? '25px' : '70px' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: mobileView ? '80px' : '180px',
                            height: mobileView ? '80px' : '180px',
                            background: '#FFFFFF',
                            borderRadius: '50%',
                            padding: '7px'
                        }}>
                        <Avatar
                            src={playerDetails?.image || null}
                            alt='playerPic'
                            sx={{ width: '100%', height: '100%', borderRadius: '50%' }}
                        />
                    </Box>
                    <Typography
                        variant="h2"
                        align="center"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 500,
                            fontSize: mobileView ? '13px' : '25px'
                        }}
                    >
                        {playerDetails?.name || ""}
                    </Typography>
                    <Typography
                        variant="h2"
                        align="center"
                        sx={{
                            color: '#D9D9D9',
                            fontWeight: 400,
                            fontSize: mobileView ? '12px' : '25px'
                        }}
                    >
                        {playerDetails?.specialization || ""}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h2"
                        align="center"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 500,
                            fontSize: mobileView ? '17px' : '25px'
                        }}
                    >
                        Sold At
                    </Typography>
                    <Typography
                        variant="h2"
                        align="center"
                        sx={{
                            color: '#57F51E',
                            fontWeight: 600,
                            fontSize: mobileView ? '20px' : '30px',
                            marginTop: '20px'
                        }}
                    >
                        {playerDetails?.soldAt || 0} L
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                    <img
                        src={playerDetails?.team?.image || null} alt='logo'
                        style={{
                            width: mobileView ? '80px' : '180px',
                            height: mobileView ? '80px' : '180px',
                            borderRadius: '50%'
                        }} />
                    <Typography
                        variant="h2"
                        align="center"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 500,
                            fontSize: mobileView ? '13px' : '25px'
                        }}
                    >
                        {playerDetails?.team?.name || ""}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: mobileView ? '55%' : '27%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#FFFFFF21',
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px',
                    padding: '10px 15px'
                }}
            >
                <Typography
                    variant="h6"
                    align="center"
                    sx={{
                        color: '#FFFFFFCC',
                        fontWeight: 400,
                        fontSize: '15px'
                    }}
                >
                    Base Price
                </Typography>
                <Typography
                    variant="h5"
                    align="center"
                    sx={{
                        color: '#FFFFFF',
                        fontWeight: 500,
                        fontSize: '20px'
                    }}
                >
                    {playerDetails?.basePrice || 0} L
                </Typography>
            </Box>
        </Box >
    )
}

export default Leaderboard;