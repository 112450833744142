import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getStorageItem } from "../../../utils/sessionStorage";
import SinglePhoto from "./SinglePhoto";
import './index.scss';

export default function GetAll() {
    const accessToken = getStorageItem("token");
    const [coverphotos, setcoverPhotos] = useState([]);
    const [change, setChange] = useState(true);

    const handleDelete = () => {
        setChange(!change);
    }

    const handleCoverPhotos = () => {
        if (!accessToken) return
        const getAllcover = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/coverphoto/getAll/1/10",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        }
        axios(getAllcover)
            .then((response) => {
                console.log(response.data)
                if (response.data) {
                    setcoverPhotos(response.data);
                }

            })
            .catch((err) => {
                console.log("error", err)
            });
    }

    useEffect(() => {
        handleCoverPhotos();
    }, [change])


    return (
        <Box>
            <Typography variant="h4">All cover photos</Typography>

            <Box marginTop={2} className="maincoverPHoto">
                {coverphotos.map((i) => (
                    <SinglePhoto data={i} handleDelete={handleDelete} />
                ))}

            </Box>
        </Box>
    )
}
