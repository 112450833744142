import React, { useEffect, useState } from 'react'
import cross from "../../../../assets/images/rewards/wrongsign.png"
import right from "../../../../assets/images/rewards/rightsign.png"


export default function Optionanswer({ optiondata, wholequestion }) {
    const [percentage, setPercentage] = useState("0");
    const [istrue, setansStatus] = useState(false);
    const [whatcolor, setwhatcolor] = useState("#2586DB");
    console.log("opriondata", optiondata)
    // console.log("wholequertion", wholequestion)

    useEffect(() => {

        const newdata = (optiondata?.totalUserSelectThisOption / wholequestion?.totaluserAttemptThisQuestion
        ) * 100;
        setPercentage(Math.floor(newdata));
        if (wholequestion?.rightAns?.text) {

            if (wholequestion?.rightAns?.text === optiondata?.text) {
                console.log("hey iam status")
                setansStatus(true);
            }
        }

        if (wholequestion?.userselectedoption?.text === wholequestion?.rightAns?.text) {
            setwhatcolor("#48BD53");
            console.log("hey iam color")
        }
        if (optiondata?.text === wholequestion?.userselectedoption?.text) {
            if (wholequestion?.userselectedoption?.text != wholequestion?.rightAns?.text) {
                console.log("hey iam true color")
                setwhatcolor("#E54B4B");
            }
        }

        if (optiondata?.text !== wholequestion?.userselectedoption?.text) {
            setwhatcolor("#2586DB");
        }
        return () => {
            setPercentage('0');
            setansStatus(false);
            setwhatcolor('')
        }
    }, [wholequestion])
    return (
        <div className='resultfont12' style={{ position: "relative" }} >
            <div className="answercontainer" style={{ width: `${percentage}%`, backgroundColor: whatcolor }}>
                <p style={{ visibility: 'hidden' }}>t</p>
                <p className='resultoptiontxt' >{optiondata?.text} </p>
                <p className='resultpercantage'>{percentage}%</p>
                <img src={istrue ? right : cross} className="resultIcon" />
            </div>
        </div>
    )
}
