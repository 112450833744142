import {
  TextField,
  Input,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  Grid,
} from "@mui/material";
import * as React from "react";
import axios from "axios";
import { FormControl, InputAdornment } from "@material-ui/core";
import { CiSquarePlus, CiCirclePlus } from "react-icons/ci";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Select, MenuItem } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { GrAddCircle } from "react-icons/gr";
import { useMediaQuery } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { MentionsInput, Mention } from "react-mentions";
import "../../commons/components/post-modal/index.scss"
import config from "../../config";
import { getStorageItem } from "../../utils/sessionStorage";
import { getMouseEventOptions } from "@testing-library/user-event/dist/utils";
import { max } from "moment/moment";
import { grey } from "@mui/material/colors";
const baseURL = config.ROOTURL.prod;
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const stylePollMobile = {
  // background: "linear-gradient(to bottom, #0C5BA0 10%, white 9%)",
  color: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 625,
  bgcolor: "#FFFFFF",
  border: "2px solid #F1F1F5",
  boxShadow: 24,
  minHeight: "100%",
  width: "100%",
  p: 0,
};
const stylePoll = {
  // background: "linear-gradient(to bottom, #0C5BA0 15%, white 7%)",
  color: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "345px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // pt: 2,
  // px: 4,
  // pb: 3,
};
const styleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};
const tagStyles = {
  // fontFamily: "Poppins",
  // fonStyle: "normal",
  // fontWeight: 500,
  // fontSize: "14px",
  // lineHeight: "21px",
  // color: "#0c5ba0",
};

const MentionStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    input: {
      minHeight: "50px",
      padding: 9,
      border: "1px solid silver",
      "&focused": {
        border: "1px solid black",
      },
    },
  },

  suggestions: {
    marginTop: 50,
    list: {
      fontSize: 14,
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 5px 15px rgba(68, 68, 79, 0.1)",
      borderRadius: 8,
      minWidth: 400,
    },
    item: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: 2,
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#d2effa",
      },
    },
  },
};
const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export default function PollEdit(props) {
  console.log(props)
  const matches = useMediaQuery("(min-width:600px)");
  const { postId, postObj } = props;
  const [newContent, setNewContent] = React.useState();
  const [style, setStyle] = useState();
  const [question, setQuestion] = React.useState();
  const [questionHastag, setquestinhastag] = useState([]);
  const [maxLength, setMaxLength] = useState(20);
  const [choice1, setChoice1] = useState("");
  const [choice2, setChoice2] = useState("");
  const [choice3, setChoice3] = useState("");
  const [choice4, setChoice4] = useState("");
  const [choice5, setChoice5] = useState("");
  const [choiceLength, setChoiceLength] = useState(0);
  const [choice1Id, setChoice1Id] = useState({ _id: "", votedUserIds: [], votes: 0, createdDate: Date });
  const [choice2Id, setChoice2Id] = useState({ _id: "", votedUserIds: [], votes: 0, createdDate: Date });
  const [choice3Id, setChoice3Id] = useState({ _id: "", votedUserIds: [], votes: 0, createdDate: Date });
  const [choice4Id, setChoice4Id] = useState({ _id: "", votedUserIds: [], votes: 0, createdDate: Date });
  const [choice5Id, setChoice5Id] = useState({ _id: "", votedUserIds: [], votes: 0, createdDate: Date });
  const [updatedArray, setUpdatedArray] = React.useState(postObj);
  const mobileView = useMediaQuery("(max-width:900px)");
  const userId = getStorageItem('user_id')
  const fullName = getStorageItem('full_name')
  const getByPollId = (pollId) => {
    axios
      .get(`${baseURL}/poll/getPollByPollId/${pollId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        let que = data?.question
        setQuestion(que)
        setquestinhastag(data?.questionHastag)
        setChoiceLength(data?.options?.length)
        data?.options?.map((a, i) => {
          if (i === 0) {
            let t1 = a.text;
            setChoice1(t1);
            setChoice1Id({ _id: a._id, votes: a.votes, votedUserIds: a.votedUserIds, createdDate: a.createdDate })
          }
          if (i === 1) {
            let t2 = a.text;
            setChoice2(t2);
            setChoice2Id({ _id: a._id, votes: a.votes, votedUserIds: a.votedUserIds, createdDate: a.createdDate })
          }
          if (i === 2) {
            let t3 = a.text;
            setChoice3(t3);
            setChoice3Id({ _id: a._id, votes: a.votes, votedUserIds: a.votedUserIds, createdDate: a.createdDate })
          }
          if (i === 3) {
            let t4 = a.text;
            setChoice4(t4);
            setChoice4Id({ _id: a._id, votes: a.votes, votedUserIds: a.votedUserIds, createdDate: a.createdDate })
          }
          if (i === 4) {
            let t5 = a.text;
            setChoice5(t5);
            setChoice5Id({ _id: a._id, votes: a.votes, votedUserIds: a.votedUserIds, createdDate: a.createdDate })
          }
          return true;

        });
        // setChoice3(data.options[2].text)
        // setChoice4(data.options[3].text)
        // setChoice5(data.options[4].text)

      })
      .catch((error) => {
        // console.log(error);
      });

  }

  useEffect(() => {
    if (postObj?.options) {
      getByPollId(!postObj.pinpoll?postObj.feed_id:postObj._id);
    }

    if (!matches) {
      setStyle(stylePollMobile);
    } else {
      setStyle(stylePoll);
    }
  }, [postObj]);


  const handleClick = () => {
    let option = [];
    props.handleUpdatePoll(postId, option, question, questionHastag);
    createHashTag(pollDescription.hashtag, !postObj.pinpoll?postObj.feed_id:postObj._id)
    if (choiceLength === 1 && question !== "" && choice1 !== "") {
      option.push({ ...choice1Id, text: choice1 });
      props.setPostObj({ ...postObj, options: option, question, questionHastag });
    }
    else if (choiceLength === 2 && question !== "" && choice1 !== "" && choice2 !== "") {
      option.push({ ...choice1Id, text: choice1 });
      option.push({ ...choice2Id, text: choice2, });
      props.setPostObj({ ...postObj, options: option, question, questionHastag });
    }
    else if (choiceLength === 3 && question !== "" && choice1 !== "" && choice2 !== "" && choice3 !== "") {
      option.push({ ...choice1Id, text: choice1 });
      option.push({ ...choice2Id, text: choice2, });
      option.push({ ...choice3Id, text: choice3 });
      props.setPostObj({ ...postObj, options: option, question, questionHastag });
    }
    else if (choiceLength === 4 && question !== "" && choice1 !== "" && choice2 !== "" && choice3 !== "" && choice4 !== "") {
      option.push({ ...choice1Id, text: choice1 });
      option.push({ ...choice2Id, text: choice2, });
      option.push({ ...choice3Id, text: choice3 });
      option.push({ ...choice4Id, text: choice4 });
      props.setPostObj({ ...postObj, options: option, question, questionHastag });
    }
    else if (choiceLength === 5 && question !== "" && choice1 !== "" && choice2 !== "" && choice3 !== "" && choice4 !== "" && choice5 !== "") {
      option.push({ ...choice1Id, text: choice1 });
      option.push({ ...choice2Id, text: choice2, });
      option.push({ ...choice3Id, text: choice3 });
      option.push({ ...choice4Id, text: choice4 });
      option.push({ ...choice5Id, text: choice5 });
      props.setPostObj({ ...postObj, options: option, question, questionHastag });
    } else if (question !== "") {
      props.setPostObj({ ...postObj, question, questionHastag });
    }
    else {
      props.setPostObj({ ...postObj });
    }
  }

  const [pollDescription, setPollDescription] = useState({
    hashtag: [],
    text: [],
  });
  const [PollContent, setPollContent] = useState({
    message: "",
    messageValue: postObj.question,
    hashtags: [],
  })
  const [postMessageWithoutHtml, setPostMessageWithoutHtml] = useState("");
  const [pollQuestionWithoutHtml, setPollQuestionWithoutHtml] = useState("");



  const handlePollInputChange = (event, newValue, newPlainTextValue) => {
    // console.log(event, newValue, newPlainTextValue);
    setPollQuestionWithoutHtml(newPlainTextValue);
    // below regex excluedes tag
    // let regexAllTags = /(&nbsp;|<([^>]+)>)/ig
    let hashtagRegex = /#[^\s#]*/;
    const hTag = [];
    const plainTxt = [];
    let splitted = "";
    var one_or_two = new RegExp("(" + hashtagRegex.source + ")");
    // if(newPlainTextValue){
    splitted = newPlainTextValue?.split(one_or_two);
    splitted?.map((txt, i) => {
      if (txt.match(hashtagRegex)) {
        hTag.push({ index: i, text: txt });
      } else {
        plainTxt.push({ index: i, text: txt });
      }
      return true;
    });
    setPollDescription({ hashtag: hTag, text: plainTxt });
    // }


    const hashtags = newPlainTextValue
      ? newPlainTextValue.match(/#[^\s#]*/gim)?.filter((x, i, a) => a.indexOf(x) === i)
      : [];

    const { value } = event.target || {};

    setPollContent({
      message: newValue,
      messageValue: value,
      hashtags: hashtags,
    });
    setQuestion(newValue.trim());
    setquestinhastag({ description: { hashtags: hTag, text: plainTxt }, PollMessageWithoutHtml: newPlainTextValue.trim() })

  };

  const getHashtags = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/hash-profile-tag/searchHashTag",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "#" + data.hashTagText,
          };
        });
      })
      .then(callback);
  };
  const createHashTag = async (hashtags, pitchId) => {
    if (hashtags && hashtags.length) {
      for (let index = 0; index < hashtags.length; index += 1) {
        const element = hashtags[index];
        const hashTagText = element.substring(1);
        const createdUserId = userId;
        const createdUserName = fullName;
        const data = {
          hashTagText: hashTagText,
          createdUserId: createdUserId,
          createdUserName: createdUserName,
        };
        const options = {
          method: "POST",
          url: global.config.ROOTURL.prod + "/hash-profile-tag/createHashTag",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          data: data,
        };
        axios(options)
          .then(({ data }) => {
            createPollHashTag(data._id, data.hashTagText, pitchId);

          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              console.log(error);
            }
          });
      }
    }
  };
  const createPollHashTag = async (_id, hashTagText, pollId) => {
    if (_id && hashTagText && pollId) {
      const data = {
        hashTagId: _id,
        hashTagText: hashTagText,
        pollId: pollId,
      };
      const options = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/hash-profile-tag/pollHashTag/createPollHashTag",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: data,
      };
      axios(options)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log(error);
          }
        });
    }
  };

  // const questionData = {
  //   description: pollDescription,
  //   PollMessageWithoutHtml: pollQuestionWithoutHtml.trim()
  // }
  // const pollData = {
  //   question: PollContent.message.trim(),
  //   questionHastag: questionData,
  //   options,
  //   noOfActiveDay: noOfActiveDays,
  // };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={mobileView ? stylePollMobile : stylePoll}>
          <Box className="post__polls--header">
            <div className="post__polls--back_button">
              <IconButton
                className="close__icon"
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                  color: "black",
                }}
              >
                {/* <CloseIcon /> */}
                <HiOutlineArrowNarrowLeft
                  style={{ color: "white" }}
                // onClick={handleResetPoll}
                />
              </IconButton>
            </div>
            <h2 className="post__polls__heading">Update Poll</h2>
          </Box>
          <Grid container columnSpacing={1} style={{ padding: "0px 25px 25px 25px" }}>
            {/* Title */}

            <Grid item xs={12} style={{ padding: "10px", paddingLeft: "10px" }}>
              {" "}
              <Box className="title-poll-icon">
                <Typography className="pedestal__title">
                  <h4 style={{ color: "black" }}>Ask Question</h4>
                </Typography>
                {/* <div
                style={{
                  border: "2px solid #fff8dc",
                  backgroundColor: "#fff8dc",
                }}
              >
                {" "}
                <svg
                  style={{ marginRight: "2px" }}
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.9446 1.66667C9.2277 1.66667 11.8892 4.20372 11.8892 7.33333C11.8892 10.4629 9.2277 13 5.9446 13C2.66149 13 0 10.4629 0 7.33333C0 4.20372 2.66149 1.66667 5.9446 1.66667ZM5.9446 2.66667C3.24086 2.66667 1.04905 4.75601 1.04905 7.33333C1.04905 9.91067 3.24086 12 5.9446 12C8.64834 12 10.8402 9.91067 10.8402 7.33333C10.8402 4.75601 8.64834 2.66667 5.9446 2.66667ZM5.9446 3.66667C6.21015 3.66667 6.42961 3.85477 6.46437 4.09882L6.46912 4.16667V7.16667C6.46912 7.4428 6.23428 7.66667 5.9446 7.66667C5.67905 7.66667 5.45959 7.47853 5.42483 7.23453L5.42008 7.16667V4.16667C5.42008 3.89053 5.65492 3.66667 5.9446 3.66667ZM10.9431 1.74247L11.0009 1.78237L11.811 2.42515C12.0337 2.60181 12.0639 2.91708 11.8786 3.12932C11.7087 3.32387 11.4166 3.36433 11.1977 3.23365L11.1399 3.19375L10.3298 2.55097C10.1071 2.37431 10.0769 2.05904 10.2621 1.8468C10.4321 1.65225 10.7241 1.61179 10.9431 1.74247ZM7.51817 0C7.80785 0 8.0427 0.22386 8.0427 0.5C8.0427 0.753133 7.84533 0.962327 7.58937 0.995433L7.51817 1H4.37103C4.08135 1 3.84651 0.77614 3.84651 0.5C3.84651 0.246867 4.04383 0.0376733 4.29986 0.00456667L4.37103 0H7.51817Z"
                    fill="#212121"
                  />
                </svg>
                <Select
                  displayEmpty
                  disableunderline='true'
                  value={noOfActiveDays}
                  style={{
                    backgroundColor: "#fff8dc",
                    height: "1px",
                    width: "90px",
                  }}
                  onChange={(e) =>
                    setNoOfActiveDays(e.target.value)
                  }
                  required
                >
                  <MenuItem value="" disabled selected>
                    Duration
                  </MenuItem>{" "}
                  <MenuItem value={1}>1 day</MenuItem>
                  <MenuItem value={7}>1 week</MenuItem>
                  <MenuItem value={30}>1 month</MenuItem>
                </Select>
              </div> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <div className="POllMention">
                <MentionsInput
                  value={PollContent.messageValue ? PollContent.messageValue : " "}
                  name="message"
                  placeholder="Start typing here"
                  id="postContent"
                  className="pollContent"
                  onChange={(event, newValue, newPlainTextValue, mentions) => {
                    handlePollInputChange(event, newValue, newPlainTextValue, mentions);
                  }}
                  allowSpaceInQuery={true}
                  style={MentionStyle}
                >
                  <Mention
                    trigger="#"
                    style={tagStyles}
                    appendSpaceOnAdd={true}
                    allowSpaceInQuery={false}
                    data={getHashtags}
                    markup="<a class='hashtagged' href='/hashtags?id=__id__'>__display__</a>"
                  />
                </MentionsInput>
              </div>
            </Grid>
            {/* choice 1 */}
            <Typography className="pedestal__title">
              <h4 style={{ color: "black", paddingLeft: "8px" }}>Options</h4>
            </Typography>
            <Grid item xs={12}>
              <div className="form_controls">
                <input
                  maxLength={maxLength}
                  type="text"
                  id="outlined-adornment-amount"
                  placeholder="choice 1"
                  name="choice 1"
                  autoFocus
                  margin="normal"
                  value={choice1}
                  sx={{ input: { paddingLeft: "5px" } }}
                  required
                  onChange={(e) => setChoice1(e.target.value)}
                />
              </div>{choice1.length >= maxLength ? <div style={{ fontSize: "10px", color: "darkgrey", marginLeft: "5px" }}>max. {maxLength} characters allowed</div> : null}
            </Grid>

            <Grid item xs={12}>
              <div className="form_controls">
                <input
                  maxLength={maxLength}
                  type="text"
                  id="outlined-adornment-amount"
                  placeholder="choice 2"
                  name="choice 2"
                  margin="normal"
                  value={choice2}
                  sx={{ input: { paddingLeft: "5px" } }}
                  required
                  onChange={(e) => setChoice2(e.target.value)}
                />
              </div>{choice2.length >= maxLength ? <div style={{ fontSize: "10px", color: "darkgrey", marginLeft: "5px" }}>max. {maxLength} characters allowed</div> : null}
            </Grid>
            {choiceLength > 2 && <Grid item xs={12}>
              <div className="form_controls">
                <input
                  maxLength={maxLength}
                  type="text"
                  id="outlined-adornment-amount"
                  placeholder="choice 3"
                  name="choice 3"
                  margin="normal"
                  value={choice3}
                  sx={{ input: { paddingLeft: "5px" } }}
                  required
                  onChange={(e) => setChoice3(e.target.value)}
                />
              </div>{choice3.length >= maxLength ? <div style={{ fontSize: "10px", color: "darkgrey", marginLeft: "5px" }}>max. {maxLength} characters allowed</div> : null}
            </Grid>}
            {choiceLength > 3 && <Grid item xs={12}>
              <div className="form_controls">
                <input
                  maxLength={maxLength}
                  type="text"
                  id="outlined-adornment-amount"
                  placeholder="choice 4"
                  name="choice 4"
                  margin="normal"
                  value={choice4}
                  sx={{ input: { paddingLeft: "5px" } }}
                  required
                  onChange={(e) => setChoice4(e.target.value)}
                />
              </div>{choice4.length >= maxLength ? <div style={{ fontSize: "10px", color: "darkgrey", marginLeft: "5px" }}>max. {maxLength} characters allowed</div> : null}
            </Grid>}
            {choiceLength > 4 && <Grid item xs={12}>
              <div className="form_controls">
                <input
                  maxLength={maxLength}
                  type="text"
                  id="outlined-adornment-amount"
                  placeholder="choice 5"
                  name="choice 5"
                  margin="normal"
                  value={choice5}
                  sx={{ input: { paddingLeft: "5px" } }}
                  required
                  onChange={(e) => setChoice5(e.target.value)}
                />
              </div>{choice5.length >= maxLength ? <div style={{ fontSize: "10px", color: "darkgrey", marginLeft: "5px" }}>max. {maxLength} characters allowed</div> : null}
            </Grid>}

            <Grid item xs={12}>
              {/* <FormControl fullWidth></FormControl> */}
              <Button
                style={{
                  backgroundColor: "#FECC08",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  width: "25px",
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                margin="normal"
                onClick={handleClick}
                fullWidth
              >
                Post
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
