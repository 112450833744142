import React from "react";
import "./index.scss";
import logo from "../../../assets/images/newLandingPage/logo.png";
import Carousel1 from "./carousel1";
import Carousel2 from "./carouse2";
import { Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
} from "@material-ui/core";

const FirstSection = () => {
  const navigate = useNavigate();
  return (
    <div className="first_section">
      <div className="container1">
        <Toolbar>
          <img src={logo} alt="Champhunt" />
          <label className="logo_text">CHAMPHUNT</label>
        </Toolbar>
        <div className="text_container">
          <p className="bold_text">UNLEASH YOUR</p>
          <p className="bold_text">CRICKET PASSION!</p>
          <p id="small_text">AND EARN REWARDS</p>
          <div className="button" onClick={() => navigate("/signup")}>
            <Button className="join_btn">JOIN NOW!</Button>
            <span id="arrow">&#8594;</span>
          </div>
        </div>
        <div className="offer">
          <p className="small_text">NEW OFFERS</p>
          <Carousel1 />
        </div>
      </div>
      <div className="container2">
        <div className="login_signup">
          <Button className="login" onClick={() => navigate("/login")}>
            Log in
          </Button>
          <Button className="signup" onClick={() => navigate("/signup")}>
            Sign up
          </Button>
        </div>
        <Carousel2 />
      </div>
    </div>
  );
};

export default FirstSection;
