import React from 'react'
import Modal from "@mui/material/Modal";
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import PitchPage from '../../components/posts/PitchPage';


export default function NotificationModal({ id, open, setOpen, handleclose }) {



    const handlePostClose = () => {
        setOpen(false);
        handleclose()
    }
    return (


        <Modal
            open={open}
            onClose={handlePostClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <PitchPage pid={id} test={"Test"}  type={"notificationpopup"}/>

        </Modal>
        // </div>
    )
}
