import { useMediaQuery } from '@material-ui/core';
import { Box } from '@mui/system';
import React from 'react'
import Header from '../header/header-without-signin';
import Grid from '@mui/material/Grid';
import Logo from "../../assets/images/header/logo.png";
import bookmark from "../../assets/images/deals/SaveBookmark.png";
import Bag from "../../assets/images/deals/Bag.png";
import Building from "../../assets/images/deals/building.png";
import wallet from "../../assets/images/deals/wallet.png";
import Popup from './Popup';
import './JobPreview.scss'
import { Button } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getjobByid, getAllJobPostedBasedOnWorkPlaceType } from './JobPreviewService';
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import parse from 'html-react-parser';
import ReactTimeAgo from "react-time-ago";
import axios from "axios";
import config from "../../config";
import CommentsIconGray from "../../assets/images/icons/comments.svg";
import ShareIconGray from "../../assets/images/icons/share.svg";
import CommentsIcon from "../../assets/images/comment_blue_icon.png";
// import ShareIcon from "../../assets/images/icons/share.svg";
import RunsIcon from "../../assets/images/icons/post-action.png";
import BallIcon from "../../assets/images/icons/ball-white.svg";
import ShareIcon from "../../assets/images/share_pink_icon.png";
import RunCard from "../posts/run";
import { getStorageItem } from "../../utils/sessionStorage";
import {Link} from "@mui/material";


const style = {};
const mobilestyle = {
    backgroundColor: "white"
};
const childwidthmobile={
    width:'100%'
}
const childwidthweb={
    width:'50%'
}
export default function JobPreview(props ) {
    var regex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
    const mobileView = useMediaQuery("(max-width:600px)");
    const [number, setnumber] = useState(1);
    const [job, setJob] = useState(null);
    const [MoreJobs, setMorejobs] = useState(null);
    const [Open, setOpen] = useState(false);
    const baseURL = config.ROOTURL.prod;
    const [comments, setComments] = useState([]);
    const [posts, setPosts] = useState([]);
    const [postsOfUserId, setPostsOfUserId] = useState([]);
    const [jobData, setJobData] = useState();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    
    const params = useParams();
    const jobId = params.id;
    const [pageNumber, setPageNumber] = useState(1);

    const handleOpen = () => setOpen(true);
    const handleClose = (data) => setOpen(data);
    useEffect(() => {
        mobileView ? setnumber(1) : setnumber(2);
    }, [mobileView])

    const getalljobs = (data) => {
        console.log('data', data)
        if (data) {

            const maindata = {
                jobId:data._id,
                workType:data.workPlaceType,
                pageNumber: 1,
                pagePerSize: 2,

            }
            console.log("maind", maindata)
            getAllJobPostedBasedOnWorkPlaceType(maindata)
                .then((res) => {
                    console.log('res',res)
                    setMorejobs(res.data)
                }).catch((error) => {
                    console.log(error)
                })
        }
    }

    // useEffect(() => {
    //     getjobByid(jobId)
    //         .then(res => {
    //             console.log(res)

    //             setJob(res.data.data)

    //             getalljobs(res.data.data)
    //         })
    //         .catch(error => { console.log(error) })
    // }, [])

    const fetchDataByjobId = () => {
        console.log("Entered")
        axios
          .get(`${baseURL}/job/getByjobIdForPreview/${jobId}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("fetchDataByjobId", response.data);
            setPosts(response.data);
            setJobData(response.data);
            fetchSamplejobesByUserId(job.userId);
          })
          .catch((error) => {
            console.log("error", error);
          });
      };

   
      const fetchSamplejobesByUserId = () => {
        axios
          .post(`${baseURL}/job/getjobByUserIdForPreview`, {
            pageNumber,
            pagePerSize: "2",
            jobId,
          }, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response.data.data", response.data);
            setPostsOfUserId(response.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      };
      useEffect(() => {
        fetchDataByjobId();
        // fetchCommentByjobId();
    
      }, []);

      let JobId1;
      const getJobId = (id) => {
          JobId1 = id;
          // console.log("this is getJobId function", JobId);
          window.location.href = `/job-description/${JobId1}`
      }

    
    return (

        <Box sx={!mobileView ? style : mobilestyle}>
            <Header />
            <Box className="Main_Container_">
                {console.log('job?', MoreJobs)}

                <Grid container spacing={2} sx={{ backgroundColor: 'white' }} className={'gridPadding'}>
                    <Grid item xs={12} spacing={2}>
                        <Grid container >
                            <Grid item xs={9} md={11} className="commmonclass">
                            <Link onClick={() => getJobId(jobData._id)} about='_blank' underline="hover" >
                                <img src={!job?.companyLogo ? defaultAvatar : job?.companyLogo}  className="logostyle" />
                                <span className='HeadingStyle' onClick={handleOpen}>{jobData?.jobTitle}</span>
                                </Link>
                            </Grid>
                           
                            <Grid item xs={3} md={1} className="commmonclass" sx={{ justifyContent: 'center' }}>
                                <img src={bookmark} className="bookmark__" onClick={handleOpen} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} spacing={2}>
                        <div className='font24Preview commonPadding'>
                            <img src={Building} /> <span className='commonpaddingforchild'>{job?.companyName}</span>
                        </div>
                        <div className='font24Preview commonPadding'>
                            <img src={Bag} /> <span className='commonpaddingforchild'>{job?.Experience}</span>
                        </div>
                        <div className='font24Preview commonPadding'>
                            <img src={wallet} /> <span className='commonpaddingforchild'>{job?.Remuneration}</span>
                        </div>
                        <div className='commonPadding'>
                            <p className='HeadingStyle' style={{ paddingLeft: '6px' }}>Job description</p>
                            <p className='font20Preview commonPadding'>
                                {parse(`${job?.jobDescriptionsFileName}`)}
                            </p>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#FECC08",
                                    color: '#00247B', borderRadius: "5px", fontWeight: '700', textTransform: 'none'
                                }}
                                className="marginbutton"
                                onClick={handleOpen}

                            >
                                Apply Now
                            </Button>
                        </div>
                    </Grid>
                </Grid>


                {
                    mobileView &&
                    <Box className="footer_container_">
                        <div className='footer_first'>
                            <p className='footerPara_'><hr /></p>
                            <img src={Logo} className="footerimage_" />
                            <p className='footerPara_2'><hr /></p>
                        </div>
                        <p className="footer__">Sign up for our best experience </p>
                    </Box>

                }

                <Box className="morejobsParent">
                    <div className='viewAll__'>
                        <p className='HeadingStyle2' onClick={handleOpen} >More Jobs</p>
                        <p className='viewallfont' onClick={handleOpen}>View all</p>
                    </div>

                    <Box className='morejobparentcontainer' >

                        {MoreJobs?.slice(0, number).map((i,index) => (
                            <Box sx={mobileView?childwidthmobile:childwidthweb}  key={index}>
                                {console.log("morejob",MoreJobs)}
                                <Grid container xs={11} spacing={2} sx={{ backgroundColor: 'white' }} className={'gridPadding__sm'}>
                                    <Grid item xs={12} sx={{ marginLeft: '-12px' }} >
                                        <Grid container >
                                            <Grid item xs={11} className="commmonclass">
                                                <img src={i.userProfilePhoto ? i.userProfilePhoto : defaultAvatar} className='logostyle__sm' alt=''/>
                                                <span className='HeadingStyle_sm' onClick={handleOpen}>{i?.jobTitle}</span>
                                            </Grid>
                                            <Grid item xs={1} className="commmonclass" sx={{ justifyContent: 'center' }}>
                                                <img src={bookmark} className="bookmark__sm" onClick={handleOpen} alt='' />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={10} sx={{ paddingTop: '0px' }} >
                                        <div className='font24Preview__sm commonPadding__sm'>
                                            <img src={Building} /> <span className='commonpaddingforchild'>{i?.companyName}</span>
                                        </div>
                                        <div className='font24Preview__sm commonPadding__sm'>
                                            <img src={Bag} /> <span className='commonpaddingforchild'>{i?.Experience}</span>
                                        </div>
                                        <div className='font24Preview__sm commonPadding__sm'>
                                            <img src={wallet} /> <span className='commonpaddingforchild'>{i?.Remuneration}</span>
                                        </div>
                                        <div className='commonPadding__sm'>
                                            <p className='HeadingStyle_sm' style={{ paddingLeft: '6px' }}>Job description</p>
                                            <p className='font20Preview__sm commonPadding__sm'>
                                                 {parse(`${i?.jobDescriptionsFileName}`)} </p>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#FECC08",
                                                    color: '#00247B', borderRadius: "5px", fontWeight: '700', textTransform: 'none'
                                                }}
                                                className="marginbutton__sm"
                                                onClick={handleOpen}
                                            >
                                                Apply Now
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>

                </Box>

                {
                    !mobileView &&
                    <Box className="footer_container_">
                        <div className='footer_first'>
                            <p className='footerPara_'><hr /></p>
                            <img src={Logo} className="footerimage_" />
                            <p className='footerPara_2'><hr /></p>
                        </div>
                        <p className="footer__">Sign up for our best experience </p>
                    </Box>

                }
            </Box>
            {
                mobileView &&
                <Box className=" mobilefooter">
                    <div className='footer_first'>
                        <img src={Logo} className="" />
                        <p className='mobilefootername'>ChampHunt</p>
                    </div>
                    <p className="mobilefooter__">Champhunt is a 360 degree platform for all things cricket. Your one-stop destination to earn from your love of cricket on the internet. </p>
                </Box>

            }
            {Open && <Popup Open={Open} handleClose={handleClose} />}

        
        </Box>
    )
}
