import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import PaginationRounded from "../PaginationRounded";
import { toast } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const CreateChallenge = () => {
    const startDayRef = useRef();
    const startTimeRef = useRef();
    // const runsForEntryRef = useRef();
    const endDayRef = useRef();
    const endTimeRef = useRef();
    // const rewardPriceRef = useRef();
    const [challengeQuestion, setChallengeQuestion] = useState("");
    const option1Ref = useRef();
    const option2Ref = useRef();
    const option3Ref = useRef();
    const option4Ref = useRef();
    const option5Ref = useRef();
    const [showOption5, setShowOption5] = useState(false);
    const [showOption4, setShowOption4] = useState(false);
    const [showOption3, setShowOption3] = useState(false);
    const handleChallengeCreation = async () => {
        if (!accessToken) return
        const startDayRefValue = startDayRef?.current?.value;
        const startTimeRefValue = startTimeRef?.current?.value;
        // const runsForEntryRefValue = runsForEntryRef?.current?.value;
        const endDayRefValue = endDayRef?.current?.value;
        const endTimeRefValue = endTimeRef?.current?.value;
        const challengeOption1 = option1Ref?.current?.value;
        const challengeOption2 = option2Ref?.current?.value;
        const challengeOption3 = option3Ref?.current?.value;
        const challengeOption4 = option4Ref?.current?.value;
        const challengeOption5 = option5Ref?.current?.value;

        console.log({
            challengeQuestion,
            challengeOption1,
            challengeOption2,
            challengeOption3: challengeOption3 || '',
            challengeOption4: challengeOption4 || '',
            challengeOption5: challengeOption5 || '',
            startTime: startTimeRefValue,
            startDate: moment(startDayRefValue).format("YYYY-MM-DD"),
            endTime: endTimeRefValue,
            // redeemRun: runsForEntryRefValue,
            endDate: moment(endDayRefValue).format("YYYY-MM-DD"),
        })
        if (!challengeQuestion || !challengeOption1 || !challengeOption2 ||
            !startDayRefValue ||
            !startTimeRefValue ||
            !endDayRefValue ||
            !endTimeRefValue
        ) {
            toast.error("Unfilled required fields");
            return;
        }
        const data = {
            challengeQuestion,
            challengeOption1,
            challengeOption2,
            challengeOption3: challengeOption3 || '',
            challengeOption4: challengeOption4 || '',
            challengeOption5: challengeOption5 || '',
            startTime: startTimeRefValue,
            startDate: moment(startDayRefValue).format("YYYY-MM-DD"),
            endTime: endTimeRefValue,
            // redeemRun: runsForEntryRefValue,
            endDate: moment(endDayRefValue).format("YYYY-MM-DD"),
        };
        const options = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/contest/challenge/createChallenge",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                role: "Admin",
            },
            data: data,
        };
        axios(options)
            .then(({ data }) => {

                if (data._id) {

                    toast.success("New Challenge created successfully");
                    startDayRef.current.value = null;
                    startTimeRef.current.value = null;
                    // runsForEntryRef.current.value = null;
                    endDayRef.current.value = null;
                    endTimeRef.current.value = null;
                    option1Ref.current.value = null;
                    option2Ref.current.value = null;
                    option3Ref.current.value = null;
                    option4Ref.current.value = null;
                    option5Ref.current.value = null;
                    setChallengeQuestion("")

                }


            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    console.log(error);
                }
            });
    };
    console.log("challengeQuestion", challengeQuestion)
    useEffect(() => {
        if (challengeQuestion && challengeQuestion.length > 50) {
            const updatedNameValue = challengeQuestion.slice(0, 50);
            setChallengeQuestion(updatedNameValue);
        }
    }, [challengeQuestion]);
    return (
        <div className="contest-admin-create">
            <div className="contest-admin-create-wrapper">
                <div className="contest-admin-create-wrapper-main">
                    <div className="contest-admin-create-wrapper-header">
                        <p className="contest-admin-create-wrapper-header--text">Name</p>
                        {/* <p className="contest-admin-create-wrapper-header--delete-text">
                            Delete
                        </p> */}
                    </div>
                    <div className="contest-admin-create-wrapper-form">
                        <div className="contest-create-name-wrapper">
                            <input
                                type="text"
                                id="question"
                                className="contest-create-name"
                                placeholder="question..."
                                onChange={(e) => {
                                    setChallengeQuestion(e.target.value)
                                }}
                                required
                            />
                            <p className="contest-create-name-extra">Max 50 letters</p>
                            <p className="contest-create-name-count-indicator">
                                {challengeQuestion?.length && challengeQuestion?.length < 50 ? 50 - challengeQuestion?.length : 0}
                            </p>
                        </div>
                        <div className="contest-create-row2-wrapper">
                            <div className="contest-create-row2-block1">
                                <p className="admin-contest-creation-general-text">Start</p>
                                <input
                                    type="date"
                                    className="contest-create-start-date"
                                    ref={startDayRef}
                                    required
                                />
                                <input
                                    type="time"
                                    className="contest-create-start-time"
                                    ref={startTimeRef}
                                    required
                                />
                            </div>
                            {/* <div className="contest-create-row2-block2">
                                <p className="admin-contest-creation-general-text">
                                    Runs for Entry
                                </p>
                                <input
                                    type="text"
                                    className="contest-create-start-input"
                                    ref={runsForEntryRef}
                                    required
                                />
                            </div> */}
                        </div>
                        <div className="contest-create-row3-wrapper">
                            <div className="contest-create-row3-block1">
                                <p className="admin-contest-creation-general-text">End</p>
                                <input
                                    type="date"
                                    className="contest-create-start-date"
                                    ref={endDayRef}
                                    required
                                />
                                <input
                                    type="time"
                                    className="contest-create-start-time"
                                    ref={endTimeRef}
                                    required
                                />
                            </div>
                            {/* <div className="contest-create-row3-block2">
                                <p className="admin-contest-creation-general-text">Reward</p>
                                <input
                                    type="text"
                                    className="contest-create-start-input"
                                    ref={rewardPriceRef}
                                    required
                                />
                            </div> */}
                        </div>
                        {/* <div className="contest-create-row4-wrapper">
                            <p className="admin-contest-creation-general-text">Q1</p>
                            <input
                                type="text"
                                className="contest-create-start-input-full"
                                ref={challengeQuestion}
                                required
                            />
                        </div> */}
                        <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A1</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-1"
                                    id="option-1"
                                    ref={option1Ref}
                                />
                            </div>
                        </div>
                        <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A2</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-1"
                                    id="option-2"
                                    ref={option2Ref}
                                />
                            </div>
                        </div>
                        {showOption3 && <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A3</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-3"
                                    id="option-3"
                                    ref={option3Ref}
                                />
                            </div>
                        </div>}
                        {showOption4 && <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A4</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-4"
                                    id="option-4"
                                    ref={option4Ref}
                                />
                            </div>
                        </div>}
                        {showOption5 && (
                            <div className="contest-create-row5-wrapper">
                                <p className="admin-contest-creation-general-text">A5</p>
                                <div className="contest-create-radio-button-wrapper">
                                    <input
                                        type="text"
                                        className="contest-create-start-input-options"
                                        placeholder="Option-5"
                                        id="option-5"
                                        ref={option5Ref}
                                        required
                                    />
                                </div>
                            </div>
                        )}

                        <div className="contest-create-add-more">
                            <div className="contest-create-add-more--main">
                                <AddCircleOutlineIcon
                                    className="contest-create-add-more-icons"
                                    onClick={() => {
                                        if (!showOption3) {
                                            setShowOption3(true);
                                        } else if (!showOption4) {
                                            setShowOption4(true);
                                        } else if (!showOption5) {
                                            setShowOption5(true);
                                        } else {
                                            toast.error("Max limit is 3 options per challenge");
                                        }
                                    }}
                                />
                                <RemoveCircleOutlineIcon
                                    className="contest-create-add-more-icons"
                                    onClick={() => {
                                        if (showOption5) {
                                            setShowOption5(false);
                                        } else if (showOption4) {
                                            setShowOption4(false);
                                        } else if (showOption3) {
                                            setShowOption3(false);
                                        }

                                    }}
                                />
                            </div>
                        </div>
                        <button
                            onClick={() => handleChallengeCreation()}
                            className="contest-preediction-admin-creation-submit-button"
                        >
                            Confirm
                        </button>
                        {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateChallenge;
