import { useState, useRef, useEffect, useCallback } from "react";
import { Paper, Card, CardContent, Grid, Typography, Box, Modal, Button, Divider } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import {  getStorageItem } from "../../utils/sessionStorage";
import pollVoterListScroller from "../poll/votedListUseScroller";
import UserCard from './usercardforruns'
const accessToken = getStorageItem("token");
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '500px',
  width: '400px',
  // left: '0px',
  // top: '0px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '12px',
  p: 4,

};
const Run = (props) => {
  const [pageNum, setPageNum] = useState(1);
  let pSize = 7;
  let _url;
  if(props?.pitchId){
_url=global.config.ROOTURL.prod + `/pitch/run/getSpecificRunDetailsByPitchId/${props?.pitchId}/${props?.run}/${pageNum}/${pSize}`
  }
  if(props?.shareJobId){
    _url=global.config.ROOTURL.prod + `/job/sharejob/run/getSpecificRunDetailsByShareJobId/${props?.shareJobId}/${props?.run}/${pageNum}/${pSize}`
      }
  let req = {
    method: "GET",
    url: _url, 
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  }

  const { isLoading, error, list, hasMore } = pollVoterListScroller(pageNum, req);
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prev) => prev + 1);
          console.log("hhg")
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );


return (
    <div className="scrollable-container">
     { list.map((votedUsers, index) => (
        <UserCard
          key={index}
          votedUsers={votedUsers}
          index={index}
          lastElementRef={lastElementRef}
          defaultAvatar={defaultAvatar}
          type={props?.run}
        />
      ))}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px",
        }}
      >
        {isLoading && (
          <BallTriangle
            height="50"
            width="50"
            color="grey"
            ariaLabel="loading"
          />
        
        )}
          {!isLoading &&list?.length===0 && <div className="empty-comment">you are yet to receive {props?.run} Runs</div> }
      </div>
    </div>
)
  
        }
export default Run;