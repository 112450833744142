import React from 'react'
import batingpic from "../../assets/images/landigpage2/batingpic.png"
import SignUp from '../signin-signup/signup/signup.component'
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import logo from '../../assets/images/landigpage2/logo.svg'
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
export default function First() {
    const mobileView = useMediaQuery("(max-width:600px)");
    const navigate= useNavigate()
    return (
        <>
            <div className='blurr'>
                <div className={mobileView ?'navbarContainermobile':'navbarContainer'}>
                    <div className='flexbox' style={{ marginLeft: '5vw',height:98 }}>
                        <div style={{ width: '45vw' }} className="flexbox">
                            <div> <img src={logo} /></div>
                            <div className={mobileView ? 'navTitle font14' : 'navTitle font24'}><p>ChampHunt</p></div>
                        </div>
                        <div style={{ width: '45vw', textAlign: 'end' }}>
                            <Button size="small" style={{ backgroundColor: '#FFFFFF' }} className="font14"  onClick={()=>navigate('/login')}>Login</Button>
                        </div>
                    </div>
                    <div style={{ marginLeft: '5vw', paddingRight: '2.7vw' }} className= {mobileView ? 'flexbox2 firstMainContainer':'widthvh flexbox2 firstMainContainer'} >
                        <div >
                            <div className='pa34'>
                                <p style={{ color: '#202020' }} className={mobileView ? 'font24' : ' font64'}>Are you the next</p>
                                <p style={{ color: '#E7489D' }} className={mobileView ? 'font24' : ' font64'} >Dhoni, Pandya or Bumrah?</p>

                            </div>
                            <div className='pa34'>
                                <p className={mobileView?'font16':'font20'}>Here’s a chance to showcase your talent and take the next step towards your playing journey.</p>
                            </div>
                          

                            <div className='pa34'> <Button size="small" style={{ backgroundColor: '#0C5BA0', color: '#FFFFFF', height: 49, width: 141 }} className="font16" onClick={()=>navigate('/signup')} >Signup Now</Button></div>
                           
                        </div>
                        <Box sx={{ width: {xs:'90vw',md:'400px'} }}>
                            
                            <SignUp custClassName="landingPageSignUp" />
                           
                        </Box>
                    </div>


                </div>
            </div>
        </>
    )
}
