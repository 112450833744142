import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { X, UserPlus } from "lucide-react";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import "./RequestedInvites.scss";

const RequestedInvites = ({ open, handleClose }) => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [invites, setInvites] = useState([]);

  const fetchInvites = async () => {
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/groupchat/getFriendRequest/${userId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setInvites(response.data);
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchInvites();
    }
  }, [open]);

  const handleAcceptInvite = async (conversationId) => {
    try {
      await axios.put(
        `${global.config.ROOTURL.prod}/groupchat/setConversationStatusActive/${userId}/${conversationId}`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      fetchInvites();
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="requested-invites-modal"
      aria-describedby="list-of-friend-requests"
    >
      <Box className="requested-invites-modal">
        <div className="modal-header">
          <h2>Friend Requests</h2>
          <button className="close-button" onClick={handleClose}>
            <X />
          </button>
        </div>

        <div className="invites-list">
          {invites.length > 0 ? (
            invites.flatMap((invite) =>
              invite.conversations
                .filter((conversation) => conversation.status === "deactive")
                .map((conversation) => (
                  <div key={conversation._id} className="invite-item">
                    <img
                      src={
                        conversation.senderId.profilePhoto ||
                        "https://via.placeholder.com/50"
                      }
                      alt={`${conversation.senderId.firstName}'s profile`}
                      className="profile-photo"
                    />
                    <div className="invite-info">
                      <h3>{`${conversation.senderId.firstName} ${
                        conversation.senderId.lastName || ""
                      }`}</h3>
                      <button
                        className="accept-button"
                        onClick={() => handleAcceptInvite(conversation._id)}
                      >
                        <UserPlus size={16} />
                        Accept Request
                      </button>
                    </div>
                  </div>
                ))
            )
          ) : (
            <p className="no-invites">No pending friend requests</p>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default RequestedInvites;
