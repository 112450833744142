import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import pollimage from "../../assets/images/rewards/bars.png"
import tossball from "../../assets/images/rewards/tossball.png"
import axios from "axios";
import { getAuthToken } from '../../utils/auth';
import CreateChallenge from './CreateChallengeModal'
import './SingleChallenge.css'
// 

export default function SingleChallenge({ data, runs }) {

    const accessToken = getAuthToken();
    const userId = localStorage.getItem('user_id');


    const [createChallengeModal, setCreateChallengeModal] = useState(false);

    const {
        challengeQuestion,
        hasCreated,
        expired,
        comingSoon,
        endDate,
        endTime,
        rewardsDistributed,
        startDate,
        startTime,
        _id,
        startDateAndTimeDiff,
        endDateAndTimeDiff

    } = data
    const [insufficientRuns, setInsufficientRuns] = useState(false)
    const [createdAlready, setCreatedAlready] = useState(hasCreated ? hasCreated : false)
    const handleCreateChallenge = () => {
        if (runs <= 0) { toast.error("Insufficient runs") }
        else { setCreateChallengeModal(!createChallengeModal) }
    }


    return (
        <div key={_id} className="challenge__container">
            {/* <img src={pollimage} className="PollImageinPrediction" /> */}

            <div className="challenge__container1"><div style={{ color: "#1A1919", margin: "5px", fontWeight: "600" }}>Predict the winner!</div>
                <div style={{ margin: "5px" }}>{challengeQuestion}</div>
                <div className="win__text">WIN x2</div>
                {expired || data?.endChallenge ? <div style={{ margin: "5px" }}><Button className="accept__challenge" disabled={true}>
                    Challenge Ended
                </Button> </div> :
                    createdAlready ? <div style={{ margin: "5px" }}><Button className="accept__challenge" disabled={true}>
                        Accepted
                    </Button></div> :
                        //  comingSoon ? <div style={{ margin: "5px" }}><Button className="accept__challenge" disabled={true}>
                        //     Coming Soon
                        // </Button></div> :
                        <div style={{ margin: "5px" }}> <Button className="accept__challenge" onClick={() => { handleCreateChallenge() }} style={{ color: "#0C5BA0" }} >
                            Accept Challenge
                        </Button></div >
                }</div>
            {createChallengeModal && <CreateChallenge
                runs={runs} data={data}
                open={createChallengeModal}
                handleClose={handleCreateChallenge}
                setCreatedAlready={setCreatedAlready} />}
        </div >

    )
}
