import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getStorageItem } from "../../../utils/sessionStorage";
import { SocketContext } from "../../../context/SocketContext";
import { toast } from "react-toastify";
import rafiki from "../../../assets/images/book-cricket/rafiki.png";
import pana from "../../../assets/images/book-cricket/pana.png";

const RunsModal = ({ open, handleClose, isComputer }) => {
  const [socket, useSocket, isVerified, setIsVerified] =
    useContext(SocketContext);
  const userId = localStorage.getItem("user_id");
  const accessToken = getStorageItem("token");
  const navigate = useNavigate();
  const [totalRun, setTotalRuns] = useState(0);
  const [eligibilityPassed, setEligibilityPassed] = useState(true);
  const [loading, setLoading] = useState(false);
  const getTotalRuns = async () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");
    setLoading(true);
    try {
      const getTotalRun = {
        method: "GET",
        url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      return axios(getTotalRun)
        .then((response) => {
          setTotalRuns(response?.data);
          return response?.data;
        })
        .catch((error) => {
          console.log(
            `Some error occured while checking user eligibility to play, ${error}`
          );
          return 0;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSendToFriendList = async () => {
    // Check user runs eligibility
    const result = await getTotalRuns();
    if (result < 200) {
      // Set it back to 200 --important
      setEligibilityPassed(false);
      return;
    }
    // send a join request to socket
    setIsVerified(true);
    if (isComputer) {
      navigate("/games/book-cricket/play-with-computer");
    } else {
      navigate("/games/book-cricket/battle-list");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="box-style">
        {eligibilityPassed ? (
          <>
            <img className="bg-image" src={rafiki} alt="No Image" />
            <Typography
              className="bold-text-1"
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <span className="span-text1">200 Runs</span> to play
            </Typography>
            <Typography
              className="bold-text-2"
              id="modal-modal-description"
              sx={{ mt: 1 }}
            >
              Winner receives <span className="span-text2">2000 Runs</span>
            </Typography>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              marginTop={2}
            >
              <Button
                className="btnStyle"
                id="cancelBtn"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="btnStyle"
                variant="contained"
                onClick={handleSendToFriendList}
                loading={loading}
              >
                Play
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <img className="bg-image2" src={pana} alt="No Image" />
            <Typography
              className="bold-text-3"
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Insufficient Runs
            </Typography>
            <Typography
              className="bold-text-4"
              id="modal-modal-description"
              sx={{ mt: 1 }}
            >
              Need <span className="span-text3">{200 - totalRun} Runs</span>{" "}
              more to play
            </Typography>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              marginTop={2}
            >
              <Button
                className="btnStyle"
                variant="contained"
                onClick={() => {
                  setEligibilityPassed(true);
                  handleClose();
                }}
              >
                Back
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};
export default RunsModal;
