import React from "react";
import PaginationRounded from "../PaginationRounded";
import "./index.css";
import { useNavigate } from "react-router";

const Index = () => {
  const navigate = useNavigate();
  return (
    <div className="contest-admin-result">
      <div className="contest-admin-answers">
        <div className="contest-admin-answers-inner">
          <div className="contest-admin-answers-header">
            <h3 className="contest-admin-answers-header-text">Prediction 1</h3>
            <button className="contest-admin-answers-header-button">
              Delete
            </button>
          </div>
          <div className="contest-admin-answers-question">
            <h4 className="contest-admin-answers-question-number">Q1</h4>
            <p className="contest-admin-answers-question-main">
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="contest-admin-create-wrapper-forms">
            {[
              [...Array(4)].map((question, index) => {
                return (
                  <div className="contest-results-answer-inner">
                    <h4 className="contest-admin-answer-number" key={index}>
                      A {index + 1}
                    </h4>
                    <div className="contest-admin-answer-single-answer">
                      <div
                        className="contest-admin-answer-single-answer-inner"
                        style={{
                          width: "117px",
                          background: "#2586db",
                          borderRadius: "4px",
                          height: "38px",
                          padding: "8px",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <p className="results-question-text">{question}</p>
                      </div>
                    </div>
                    <p className="contest-result-percentage">20%</p>
                    <p
                      className="contest-result-show-list"
                      onClick={() => {
                        navigate(
                          "/admin/contest/results/list/:contestid/:listid"
                        );
                      }}
                    >
                      Show list
                    </p>
                  </div>
                );
              }),
            ]}
          </div>
          <div className="contest-create-pagination-rounded-wrapper">
            <PaginationRounded />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
